// Files exporting the whitelist to access the platform.

const whitelist_array = [
  "0x42bd0968c483cf9ce63784dae39d4de379679614",
  "0x13354193eeABe1Ef4b3f02daF4cD88958C1589EE",
  "0x665452eE2Ca8BD8b650847f7B522770a40c29248",
  "0x841d0ed434Ce4FDD62507d2dE0BB1A4e9Cf83dE5",
  "0x6f230188Bde33d8135646826fB75Db833161EE5F",
  "0x2C640234716974D147647CEDCA4F67685EaAF4E7",
  "0x6152Dc338eE80C8eC9004b33F9039d3b0A375DE7",
  "0xb14Fb518B1dd17bc03fc771C3EBe9440E67bb2f7",
  "0x8D35CEDeC6e33e8e7B488b5E64a7b318e15d5565",
  "0xdEB431Bcb9a6Ae5eCaB2098576eB65865BBb4904",
  "0x38235211027C1f3F27496918176d9cF783CfECBa",
  "0x4cECD7689243551F3A6B181Ba0523fa27918941E",
  "0xd45E222172e74ACE40815020d7a13FCc5761fBd2",
  "0x4E8E2AA6322fd8177782cD44fB322b30bB8f32B4",
  "0x31a6b9620DC7529034D1d4E97eBeBFD871cbB0B6",
  "0x42830b8CB5651d8252913F8bEbfC4D8290b41780",
  "0xF5F06a1aA1C6DF14443Be447ACd30478D39E5db7",
  "0x28F8492FEB86803199e0c07aA1a4c00D64EA2B6a",
  "0xE3906d5F4b4Ff780b32031a4f94EBcB5BD14B98C",
  "0x77dda2dC211df3ad9b5033180FB090A0200eb211",
  "0x54EF39d6D82686e335f4ff8B9e3306bbc5165fbD",
  "0x8360Fd78Ddca061836d132f71f8dCdf8F3E9c9dC",
  "0x64b4286281B03Af1E3c287E7d0Ad94A8BDa7d847",
  "0x7EFDfA3049818851627BcAEEE166E4996E5b6017",
];

module.exports = whitelist_array;
