import { Checkbox } from "@material-tailwind/react";
import React from "react";

class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedButton: 1,
      longChecked: true,
      shortChecked: true,
    };
  }

  handleLongChange = (event) => {
    this.setState({ longChecked: event.target.checked });
  };

  handleShortChange = (event) => {
    this.setState({ shortChecked: event.target.checked });
  };

  handleButtonClick = (buttonNumber) => {
    this.setState({ selectedButton: buttonNumber });
  };
  render() {
    return (
      <div className="">
        <div>
          <h1 className="gradient-text text-center text-2xl font-bold text-white poppins">Filters</h1>
        </div>
        <div className="space-y-2">
          <div className="inline-flex">
            <div>
              <button
                onChange={(event) => {
                  this.handleLongChange(event);
                  this.props.get_filters_checked();
                }}
                className={`bg-bbbglight hover:text-gray-600 text-gray-400 font-bold py-2 px-4 rounded-l`}
              >
                <input
                  checked={this.state.longChecked}
                  type="checkbox"
                  id="long"
                  name="long"
                  value="long"
                  className="aspect-square radiolong appearance-none bg-#17181d border-gray-400 rounded border-solid border-1 box-border w-4 h-4 hover:scale-110"
                />{" "}
                <label className="text-green-400" for="long">
                  Long
                </label>
              </button>
            </div>
            <div>
              <button
                onChange={(event) => {
                  this.handleShortChange(event);
                  this.props.get_filters_checked();
                }}
                className={`space-x-1 bg-bbbglight hover:text-gray-600 text-gray-400 font-bold py-2 px-4 rounded-r`}
              >
                <input
                  checked={this.state.shortChecked}
                  type="checkbox"
                  id="short"
                  name="short"
                  value="short"
                  className="aspect-square radioshort appearance-none bg-#17181d border-gray-400 rounded border-solid border-1 box-border w-4 h-4 hover:scale-110"
                />
                <label className="text-tomato" for="short">
                  Short
                </label>
              </button>
            </div>
            {/* <button onClick={() => this.props.filter_long_short("long")}>Long</button>
          <button onClick={() => this.props.filter_long_short("short")}>Short</button> */}
          </div>
          <div className="grid grid-cols-9">
            <div className="col-span-3"></div>
            <div className="bg-bbcard font-bold col-span-3 grid grid-cols-3">
              <button className={`hover:text-gray-700 border border-gray-500 rounded-l ${this.state.selectedButton === 1 ? 'gradient-text' : 'text-gray-500'}`}  onClick={() => this.handleButtonClick(1)}>System 1</button>
              <button className={`hover:text-gray-700 border border-gray-500 ${this.state.selectedButton === 2 ? 'gradient-text' : 'text-gray-500'}`}  onClick={() => this.handleButtonClick(2)}>System 2</button>
              <button className={`hover:text-gray-700 border border-gray-500 rounded-r ${this.state.selectedButton === 3 ? 'gradient-text' : 'text-gray-500'}`}  onClick={() => this.handleButtonClick(3)}>System 3</button>
            </div>
          </div>
          <div className="flex place-content-center items-center py-1">
            <div
              className={`${
                this.state.selectedButton === 1 ? "visible" : "hidden"
              }`}
            >
              <ul
                onClick={() => this.props.get_filters_checked()}
                id="filters-archive"
                className={`text-center bg-gray-800 items-center text-sm font-medium rounded-lg border-totalWhite sm:flex`}
              ></ul>
            </div>
            <div
              className={`${
                this.state.selectedButton === 2 ? "visible" : "hidden"
              }`}
            >
              <ul
                onClick={() => this.props.get_filters_checked()}
                id="filters-archive2"
                className={`text-center bg-gray-800 items-center text-sm font-medium rounded-lg border-totalWhite sm:flex`}
              ></ul>
            </div>
            <div
              className={`${
                this.state.selectedButton === 3 ? "visible" : "hidden"
              }`}
            >
              <ul
                onClick={() => this.props.get_filters_checked()}
                id="filters-archive3"
                className={`text-center bg-gray-800 items-center text-sm font-medium rounded-lg border-totalWhite sm:flex`}
              ></ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default React.memo(Filter);
