import React, { useEffect, useState } from "react";
import axios from "axios";
import Post from "./Post";
import { getUserAddress } from "../../config/connection_manager";
import CreatePost from "./CreatePost";
import Navbar from "../Navbar";
import { verify_wallet_address } from "../../config/wallet_signer";
import { connectWallet } from "../../config/connection_manager";

function Discover() {
  const [discoverFeed, setDiscoverFeed] = useState([]);
  const [walletAddress, setWalletAddress] = useState("");
  const [offset, setOffset] = useState(0);
  const [isFetching, setIsFetching] = useState(false);

  const handleDeletePost = (postId) => {
    setDiscoverFeed((discoverFeed) =>
      discoverFeed.filter((post) => post.id !== postId)
    );
  };

  useEffect(() => {
    const initialize = async () => {
      const addr = await getUserAddress();
      setWalletAddress(addr);
    };
    initialize();

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []); // Empty dependency array ensures this runs only on component mount and unmount.

  async function fetchMorePosts() {
    if (isFetching) return; // Prevent multiple simultaneous requests.
    setIsFetching(true);
    try {
      setOffset((prevOffset) => prevOffset + 40);
    } catch (error) {
      console.error("Error fetching more posts:", error);
    } finally {
      setIsFetching(false);
    }
  }

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 200 && // You can adjust the threshold as needed
      !isFetching
    ) {
      fetchMorePosts();
    }
  };

  useEffect(() => {
    console.log("Current Offset:", offset);
    try {
      setIsFetching(true);
      console.log(
        `https://latf-paper-trading-backend.herokuapp.com/posts/discover?walletaddress=${walletAddress}&offset=${offset}`
      );
      async function fetchPosts() {
        const response = await axios.get(
          `https://latf-paper-trading-backend.herokuapp.com/posts/discover?walletaddress=${walletAddress}&offset=${offset}`
        );

        // add the new posts to the end of the discoverFeed array, and remove duplicates by id
        console.log(response.data);
        setDiscoverFeed((prevPosts) =>
          removeDuplicatesByProperty([...prevPosts, ...response.data], "id")
        );
      }
      fetchPosts();
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      setIsFetching(false);
    }
  }, [walletAddress, offset]);

  function removeDuplicatesByProperty(array, propertyName) {
    const uniqueMap = new Map();
    return array.filter((item) => {
      if (!uniqueMap.has(item[propertyName])) {
        uniqueMap.set(item[propertyName], true);
        return true;
      }
      return false;
    });
  }

  const handlePostCreated = (newPost) => {
    console.log(newPost);
    setDiscoverFeed([newPost, ...discoverFeed]);
  };

  const connect = async () => {
    const [signerAddr, isOwner] = await verify_wallet_address(
      "Welcome to the LATF Paper Trading.\n\nSign this request to access your account.\n\nThis request will not trigger a blockchain transaction or cost any gas fees."
    );
    if (isOwner) {
      try {
        localStorage.setItem("isWalletConnected", true);
      } catch (ex) {
        console.log(ex);
      }
      try {
        connectWallet();
      } catch (err) {
        localStorage.setItem("isWalletConnected", false);
        document.getElementById("sign-up-popup").classList.remove("hidden");
      }
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <Navbar {...{ connect }} />

      <div className="container-bis">
        <div className="lg:mx-24">
          <CreatePost
            walletAddress={walletAddress}
            onPostCreated={handlePostCreated}
          />

          {discoverFeed.map((post) => (
            <Post
              key={post.id}
              post={post}
              walletAddress={walletAddress}
              onDelete={handleDeletePost}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Discover;

function debounce(func, wait) {
  let timeout;
  return function () {
    const context = this,
      args = arguments;
    const later = function () {
      timeout = null;
      func.apply(context, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}
