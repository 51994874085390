import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useRef } from "react";
import load_archives from "./load_data_chart";
import Web3 from "web3";
import { getAccount } from '@wagmi/core'
import { getUserAddress } from "../config/connection_manager";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  title: {
    display: true,
    text: "Balance",
    fontSize: 20,
  },
  legend: {
    display: true,
    position: "right",
  },
  interaction: {
    mode: "index",
    intersect: false,
  },
};

const labels = ["/", "/", "/"];

export const data = {
  labels,
  datasets: [
    {
      fill: true,
      label: "Balance",
      data: [2, 1, 1],
      borderColor: "#10424e",
      backgroundColor: "#134E5E",
    },
  ],
};

async function get_address(chartRef) {
  try {
    const walletaddress = await getUserAddress()
    load_archives(walletaddress, chartRef);
  }
  catch (e) {
    console.log(e);
  }
}
export default function DashGraph() {
  const chartRef = useRef();
  return (
    <Line
      id="canvas"
      options={options}
      data={data}
      ref={chartRef}
      onLoad={get_address(chartRef)}
    />
  );
}
