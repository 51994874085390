import React, { useState, useEffect, useRef } from "react";
import { getUserAddress } from "../../config/connection_manager";

const EditProfileOverlay = ({
  discordName,
  profilePicture,
  onNameChange,
  onBioChange,
  onClose,
  profileDescription,
}) => {
  const [newName, setNewName] = useState(discordName);
  const [newBio, setNewBio] = useState(profileDescription);
  const overlayRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (overlayRef.current && !overlayRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSave = async () => {
    // Call the onBioChange callback with the new bio
    document.getElementById("username").innerHTML = newName.slice(0, -5);
    document.getElementById("profile-description").innerHTML = newBio;
    handleClose();
    const addr = await getUserAddress();
    if (newName.includes("#")) {
      await fetch(
        `https://latf-paper-trading-backend.herokuapp.com/set/discordname?walletaddress=${addr}&discordname=${newName.replace(
          "#",
          "%23"
        )}`
      );
      await fetch(
        `https://latf-paper-trading-backend.herokuapp.com/profile/description/edit?description=${newBio}&walletaddress=${addr}`
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
        });
    } else {
      alert("Please enter a valid Discord name (Exemple: Username#9735).");
    }
  };

  const handleClose = () => {
    // Call the onClose callback to close the overlay
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75">
      <div
        ref={overlayRef}
        className="bg-white w-full max-w-md rounded shadow-lg"
      >
        <div className="flex justify-end">
          <div
            onClick={handleClose}
            className="bg-tomato rounded-bl-3xl h-10 w-10 flex items-center justify-center cursor-pointer"
            style={{}}
          >
            <i className="material-icons text-totalWhite text-2xl rotator-45">
              close
            </i>
          </div>
        </div>
        <div className="px-6">
          <div className="flex items-center bg-totalWhite bg-opacity-50 rounded-xl p-1">
            <div className="h-14 w-14 rounded-full overflow-hidden">
              <img
                src={profilePicture}
                alt="Profile Picture"
                className="h-full w-full object-cover"
              />
            </div>
            <div className="ml-4">
              <div className="text-lg font-semibold nordique">Discord Name</div>
              <div className="mt-1">
                <input
                  type="text"
                  placeholder="Enter new Discord name"
                  value={newName}
                  onChange={(e) => setNewName(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-r-full bg-gray-100 focus:ring"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="px-6 py-4">
          <div className="bg-totalWhite bg-opacity-50 rounded-xl py-3 px-2">
            <div className="mb-2 text-lg font-medium nordique">Bio</div>
            <textarea
              rows="3"
              placeholder="Enter new bio"
              value={newBio}
              onChange={(e) => setNewBio(e.target.value)}
              className="bg-gray-100 focus:ring w-full px-3 py-2 border border-gray-300 rounded focus:border-blue-500"
            ></textarea>
          </div>
        </div>
        <div className="px-6 py-3 flex justify-end">
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditProfileOverlay;
