import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const data = {
  labels: ["Top Up"],
  datasets: [
    {
      data: [50000],
      backgroundColor: ["#9ad491"],
      hoverBackgroundColor: ["#9ad491"],
      borderColor: ["#9ad491"], // <-- ajoutez cette ligne
      borderWidth: 2, // <-- ajoutez cette ligne
      borderRadius: 1,
    },
  ],
};

const options = {
  // responsive: false,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
};

export const DoughnutChartTopUp = () => {
  return (
    <div className="outer-div">
      <div className="">
        <Doughnut data={data} options={options} width="300" height="150" />
      </div>
      <div className="inner-div">
        <i className="material-icons text-gray-100">savings</i>
      </div>
    </div>
  );
};
