import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Navbar from "../Navbar";
import { set } from "animejs";

function BettingInterface() {
  const [traders, setTraders] = useState([]);
  const [selectedTrader, setSelectedTrader] = useState(null);
  const [amount, setAmount] = useState(0);
  const [message, setMessage] = useState("");
  const [endTime, setEndTime] = useState(null);
  const [durationSeconds, setDuration] = useState(0);
  const [numberTraders, setNumberTraders] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    // Fetch tournament end time once and store it
    const fetchTournamentDetails = async () => {
      try {
        const response = await axios.get(
          `https://latf-paper-trading-backend.herokuapp.com/tournament/details`
        );
        const unixTimestamp = parseInt(response.data.endTime, 10);
        const milliseconds = unixTimestamp * 1000;
        console.log(new Date(milliseconds));
        setEndTime(new Date(milliseconds));
        setDuration(response.data.duration);
      } catch (error) {
        console.error("Error fetching  tournament details:", error);
      }
    };
    fetchTournamentDetails();
  }, []);

  useEffect(() => {
    const fetchFeaturedTraders = async () => {
      try {
        const response = await axios.get(
          `https://latf-paper-trading-backend.herokuapp.com/leaderboard`
        );
        const reponseUsers = await axios.get(
          `https://latf-paper-trading-backend.herokuapp.com/users`
        );
        setTraders(response.data.slice(0, 10)); // Display top 10 traders for demo purposes
        setNumberTraders(reponseUsers.data.length);
      } catch (error) {
        console.error("Error fetching traders:", error);
      }
    };
    fetchFeaturedTraders();
  }, []);

  const intervalIdRef = useRef();

  useEffect(() => {
    const calculateDynamicQuote = () => {
      if (
        endTime &&
        traders.length > 0 &&
        durationSeconds > 0 &&
        numberTraders > 0
      ) {
        const now = new Date();
        const elapsedTime = (endTime - now) / 1000;
        const timeFactor = (durationSeconds - elapsedTime) / durationSeconds;
        if (elapsedTime < 0) {
          // If endTime has passed, display a message or handle it as needed.
          setTimeLeft("Tournament is finished.");
        } else {
          // Calculate the time left in the HH:mm:ss format.
          setTimeLeft(new Date(endTime - now).toISOString().substr(11, 8));
        }
        
        const totalBalance = traders.reduce((sum, trader) => sum + trader.totalbalance, 0);
        
        const updatedLeaderboard = traders.map((trader, idx) => {
          const rank = idx + 1; // Assuming traders are sorted by rank

          // Constants to adjust the influence of rank and time on the quote
          const BASE_QUOTE = 6; // Starting point for quote calculation
          const RANK_INFLUENCE = 3; // Adjust to control the influence of rank on the quote
          const TIME_INFLUENCE = 20; // Adjust to control the influence of time on the quote
          const traderBalance = trader.totalbalance;
          const traderBalancePercentage = traderBalance / totalBalance;
          console.log("total balance = " + totalBalance, "trader balance = " + traderBalance, "trader balance percentage = " + traderBalancePercentage);
          // Dynamic quote calculation considering base, rank, and time influences
          let dynamicQuote =
            BASE_QUOTE +
            rank / RANK_INFLUENCE +
            TIME_INFLUENCE * (1 - timeFactor) -
            100 * traderBalancePercentage;
          // Optional: Cap the dynamic quote to a maximum value
          if (dynamicQuote > 100) {
            dynamicQuote = 100;
          } else if (dynamicQuote < 1) {
            // Ensure the quote doesn't fall below a minimum value
            dynamicQuote = 1;
          }
          return {
            ...trader,
            dynamicQuote: dynamicQuote.toFixed(2),
          };
        });
        setTraders(updatedLeaderboard);
      }
    };
    

    if (!intervalIdRef.current) {
      intervalIdRef.current = setInterval(() => {
        calculateDynamicQuote();
      }, 500); // 10000ms = 10s
    }

    // Cleanup: clear the interval if the component is unmounted
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    };
  }, [endTime, traders, durationSeconds, numberTraders]);

  const handleSelectTrader = (trader) => {
    setSelectedTrader(trader);
  };

  const handleBet = async () => {
    if (selectedTrader) {
      // Implement the logic to place the bet.
      setMessage(
        `Successfully placed a bet of $${amount} on trader ${selectedTrader.discordname}`
      );
    } else {
      setMessage("Please select a trader first.");
    }
  };

  return (
    <>
      <Navbar />
      <div className="bg-gray-100 min-h-screen p-6">
        <h2 className="text-2xl font-bold mb-6 text-blue-600">
          Place Your Bets
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="col-span-2 space-y-4">
            {traders.map((trader, idx) => (
              <div
                key={idx}
                className={`p-4 rounded-lg shadow-md cursor-pointer transition-transform transform-gpu ${
                  selectedTrader === trader
                    ? "scale-105 bg-blue-100"
                    : "bg-white hover:bg-gray-50"
                }`}
                onClick={() => handleSelectTrader(trader)}
              >
                <h3 className="text-lg font-semibold text-blue-600">
                  {trader.discordname}
                </h3>
                <p className="text-gray-500">
                  Dynamic Quote:{" "}
                  <span className="font-bold text-gray-400">
                    {trader.dynamicQuote}
                  </span>
                </p>
                <p className="text-gray-500">
                  Rank:{" "}
                  <span className="font-bold text-gray-400">{idx + 1}</span>
                </p>
                <p className="text-gray-500">
                  Total Balance:{" "}
                  <span className="font-bold text-gray-400">{trader.totalbalance.toFixed(2)}</span>
                </p>
              </div>
            ))}
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-bold mb-4 text-blue-600">
              Betting Panel
            </h3>
            <p className="mb-4 text-gray-500">
              Tournament Duration:{" "}
              <span className="font-semibold text-gray-500">{convertSecondsToTime(durationSeconds)}</span>
            </p>
            <p className="mb-4 text-gray-500">
              Time left:{" "}
              <span className="font-semibold text-gray-500">{timeLeft}</span>
            </p>
            {selectedTrader ? (
              <>
                <p className="mb-4 text-gray-500">
                  Betting on:{" "}
                  <span className="font-semibold text-gray-500">
                    {selectedTrader.discordname}
                  </span>
                </p>
                <div className="mb-4">
                  <label className="block text-gray-600 font-semibold mb-2">
                    Amount:
                  </label>
                  <input
                    type="number"
                    className="w-full p-2 border rounded-md"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    placeholder="Enter amount"
                  />
                </div>
                <button
                  className="w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 focus:outline-none focus:bg-blue-700 transition-transform transform-gpu hover:scale-105"
                  onClick={handleBet}
                >
                  Place Bet
                </button>
                {message && <p className="mt-4 text-blue-600">{message}</p>}
              </>
            ) : (
              <p className="text-gray-500">
                Select a trader from the list to place a bet.
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default BettingInterface;

const convertSecondsToTime = (seconds) => {
  const days = Math.floor(seconds / (24 * 3600));
  seconds %= (24 * 3600);
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  seconds %= 60;
  return `${days.toString().padStart(2, '0')}D:${hours.toString().padStart(2, '0')}H:${minutes.toString().padStart(2, '0')}M`;
};