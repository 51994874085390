import { useState, useEffect, useRef } from "react";
import VanillaTilt from "vanilla-tilt";
import videoSource from "./videos/intro-video.mp4";

export default function VideoLanding() {
  const tiltRef = useRef(null);

  useEffect(() => {
    if (tiltRef.current) {
      VanillaTilt.init(tiltRef.current, {
        max: 5,
        speed: 50,
        glare: true,
        "max-glare": 0.3,
        reverse: true,
      });
    }
  }, [tiltRef]);

  return (
    <>
      <div ref={tiltRef} className="h-full w-full">
      <h1 className="nordique text-center">Enter the arena</h1>
        <div  className="rounded-3xl">
          <video controls width="100%">
            <source src={videoSource} type="video/mp4" />
            Sorry, your browser doesn't support embedded videos.
          </video>
          {/* <p>This component will tilt when you hover over it.</p> */}
        </div>
      </div>
    </>
  );
}
