import React, { useState } from "react";

function LeverageSlider(props) {
  // Get the leverage from local storage, or default to 1 if not set
  const storedLeverage = localStorage.getItem("leverage") || 1;
  const [leverage, setLeverage] = useState(storedLeverage);
  // set leverage to 1 if leverage isn't defined
  if (leverage === null) {
    setLeverage(1);
  }
  // Save leverage to local storage whenever it changes
  React.useEffect(() => {
    localStorage.setItem("leverage", leverage);
  }, [leverage]);
  return (
    <div className="relative w-full flex flex-col px-3 text-gray-300">
      <div className="flex justify-between items-center">
        <div className="text-lg font-semibold nordique gradient-text">Leverage</div>
        <div className="font-bold nordique text-lg text-totalWhite py-1 px-3 bg-black rounded-3xl">
          {leverage}x
        </div>
      </div>
      <input
        type="range"
        className="w-full cursor-pointer accent-logoColor"
        min={1}
        max={2}
        step={0.1}
        value={leverage}
        onChange={(e) => setLeverage(e.target.value)}
      />
    </div>
  );
}

export default LeverageSlider;
