import React from "react";
import NavbarLobby from "./NavbarLobby";
import { getUserAddress } from "../../config/connection_manager";
import { verify_wallet_address } from "../../config/wallet_signer";
import { connectWallet } from "../../config/connection_manager";

function Lobby() {
  return (
    <div className="h-screen inset-1 flex flex-col">
      <NavbarLobby {...{ connect }} />
      <div className="flex-grow relative flex items-center justify-center">
        <div className="bg-gray-700 p-4 sm:p-6 rounded-lg shadow-2xl w-full sm:w-96 space-y-2 relative h-44">
          <h1 className="text-2xl sm:text-3xl font-extrabold text-white mb-4 text-center">
            PvP Trading <span className="text-yellow">Lobby</span>
          </h1>
          <button
            className="w-full font-bold bg-gradient-to-r from-blue-500 to-purple text-totalWhite px-4 py-2 rounded transform transition-transform duration-200 hover:scale-105"
            onClick={start1v1Battle}
          >
            Start 1v1 Battle
          </button>
          <button
            className="right-2 absolute block font-bold bg-gradient-to-l from-blue-500 to-blue-600 text-totalWhite px-2 py-1 rounded transform transition-transform duration-200 hover:scale-105"
            onClick={rejoinLastBattle}
          >
            Rejoin last battle
          </button>
        </div>
      </div>
    </div>
  );
}

async function start1v1Battle() {
  try {
    const walletAddress = await getUserAddress();
    const response = await fetch(
      `https://latf-paper-trading-backend.herokuapp.com/battles/join/1v1?walletAddress=${walletAddress}`
    );
    const data = await response.json();
    console.log(data);

    const battleId = data.battleId;
    // redirect to the battle page if it's number or string number
    if (Number.isInteger(battleId) || /^\d+$/.test(battleId)) {
      window.location.href = `/pvp/battle/${battleId}`;
    } else {
      alert("Error: " + data.message);
    }
  } catch (error) {
    console.error("Error:", error);
  }
}

async function rejoinLastBattle() {
  // fetch from local storage battle id and send to /pvp/battle/:id if there is a battle if not alert
  const battleId = localStorage.getItem("battleId");
  if (battleId) {
    window.location.href = `/pvp/battle/${battleId}`;
  } else {
    alert("No battle found.");
  }
}

export default Lobby;

async function connect() {
  const [signerAddr, isOwner] = await verify_wallet_address(
    "Welcome to the LATF Paper Trading.\n\nSign this request to access your account.\n\nThis request will not trigger a blockchain transaction or cost any gas fees."
  );
  if (isOwner) {
    try {
      localStorage.setItem("isWalletConnected", true);
    } catch (ex) {
      console.log(ex);
    }
    try {
      connectWallet();
    } catch (err) {
      localStorage.setItem("isWalletConnected", false);
      document.getElementById("sign-up-popup").classList.remove("hidden");
    }
  }
}
