import React, { useState, useEffect } from "react";
import DropdownMenu from "../DropdownMenu";
import BugReport from "../BugReport";
import MetamaskModal from "../MetamaskModal";
import { useNavigate } from "react-router-dom";

import { connectWallet, getUserAddress } from "../../config/connection_manager";

const Navbar = ({ connect }) => {
  const navigate = useNavigate();
  const [showBugReport, setShowBugReport] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // on load, check if user is connected to metamask
  useEffect(() => {
    async function get_address() {
      if (localStorage.getItem("isWalletConnected") === "true") {
        try {
          const addr = await getUserAddress();
          const addr_display =
            addr.substring(0, 4) +
            "..." +
            addr.substring(addr.length - 4, addr.length);
          console.log("addr === ", addr);
          document.getElementById("connect-span").innerHTML = addr_display;
          document
            .getElementById("connect-btn")
            .classList.add("bg-color-animation");
          document
            .getElementById("connect-btn")
            .classList.add("hover:scale-95");
          document.getElementById("connect-btn").classList.remove("nordique");
        } catch (error) {
          console.error(error);
          localStorage.setItem("isWalletConnected", false);
          var button = document.getElementById("connect-btn");
          button.classList.add("show-bubbles");
          document.getElementById("connect-span").innerHTML = "Connect";
        }
      } else {
        document.getElementById("connect-span").innerHTML = "Connect";
        var button = document.getElementById("connect-btn");
        button.classList.add("show-bubbles");
      }
    }
    get_address();
  });

  function openModal() {
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
  }

  async function connect_metamask() {
    connectWallet();
  }

  return (
    <div className="bg-normalbg border-b-4 border-black">
      <div className="bg-yellow-300 text-totalWhite py-1 mb-1 text-center text-sm italic">
        Thanks for participating in the beta <span id="username-banner"></span>!
        Please report any bugs by clicking on the flag icon. We appreciate your
        feedback and will be pushing an update soon.
      </div>
      <meta charSet="utf-8" />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
      <title>LATF Paper Trading</title>
      <div className="h-fit">
        <nav className="navbar navbar-expand-lg navbar-dark bg-backgroundbase p-1">
          <div className="container-fluid bg-backgroundbase">
            <div
              className="flex flex-wrap items-center justify-between relative"
              id="example-navbar-danger"
            >
              <ul className="navbar-nav ms-auto flex flex-wrap">
                <li className="mr-4">
                  <button
                    className="bg-red-500 hover:bg-red-600 text-white px-3 py-2 rounded-full transition-colors duration-200"
                    onClick={() => navigate("/")}
                  >
                    ←
                  </button>
                </li>
                <li className="nav-item z-10 flex-grow">
                  <button
                    id="connect-btn"
                    className="bubbles-animation hover:bg-blue-600 px-4 py-2 bg-color-animation nordique text-semibold text-totalWhite rounded-full absolute left-1/2 transform -translate-x-1/2"
                    onClick={openModal}
                  >
                    <span id="connect-span" className="font-semibold">
                      <div role="status">
                        {" "}
                        <svg
                          aria-hidden="true"
                          class="w-6 h-6 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span className="sr-only">Loading...</span>
                      </div>
                    </span>
                  </button>

                  {showModal && (
                    <MetamaskModal
                      walletconnect_connect={connect}
                      connect={connect_metamask}
                      closeModal={closeModal}
                      connector={connect}
                    />
                  )}
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
