import { useState, useEffect } from "react";
import { Web3Button } from "@web3modal/react";
import { connectWallet } from "../config/connection_manager";

function MetamaskModal(props) {
  const [metamaskInstalled, setMetamaskInstalled] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      const modal = document.getElementById("metamask-modal");
      const connectButton = document.getElementById("connect-btn");
      if (
        modal &&
        !modal.contains(event.target) &&
        !connectButton.contains(event.target)
      ) {
        props.closeModal();
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [props]);

  // This function will handle the Metamask connection
  async function connectMetamask() {
    try {
      connectWallet();
      // Close the modal
      props.closeModal();
    } catch (error) {
      console.error(error);
    }
  }

  // This function will handle the Metamask installation
  function installMetamask() {
    window.open("https://metamask.io/download.html", "_blank");
  }

  // Check if Metamask is installed
  if (typeof window.ethereum === "undefined") {
    return (
      <div className="modal-overlay grid sm:grid-cols-1 md:grid-cols-3">
        <div
          className="modal-content-connect grid grid-cols-2 border-l border-black"
          id="metamask-modal"
        >
          <div>
            <h2 className="text-lg font-medium mb-4">Connect using Metamask</h2>
            <p className="text-gray-500 mb-4">
              Metamask is not installed. Click the button below to install
              Metamask.
            </p>
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-lg"
              onClick={installMetamask}
            >
              Install Metamask
            </button>
          </div>
          <div>
            <h2 className="text-lg font-medium mb-4">
              Wallet Connect
              <span className="block text-gray-600 italic text-sm">
                Compatible with Metamask Mobile only
              </span>
            </h2>
            <Web3Button onClick={props.walletconnect_connect} label="Wallet Connect" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="modal-overlay grid sm:grid-cols-1 md:grid-cols-3">
      <div></div>
      <div
        className="modal-content-connect grid grid-cols-2"
        id="metamask-modal"
      >
        <div>
          <h2 className="text-lg font-medium mb-4">Metamask</h2>
          <button
            className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-lg"
            onClick={connectMetamask}
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/800px-MetaMask_Fox.svg.png"
              alt="Metamask"
              className="inline-block h-6 mr-2"
            />
            Metamask
          </button>
        </div>
        <div className="border-l border-black">
          <h2 className="text-lg font-medium">
            Wallet Connect
            <span className="block text-gray-600 italic text-sm">
              Compatible with Metamask Mobile only
            </span>
          </h2>
          <Web3Button onClick={props.walletconnect_connect} label="Wallet Connect" />
        </div>
      </div>
      <div></div>
    </div>
  );
}

export default MetamaskModal;
