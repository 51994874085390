import React, { createRef, useEffect } from "react";
import { Link } from "react-router-dom"; // import { AdvancedChart } from "react-tradingview-embed";
import { ethers } from "ethers";
import Web3 from "web3";
import { v4 as uuidv4 } from "uuid";
import {
  load_positions,
  display_close_limit,
  display_close_market,
  close_partial_positon,
} from "../../config/position_load.js";
import Qtyslider from "./Qtyslider";
import QtysliderRiskMngt from "./QtysliderRiskMngt";
import TVchart from "./TVchart";
import RadioTPSL from "./RadioTPSL";
import Navbar from "../Navbar";
import { pairs } from "../pairs";
import CurrenciesDropdown from "./CurrenciesDropdown";
import { currencyPairs } from "./CurrenciesDropdown";
import ButtonLimitMarket from "./ButtonLimitMarket";
import UsersetMenu from "./UsersetMenu";
import LeverageSlider from "./LeverageSlider";
import whitelist_array from "../whitelist.js";
import refresh_pnl from "../../config/price_refresher.js";
import SignUp from "../SignUp";
import { getAccount } from "@wagmi/core";
import { verify_wallet_address } from "../../config/wallet_signer";
import { getUserAddress, connectWallet } from "../../config/connection_manager";
import DisplayPositionsOrders from "./DisplayPositionsOrders";

function close_modal() {
  document.getElementById("modal-close-position").style.display = "none";
}

async function cancel_order_manager(orderid, walletaddress, handleLoad) {
  await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/order/byid?orderid=${orderid}`
  )
    .then((response) => response.json())
    .then((data) => {
      const quantity = data[0].quantity;
      const orderprice = data[0].orderprice;
      cancel_order(orderid, walletaddress, quantity, orderprice, handleLoad);
    });
}

async function cancel_order(
  orderid,
  walletaddress,
  quantity,
  orderprice,
  handleLoad
) {
  // Check a way to query quantity and order price from DB to be sure
  await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/cancel/order?orderid=${orderid}&walletaddress=${walletaddress}&quantity=${quantity}&orderprice=${orderprice}`
  )
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      handleLoad();
      // window.location.reload();
    });
}

async function load_orders(walletaddress, handleLoad) {
  // Change walletaddress (uppercase) to see someone else orders.
  await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/orders/user?walletaddress=${walletaddress}`
  )
    .then((response) => response.json())
    .then((data) => {
      const fragment = document.createDocumentFragment(); // Create a document fragment to hold the new rows

      // Iterate over the data and construct the new rows in the document fragment
      data.forEach((order, index) => {
        let color = "tomato";
        if (order.side === "long") {
          color = "green";
        }

        const row = document.createElement("tr");
        row.className =
          "border-b bg-gray-800 border-gray-700 hover:bg-gray-700 hover:border-gray-800";

        const th = document.createElement("th");
        th.scope = "row";
        th.className = `py-1 px-3 font-medium ${color}-color whitespace-nowrap`;
        th.innerHTML = `
          <div class="font-medium">${order.contract}</div>
          <div class="text-xs text-gray-200">${order.leverage}x</div>
        `;
        row.appendChild(th);

        const tdQuantity = document.createElement("td");
        tdQuantity.className = "py-3 px-3";
        tdQuantity.textContent = order.quantity.toFixed(5);
        row.appendChild(tdQuantity);

        const tdOrderPrice = document.createElement("td");
        tdOrderPrice.className = "py-3 px-3";
        tdOrderPrice.textContent = order.orderprice;
        row.appendChild(tdOrderPrice);

        const tdTakeProfit = document.createElement("td");
        tdTakeProfit.className = "py-3 px-3";
        tdTakeProfit.id = `edit-order-tp-${index}`;
        tdTakeProfit.textContent = order.takeprofit || "";
        row.appendChild(tdTakeProfit);

        const tdStopLoss = document.createElement("td");
        tdStopLoss.className = "py-3 px-3";
        tdStopLoss.id = `edit-order-sl-${index}`;
        tdStopLoss.textContent = order.stoploss || "";
        row.appendChild(tdStopLoss);

        const tdOrderID = document.createElement("td");
        tdOrderID.className = "py-3 px-3";
        tdOrderID.textContent = order.orderid;
        row.appendChild(tdOrderID);

        const tdCancelButton = document.createElement("td");
        tdCancelButton.className = "py-3 px-3";
        tdCancelButton.id = `cancel-button-${index}`;
        row.appendChild(tdCancelButton);

        fragment.appendChild(row);

        const cancelButton = document.createElement("button");
        cancelButton.onclick = function () {
          cancel_order_manager(order.orderid, walletaddress, handleLoad);
        };
        cancelButton.id = `button-cancel-order-${index}`;
        cancelButton.className = "btn-close btn-close-white";
        cancelButton.value = order.orderid;
        tdCancelButton.appendChild(cancelButton);

        const tpButton = createEditButton(
          order.orderid,
          "tp",
          `edit-order-tp-${index}`
        );
        tdTakeProfit.appendChild(tpButton);

        const slButton = createEditButton(
          order.orderid,
          "sl",
          `edit-order-sl-${index}`
        );
        tdStopLoss.appendChild(slButton);
      });

      const table = document.getElementById("orders-table");
      clearTable(table); // Clear the contents of the table

      table.appendChild(fragment); // Append the document fragment to the table, updating the table in a single operation
      const ordersTable = document.getElementById("main-table-orders");
      if (ordersTable) {
        const n_orders = ordersTable.getElementsByTagName("tr").length - 1;
        document.getElementById("orders-count").innerHTML = n_orders;
      }
      // Perform any visual changes or animations here after the update is complete
    });
}

function clearTable(table) {
  while (table.firstChild) {
    table.removeChild(table.firstChild);
  }
}

function createEditButton(orderId, type, parentID) {
  const button = document.createElement("button");
  button.onclick = function () {
    console.log(`Clicked on button edit ${type}`, button.value);
    allow_edit_order(orderId, type, button.id, parentID);
  };
  button.value = orderId;
  button.id = `button-edit-order-${type}-${orderId}`;
  button.className =
    "p-0.5 bg-yellow rounded-xl hover:rounded-3xl hover:bg-yellowlight transition-all duration-300 text-white";
  button.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2"><path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" /></svg>`;
  return button;
}

async function allow_edit_order_price(orderid, buttonID, parentID) {
  document.getElementById(buttonID).remove();
  document.getElementById(parentID).textContent = "";
  const button = document.createElement("button");
  const input = document.createElement("input");
  input.className = "text-black text-center";
  button.className = "text-green-300";
  button.textContent = "✓";
  button.value = orderid;
  button.onclick = async function () {
    console.log(button.value, input.value);
    await fetch(
      `https://latf-paper-trading-backend.herokuapp.com/edit/order/price?orderid=${button.value}&orderprice=${input.value}`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data === 200) {
          document.getElementById(parentID).textContent = input.value;
          window.location.reload();
        }
      });
  };
  document.getElementById(parentID).appendChild(input);
  document.getElementById(parentID).appendChild(button);
}

async function allow_edit_order(orderid, tpSl, buttonID, parentID) {
  const button = document.createElement("button");
  const input = document.createElement("input");
  input.className = "text-black text-center";
  button.className = "text-green-300";
  button.textContent = "✓";
  button.value = orderid;

  let url = "";
  if (tpSl === "tp") {
    url = `https://latf-paper-trading-backend.herokuapp.com/edit/order/tp?orderid=${button.value}&takeprofit=`;
  } else {
    url = `https://latf-paper-trading-backend.herokuapp.com/edit/order/sl?orderid=${button.value}&stoploss=`;
  }
  button.onclick = async function () {
    input.style.display = "none";
    button.style.display = "none";
    await fetch(url + input.value)
      .then((response) => response.json())
      .then((data) => {
        if (data === 200) {
          document.getElementById(parentID).textContent = input.value;
          window.location.reload();
        }
      });
  };
  document.getElementById(parentID).textContent = "";
  document.getElementById(parentID).appendChild(button);
  document.getElementById(parentID).appendChild(input);
}

async function fetch_new_prices(walletaddress) {
  const proxyURL = "https://latf-proxy.herokuapp.com/";
  await fetch(
    `${proxyURL}https://api.binance.com/api/v3/ticker/price?symbols=${pairs}`
  )
    .then((response) => response.json())
    .then((data) => {
      // console.log("using proxy here");
      var dict_current_prices = {};
      let i = 0;
      while (i < data.length) {
        try {
          dict_current_prices[`${data[i].symbol}`] = data[i].price;
          localStorage.setItem(data[i].symbol, data[i].price);
          var price = data[i].price;
          // console.log(price, price.length);
          if (parseFloat(price) > 1) {
            price = parseFloat(price).toFixed(2);
          } else {
            price = parseFloat(price).toFixed(5);
          }
          document.getElementById(data[i].symbol).textContent = `${price}`;
        } catch (err) {
          console.log(err, data[i].symbol, "Add the pair to the dropdown");
        }
        i++;
      }
      // console.log(dict_current_prices);
      refresh_pnl(walletaddress, dict_current_prices);
    });
}

async function position_manager(walletaddress, list_contracts, handleLoad) {
  const proxyURL = "https://latf-proxy.herokuapp.com/";
  await fetch(
    `${proxyURL}https://api.binance.com/api/v3/ticker/price?symbols=${list_contracts}`
  )
    .then((response) => response.json())
    .then((data) => {
      console.log("using proxy here");
      var dict_current_prices = {};
      let i = 0;
      while (i < data.length) {
        try {
          dict_current_prices[`${data[i].symbol}`] = data[i].price;
          localStorage.setItem(data[i].symbol, data[i].price);
          var price = data[i].price;
          // console.log(price, price.length);
          if (parseFloat(price) > 1) {
            price = parseFloat(price).toFixed(2);
          } else {
            price = parseFloat(price).toFixed(5);
          }
          document.getElementById(data[i].symbol).textContent = `${price}`;
        } catch (err) {
          console.log(err, data[i].symbol, "Add the pair to the dropdown");
        }
        i++;
      }
      console.log(dict_current_prices);
      load_positions(walletaddress, dict_current_prices, handleLoad);
    });
}

async function load_dropdown_prices(list_contracts) {
  const proxyURL = "https://latf-proxy.herokuapp.com/";
  await fetch(
    `${proxyURL}https://api.binance.com/api/v3/ticker/price?symbols=${list_contracts}`
  )
    .then((response) => response.json())
    .then((data) => {
      console.log("using proxy here");
      var dict_current_prices = {};
      let i = 0;
      while (i < data.length) {
        try {
          dict_current_prices[`${data[i].symbol}`] = data[i].price;
          localStorage.setItem(data[i].symbol, data[i].price);
          var price = data[i].price;
          // console.log(price, price.length);
          if (parseFloat(price) > 1) {
            price = parseFloat(price).toFixed(2);
          } else {
            price = parseFloat(price).toFixed(5);
          }
          document.getElementById(data[i].symbol).textContent = `${price}`;
        } catch (err) {
          console.log(err, data[i].symbol, "Add the pair to the dropdown");
        }
        i++;
      }
    });
}

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.handleLoad = this.handleLoad.bind(this);
    this.handler = this.handler.bind(this);
    this.handleSymbolSelect = this.handleSymbolSelect.bind(this);
    this.state = {
      selectedSymbol: localStorage.getItem("selectedSymbol") || "BTCUSDT",
    };
  }
  
  handleSymbolSelect(symbol) {
    this.setState({ selectedSymbol: symbol });
  }

  handler() {
    console.log("length = ", this.search.value.length);
    if (this.tpPrice.value.length === 0) {
      this.setState({
        recapTP: "",
        recapTPmarket: "",
      });
    } else if (document.getElementById("radio-short").checked) {
      this.setState({
        recapTP: -(
          (this.tpPrice.value - this.search.value) *
          this.search_bis.value
        ).toFixed(2),
        recapTPmarket: -(
          (this.tpPrice.value -
            parseFloat(localStorage.getItem(geturl_symbol()))) *
          this.search_bis.value
        ).toFixed(2),
      });
    } else {
      this.setState({
        recapTP: (
          (this.tpPrice.value - this.search.value) *
          this.search_bis.value
        ).toFixed(2),
        recapTPmarket: (
          (this.tpPrice.value -
            parseFloat(localStorage.getItem(geturl_symbol()))) *
          this.search_bis.value
        ).toFixed(2),
      });
    }
    if (this.slPrice.value.length === 0) {
      this.setState({
        recapSL: "",
        recapSLmarket: "",
      });
    } else if (document.getElementById("radio-short").checked) {
      this.setState({
        recapSL: -(
          (this.slPrice.value - this.search.value) *
          this.search_bis.value
        ).toFixed(2),
        recapSLmarket: -(
          (this.slPrice.value -
            parseFloat(localStorage.getItem(geturl_symbol()))) *
          this.search_bis.value
        ).toFixed(2),
      });
    } else {
      this.setState({
        recapSL: (
          (this.slPrice.value - this.search.value) *
          this.search_bis.value
        ).toFixed(2),
        recapSLmarket: (
          (this.slPrice.value -
            parseFloat(localStorage.getItem(geturl_symbol()))) *
          this.search_bis.value
        ).toFixed(2),
      });
    }
    if (this.search.value.length === 0) {
      this.setState({
        recapTP: "",
        recapSL: "",
      });
    }
  }

  toggleDropdown = (e) => {
    this.setState((prevState) => ({
      dropdownVisible: !prevState.dropdownVisible,
    }));
  };

  componentDidMount() {
    this.handleLoad();
    this.interval = setInterval(this.handleLoad, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleLoad = async (e) => {
    document.getElementById("symbol-selected").textContent = geturl_symbol();
    document.getElementById("symbol-selected-img").src = currencyPairs.find(
      (pair) => pair.symbol === geturl_symbol()
    ).imgSrc;
    load_dropdown_prices(pairs);
    if (localStorage?.getItem("isWalletConnected") === "true") {
      const addr = await getUserAddress();
      console.log("addr", addr);
      try {
        await fetch(
          `https://latf-paper-trading-backend.herokuapp.com/balance?walletaddress=${addr}`
        )
          .then((response) => response.json())
          .then((data) => {
            console.log(
              "loading handle home",
              data,
              data.discordname,
              data.balance
            );
            // To remove once openbeta banner is removed
            try {
              document.getElementById("username-banner").textContent =
                data.discordname.slice(0, -5);
            } catch {
              console.log("username-banner not found");
            }
            const balance_object = document.getElementById("balance");
            balance_object.textContent = data.balance.toFixed(2);
            document.getElementById("balance-drop").textContent =
              data.balance.toFixed(2);
            localStorage.setItem("isWalletConnected", true);
            localStorage.setItem("walletAddress", addr);
            localStorage.setItem("balance", data.balance);
            load_orders(addr.toString().toUpperCase(), this.handleLoad);
            position_manager(
              addr.toString().toUpperCase(),
              pairs,
              this.handleLoad
            );
            risk_management_tool(addr);
            // make a function that is called every 5 seconds that call fetch_new_prices
            // setInterval(() => {
            fetch_new_prices(addr);
            getDynamicBalanceBis(addr);
            // }, 5000);
          });
      } catch (error) {
        document.getElementById("sign-up-popup").classList.remove("hidden");
      }
    } else {
      try {
        console.log("HERE", localStorage.getItem("wagmi.connected") === "true");
        const isConnected = localStorage.getItem("wagmi.connected") === "true";
        if (isConnected) {
          setTimeout(() => {
            connect();
            console.log("x");
          }, 2000);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  state = {
    query: "BINANCE:BTCUSDT",
    searchSymbol: "BTC/USDT",
    ordervalue: 0,
    ordervalueMarket: 0,
    closeQuantityMarket: 0,
    closeQuantityLimit: 0,
    closePriceLimit: 0,
    navbarOpen: 0,
    setNavbarOpen: 0,
    slPrice: "",
    tpPrice: "",
  };

  handleInputChange = () => {
    document.getElementById("limit-order-value1").style.display = "block";
    document.getElementById("limit-order-value2").style.display = "none";
    document.getElementById("market-order-value1").style.display = "block";
    document.getElementById("market-order-value2").style.display = "none";
    this.setState({
      ordervalueMarket: (
        parseFloat(localStorage.getItem(geturl_symbol())) *
        this.search_bis.value *
        1.01
      ).toFixed(2),
      ordervalue: (this.search.value * this.search_bis.value).toFixed(2),
    });
    if (this.tpPrice.value.length === 0) {
      this.setState({
        recapTP: "",
        recapTPmarket: "",
      });
    } else if (document.getElementById("radio-short").checked) {
      this.setState({
        recapTP: -(
          (this.tpPrice.value - this.search.value) *
          this.search_bis.value
        ).toFixed(2),
        recapTPmarket: -(
          (this.tpPrice.value -
            parseFloat(localStorage.getItem(geturl_symbol()))) *
          this.search_bis.value
        ).toFixed(2),
      });
    } else {
      this.setState({
        recapTP: (
          (this.tpPrice.value - this.search.value) *
          this.search_bis.value
        ).toFixed(2),
        recapTPmarket: (
          (this.tpPrice.value -
            parseFloat(localStorage.getItem(geturl_symbol()))) *
          this.search_bis.value
        ).toFixed(2),
      });
    }
    if (this.slPrice.value.length === 0) {
      this.setState({
        recapSL: "",
        recapSLmarket: "",
      });
    } else if (document.getElementById("radio-short").checked) {
      this.setState({
        recapSL: -(
          (this.slPrice.value - this.search.value) *
          this.search_bis.value
        ).toFixed(2),
        recapSLmarket: -(
          (this.slPrice.value -
            parseFloat(localStorage.getItem(geturl_symbol()))) *
          this.search_bis.value
        ).toFixed(2),
      });
    } else {
      this.setState({
        recapSL: (
          (this.slPrice.value - this.search.value) *
          this.search_bis.value
        ).toFixed(2),
        recapSLmarket: (
          (this.slPrice.value -
            parseFloat(localStorage.getItem(geturl_symbol()))) *
          this.search_bis.value
        ).toFixed(2),
      });
    }
    if (this.search.value.length === 0) {
      this.setState({
        recapTP: "",
        recapSL: "",
      });
    }
  };

  handleModalQuantityChangeMarket = () => {
    this.setState({
      closeQuantityMarket: this.search_quantity.value,
    });
  };
  handleModalQuantityChangeLimit = () => {
    this.setState({
      closeQuantityLimit: this.search_quantityLimit.value,
    });
  };
  handleModalPriceChangeLimit = () => {
    this.setState({
      closePriceLimit: this.search_PriceLimit.value,
    });
  };

  handleSymbolChange = () => {
    const input = this.searchSymbol.value;
    // Get all of the table rows
    const rows = document.querySelectorAll("tbody tr");

    // Loop through each row
    rows.forEach((row) => {
      // Get the first column of the row (where the symbol is)
      const symbolCell = row.querySelector("td:nth-child(1)");

      // If the symbol contains the user's input, show the row,
      // otherwise, hide it
      try {
        if (
          symbolCell.textContent.toLowerCase().includes(input.toLowerCase())
        ) {
          row.style.display = "table-row";
        } else {
          row.style.display = "none";
        }
      } catch {}
    });
  };

  render() {
    const { query } = this.state;
    const { ordervalue } = this.state;
    const { ordervalueMarket } = this.state;
    const { recapTP } = this.state;
    const { recapTPmarket } = this.state;
    const { recapSL } = this.state;
    const { recapSLmarket } = this.state;
    const { tpPrice } = this.state;
    const { slPrice } = this.state;
    const { closeQuantityMarket } = this.state;
    const { closeQuantityLimit } = this.state;
    const { closePriceLimit } = this.state;
    return (
      <div className="h-full bg-bbbglight">
        <Navbar {...{ connect }} />
        <SignUp {...{ document }} />
        <div className="box">
          <div
            id="modal-close-position"
            className="content rounded-xl hidden text-center grid grid-rows-6 gap-20 p-1"
            style={{
              backgroundImage: 'url("../../images/backgrounddetails.png")',
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
          >
            <div className="grid place-items-center place-content-center gap-4">
              <div className="flex row-span-1">
                <button
                  onClick={close_modal}
                  className="btn-close btn-close-white absolute top-0 right-0 rotator-45"
                ></button>
              </div>
              <div className="row-span-1">
                <h2 class="text-center text-totalWhite text-3xl font-bold mb-4">
                  Closing <span class="text-yellow">Position</span>
                </h2>
                <div className="row-span-1 grid grid-cols-2 place-content-stretch">
                  <div className="inline-flex">
                    <button
                      onClick={display_close_market}
                      id="btn-close-market"
                      className="w-full hover:bg-tvcolor text-yellow font-semibold hover:text-totalWhite hover:border-transparent font-bold py-2 px-4 rounded-l bg-gray-700"
                    >
                      Market
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={display_close_limit}
                      id="btn-close-limit"
                      className="w-full hover:bg-tvcolor text-gray-500 font-semibold hover:text-gray-600 hover:border-transparent font-bold py-2 px-4 rounded-r bg-bbbglight"
                    >
                      Limit
                    </button>
                  </div>
                </div>
                <div className="row-span-1">
                  <p id="store_order_id" className="text-sm italic">
                    {" "}
                    Order ID:
                  </p>
                  <p id="store_quantity" className="text-sm italic"></p>
                </div>
                <div id="limit-close" className="hidden row-span-1">
                  <form className="w-full max-w-lg">
                    <div className="flex flex-wrap -mx-3 mb-6">
                      <div
                        id="div-close-limit-price"
                        className="w-full md:w-1/2 px-3"
                      >
                        <label
                          className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2"
                          htmlFor="grid-first-name"
                        >
                          Closing Price
                        </label>
                        <input
                          id="close-limit-price"
                          className="text-center appearance-none block w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          placeholder=""
                          ref={(input_bis) =>
                            (this.search_PriceLimit = input_bis)
                          }
                          onChange={this.handleModalPriceChangeLimit}
                        />
                      </div>
                      <div className="w-full md:w-1/2 px-3">
                        <label
                          className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Closing Qty
                        </label>
                        <input
                          id="close-limit-qty"
                          className="text-center appearance-none block w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                          placeholder=""
                          ref={(input_bis) =>
                            (this.search_quantityLimit = input_bis)
                          }
                          onChange={this.handleModalQuantityChangeLimit}
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div id="market-close" className="row-span-1">
                  <form className="w-full max-w-lg">
                    <div className="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full px-3">
                        <label
                          className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Closing Qty
                        </label>
                        <input
                          id="close-market-qty"
                          className="text-center appearance-none block w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                          placeholder=""
                          ref={(input) => (this.search_quantity = input)}
                          onChange={this.handleModalQuantityChangeMarket}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="flex place-content-center">
              <div
                id="modal-text-market"
                className="text-center p-6 max-w-sm bg-tvcolor rounded-lg border border-gray-200 shadow-md mb-1"
              >
                {closeQuantityMarket ? (
                  <p className="mb-3 font-normal text-totalWhite text-totalWhite">
                    {closeQuantityMarket} contract(s) will be closed at the
                    current price.
                  </p>
                ) : (
                  "Selected quantity contracts will be closed at the current price."
                )}
              </div>
              <div
                id="modal-text-limit"
                className="text-center p-6 max-w-sm bg-tvcolor rounded-lg border border-gray-200 shadow-md mb-1 hidden"
              >
                {closeQuantityLimit ? (
                  <p className="mb-3 font-normal text-totalWhite text-totalWhite">
                    {closeQuantityLimit} contract(s)&nbsp;
                  </p>
                ) : (
                  "Selected quantity contracts(s) "
                )}
                {closePriceLimit ? (
                  <p className="mb-3 font-normal text-totalWhite text-totalWhite">
                    will be closed at ${closePriceLimit}.
                  </p>
                ) : (
                  "will be closed at $..."
                )}
              </div>
            </div>
            <div className="row-span-1">
              <button
                onClick={() => {
                  close_partial_positon(this.handleLoad);
                  document.getElementById(
                    "modal-close-position"
                  ).style.display = "none";
                }}
                className="text-totalWhite font-semibold rounded-md hover:scale-95 px-2 py-1 bg-gray-700"
              >
                Submit
              </button>
            </div>
          </div>
          <div
            id="column tv-chart"
            className="column border-black"
            style={{ backgroundColor: "#17181f" }}
          >
            <div className="grid grid-cols-1 divide-y">
              <div></div>
              <div className="charty border-none">
                <form>
                  <div className="inner">
                    <div className="div-hover full-height z-20 bg-tvcolor text-totalWhite text-center hover:bg-gray-800">
                      <div className="flex items-center place-content-center space-x-1 py-1">
                        <img
                          id="symbol-selected-img"
                          className="rounded-full h-5"
                        ></img>
                        <span
                          id="symbol-selected"
                          className="text-lg font-semibold"
                        ></span>
                      </div>
                      <div className="div-hovered w-96 h-80 bg-bbbglight hidden grid grid-rows-10 overflow-y-auto overflow-none">
                        <div className="row-span-2 place-items-center">
                          {/* Create a input bar */}
                          <div className="h-1/6">
                            <input
                              id="search-symbol"
                              className="w-4/5 h-3/5 mt-3 text-center bg-gray-200 text-black border border-gray-200 rounded py-1.5 px-1 focus:outline-none focus:bg-white focus:border-gray-500"
                              placeholder="Search Symbol"
                              ref={(input) => (this.searchSymbol = input)}
                              onChange={this.handleSymbolChange}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="row-span-8 -mt-2">
                          <table className="table-auto text-totalWhite w-full text-center border-collapse border-0 text-sm">
                            <CurrenciesDropdown
                              onSymbolSelect={this.handleSymbolSelect}
                            />
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div id="container-tvchart" className="">
              <TVchart className="" symbol={this.state.selectedSymbol} />
            </div>
          </div>

          <div
            className="float-right column2 text-center border-l-4 border-black overflow-auto py-1 space-y-1"
            style={{ backgroundColor: "#17181f" }}
          >
            <a onClick={this.handleInputChange}>
              <LeverageSlider></LeverageSlider>
            </a>
            <ButtonLimitMarket {...{ limit_order, market_order }} />
            <div className="space-y-3">
              <div className="text-totalWhite">
                <form className="">
                  <div className="flex flex-wrap mb-2 px-2">
                    <div id="div-order-price" className="w-full md:w-1/2 px-3">
                      <label
                        className="block uppercase tracking-wide text-totalWhite text-xs font-bold mb-2"
                        htmlFor="grid-first-name"
                      >
                        Order Price
                      </label>
                      <input
                        ref={(input) => (this.search = input)}
                        onChange={this.handleInputChange}
                        id="order-price"
                        className="text-center appearance-none block w-full bg-gray-200 text-black border border-gray-200 rounded py-1 px-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        placeholder=""
                        autoComplete="off"
                      ></input>
                    </div>
                    <div className="w-full md:w-1/2 px-3 grid justify-items-center">
                      <div>
                        <label
                          className="block uppercase tracking-wide text-totalWhite text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Order Qty
                        </label>
                        <input
                          ref={(input) => (this.search_bis = input)}
                          onChange={this.handleInputChange}
                          id="order-qty"
                          className="text-center appearance-none block w-full bg-gray-200 text-black border border-gray-200 rounded py-1 px-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                          placeholder=""
                          autoComplete="off"
                        ></input>
                      </div>
                      <div className="w-2/3">
                        <a onClick={this.handler}>
                          <Qtyslider></Qtyslider>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="flex place-content-center space-x-2 -mb-6">
                    <RadioTPSL handler={this.handler} />
                  </div>
                  <div
                    id="div-tpsl"
                    className="flex flex-wrap mb-2 px-2 hidden"
                  >
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label
                        className="block uppercase tracking-wide text-totalWhite text-xs font-bold mb-2"
                        htmlFor="grid-first-name"
                      >
                        Take Profit
                      </label>
                      <input
                        ref={(input1) => (this.tpPrice = input1)}
                        onChange={this.handleInputChange}
                        id="take-profit"
                        className="text-center appearance-none block w-full bg-gray-200 text-black border rounded py-1 px-1 leading-tight focus:outline-none focus:bg-white"
                        placeholder=""
                        autoComplete="off"
                      ></input>
                      {recapTP ? (
                        <p id="recapTP" className="text-gray-400">
                          {recapTP}
                        </p>
                      ) : (
                        <p id="recapTP" className="text-gray-400"></p>
                      )}
                      {recapTPmarket ? (
                        <p id="recapTPmarket" className="text-gray-400 hidden">
                          {recapTPmarket}
                        </p>
                      ) : (
                        <p
                          id="recapTPmarket"
                          className="text-gray-400 hidden"
                        ></p>
                      )}
                    </div>
                    <div className="w-full md:w-1/2 px-3 grid justify-items-stretch">
                      <label
                        className="block uppercase tracking-wide text-totalWhite text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Stop Loss
                      </label>
                      <input
                        ref={(input2) => (this.slPrice = input2)}
                        onChange={this.handleInputChange}
                        id="stop-loss"
                        className="text-center appearance-none block w-full bg-gray-200 text-black border rounded py-1 px-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type=""
                        placeholder=""
                        autoComplete="off"
                      ></input>
                      {recapSL ? (
                        <p id="recapSL" className="text-gray-400">
                          {recapSL}
                        </p>
                      ) : (
                        <p id="recapSL" className="text-gray-400"></p>
                      )}
                      {recapSLmarket ? (
                        <p id="recapSLmarket" className="text-gray-400 hidden">
                          {recapSLmarket}
                        </p>
                      ) : (
                        <p
                          id="recapSLmarket"
                          className="text-gray-400 hidden"
                        ></p>
                      )}
                      <div
                        id="risk-management-mode"
                        className="text-sm text-gray-400 italic hidden"
                      >
                        <label>
                          <input
                            defaultChecked=""
                            id="risk-management-checkbox"
                            type="checkbox"
                            onClick={risk_management}
                          />
                          &nbsp;Risk Management
                        </label>
                      </div>
                      <div
                        className="w-3/4 justify-self-center hidden"
                        id="risk-management-slider"
                      >
                        <a onClick={this.handler}>
                          <QtysliderRiskMngt></QtysliderRiskMngt>
                        </a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="grid gap-1 flex place-items-center">
                <div id="div-order-value">
                  <div className="text-totalWhite flex relative inline-block w-full justify-center">
                    {ordervalue ? (
                      <span id="limit-order-value1" className="text-totalWhite">
                        Order cost:{" "}
                        {(
                          ordervalue / localStorage.getItem("leverage")
                        ).toFixed(2)}
                        $
                      </span>
                    ) : (
                      <span id="limit-order-value1" className="text-totalWhite">
                        Order cost: 0.00$
                      </span>
                    )}
                    <span
                      id="limit-order-value2"
                      className="text-totalWhite"
                    ></span>
                    <img
                      className="w-6 h-6 ml-1 rounded-full"
                      src="./images/coins.png"
                    ></img>
                  </div>
                </div>
                <div id="div-market-value" className="hidden">
                  <div className="text-totalWhite flex relative inline-block w-full justify-center">
                    {ordervalueMarket ? (
                      <span
                        id="market-order-value1"
                        className="text-totalWhite"
                      >
                        Order cost:{" "}
                        {(
                          ordervalueMarket / localStorage.getItem("leverage")
                        ).toFixed(2)}
                        $
                      </span>
                    ) : (
                      <span
                        id="market-order-value1"
                        className="text-totalWhite"
                      >
                        Order cost: 0.00$
                      </span>
                    )}
                    <span
                      id="market-order-value2"
                      className="text-totalWhite"
                    ></span>
                    <img
                      className="w-6 h-6 ml-1 rounded-full"
                      src="./images/coins.png"
                    ></img>
                  </div>
                </div>

                <div className="flex justify-center w-full">
                  <details>
                    <summary className="text-blue-400 hover:text-blue-500 py-2 px-2">
                      Advanced
                    </summary>
                    <UsersetMenu />
                    <div className="flex justify-around space-x-2">
                      <div>
                        <label className="text-sm font-medium text-gray-300">
                          Note:
                        </label>
                      </div>
                      <div>
                        <input id="note" className="rounded-r-xl"></input>
                      </div>
                    </div>
                  </details>
                </div>
                <div>
                  <div id="btn-limit" className="space-x-0.5">
                    <button
                      onClick={() => {
                        open_limit_long(this.handleLoad);
                      }}
                      className="bg-normalGreen hover:scale-95 text-white font-bold py-2 px-3 rounded-l-full text-base"
                      type="button"
                    >
                      Open Long
                    </button>
                    <button
                      onClick={() => {
                        open_limit_short(this.handleLoad);
                      }}
                      className="bg-tomato hover:scale-95 text-white font-bold py-2 px-3 rounded-r-full text-base"
                      type="button"
                    >
                      Open Short
                    </button>
                  </div>
                  <div id="btn-market" className="hidden space-x-0.5">
                    <button
                      onClick={() => {
                        open_market_long(this.handleLoad);
                      }}
                      className="bg-normalGreen hover:scale-95 text-white font-bold py-2 px-3 rounded-l-full text-base"
                      type="button"
                    >
                      Open Long
                    </button>
                    <button
                      onClick={() => {
                        open_market_short(this.handleLoad);
                      }}
                      className="bg-tomato hover:scale-95 text-white font-bold py-2 px-3 rounded-r-full text-base"
                      type="button"
                    >
                      Open Short
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row2 mb-4 bg-tvcolor grid grid-cols-8 float-left border-black">
            <DisplayPositionsOrders
              {...{ display_positions, document, display_orders }}
            />
            <div className="col-span-6 lg:col-span-7">
              <div
                id="table-positions"
                className="h-full border-totalWhite text-xl text-totalWhite"
              >
                <div className="relative bg-tvcolor rounded-bl-xl">
                  <div className="overflow-x-auto">
                    <table
                      id="main-table-positions"
                      className="w-full text-sm text-left text-gray-500 text-gray-400"
                    >
                      <thead className="text-center text-xs uppercase bg-gray-700 text-gray-400 sticky top-0">
                        <tr>
                          <th scope="col" className="py-1 px-2">
                            Contract
                          </th>
                          <th scope="col" className="py-1 px-2">
                            Qty
                          </th>
                          <th scope="col" className="py-1 px-2">
                            Order Value
                          </th>
                          <th scope="col" className="py-1 px-2">
                            Entry Price
                          </th>
                          <th scope="col" className="py-1 px-2">
                            Current Price
                          </th>
                          <th scope="col" className="py-1 px-2">
                            TP
                          </th>
                          <th scope="col" className="py-1 px-2">
                            SL
                          </th>
                          <th scope="col" className="py-1 px-2">
                            ID
                          </th>
                          <th scope="col" className="py-1 px-2">
                            Unrealized P&L
                          </th>
                          <th scope="col" className="py-1 px-2">
                            Close
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        id="positions-table"
                        className="text-totalWhite"
                      ></tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div
                id="table-orders"
                className="h-full border-totalWhite text-xl text-totalWhite hidden"
              >
                <div className="relative bg-tvcolor rounded-bl-xl">
                  <div className="overflow-x-auto">
                    <table
                      id="main-table-orders"
                      className="w-full text-sm text-left text-gray-500 text-gray-400 text-center"
                    >
                      <thead className="text-xs uppercase bg-gray-700 text-gray-400">
                        <tr>
                          <th scope="col" className="py-1 px-2">
                            Contract
                          </th>
                          <th scope="col" className="py-1 px-2">
                            Qty
                          </th>
                          <th scope="col" className="py-1 px-2">
                            Order Price
                          </th>
                          <th scope="col" className="py-1 px-2">
                            TP
                          </th>
                          <th scope="col" className="py-1 px-2">
                            SL
                          </th>
                          <th scope="col" className="py-1 px-2">
                            ID
                          </th>
                          <th scope="col" className="py-1 px-2">
                            Cancel
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        id="orders-table"
                        className="text-totalWhite"
                      ></tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;

function existCommonElement(array1, array2) {
  for (let i = 0; i < array1.length; i++) {
    for (let j = 0; j < array2.length; j++) {
      if (array1[i] === array2[j]) {
        return true;
      }
    }
  }
  return false;
}

function limit_order() {
  const btn_limit = document.getElementById("limit-order");
  const btn_market = document.getElementById("market-order");
  const btn_limit_order = document.getElementById("btn-limit");
  const btn_market_order = document.getElementById("btn-market");
  const div_order_price = document.getElementById("div-order-price");
  const div_order_value = document.getElementById("div-order-value");
  document.getElementById("risk-management-mode").className =
    "text-sm text-gray-400 italic hidden";
  document.getElementById("risk-management-slider").className = "hidden";
  document.getElementById("risk-management-checkbox").checked = false;
  btn_limit.style.color = "#f0a61b";
  if ((btn_market.style.color = "#f0a61b")) {
    btn_market.style.color = "#757575";
    btn_market_order.style.display = "none";
  }
  document.getElementById("div-market-value").className = "hidden";
  document.getElementById("recapTP").className = "visible text-gray-400";
  document.getElementById("recapSL").className = "visible text-gray-400";
  document.getElementById("recapTPmarket").className = "hidden";
  document.getElementById("recapSLmarket").className = "hidden";
  div_order_price.style.display = "block";
  btn_limit_order.style.display = "block";
  div_order_value.style.display = "block";
}

function market_order() {
  const btn_limit = document.getElementById("limit-order");
  const btn_market = document.getElementById("market-order");
  const btn_limit_order = document.getElementById("btn-limit");
  const btn_market_order = document.getElementById("btn-market");
  const div_order_price = document.getElementById("div-order-price");
  const div_order_value = document.getElementById("div-order-value");
  document.getElementById("risk-management-mode").className =
    "text-sm text-gray-400 italic";

  btn_market.style.color = "#f0a61b";
  if ((btn_limit.style.color = "#f0a61b")) {
    btn_limit.style.color = "#757575";
    btn_limit_order.style.display = "none";
    div_order_value.style.display = "none";
  }
  div_order_price.style.display = "none";
  btn_market_order.style.display = "block";
  document.getElementById("div-market-value").className = "visible";
  document.getElementById("recapTPmarket").className = "visible text-gray-400";
  document.getElementById("recapSLmarket").className = "visible text-gray-400";
  document.getElementById("recapTP").className = "hidden";
  document.getElementById("recapSL").className = "hidden";
}

function risk_management() {
  if (document.getElementById("risk-management-checkbox").checked) {
    document.getElementById("risk-management-slider").className =
      "w-3/4 justify-self-center";
  } else {
    document.getElementById("risk-management-slider").className = "hidden";
  }
}

function isInArray(value, array) {
  return array.indexOf(value) > -1;
}

async function connect() {
  const [signerAddr, isOwner] = await verify_wallet_address(
    "Welcome to the LATF Paper Trading.\n\nSign this request to access your account.\n\nThis request will not trigger a blockchain transaction or cost any gas fees."
  );
  if (isOwner) {
    try {
      localStorage.setItem("isWalletConnected", true);
    } catch (ex) {
      console.log(ex);
    }
    try {
      connectWallet();
    } catch (err) {
      localStorage.setItem("isWalletConnected", false);
      document.getElementById("sign-up-popup").classList.remove("hidden");
    }
  }
}

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

function geturl_symbol() {
  return localStorage.getItem("selectedSymbol");
}

async function create_order(
  contract,
  quantity,
  orderprice,
  takeprofit,
  stoploss,
  liquidationprice,
  ordertype,
  ordertime,
  orderid,
  side,
  signerAddr,
  note,
  leverage,
  handleLoad
) {
  const setchecked = get_set_checked();
  var notp, nosl;
  if (isNaN(takeprofit)) {
    notp = "X";
  }
  if (isNaN(stoploss)) {
    nosl = "X";
  }
  if (
    isNaN(quantity) ||
    isNaN(orderprice) ||
    quantity < 0 ||
    orderprice < 0 ||
    takeprofit < 0 ||
    stoploss < 0
  ) {
    alert("Please fill all order fields before submitting an order.");
    return 1;
  }
  if (ordertype == "limit") {
    const contractPrice = parseFloat(localStorage.getItem(contract));
    if (side == "long") {
      if (orderprice > contractPrice * 1.005) {
        orderprice = contractPrice;
      }
    } else if (side == "short") {
      if (orderprice < contractPrice * 0.995) {
        orderprice = contractPrice;
      }
    }
    // check if order value isn't above balance now
    const balance = parseFloat(localStorage.getItem("balance"));
    const orderValue = (quantity * orderprice) / leverage || 1;
    if (orderValue > balance) {
      alert(
        "The order price is set incorrectly, and would lead to unwanted losses."
      );
      return 1;
    }
    await fetch(
      `https://latf-paper-trading-backend.herokuapp.com/limit/create/order?note=${note}&setchecked=${setchecked}&contract=${contract}&quantity=${quantity}&orderprice=${orderprice}&takeprofit=${takeprofit}&stoploss=${stoploss}&liquidationprice=${liquidationprice}&ordertype=${ordertype}&ordertime=${ordertime}&orderid=${orderid}&side=${side}&walletaddress=${signerAddr}&notp=${notp}&nosl=${nosl}&leverage=${
        localStorage.getItem("leverage") || 1
      }`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        handleLoad();
        // window.location.reload();
      });
  } else if (ordertype == "market") {
    await fetch(
      `https://latf-paper-trading-backend.herokuapp.com/market/create/order?note=${note}&setchecked=${setchecked}&contract=${contract}&quantity=${quantity}&orderprice=${orderprice}&takeprofit=${takeprofit}&stoploss=${stoploss}&liquidationprice=${liquidationprice}&ordertype=${ordertype}&ordertime=${ordertime}&orderid=${orderid}&side=${side}&walletaddress=${signerAddr}&notp=${notp}&nosl=${nosl}&leverage=${
        localStorage.getItem("leverage") || 1
      }`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        handleLoad();
        // window.location.reload();
      });
  }
}

async function open_limit_long(handleLoad) {
  if (localStorage?.getItem("isWalletConnected") === "true") {
    const contract = geturl_symbol();
    const leverage = localStorage.getItem("leverage");
    const quantity = parseFloat(document.getElementById("order-qty").value);
    const orderprice = parseFloat(document.getElementById("order-price").value);
    const takeprofit = parseFloat(document.getElementById("take-profit").value);
    const stoploss = parseFloat(document.getElementById("stop-loss").value);
    const liquidationprice = 0;
    const ordertype = "limit";
    const d = new Date(),
      ordertime =
        [
          (d.getMonth() + 1).padLeft(),
          d.getDate().padLeft(),
          d.getFullYear(),
        ].join("/") +
        " " +
        [
          d.getHours().padLeft(),
          d.getMinutes().padLeft(),
          d.getSeconds().padLeft(),
        ].join(":");
    const orderid = uuidv4().toString();
    const side = "long";
    const note = document.getElementById("note").value;
    const [signerAddr, isOwner] = await verify_wallet_address(
      `Sign to ${side} ${contract} at ${orderprice} for a quantity of ${quantity} using a leverage of ${leverage} with your paper money.\n\nThis request will not trigger a blockchain transaction or cost any gas fees.`
    );
    async function asyncCall() {
      if (isOwner) {
        if (await has_balance(quantity, orderprice, signerAddr, leverage)) {
          create_order(
            contract,
            quantity,
            orderprice,
            takeprofit,
            stoploss,
            liquidationprice,
            ordertype,
            ordertime,
            orderid,
            side,
            signerAddr,
            note,
            leverage,
            handleLoad
          );
        } else {
          alert("Insufficient balance, reduce your order quantity.");
        }
      }
    }
    asyncCall();
  } else {
    alert("You aren't currently connected.");
  }
}

async function open_limit_short(handleLoad) {
  if (localStorage?.getItem("isWalletConnected") === "true") {
    const contract = geturl_symbol();
    const leverage = localStorage.getItem("leverage");
    const quantity = parseFloat(document.getElementById("order-qty").value);
    const orderprice = parseFloat(document.getElementById("order-price").value);
    const takeprofit = parseFloat(document.getElementById("take-profit").value);
    const stoploss = parseFloat(document.getElementById("stop-loss").value);
    const liquidationprice = orderprice * 2;
    const ordertype = "limit";
    const d = new Date(),
      ordertime =
        [
          (d.getMonth() + 1).padLeft(),
          d.getDate().padLeft(),
          d.getFullYear(),
        ].join("/") +
        " " +
        [
          d.getHours().padLeft(),
          d.getMinutes().padLeft(),
          d.getSeconds().padLeft(),
        ].join(":");
    const orderid = uuidv4().toString();
    const side = "short";
    const note = document.getElementById("note").value;
    const [signerAddr, isOwner] = await verify_wallet_address(
      `Sign to ${side} ${contract} at ${orderprice} for a quantity of ${quantity} with your paper money.\n\nThis request will not trigger a blockchain transaction or cost any gas fees.`
    );
    async function asyncCall() {
      if (isOwner) {
        console.log(await has_balance(quantity, orderprice, signerAddr));
        if (await has_balance(quantity, orderprice, signerAddr, leverage)) {
          create_order(
            contract,
            quantity,
            orderprice,
            takeprofit,
            stoploss,
            liquidationprice,
            ordertype,
            ordertime,
            orderid,
            side,
            signerAddr,
            note,
            leverage,
            handleLoad
          );
        } else {
          alert(
            "Insufficient balance, the order is set to your maximum available balance."
          );
          const available_balance = localStorage.getItem("balance");
          const max_quantity =
            (available_balance / orderprice) * localStorage.getItem("leverage");
          create_order(
            contract,
            max_quantity,
            orderprice,
            takeprofit,
            stoploss,
            liquidationprice,
            ordertype,
            ordertime,
            orderid,
            side,
            signerAddr,
            note,
            leverage,
            handleLoad
          );
        }
      }
    }
    asyncCall();
  } else {
    alert("You aren't currently connected.");
  }
}

async function open_market_long(handleLoad) {
  console.log("open_market_long");
  if (localStorage?.getItem("isWalletConnected") === "true") {
    console.log("open_market_long wallet is connected.");
    const contract = geturl_symbol();
    const leverage = localStorage.getItem("leverage");
    const quantity = parseFloat(document.getElementById("order-qty").value);
    const takeprofit = parseFloat(document.getElementById("take-profit").value);
    const stoploss = parseFloat(document.getElementById("stop-loss").value);
    const ordertype = "market";
    const orderid = uuidv4().toString();
    const side = "long";
    const note = document.getElementById("note").value;
    const [signerAddr, isOwner] = await verify_wallet_address(
      `Sign to ${side} ${contract} at current price for a quantity of ${quantity} with your paper money.\n\nThis request will not trigger a blockchain transaction or cost any gas fees.`
    );
    const proxyURL = "https://latf-proxy.herokuapp.com/";
    await fetch(
      `${proxyURL}https://api.binance.com/api/v3/ticker/price?symbol=${contract}`
    )
      .then((response) => response.json())
      .then((data) => {
        const d = new Date(),
          ordertime =
            [
              (d.getMonth() + 1).padLeft(),
              d.getDate().padLeft(),
              d.getFullYear(),
            ].join("/") +
            " " +
            [
              d.getHours().padLeft(),
              d.getMinutes().padLeft(),
              d.getSeconds().padLeft(),
            ].join(":");
        const ticker_price = parseFloat(data.price);
        const orderprice = ticker_price;
        const liquidationprice = 0;
        async function asyncCall() {
          if (isOwner) {
            if (await has_balance(quantity, orderprice, signerAddr, leverage)) {
              create_order(
                contract,
                quantity,
                orderprice,
                takeprofit,
                stoploss,
                liquidationprice,
                ordertype,
                ordertime,
                orderid,
                side,
                signerAddr,
                note,
                leverage,
                handleLoad
              );
            } else {
              alert(
                "Insufficient balance, the order is set to your maximum available balance."
              );
              const available_balance = localStorage.getItem("balance");
              const max_quantity =
                (available_balance / orderprice) *
                localStorage.getItem("leverage");
              create_order(
                contract,
                max_quantity,
                orderprice,
                takeprofit,
                stoploss,
                liquidationprice,
                ordertype,
                ordertime,
                orderid,
                side,
                signerAddr,
                note,
                leverage,
                handleLoad
              );
            }
          } else {
            alert("You aren't currently connected.");
          }
        }
        asyncCall();
      });
  }
}

async function open_market_short(handleLoad) {
  const contract = geturl_symbol();
  if (localStorage?.getItem("isWalletConnected") === "true") {
    const contract = geturl_symbol();
    const leverage = localStorage.getItem("leverage");
    const quantity = parseFloat(document.getElementById("order-qty").value);
    const takeprofit = parseFloat(document.getElementById("take-profit").value);
    const stoploss = parseFloat(document.getElementById("stop-loss").value);
    const ordertype = "market";
    const orderid = uuidv4().toString();
    const side = "short";
    const note = document.getElementById("note").value;
    const [signerAddr, isOwner] = await verify_wallet_address(
      `Sign to ${side} ${contract} at current price for a quantity of ${quantity} with your paper money.\n\nThis request will not trigger a blockchain transaction or cost any gas fees.`
    );
    const proxyURL = "https://latf-proxy.herokuapp.com/";
    await fetch(
      `${proxyURL}https://api.binance.com/api/v3/ticker/price?symbol=${contract}`
    )
      .then((response) => response.json())
      .then((data) => {
        const d = new Date(),
          ordertime =
            [
              (d.getMonth() + 1).padLeft(),
              d.getDate().padLeft(),
              d.getFullYear(),
            ].join("/") +
            " " +
            [
              d.getHours().padLeft(),
              d.getMinutes().padLeft(),
              d.getSeconds().padLeft(),
            ].join(":");
        const ticker_price = parseFloat(data.price);
        const orderprice = ticker_price;
        const liquidationprice = orderprice * 2;
        async function asyncCall() {
          if (isOwner) {
            if (await has_balance(quantity, orderprice, signerAddr, leverage)) {
              create_order(
                contract,
                quantity,
                orderprice,
                takeprofit,
                stoploss,
                liquidationprice,
                ordertype,
                ordertime,
                orderid,
                side,
                signerAddr,
                note,
                leverage,
                handleLoad
              );
            } else {
              alert(
                "Insufficient balance, the order is set to your maximum available balance."
              );
              const available_balance = localStorage.getItem("balance");
              const max_quantity =
                (available_balance / orderprice) *
                localStorage.getItem("leverage");
              create_order(
                contract,
                max_quantity,
                orderprice,
                takeprofit,
                stoploss,
                liquidationprice,
                ordertype,
                ordertime,
                orderid,
                side,
                signerAddr,
                note,
                leverage,
                handleLoad
              );
            }
          } else {
            alert("You aren't currently connected.");
          }
        }
        asyncCall();
      });
  }
}

async function has_balance(quantity, orderprice, signerAddr, leverage) {
  const ordervalue = (parseFloat(quantity) * parseFloat(orderprice)) / leverage;
  console.log(
    `https://latf-paper-trading-backend.herokuapp.com/balance?walletaddress=${signerAddr}`
  );
  const return_value = await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/balance?walletaddress=${signerAddr}`
  )
    .then((response) => response.json())
    .then((data) => {
      const balance = data.balance;
      return ordervalue < balance;
    });
  return return_value;
}

function display_orders() {
  const table_orders = document.getElementById("table-orders");
  const table_positions = document.getElementById("table-positions");
  table_positions.style.display = "none";
  table_orders.style.display = "block";
}

function display_positions() {
  const table_orders = document.getElementById("table-orders");
  const table_positions = document.getElementById("table-positions");
  table_orders.style.display = "none";
  table_positions.style.display = "block";
}

function get_set_checked() {
  const filterPage = localStorage.getItem("setPage");
  var ids = [];
  var journal_set = document.getElementById("set1");
  if (filterPage == 2) {
    journal_set = document.getElementById("set2");
  } else if (filterPage == 3) {
    journal_set = document.getElementById("set3");
  }
  if (journal_set == null) {
    console.log("journal_set is null");
    return;
  }
  console.log(filterPage);
  const elems = journal_set.querySelectorAll("*[id]");
  let i = 0;
  while (i < elems.length) {
    if (document.getElementById(elems[i].id).checked) {
      ids.push(elems[i].id.slice(0, -9));
    }
    i++;
  }
  console.log(ids);
  return ids;
}

function get_set() {
  var ids = [];
  const elems = document.querySelectorAll(`[id^="div-set-"]`);
  let i = 0;
  while (i < elems.length) {
    // push into ids if the elem is displayed
    if (elems[i].style.display !== "none") {
      ids.push(elems[i].id.slice(8));
    }
    i++;
  }
  return ids;
}

async function remove_set_element(set_to_remove, walletaddress) {
  const set = get_set();
  set_to_remove = set_to_remove.slice(8);
  const final_set = set.filter((e) => e !== set_to_remove);
  console.log(final_set, walletaddress);
  var str_final_set = "[";
  let i = 0;
  while (i < final_set.length) {
    if (i === final_set.length - 1) {
      str_final_set += `"${final_set[i]}"`;
    } else {
      str_final_set += `"${final_set[i]}",`;
    }
    i++;
  }
  str_final_set += "]";
  console.log(str_final_set);
  await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/set/edit?walletaddress=${walletaddress}&userset=${str_final_set}`
  )
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      document.getElementById(`div-set-${set_to_remove}`).style.display =
        "none";
    });
}

async function add_set_element(set_to_add, walletaddress) {
  // Update the set on the server
  const set = get_set();
  var str_final_set = "[";
  let i = 0;
  while (i < set.length) {
    str_final_set += `"${set[i]}",`;
    i++;
  }
  str_final_set += `"${set_to_add}"]`;
  console.log(str_final_set);
  await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/set/edit?walletaddress=${walletaddress}&userset=${str_final_set}`
  )
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      // Create the new element to be added to the table
      const li_set = document.createElement("li");
      li_set.setAttribute("class", "w-full rounded-t-lg");
      const div_set = document.createElement("div");
      div_set.setAttribute("id", `div-set-${set_to_add}`);
      div_set.setAttribute("class", "flex items-center pl-3");
      const input_checkbox = document.createElement("input");
      input_checkbox.setAttribute("id", `${set_to_add}-checkbox`);
      input_checkbox.setAttribute("type", "checkbox");
      input_checkbox.setAttribute(
        "class",
        "w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-blue-600 ring-offset-gray-700 focus:ring-2 bg-gray-600 border-gray-500"
      );
      const label = document.createElement("label");
      label.setAttribute("for", `${set_to_add}-checkbox`);
      label.setAttribute(
        "class",
        "py-2 ml-2 w-full text-sm font-medium text-gray-300"
      );
      label.innerHTML = set_to_add;
      const button = document.createElement("button");
      button.setAttribute("class", "btn-close");
      button.onclick = function () {
        console.log(`Clicked on button`);
        remove_set_element(this.parentNode.id, walletaddress);
      };
      div_set.appendChild(input_checkbox);
      div_set.appendChild(label);
      div_set.appendChild(button);
      li_set.appendChild(div_set);

      // Check if we should append to trading-journal1 or trading-journal2
      const journal_set = document.getElementById("trading-journal1");
      const journal_set_bis = document.getElementById("trading-journal2");
      const elems = Array.from(
        journal_set.querySelectorAll("input:not([style='display: none;'])")
      ).filter((input) => {
        const div = input.parentNode;
        return div.style.display !== "none";
      });
      const elems_bis = Array.from(
        journal_set_bis.querySelectorAll("input:not([style='display: none;'])")
      ).filter((input) => {
        const div = input.parentNode;
        return div.style.display !== "none";
      });
      console.log(elems.length, elems_bis.length, elems_bis.length < 6);
      if (elems.length < 4) {
        journal_set.appendChild(li_set);
      } else if (elems.length >= 4 && elems_bis.length < 5) {
        journal_set_bis.appendChild(li_set);
      } else {
        alert("You can only have 8 sets per page.");
      }
    });
}

async function risk_management_tool(walletaddress) {
  try {
    const past_positions = await fetch(
      `https://latf-paper-trading-backend.herokuapp.com/user/archive/positions?walletaddress=${walletaddress}`
    )
      .then((response) => response.json())
      .then((data) => {
        localStorage.setItem(
          "total_balance",
          data[data.length - 1].total_balance
        );
      });
  } catch {
    localStorage.setItem("total_balance", localStorage.getItem("balance"));
  }
  console.log(
    localStorage.getItem("balance"),
    localStorage.getItem("total_balance")
  );
}

async function getDynamicBalance(walletaddress) {
  // make id total-assets display a loader until the fetch is done
  try {
    document.getElementById("total-assets").innerHTML = `<div role="status">
    <svg aria-hidden="true" class="w-6 h-6 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
      </svg>
      <span class="sr-only">Loading...</span>
</div>`;
    const total_assets = await fetch(
      `https://latf-paper-trading-backend.herokuapp.com/user/dynamicbalance?walletaddress=${walletaddress}`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        return data;
      });
    document.getElementById("total-assets").innerHTML = total_assets.toFixed(2);
  } catch {
    document.getElementById("total-assets").innerHTML = "---";
    window.location.reload();
  }
}

async function getDynamicBalanceBis(walletaddress) {
  // the difference is that we don't display loader
  const total_assets = await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/user/dynamicbalance?walletaddress=${walletaddress}`
  )
    .then((response) => response.json())
    .then((data) => {
      // console.log(data);
      return data;
    });
  // console.log(total_assets, "total_assets");
  document.getElementById("total-assets").innerHTML = total_assets.toFixed(2);
}

function emptyOrdersTable() {
  const ordersTable = document.getElementById("orders-table");
  // Clear orders table
  while (ordersTable.rows.length > 0) {
    ordersTable.deleteRow(0);
  }
}
