import React, { useState, useEffect } from "react";

const BattleArchivePositions = ({ walletAddress, battleId }) => {
  const [positions, setPositions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

 
useEffect(() => {
  const fetchPositions = () => {
    fetch(`https://latf-paper-trading-backend.herokuapp.com/battle/user/archive/positions?battleid=${battleId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch");
        }
        return response.json();
      })
      .then((data) => {
        setPositions(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  // Call once immediately upon mounting
  fetchPositions();

  // Then set interval for subsequent fetches
  const intervalId = setInterval(fetchPositions, 10000); // every 5 seconds

  // Cleanup function to clear the interval when the component is unmounted
  return () => clearInterval(intervalId);

}, [walletAddress, battleId]);

  if (loading) return <p className="text-lg text-center">Loading...</p>;
  if (error)
    return (
      <p className="text-lg text-center text-red-500">Error: {error.message}</p>
    );

  return (
    <div className="md:p-4 rounded-3xl bg-gray-800 shadow-xl w-full max-w-4xl mx-auto text-white flex flex-col md:flex-row">
      {/* Parent container */}

      {/* 1/4 width column for the title on large screens, full width on mobile */}
      <div className="w-full md:w-1/4 pt-4 px-4">
        <h1 className="text-xl md:text-3xl mb-4 font-bold text-center">
          Archive <span className="text-yellow">Positions</span>
        </h1>
      </div>

      {/* 3/4 width column for the table on large screens, full width on mobile */}
      <div className="w-full md:w-3/4 px-4">
        <table className="min-w-full divide-y divide-gray-700">
          <thead>
            <tr className="text-gray-500 text-center">
              <th className="w-1/4 py-2">Contract</th>
              <th className="w-1/4 py-2">Wallet Address</th>
              <th className="w-1/4 py-2">PnL</th>
              <th className="w-1/4 py-2">Time</th>
            </tr>
          </thead>
          <tbody>
            {positions
              ?.slice()
              .reverse()
              .map((position, index) => {
                const isRed =
                  determinePositionType(
                    position.profitloss,
                    position.contractprice,
                    position.orderprice
                  ) === "short";
                const orderTime = new Date(position.ordertime);
                const currentTimeEST = new Date(
                  new Date().toLocaleString("en-US", {
                    timeZone: "America/New_York",
                  })
                );
                const differenceInMinutes = Math.floor(
                  (currentTimeEST - orderTime) / (1000 * 60)
                );

                return (
                  <tr
                    key={index}
                    className="hover:bg-gray-700 transition-all duration-300 text-totalWhite text-center"
                  >
                    <td className="text-center py-2">
                      {position.walletaddress.slice(0, 10) + "..."}
                    </td>
                    <td
                      className={`text-center py-2 ${
                        isRed ? "text-red-500" : "text-green-500"
                      }`}
                    >
                      {position.contract}
                    </td>
                    <td
                      className={`text-center py-2 ${
                        parseFloat(position.profitloss) >= 0
                          ? "text-green-500"
                          : "text-red-500"
                      }`}
                    >
                      {parseFloat(position.profitloss).toFixed(2)}
                    </td>
                    <td className="text-center py-2">
                      {differenceInMinutes}
                      {" minutes ago"}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BattleArchivePositions;

const determinePositionType = (profitloss, contractPrice, orderPrice) => {
  if (profitloss > 0) {
    if (contractPrice > orderPrice) {
      return "long";
    } else {
      return "short";
    }
  } else {
    if (contractPrice > orderPrice) {
      return "short";
    } else {
      return "long";
    }
  }
};
