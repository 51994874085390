import { getAccount } from '@wagmi/core'

export async function getUserAddress() {
  try {
    const addresses = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    const addr = addresses[0];
    return addr;
  } catch (error) {
    const addr = await getAccount().address;
    return addr;
  }
}

export async function connectWallet() {
    try {
      const addr = await getUserAddress()
      localStorage.setItem("isWalletConnected", true);
      localStorage.setItem("walletAddress", addr);
      window.location.reload();
      return addr;
    } catch (error) {
      console.error(error);
      localStorage.setItem("isWalletConnected", false);
      return null;
    }
  }
  
  // Disconnect from Metamask
  export function disconnectWallet() {
    localStorage.setItem("isWalletConnected", false);
    localStorage.removeItem("walletAddress");
  }
  
  // Check if Metamask is connected
  export function isWalletConnected() {
    return localStorage.getItem("isWalletConnected") === "true";
  }
  