import React, { useEffect, useState } from "react";
import {
  SkillTreeGroup,
  SkillTree,
  SkillProvider,
  SkillType,
  SkillGroupDataType,
  SavedDataType,
} from "beautiful-skill-tree";

// We have a backend that stores the skills that the user has unlocked
// We need to fetch the data from the backend and update the savedData state
export default function ConstellationTree() {
  const [backend_data, setData] = useState([]);
  const [savedData, setSavedData] = useState({
    sailor: {
      optional: false,
      nodeState: "",
    },
    "ship-wheel": {
      optional: false,
      nodeState: "",
    },
    scribe: {
      optional: false,
      nodeState: "",
    },
    twitter: {
      optional: false,
      nodeState: "",
    },
    discord: {
      optional: false,
      nodeState: "",
    },
    bull: {
      optional: false,
      nodeState: "",
    },
    bear: {
      optional: false,
      nodeState: "",
    },
    firstwinstreak: {
      optional: false,
      nodeState: "",
    },
    thirtypercentrealized: {
      optional: false,
      nodeState: "",
    },
    seventyfivepercentrealized: {
      optional: false,
      nodeState: "",
    },
    hundredpercentrealized: {
      optional: false,
      nodeState: "",
    },
    last15winrate75: {
      optional: false,
      nodeState: "",
    },
    diversifiedportfolio: {
      optional: false,
      nodeState: "",
    },
    oldoak: {
      optional: false,
      nodeState: "",
    },
    top30: {
      optional: false,
      nodeState: "",
    },
    top5: {
      optional: false,
      nodeState: "",
    },
    top1: {
      optional: false,
      nodeState: "",
    },
  });
  useEffect(() => {
    var userid = getUserId();
    const addr = window.atob(userid);
    if (addr.toLowerCase() !== localStorage.getItem("walletAddress").toLowerCase()) {
      document.getElementById("claim-div").style.display = "none";
    }
    fetch(
      `https://latf-paper-trading-backend.herokuapp.com/skilltree?walletaddress=${addr}`
    )
      .then((response) => response.json())
      .then((backend_data) => {
        setData(backend_data);
        console.log(backend_data);
      })
      .catch((error) => console.log(error));
  }, []);

  const data = [
    {
      id: "twitter",
      title: "Twitter",
      tooltip: {
        content: "Claim your Twitter handler on the Twitter leaderboard.",
      },
      icon: "https://i.ibb.co/c6fhSX5/icons8-twitter-96.png",
      children: [
        {
          id: "discord",
          title: "Discord",
          tooltip: {
            content: "Claim your Discord handler on your profile Page.",
          },
          icon: "https://i.ibb.co/0FJ8B88/icons8-discord-96.png",
          children: [],
        },
      ],
    },
    {
      id: "sailor",
      title: "Sailor",
      tooltip: {
        content:
          "Successfully joined the Trading Arena. You can now start competing with other sailors.  🎁 Discord Badge : 'Sailor'",
      },
      icon: "https://i.ibb.co/CKFYJsY/sinagot.png",
      children: [
        {
          id: "ship-wheel",
          title: "Ship Wheel",
          tooltip: {
            content:
              "Achieve your first trade. The position must be closed. You can now retrieve it in your Dashboard !",
          },
          icon: "https://i.ibb.co/xFq2w93/ship-wheel.png",
          children: [
            {
              id: "thirtypercentrealized",
              title: "Profit Pursuit",
              tooltip: {
                content:
                  "30% Realized profits reached.  🎁 Discord Badge : 'Profit Pursuit'",
              },
              icon: "https://i.ibb.co/GJL613b/rank-1.png",
              children: [
                {
                  id: "seventyfivepercentrealized",
                  title: "The Great Profits",
                  tooltip: {
                    content: "75% Realized profits reached.",
                  },
                  icon: "https://i.ibb.co/71NHGKC/rank-2.png",
                  children: [
                    {
                      id: "hundredpercentrealized",
                      title: "Profit Mastery",
                      tooltip: {
                        content:
                          "100% Realized profits reached. 🎁 Discord Badge : 'Profit Mastery'",
                      },
                      icon: "https://i.ibb.co/dfh9zzm/rank-3.png",
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: "scribe",
          title: "Scribe",
          tooltip: {
            content:
              "Achieve a trade containing a note. The position must be closed. You can now edit the note from your Dashboard.",
          },
          icon: "https://i.ibb.co/Rv41tX6/scroll-unfurled.png",
          children: [
            {
              id: "bull",
              title: "Bull",
              tooltip: {
                content: "Close five long positions for profits.",
              },
              icon: "https://i.ibb.co/vVptPps/bull-horns.png",
              children: [
                {
                  id: "firstwinstreak",
                  title: "Win Streak I",
                  tooltip: {
                    content: "Close 25 positions for profits.",
                  },
                  icon: "https://i.ibb.co/q1YGhNc/coins.png",
                  children: [
                    {
                      id: "last15winrate75",
                      title: "Win Streak II",
                      tooltip: {
                        content:
                          "75% winrate (with at least 1000USD of profit per trade) over the 15 last trades. 🎁 Discord Badge : 'Win Streak II'",
                      },
                      icon: "https://i.ibb.co/DbY2tJK/coins-pile.png",
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              id: "bear",
              title: "Bear",
              tooltip: {
                content: "Close five short positions for profits.",
              },
              icon: "https://i.ibb.co/r5D8KwC/bear-head.png",
              children: [],
            },
            // ace : https://i.ibb.co/JjQbzLt/ace.png
          ],
        },
        {
          id: "diversifiedportfolio",
          title: "Diversified Portfolio",
          tooltip: {
            content:
              "Have successfully traded on at least 10 different contracts.",
          },
          icon: "https://i.ibb.co/RHzXyPm/tree-branch.png",
          children: [
            {
              id: "oldoak",
              title: "Old Oak",
              tooltip: {
                content: "Close at least 500 trades.",
              },
              icon: "https://i.ibb.co/Hz0Q3fZ/oak.png",
              children: [],
            },
          ],
        },
        {
          id: "top30",
          title: "Top 30",
          tooltip: {
            content: "Be in the top 30 of the leaderboard.",
          },
          icon: "https://i.ibb.co/ZdGvFJ6/chess-knight.png",
          children: [
            {
              id: "top5",
              title: "Top 5",
              tooltip: {
                content: `Be in the top 5 of the leaderboard.  🎁 Discord Badge : 'Top 5'`,
              },
              icon: `https://i.ibb.co/XYCXG7r/chess-rook.png`,
              children: [
                {
                  id: "top1",
                  title: "Top 1",
                  tooltip: {
                    content:
                      "Be in the top 1 of the leaderboard. 🎁 Discord Badge : 'Top 1'",
                  },
                  icon: "https://i.ibb.co/2PWHbTc/chess-king.png",
                  children: [],
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  useEffect(() => {
    try {
      const updatedSavedData = { ...savedData };
      backend_data.forEach((element) => {
        console.log(element, updatedSavedData, element in updatedSavedData);
        if (element in updatedSavedData) {
          updatedSavedData[element].nodeState = "selected";
        }
      });
      setSavedData(updatedSavedData);
      try {
        document.getElementsByClassName("sc-fzoXzr cUgBFa")[0].innerHTML = "";
      }
      catch (error) {
        // wait 2 sec
        setTimeout(() => {
          document.getElementsByClassName("sc-fzoXzr iONATL")[0].innerHTML = "";
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  }, [backend_data]);

  return (
    <SkillProvider>
      <SkillTreeGroup
        theme={{
          headingFont: "Helvetica Neue",
          tooltipBackgroundColor: "#5772E1",
          tooltipBackgroundColor: "#5772E1",
          treeBackgroundColor: "",
          borderRadius: "35px",
          border: "none",
          nodeActiveBackgroundColor: "#C6C6C6",
          nodeBorderColor: "transparent",
          edgeBorder: "1px solid #282C34",
          nodeHoverBorder: "none",
          nodeIconWidth: "100px",
        }}
      >
        {(skillCount) => {
          return (
            <SkillTree
              savedData={savedData}
              treeId="tree"
              data={data}
            ></SkillTree>
          );
        }}
      </SkillTreeGroup>
    </SkillProvider>
  );
}

function countNodes(node) {
  let count = 1; // include the current node
  if (node.children) {
    for (let child of node.children) {
      count += countNodes(child);
    }
  }
  return count;
}

function getUserId() {
  var url = window.location.href;
  var urlObject = new URL(url);
  var userid = urlObject.searchParams.get("userid");
  return userid;
}