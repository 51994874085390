import React from "react";

const DropdownMenu = () => (
  <div className="dropdown">
    <span className="dropbtn material-icons">menu</span>
    <div className="dropdown-content">
      <a
        href="/"
        className={`${
          window.location.href.includes("trade") ? "gradient-text" : ""
        } text-gray-200 no-underline text-lg border-gray-300`}
      >
        Trade
        <i className="material-icons align-top">home</i>
      </a>
      <a
        href="/pvp"
        className={`${
          window.location.href.includes("pvp") ? "gradient-text" : ""
        } text-gray-200 no-underline text-lg border-gray-300`}
      >
        PvP
        <i className="material-icons align-top">sword</i>
      </a>
      <a
        href="/dashboard"
        className={`${
          window.location.href.includes("dashboard") ? "gradient-text" : ""
        } text-gray-200 no-underline text-lg border-gray-300`}
      >
        Dashboard
        <i className="material-icons align-top">dashboard</i>
      </a>
      <a
        href="/posts/discover"
        className={`${
          window.location.href.includes("/posts/discover") ? "gradient-text" : ""
        } text-gray-200 no-underline text-lg border-gray-300`}
      >
        Community
        <i className="material-icons align-top">ballot</i>
      </a>
      <a
        href="/leaderboard"
        className={`${
          window.location.href.includes("leaderboard") && !window.location.href.includes("leaderboardtw") ? "gradient-text" : ""
        } text-gray-200 no-underline text-lg border-gray-300`}
      >
        Leaderboard Global
      </a>
      <a
        href="/leaderboardtw"
        className={`${
          window.location.href.includes("leaderboardtw") ? "gradient-text" : ""
        } text-gray-200 no-underline text-lg border-gray-300`}
      >
        Leaderboard Twitter
      </a>
      <a
        href={`/profile?userid=${window.btoa(
          localStorage.getItem("walletAddress") ||
            "MFhGQzNDMUE5MjkwRjgwRUM5OUJFQ0UzNTNBQjEyMDAxRDRDMjM3QUQz"
        )}`}
        className={`${
          window.location.href.includes("profile") ? "gradient-text" : ""
        } text-gray-200 no-underline text-lg border-gray-300`}
      >
        Prof&zwnj;ile
        <i className="material-icons align-top">account_circle</i>
      </a>
      <a>
        {" "}
        <span className="text-totalWhite">Balance:</span>
        <div className="flex relative inline-block float-right">
          <span id="balance-drop" className="ml-2"></span>{" "}
          <img className="w-6 h-6 ml-1 rounded-full" src="/images/coins.png" />
        </div>
      </a>
    </div>
  </div>
);

export default DropdownMenu;
