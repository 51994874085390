import React, { useState } from "react";
import {
  FaChartLine,
  FaRss,
  FaTree,
  FaUserFriends,
  FaGift,
  FaDiscord,
  FaVoteYea,
} from "react-icons/fa";

const ProfileTabs = ({
  showStats,
  setShowStats,
  setShowFeed,
  showFeed,
  window,
  getUserId,
}) => {
  const [showMoreOptions, setShowMoreOptions] = useState(false);

  const toggleMoreOptions = () => {
    setShowMoreOptions(!showMoreOptions);
  };

  const handleSwipe = (e) => {
    if (e.deltaX > 100) {
      setShowMoreOptions(false);
    } else if (e.deltaX < -100) {
      setShowMoreOptions(true);
    }
  };

  return (
    <div className="bg-gray-100 bg-opacity-50 p-4 md:p-8 fixed w-1/5">
      <div
        className={`mobile-more-options space-y-2${
          showMoreOptions ? "translate-x-0" : ""
        }`}
        onTouchStart={(e) => {
          e.persist();
          e.touchStartX = e.touches[0].clientX;
        }}
        onTouchMove={(e) => {
          if (e.changedTouches && e.changedTouches.length) {
            handleSwipe({
              deltaX: e.changedTouches[0].clientX - e.touchStartX,
            });
          }
        }}
      >
        <div className="flex flex-col items-center">
          <button
            className={`border-b w-full my-2 ${showStats ? "text-blue-500" : ""}`}
            onClick={() => {
              setShowStats(true);
              setShowFeed(false);
            }}
          >
            <FaChartLine className="inline-block my-2 text-2xl" /> Stats
          </button>
          <button
            className={`border-b w-full my-2 ${showFeed ? "text-blue-500" : ""}`}
            onClick={() => {
              setShowStats(false);
              setShowFeed(true);
            }}
          >
            <FaRss className="inline-block my-2 text-2xl" /> Feed
          </button>
          <button
          className="border-b w-full my-2"
          onClick={() =>
            (window.location.href = `/community`)
          }
        >
          <FaVoteYea className="inline-block my-2 text-2xl" /> Vote
        </button>
          <button
          className="border-b w-full my-2"
          onClick={() =>
            (window.location.href = `/skilltree?userid=${getUserId()}`)
          }
        >
          <FaTree className="inline-block my-2 text-2xl" /> Skill Tree
        </button>
        <button
          className="border-b w-full my-2"
          onClick={() => (window.location.href = "/referrals")}
        >
          <FaUserFriends className="inline-block my-2 text-2xl" /> Referrals
        </button>
        <button
        className="border-b w-full my-2"
          onClick={() => (window.location.href = "/rewards")}
        >
          <FaGift className="inline-block my-2 text-2xl" /> Rewards
        </button>
        <button
          onClick={() =>
            window.open(
              "https://discord.com/channels/907889583323222017/907889999561768960",
              "_blank"
            )
          }
        >
          <FaDiscord className="inline-block my-2 text-2xl" /> Discord
        </button>
        </div>

        {/* Mobile Swipeable Menu */}
        
      </div>
    </div>
  );
};

export default ProfileTabs;
