import React, { useState } from "react";

const BugReport = ({ onClose }) => {
  const [message, setMessage] = useState("");
  const [picture, setPicture] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [showBugReport, setShowBugReport] = useState(true);

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handlePictureChange = (event) => {
    setPicture(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const message_param = message;
    const walletaddress = localStorage.getItem("walletAddress");
    console.log(message, walletaddress);
  
    try {
      await fetch(
        `https://latf-paper-trading-backend.herokuapp.com/bugreport?walletaddress=${walletaddress}&message=${message_param}`
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
        });
      setMessage("");
      setPicture(null);
      setErrorMessage("");
      alert("Bug report submitted successfully. Thank you!");
      setShowBugReport(false);
      onClose();
    } catch (error) {
      setErrorMessage("Failed to submit bug report. Please try again.");
    }
  };

  return (
    <>
      {showBugReport && (
        <div
          className="bg-backgroundbase rounded-3xl py-4 px-4 text-totalWhite"
          style={{
            backgroundImage: 'url("../../images/backgrounddetails.png")',
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
          id="bugreport"
        >
          <div
            onClick={onClose}
            className="bg-tomato rounded-full h-10 w-10 flex items-center justify-center cursor-pointer absolute -bottom-12 mt-2 mr-2"
            style={{ left: "50%", marginLeft: "-20px" }}
          >
            <i className="material-icons text-totalWhite text-2xl rotator-45">
              close
            </i>
          </div>
          <h2 class="text-center text-totalWhite text-3xl font-bold mb-4">
            Report <span class="text-tomato"> a bug</span>
          </h2>
          {errorMessage && <p className="text-red-500">{errorMessage}</p>}
          <form onSubmit={handleSubmit} className="">
            <div className="flex flex-col items-start">
              <label htmlFor="message" className="py-2.5 px-2 text-lg font-semibold">
                Message   :
              </label>
              <textarea
                maxlength="800"
                id="message"
                value={message}
                onChange={handleMessageChange}
                className="rounded-xl py-2 px-4 text-backgroundbase w-96 h-40"
              />
            </div>
            <div className="justify-center flex mt-4">
              <button
                type="submit"
                className="bg-gray-800 font-semibold py-2 px-4 rounded-3xl hover:bg-gray-900"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default BugReport;
