import React from "react";

const currencyPairs = [
  {
    symbol: "BTCUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/1.png",
    id: "BTCUSDT",
  },
  {
    symbol: "ETHUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
    id: "ETHUSDT",
  },
  {
    symbol: "BNBUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png",
    id: "BNBUSDT",
  },
  {
    symbol: "ADAUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/2010.png",
    id: "ADAUSDT",
  },
  {
    symbol: "XRPUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/52.png",
    id: "XRPUSDT",
  },
  {
    symbol: "SOLUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png",
    id: "SOLUSDT",
  },
  {
    symbol: "EGLDUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/6892.png",
    id: "EGLDUSDT",
  },
  {
    symbol: "LINKUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/1975.png",
    id: "LINKUSDT",
  },
  {
    symbol: "DOTUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/6636.png",
    id: "DOTUSDT",
  },
  {
    symbol: "ATOMUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/3794.png",
    id: "ATOMUSDT",
  },
  {
    symbol: "MASKUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/8536.png",
    id: "MASKUSDT",
  },
  {
    symbol: "NEARUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/6535.png",
    id: "NEARUSDT",
  },
  {
    symbol: "MATICUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png",
    id: "MATICUSDT",
  },
  {
    symbol: "ALGOUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/4030.png",
    id: "ALGOUSDT",
  },
  {
    symbol: "UNIUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/7083.png",
    id: "UNIUSDT",
  },
  {
    symbol: "VETUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/3077.png",
    id: "VETUSDT",
  },
  {
    symbol: "SUSHIUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/6758.png",
    id: "SUSHIUSDT",
  },
  {
    symbol: "THETAUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/2416.png",
    id: "THETAUSDT",
  },
  {
    symbol: "FILUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/2280.png",
    id: "FILUSDT",
  },
  {
    symbol: "XLMUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/512.png",
    id: "XLMUSDT",
  },
  {
    symbol: "TRXUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/1958.png",
    id: "TRXUSDT",
  },
  {
    symbol: "AAVEUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/7278.png",
    id: "AAVEUSDT",
  },
  {
    symbol: "ICPUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/8916.png",
    id: "ICPUSDT",
  },
  {
    symbol: "COMPUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/5692.png",
    id: "COMPUSDT",
  },
  {
    symbol: "CAKEUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/7186.png",
    id: "CAKEUSDT",
  },
  {
    symbol: "XTZUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/2011.png",
    id: "XTZUSDT",
  },
  {
    symbol: "BCHUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/1831.png",
    id: "BCHUSDT",
  },
  {
    symbol: "GRTUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/6719.png",
    id: "GRTUSDT",
  },
  {
    symbol: "MKRUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/1518.png",
    id: "MKRUSDT",
  },
  {
    symbol: "KSMUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/5034.png",
    id: "KSMUSDT",
  },
  {
    symbol: "ZECUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/1437.png",
    id: "ZECUSDT",
  },
  {
    symbol: "DASHUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/131.png",
    id: "DASHUSDT",
  },
  {
    symbol: "CHZUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/4066.png",
    id: "CHZUSDT",
  },
  {
    symbol: "QTUMUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/1684.png",
    id: "QTUMUSDT",
  },
  {
    symbol: "CELOUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/5567.png",
    id: "CELOUSDT",
  },
  {
    symbol: "IOSTUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/2405.png",
    id: "IOSTUSDT",
  },
  {
    symbol: "MANAUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/1966.png",
    id: "MANAUSDT",
  },
  {
    symbol: "ZILUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/2469.png",
    id: "ZILUSDT",
  },
  {
    symbol: "ONTUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/2566.png",
    id: "ONTUSDT",
  },
  {
    symbol: "HBARUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/4642.png",
    id: "HBARUSDT",
  },
  {
    symbol: "ANKRUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/3783.png",
    id: "ANKRUSDT",
  },
  {
    symbol: "WAVESUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/1274.png",
    id: "WAVESUSDT",
  },
  {
    symbol: "DOGEUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/74.png",
    id: "DOGEUSDT",
  },
  {
    symbol: "LUNAUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/20314.png",
    id: "LUNAUSDT",
  },
  {
    symbol: "AVAXUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png",
    id: "AVAXUSDT",
  },
  {
    symbol: "RSRUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/3964.png",
    id: "RSRUSDT",
  },
  {
    symbol: "APTUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/21794.png",
    id: "APTUSDT",
  },
  {
    symbol: "OPUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/11840.png",
    id: "OPUSDT",
  },
  {
    symbol: "GMXUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/11857.png",
    id: "GMXUSDT",
  },
  {
    symbol: "FTMUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/3513.png",
    id: "FTMUSDT",
  },
  {
    symbol: "LTCUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/2.png",
    id: "LTCUSDT",
  },
  {
    symbol: "CRVUSDT",
    imgSrc: "https://s2.coinmarketcap.com/static/img/coins/64x64/6538.png",
    id: "CRVUSDT",
  },
];

// export currencies to be used in other files
export { currencyPairs };

const handleClick = (symbol) => {
  window.location = `/trade?symbol=BINANCE%3A${symbol}`;
};

const CurrenciesDropdown = ({ onSymbolSelect }) => (
  <>
    <thead>
      <tr>
        <th className="px-4 py-2">Symbol</th>
        <th className="px-4 py-2">Price</th>
      </tr>
    </thead>
    <tbody className="content-center">
      {currencyPairs.map((pair) => (
        <tr
          key={pair.symbol}
          className="hover:bg-gray-600 border-b border-gray-600"
          onClick={() => onSymbolSelect(pair.symbol)}
        >
          <td
            className="px-4 py-2 align-middle inline-block"
            style={{ width: "200px" }}
          >
            <img
              className="w-6 h-6 rounded-full float-left"
              src={pair.imgSrc}
              style={{ verticalAlign: "top" }}
            ></img>
            <span className="inline-block mt-1.5 float-right">
              &nbsp;&nbsp;{pair.symbol}
            </span>
          </td>
          <td id={pair.id} className="px-4 py-2"></td>
        </tr>
      ))}
    </tbody>
  </>
);

export default CurrenciesDropdown;
