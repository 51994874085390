import React, { useState, useEffect } from "react";
import DropdownMenu from "./DropdownMenu";
import BugReport from "./BugReport";

const Navbar = ({ connect, balance, totalBalance, timeRemaining }) => {
  const [showBugReport, setShowBugReport] = useState(false);

  useEffect(() => {
    // log params received
    console.log("From navbar:", balance, totalBalance, timeRemaining);
  }, [balance, totalBalance, timeRemaining]);

  return (
    <>
      <meta charSet="utf-8" />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      ></link>
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
        crossOrigin="anonymous"
      />
      <script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
        crossOrigin="anonymous"
      />

      <meta
        name="viewport"
        content="width=device-width,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0"
      />
      <title>LATF Paper Trading</title>

      <div className="h-fit">
        <nav className="navbar navbar-expand-lg navbar-dark  p-1">
          <div className="container-fluid ">
            {showBugReport && (
              <div className="z-20 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <BugReport onClose={() => setShowBugReport(false)} />
              </div>
            )}
            <a className="navbar-brand ml-7 animate-float" href="/">
              <img
                src="/images/logo-TA10.png"
                style={{ width: "250px" }}
                alt="TA Logo"
                height="35"
                width="320"
              ></img>
            </a>
            <div
              className={"flex flex-grow items-center justify-center"}
              id="example-navbar-danger"
            >

              </div>
              </div>

        </nav>
      </div>
    </>
  );
};

export default Navbar;
