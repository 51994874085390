import React, { useState } from "react";

const DisplayPositionsOrders = ({
  display_positions,
  document,
  display_orders,
}) => {
  const [selectedButton, setSelectedButton] = useState("positions");

  const handleDisplayPositions = () => {
    setSelectedButton("positions");
    display_positions();
  };

  const handleDisplayOrders = () => {
    setSelectedButton("orders");
    display_orders();
  };

  return (
    <div className="col-span-2 place-content-center text-center z-10 lg:col-span-1">
      <div className="">
        <div className="flex flex-col">
          <button
            onClick={handleDisplayPositions}
            id="btn-display-positions"
            className={`relative ${
              selectedButton === "positions"
                ? "bg-gray-700 text-yellow"
                : "bg-bbbglight text-binarybutton"
            } font-bold py-2 px-1 relative`}
          >
            <div className={``}>
              Positions
              <span
                id="positions-count"
                className={`${
                  selectedButton === "positions"
                    ? "text-yellow"
                    : "text-binarybutton"
                } text-sm text-xs absolute top-0 right-0 py-0.5 px-0.5`}
              ></span>
            </div>
          </button>
          <button
            onClick={handleDisplayOrders}
            id="btn-display-orders"
            className={`relative ${
              selectedButton === "orders" ? "bg-gray-700 text-yellow" : "bg-bbbglight text-binarybutton"
            } font-bold py-2 px-1 relative`}
          >
            <div className={``}>
              Orders
              <span
                id="orders-count"
                className={`${
                  selectedButton === "orders"
                    ? "text-yellow"
                    : "text-binarybutton"
                } text-sm text-xs absolute top-0 right-0 py-0.5 px-0.5`}
              ></span>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DisplayPositionsOrders;
