import { getAccount } from '@wagmi/core'
import { signMessage } from '@wagmi/core'
import { ethers } from 'ethers'
import { getUserAddress } from "./connection_manager";

export async function verify_wallet_address(message) {
  try {
      console.log("Trying to verify wallet address ...");

      const address  = await getUserAddress();
      const signature = await signMessage({
        message: message,
      });
      const signerAddr = ethers.utils.verifyMessage(message, signature);

      console.log(signerAddr, address);

      if (signerAddr.toLowerCase() !== address.toLowerCase()) {
          throw new Error("Invalid signature.");
      } else {
          console.log("Signature verified!");
          return [signerAddr.toUpperCase(), true];
      }
  } catch (error) {
      console.error("Error in verifying wallet address:", error);
      const address  = await getUserAddress();
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const signature = await signer.signMessage(message);
      const recoveredAddress = ethers.utils.verifyMessage(message, signature);
      if (recoveredAddress.toLowerCase() !== address.toLowerCase()) {
        throw new Error("Invalid signature.");
    } else {
        console.log("Signature verified!");
        return [recoveredAddress.toUpperCase(), true];
    }
  }
}
