import React from "react";

class RadioTPSL extends React.Component {
  render() {
    return (
      <a onClick={this.props.handler}>
        <div className="flex place-content-center space-x-2 h-1/2">
          <div className="flex items-center pl-4 rounded-sm">
            <input
              id="radio-long"
              type="radio"
              defaultValue=""
              name="bordered-radio"
              // onclick, display the id="div-tpsl"
              onClick={() => {document.getElementById("div-tpsl").className = "flex flex-wrap mb-2 px-2";}}
              className="aspect-square radiolong appearance-none bg-#17181d border-gray-400 rounded border-solid border-1 box-border w-4 h-4 hover:scale-110"
            />
            <label
              htmlFor="radio-long"
              className="ml-2 mr-2 w-auto text-sm font-medium text-gray-300 hover:text-green-500"
            >
              Buy Long with TP/SL
            </label>
          </div>
          <div className="flex items-center pl-4 rounded-sm border-gray-200 border-gray-700">
            <input
              id="radio-short"
              type="radio"
              defaultValue=""
              name="bordered-radio"
              onClick={() => {document.getElementById("div-tpsl").className = "flex flex-wrap mb-2 px-2";}}
              className="aspect-square radioshort appearance-none bg-#17181d border-gray-400 rounded border-solid border-1 box-border w-4 h-4 hover:scale-110"
            />
            <label
              htmlFor="radio-short"
              className="py-4 ml-2 mr-2 w-full text-sm font-medium text-gray-300 hover:text-tomato"
            >
              Sell Short with TP/SL
            </label>
          </div>
        </div>
      </a>
    );
  }
}

export default React.memo(RadioTPSL);
