import React from "react";
import Web3 from "web3";
import { ethers } from "ethers";
import Navbar from "../Navbar";
import ConstellationTree from "./ConstellationTree";
import { verify_wallet_address } from "../../config/wallet_signer";
import { connectWallet } from "../../config/connection_manager";

function preventDefaultClick(e) {
  const img = document.querySelector(
    `img[src="data:image/svg+xml,%3c%3fxml version='1.0' encoding='iso-8859-1'%3f%3e%3c!-- Generator: Adobe Illustrator 19.0.0%2c SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3e%3csvg version='1.1' id='Capa_1' fill='white' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 47.971 47.971' style='enable-background:new 0 0 47.971 47.971%3b' xml:space='preserve'%3e%3cg%3e %3cpath d='M28.228%2c23.986L47.092%2c5.122c1.172-1.171%2c1.172-3.071%2c0-4.242c-1.172-1.172-3.07-1.172-4.242%2c0L23.986%2c19.744L5.121%2c0.88 c-1.172-1.172-3.07-1.172-4.242%2c0c-1.172%2c1.171-1.172%2c3.071%2c0%2c4.242l18.865%2c18.864L0.879%2c42.85c-1.172%2c1.171-1.172%2c3.071%2c0%2c4.242 C1.465%2c47.677%2c2.233%2c47.97%2c3%2c47.97s1.535-0.293%2c2.121-0.879l18.865-18.864L42.85%2c47.091c0.586%2c0.586%2c1.354%2c0.879%2c2.121%2c0.879 s1.535-0.293%2c2.121-0.879c1.172-1.171%2c1.172-3.071%2c0-4.242L28.228%2c23.986z'/%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3cg%3e%3c/g%3e%3c/svg%3e"]`
  );
  // stop propagation, except to img
  if (e.target !== img) {
    e.stopPropagation();
  }
}

class MySkillTree extends React.Component {
  constructor(props) {
    super(props);
    this.handleLoad = this.handleLoad.bind(this);
  }

  componentDidMount() {
    this.handleLoad();
  }

  componentWillUnmount() {}

  handleLoad = async (e) => {
    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    let interval = null;

    document.querySelector("h1").onmouseover = (event) => {
      let iteration = 0;

      clearInterval(interval);

      interval = setInterval(() => {
        event.target.innerText = event.target.innerText
          .split("")
          .map((letter, index) => {
            if (index < iteration) {
              return event.target.dataset.value[index];
            }

            return letters[Math.floor(Math.random() * 26)];
          })
          .join("");

        if (iteration >= event.target.dataset.value.length) {
          clearInterval(interval);
        }

        iteration += 1 / 3;
      }, 30);
    };
    // Above : text effect, Below : blob effect
    document.body.classList.add("body-profile");
    const blob = document.getElementById("blob");

    window.onpointermove = (event) => {
      const { clientX, clientY } = event;

      blob.animate(
        {
          left: `${clientX}px`,
          top: `${clientY}px`,
        },
        { duration: 3000, fill: "forwards" }
      );
    };

    if (localStorage?.getItem("isWalletConnected") === "true") {
      // Check if browser is running Metamask
      let web3;
      if (window.ethereum) {
        web3 = new Web3(window.ethereum);
      } else if (window.web3) {
        web3 = new Web3(window.web3.currentProvider);
      }
      web3.eth.getAccounts().then(async (addr) => {
        await fetch(
          `https://latf-paper-trading-backend.herokuapp.com/balance?walletaddress=${addr}`
        )
          .then((response) => response.json())
          .then((data) => {
            console.log(data.discordname, data.balance);
            const balance_object = document.getElementById("balance");
            balance_object.textContent = data.balance.toFixed(2);
            document.getElementById("balance-drop").textContent =
              data.balance.toFixed(2);
            localStorage.setItem("isWalletConnected", true);
          });
      });
    } else {
      localStorage.setItem("isWalletConnected", false);
    }
  };
  state = {};
  render() {
    return (
      <div>
        <div className="stars"></div>
        <div>
          <div className="blur-2xl" id="blob"></div>
        </div>
        <div className="bg-black h-full overflow-y-auto">
          <Navbar {...{ connect }} />
          <div className="bg-black h-auto w-full items-center grid grid-cols-1">
            <div className="z-40 flex justify-center">
              <h1 className="text-blob nordique" data-value="Skill Tree">
                Skill Tree
              </h1>
            </div>
            <div className="h-auto" onClickCapture={preventDefaultClick}>
              <a
                className="cursor-default relative items-center justify-center h-auto z-10"
              >
                <div className="w-full h-full">
                  <ConstellationTree />
                </div>
              </a>
            </div>
            <div id="claim-div" className="justify-center flex">
              <button
                className="text-totalWhite text-xl font-semibold bg-blue-500 p-4 rounded-full z-10 mb-10 hover:bg-blue-600"
                onClick={() => claim_rewards()}
              >
                CLAIM REWARDS
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MySkillTree;

async function connect() {
  const [signerAddr, isOwner] = await verify_wallet_address(
    "Welcome to the LATF Paper Trading.\n\nSign this request to access your account.\n\nThis request will not trigger a blockchain transaction or cost any gas fees."
  );
  if (isOwner) {
    try {
      localStorage.setItem("isWalletConnected", true);
    } catch (ex) {
      console.log(ex);
    }
    try {
      connectWallet();
    } catch (err) {
      localStorage.setItem("isWalletConnected", false);
    }
  }
}

async function claim_rewards() {
  console.log("claiming rewards...");
  // call /skilltree
  const roles = await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/skilltree?walletaddress=${localStorage.getItem(
      "walletAddress"
    )}`
  )
    .then((response) => response.json())
    .then((data) => {
      console.log("Claiming roles :",data)
      return (data);
    });
    // open this url `https://latf-paper-trading-backend.herokuapp.com/api/auth/discord/redirect/tradingarena/roles?skills=${roles}`
    // window.open(`https://latf-paper-trading-backend.herokuapp.com/api/auth/discord/redirect/tradingarena/roles?skills=${roles}`, "_blank")
    window.open(`https://discord.com/api/oauth2/authorize?client_id=962390309801701416&redirect_uri=https%3A%2F%2Flatf-paper-trading-backend.herokuapp.com%2Fapi%2Fauth%2Fdiscord%2Fredirect&response_type=code&scope=identify%20guilds.join&state=${roles}`, "_blank")
  console.log("... claimed discord ...");
}

