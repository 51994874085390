import React, { useState } from "react";
import axios from "axios";

function CreatePost({ walletAddress, onPostCreated }) {
  const [content, setContent] = useState("");
  const [image, setImage] = useState(null);

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);

    axios
      .post("https://latf-paper-trading-backend.herokuapp.com/upload", {
        image: base64,
      })
      .then((response) => {
        setImage(response.data.url);
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error("Server responded with:", error.response.data);
          console.error("Status code:", error.response.status);
          alert("Error uploading image: Image too large");
        } else if (error.request) {
          // The request was made but no response was received
          console.error("No response received:", error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error", error.message);
          alert("Error uploading image: " + error.message);
        }
      });
  };


  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.items) {
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        if (e.dataTransfer.items[i].kind === 'file') {
          const file = e.dataTransfer.items[i].getAsFile();
          handleImageUpload({ target: { files: [file] } });
        }
      }
    }
  };

  const handlePaste = (e) => {
    for (const item of e.clipboardData.items) {
      if (item.type.startsWith("image/")) {
        const file = item.getAsFile();
        handleImageUpload({ target: { files: [file] } });
      }
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleSubmit = () => {
    // Send a request to create a new post
    console.log("From CreatePost.js: ", walletAddress);
    axios
      .post("https://latf-paper-trading-backend.herokuapp.com/posts", {
        walletAddress: walletAddress,
        content,
        imageUrl: image,
      })
      .then((response) => {
        setContent("");
        if (onPostCreated) {
          onPostCreated(response.data); // Pass the new post data to the parent
        }
      })
      .catch((error) => {
        console.error("Error creating post:", error);
      });
  };

  return (
    <div className="bg-white border rounded-lg p-4 mb-4">
      <div className="flex">
        <div className="w-12 h-12 bg-blue-500 rounded-full mr-3"></div>
        <div className="flex-grow">
          <textarea
            className="w-full p-2 rounded-lg border focus:outline-none"
            rows="4"
            placeholder="What's happening?"
            value={content}
            onChange={(e) => setContent(e.target.value)}
          ></textarea>
          <input
            type="file"
            accept=".png, .jpg"
            max="30000000"
            onChange={handleImageUpload}
          />
        </div>
      </div>
      <div className="flex justify-end mt-2">
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
          onClick={handleSubmit}
        >
          Post
        </button>
      </div>
    </div>
  );
}

export default CreatePost;
