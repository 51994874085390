import React, { useState, useEffect } from "react";
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import { currencyPairs } from "./CurrenciesDropdown";
const TVchartWrapper = ({ interval, theSymbol, onIntervalChange }) => {
  const [localInterval, setLocalInterval] = useState(interval);

  useEffect(() => {
    setLocalInterval(interval);
  }, [interval]);

  useEffect(() => {
    const intervalChanged = setInterval(() => {
      if (localInterval !== interval) {
        onIntervalChange(localInterval);
      }
    }, 1000);

    return () => {
      clearInterval(intervalChanged);
    };
  }, [localInterval, interval, onIntervalChange]);
  localStorage.setItem("selectedSymbol", theSymbol);
  // if theSymbol is null, then selectedSymbol should be BTCUSDT
  if (theSymbol === null) {
    localStorage.setItem("selectedSymbol", "BTCUSDT");
  }
  try {
    document.getElementById("symbol-selected").textContent = theSymbol || "BTCUSDT";
    document.getElementById("symbol-selected-img").src = currencyPairs.find(
      (pair) => pair.symbol === theSymbol
      ).imgSrc;
      try {
    document.getElementById("symbol-selected-mobile").textContent = theSymbol || "BTCUSDT";
    document.getElementById("symbol-selected-img-mobile").src = currencyPairs.find(
      (pair) => pair.symbol === theSymbol
      ).imgSrc;
    }
    catch (e) {
      console.log(e)
    }
      console.log("symbol-selected-img", document.getElementById("symbol-selected-img").src, theSymbol|| "BTCUSDT")
    }
    catch (e) {
      console.log(e)
    }
    const fullSymbol = `BINANCE:${theSymbol ? theSymbol : "BTCUSDT"}`;
  return (
    <AdvancedRealTimeChart
      theme="dark"
      symbol={fullSymbol}
      height="99%"
      width="100%"
      interval={localInterval}
      toolbar_bg="transparent"
      charts_storage_url="https://saveload.tradingview.com"
      withdateranges={false}
    />
  );
};

const TVchart = React.memo(({ symbol }) => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const storedInterval = localStorage.getItem("selectedInterval");
  const [interval, setInterval] = useState(
    storedInterval ? storedInterval : isMobile ? "1D" : "1H"
  );

  useEffect(() => {
    localStorage.setItem("selectedInterval", interval);
  }, [interval]);

  const updateInterval = (newInterval) => {
    setInterval(newInterval);
    localStorage.setItem("selectedInterval", newInterval);
  };

  return (
    <div className={`${isMobile ? "-mt-1" : ""} fit-content`}>
      <TVchartWrapper interval={interval} theSymbol={symbol} onChange={updateInterval} />
    </div>
  );
});

export default TVchart;
