const leaderboardData = [
    { name: "Trader A", profit: "$1000" },
    { name: "Trader B", profit: "$850" },
    { name: "Trader C", profit: "$820" },
    // ... Add more traders
  ];
  
  export const Leaderboard = () => {
    return (
      <table className="w-full text-center text-totalWhite">
        <thead>
          <tr>
            <th>Trader Name</th>
            <th>Profit</th>
          </tr>
        </thead>
        <tbody>
          {leaderboardData.map((trader, index) => (
            <tr key={index}>
              <td>{trader.name}</td>
              <td>{trader.profit}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  