import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// creat the component redirection, that will redirect the user to the home page
const Redirecter = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/trade?");
  }, [navigate]);
  return null;
};

export default Redirecter;