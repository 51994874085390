const testimonialData = [
  {
    twitterLink: "https://twitter.com/LuckyChartApe",
    name: "LuckyChartApe",
    feedback:
      "This trading tournament platform is one of the best out there! Intuitive UI, transparent rules, and a competitive environment make it a top choice for traders. Highly recommended!",
  }, // ... Add more testimonials
];

export const Testimonials = () => {
  return (
    <div className="flex flex-col items-center justify-center testimonial-section">
      {testimonialData.map((testimonial, index) => (
        <div key={index} className="testimonial max-w-md text-center mb-8">
          <p>"{testimonial.feedback}"</p>
          <div className="flex items-center justify-end mt-2">
            <button
              onClick={() => window.open(testimonial.twitterLink, "_blank")}
            >
            <p  className="cursor-pointer mr-2 text-blue-500">- {testimonial.name}</p>
            </button>
            <button
              className="mr-2"
              onClick={() => window.open(testimonial.twitterLink, "_blank")}
            >
              <img
                className="w-12 h-12 rounded-full shadow-lg cursor-pointer"
                src="https://i.ibb.co/prNDQFP/lucky-pp-twiter.jpg"
                alt={testimonial.name}
              />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};
