import React from 'react';

const MaintenanceComponent = () => {
  return (
    <div className="maintenance-container">
      <div className="maintenance-box">
        <h1>Under Maintenance</h1>
        <p>This page is currently under maintenance. Please check back later.</p>
      </div>
    </div>
  );
};

export default MaintenanceComponent;
