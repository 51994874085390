import { useState } from 'react';

function CopyButton() {
  const [buttonColor, setButtonColor] = useState('blue-500');

  const copyReferralLink = async() => {
    const referralLink = document.getElementById("referralLink").innerHTML;
    navigator.clipboard
    .writeText(referralLink)
    .then(() => {})
    .catch((error) => {
      alert(
        "Failed to copy referral link:",
        error,
        ". Consider copying manually."
      );
    });
    setButtonColor('green-600 scale-110');
    setTimeout(() => {
      setButtonColor('blue-500 scale-100');
    }, 500);
  };

  return (
    <button
      onClick={copyReferralLink}
      className={`rounded-r-2xl text-white text-lg font-semibold p-3 hover:bg-green-500 bg-${buttonColor}`}
    >
      <i className="material-icons hover:scale-110">
        content_copy
      </i>
    </button>
  );
}

export default CopyButton;