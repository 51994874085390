import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { getUserAddress } from "../../config/connection_manager";
import NavbarNoBg from "./NavbarBattle";
import TVchart from "../Home/TVchart";
import CurrenciesDropdown from "../Home/CurrenciesDropdown";
import { currencyPairs } from "../Home/CurrenciesDropdown";
import TradingTerminal from "./TradingTerminal";
import BattleArchivePositions from "./BattleArchivePositions";
import { verify_wallet_address } from "../../config/wallet_signer";
import { connectWallet } from "../../config/connection_manager";

function BattlePage() {
  const { battleId } = useParams();
  // save battleid in localstorage
  const [activeTab, setActiveTab] = useState("info"); // default to 'info'
  const [tournamentDetails, setTournamentDetails] = useState({
    participants: [],
  });
  const [timeRemaining, setTimeRemaining] = useState("00:00:00");
  const [opponentWalletAddress, setOpponentWalletAddress] = useState("");
  const [opponentTotalBalance, setOpponentTotalBalance] = useState(0);
  const [balance, setBalance] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [selectedSymbol, setSymbolSelect] = useState(
    localStorage.getItem("selectedSymbol") || "BTCUSDT"
  );

  const searchSymbol = useRef(null);

  useEffect(() => {
    document.getElementById("symbol-selected").textContent =
      localStorage.getItem("selectedSymbol");
    document.getElementById("symbol-selected-img").src = currencyPairs.find(
      (pair) => pair.symbol === localStorage.getItem("selectedSymbol")
    ).imgSrc;
    document.getElementById("symbol-selected-mobile").textContent =
      localStorage.getItem("selectedSymbol");
    document.getElementById("symbol-selected-img-mobile").src =
      currencyPairs.find(
        (pair) => pair.symbol === localStorage.getItem("selectedSymbol")
      ).imgSrc;
    console.log("Symbol selected", selectedSymbol);
  }, []);

  const handleSymbolChange = () => {
    const input = searchSymbol.current.value;
    const rows = document.querySelectorAll("tbody tr");
    rows.forEach((row) => {
      const symbolCell = row.querySelector("td:nth-child(1)");
      if (symbolCell) {
        if (
          symbolCell.textContent.toLowerCase().includes(input.toLowerCase())
        ) {
          row.style.display = "table-row";
        } else {
          row.style.display = "none";
        }
      }
    });
  };

  useEffect(() => {
    async function getBattleData() {
      const walletAddress = await getUserAddress();
      if (!walletAddress && !localStorage.getItem("signUpPopup")) {
        document.getElementById("sign-up-popup").classList.remove("hidden");
        // only once, block for next reloads
        localStorage.setItem("signUpPopup", "true");
      }
      const response = await fetch(
        `https://latf-paper-trading-backend.herokuapp.com/battles/${battleId}?walletAddress=${walletAddress}`
      );
      const data = await response.json();
      setTournamentDetails(data);
      console.log("Battle data", data);
      if (data.battle.status !== "Completed") {
        localStorage.setItem("battleId", battleId);
      }
      const currentBalance = await getBalanceByWallet(data, walletAddress);
      setBalance(currentBalance);
      setOpponentWalletAddress(getOpponentWalletAddress(data, walletAddress));
    }
    getBattleData();
    const intervalId = setInterval(getBattleData, 2000);
    return () => clearInterval(intervalId);
  }, [battleId]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      setTimeRemaining(
        calculateTimeLeft(tournamentDetails?.battle.endtime) || "--:--:--"
      );
      const response2 = await fetch(
        `https://latf-paper-trading-backend.herokuapp.com/battle/user/dynamicbalance?walletaddress=${await getUserAddress()}&battleid=${battleId}`
      );
      const data2 = await response2.json();
      setTotalBalance(parseFloat(data2).toFixed(2));
      const response3 = await fetch(
        `https://latf-paper-trading-backend.herokuapp.com/battle/user/dynamicbalance?walletaddress=${opponentWalletAddress}&battleid=${battleId}`
      );
      const data3 = await response3.json();
      setOpponentTotalBalance(parseFloat(data3).toFixed(2));
    }, 2000);

    return () => clearInterval(intervalId); // Clear the interval when the component unmounts
  }, [tournamentDetails.battle?.endtime]);

  return (
    <div className="h-full bg-bbbglight">
      <NavbarNoBg {...{ connect, balance, totalBalance, timeRemaining }} />

      <div
        className={`
    flex-grow 
    ${
      tournamentDetails?.battle?.status === "Completed" ||
      tournamentDetails?.battle?.status === "Waiting"
        ? "relative h-screen"
        : ""
    }
  `}
      >
        {tournamentDetails?.battle?.status === "Waiting" && (
          <>
            <div className="absolute inset-0 bg-black opacity-50 flex items-center justify-center z-50">
              <span className="text-white text-2xl font-bold">
                Battle started - Waiting for opponent - The battle will start on
                this url.
              </span>
            </div>
            <style>
              {`
            body {
              overflow: hidden;
            }
          `}
            </style>
          </>
        )}
        {tournamentDetails?.battle?.status === "Completed" && (
          <>
            <div className="absolute inset-0 bg-black opacity-50 flex items-center justify-center z-50">
              <span className="text-white text-2xl font-bold">
                BATTLE FINISHED
              </span>
            </div>
            <style>
              {`
            body {
              overflow: hidden;
            }
          `}
            </style>
          </>
        )}
        <div className="px-4 md:px-0">
          <div className="flex flex-col md:flex-row md:space-y-0 w-full mx-auto mt-2 justify-around">
            <div className={`${activeTab === "info" ? "block space-y-10" : "hidden"}`}>
              <div className="md:flex md:items-center md:space-x-20">
              {/* Leaderboard */}
              <div className="flex-2 bg-gray-800 rounded shadow overflow-x-auto">
                <table className="min-w-full">
                  <thead className="bg-gray-700 text-gray-400">
                    <tr className="text-center text-base font-serif font-base">
                      <th className="px-4 py-2">Wallet Address</th>
                      <th className="px-4 py-2">Elo Rating</th>
                      <th className="px-4 py-2">Balance</th>
                    </tr>
                  </thead>
                  <tbody className="text-totalWhite text-center">
                    {tournamentDetails.participants[0]?.walletaddress !==
                    opponentWalletAddress ? (
                      <>
                        <tr className="battle-user-color">
                          <td className=" px-4 py-2">
                            {tournamentDetails.participants[0]?.walletaddress.slice(
                              0,
                              10
                            ) + "..."}
                          </td>
                          <td className=" px-4 py-2">
                            {tournamentDetails.participants[0]?.elorating}
                          </td>
                          <td className=" px-4 py-2">{totalBalance}</td>
                        </tr>
                        <tr>
                          <td className=" px-4 py-2">
                            {tournamentDetails.participants[1]?.walletaddress.slice(
                              0,
                              10
                            ) + "..."}
                          </td>
                          <td className=" px-4 py-2">
                            {tournamentDetails.participants[1]?.elorating}
                          </td>
                          <td className=" px-4 py-2">{opponentTotalBalance}</td>
                        </tr>
                      </>
                    ) : (
                      <>
                        <tr className="border-b bg-gray-800 border-gray-700">
                          <td className=" px-4 py-2">
                            {tournamentDetails.participants[0]?.walletaddress.slice(
                              0,
                              10
                            ) + "..."}
                          </td>
                          <td className=" px-4 py-2">
                            {tournamentDetails.participants[0]?.elorating}
                          </td>
                          <td className=" px-4 py-2">{opponentTotalBalance}</td>
                        </tr>
                        <tr className="battle-user-color">
                          <td className=" px-4 py-2">
                            {tournamentDetails.participants[1]?.walletaddress.slice(
                              0,
                              10
                            ) + "..."}
                          </td>
                          <td className=" px-4 py-2">
                            {tournamentDetails.participants[1]?.elorating}
                          </td>
                          <td className=" px-4 py-2">{totalBalance}</td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>

              {/* Timer */}
              <div className="flex-2 text-xl text-totalWhite p-4 rounded shadow text-center">
                {timeRemaining === "NaN:NaN:NaN" ? (
                  <div className="text-2xl font-bold">--:--:--</div>
                ) : (
                  <>
                    <div className="text-2xl font-bold">{timeRemaining}</div>
                    <div className="text-sm font-bold">Remaining</div>
                  </>
                )}
              </div>

              {/* Balances */}
              <div className="flex-2 md:w-auto rounded shadow text-gray-600 text-center">
                <div className="text-lg font-bold bg-gray-700 text-gray-400 p-2 rounded-lg">
                  <div className="mb-2">
                    Avbl. Balance:{" "}
                    <span className="text-gray-100" id="battleBalance">
                      {balance}
                    </span>
                  </div>
                  <div>
                    Total Balance:&nbsp;
                    <span className="text-gray-100" id="battleTotalBalance">
                      {totalBalance}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <BattleArchivePositions battleId={battleId} />
          </div>
          </div>

          {/* Chart & Terminal */}
          <div className={`${activeTab === "trade" ? "block" : "hidden"}`}>
            <div
              id="column tv-chart"
              className="column border-black hide-on-mobile"
              style={{ backgroundColor: "#17181f" }}
            >
              <div className="grid grid-cols-1 divide-y">
                <div></div>
                <div className="charty border-none">
                  <form>
                    <div className="inner">
                      <div className="div-hover full-height z-20 bg-tvcolor text-totalWhite text-center hover:bg-gray-800">
                        <div className="flex items-center place-content-center space-x-1 py-1">
                          <img
                            id="symbol-selected-img"
                            className="rounded-full h-5"
                          ></img>
                          <span
                            id="symbol-selected"
                            className="text-lg font-semibold"
                          ></span>
                        </div>
                        <div className="div-hovered w-96 h-80 bg-bbbglight hidden grid grid-rows-10 overflow-y-auto overflow-none">
                          <div className="row-span-2 place-items-center">
                            {/* Create a input bar */}
                            <div className="h-1/6">
                              <input
                                id="search-symbol"
                                className="w-4/5 h-3/5 mt-3 text-center bg-gray-200 text-black border border-gray-200 rounded py-1.5 px-1 focus:outline-none focus:bg-white focus:border-gray-500"
                                placeholder="Search Symbol"
                                ref={searchSymbol}
                                onChange={handleSymbolChange}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="row-span-8 -mt-2">
                            <table className="table-auto text-totalWhite w-full text-center border-collapse border-0 text-sm">
                              <CurrenciesDropdown
                                onSymbolSelect={setSymbolSelect}
                              />
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div id="container-tvchart" className="">
                <TVchart className="" symbol={selectedSymbol} />
              </div>
            </div>
            {/* MOBILE Pair Selector */}
            <div className="relative show-on-mobile h-20 w-full">
              <div className="charty border-none">
                <form>
                  <div className="inner">
                    <div className="div-hover full-height z-20 bg-tvcolor text-totalWhite text-center hover:bg-gray-800">
                      <div className="flex items-center justify-center space-x-2 py-2">
                        <img
                          id="symbol-selected-img-mobile"
                          className="rounded-full h-6"
                        />
                        <span
                          id="symbol-selected-mobile"
                          className="text-xl font-semibold"
                        ></span>
                      </div>
                      <div className="div-hovered w-80 md:w-96 h-80 bg-bbbglight hidden grid grid-rows-10 overflow-y-auto">
                        <div className="row-span-2 place-items-center">
                          <div className="h-1/6 p-2">
                            <input
                              id="search-symbol"
                              className="w-full h-3/5 text-center bg-gray-200 text-black border border-gray-200 rounded py-1.5 px-2 focus:outline-none focus:bg-white focus:border-gray-500"
                              placeholder="Search Symbol"
                              ref={searchSymbol}
                              onChange={handleSymbolChange}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="row-span-8 -mt-2">
                          <table className="table-auto text-totalWhite w-full text-center border-collapse border-0 text-sm">
                            <CurrenciesDropdown
                              onSymbolSelect={setSymbolSelect}
                            />
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <TradingTerminal />
          </div>
        </div>


        {/* Mobile Tab Bar */}
        <div className="block fixed bottom-0 left-0 w-full border-t bg-gray-800 shadow-3xl border-gray-700 md:h-14 text-xl font-bold z-50">
          <div className="flex content-center">
            <button
              className={`flex-1 py-1 text-center ${
                activeTab === "info" ? "text-yellowlegit-500" : "text-gray-500"
              }`}
              onClick={() => setActiveTab("info")}
            >
              <i className="material-icons" style={{ fontSize: '48px' }}>home</i>
            </button>
            <button
              className={`flex-1 py-1 text-center border-l border-gray-600  ${
                activeTab === "trade" ? "text-yellowlegit-500" : "text-gray-500"
              }`}
              onClick={() => setActiveTab("trade")}
            >
              <i className="material-icons" style={{ fontSize: '48px' }}>analytics</i>
            </button>

          </div>
        </div>
      </div>
    </div>
  );
}

export default BattlePage;

const calculateTimeLeft = (endTime) => {
  const correctEndTime = parseInt(endTime, 10);
  const currentMillis = new Date().getTime();
  let millisRemaining = correctEndTime - currentMillis;
  if (millisRemaining <= 0) return "00:00:00";
  let secondsRemaining = Math.floor(millisRemaining / 1000);
  const hours = Math.floor(secondsRemaining / 3600);
  secondsRemaining %= 3600;
  const minutes = Math.floor(secondsRemaining / 60);
  const seconds = secondsRemaining % 60;
  const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  return formattedTime;
};

function getBalanceByWallet(data, walletAddress) {
  for (let participant of data.participants) {
    if (participant.walletaddress === walletAddress) {
      return participant.currentbalance;
    }
  }
  return null;
}

function getBalanceByOpponentWallet(data, walletAddress) {
  for (let participant of data.participants) {
    if (participant.walletaddress !== walletAddress) {
      return participant.currentbalance;
    }
  }
  return null;
}

function getOpponentWalletAddress(data, walletAddress) {
  for (let participant of data.participants) {
    if (participant.walletaddress !== walletAddress) {
      return participant.walletaddress;
    }
  }
}

// function to get opponent data : opponent wallet address, opponent balance, opponent elorating, opponent total balance
function getOpponentData(data, walletAddress) {
  for (let participant of data.participants) {
    if (participant.walletaddress !== walletAddress) {
      return participant;
    }
  }
  return null;
}

// /battle/user/archive/positions
async function getArchivePositions(walletAddress, battleId) {
  const response = await fetch(
    `https://latf-paper-trading-backend.herokuapp.com//battle/user/archive/positions?walletaddress=${walletAddress}&battleid=${battleId}`
  );
  const data = await response.json();
  return data;
}

async function connect() {
  const [signerAddr, isOwner] = await verify_wallet_address(
    "Welcome to the LATF Paper Trading.\n\nSign this request to access your account.\n\nThis request will not trigger a blockchain transaction or cost any gas fees."
  );
  if (isOwner) {
    try {
      localStorage.setItem("isWalletConnected", true);
    } catch (ex) {
      console.log(ex);
    }
    try {
      connectWallet();
    } catch (err) {
      localStorage.setItem("isWalletConnected", false);
      document.getElementById("sign-up-popup").classList.remove("hidden");
    }
  }
}