import React, { createRef, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  load_positions,
  display_close_limit,
  display_close_market,
  close_partial_positon,
} from "../../config/position_load_battle";
import Qtyslider from "./QtysliderBattle";
import QtysliderRiskMngt from "../Home/QtysliderRiskMngt";
import RadioTPSL from "../Home/RadioTPSL";
import { pairs } from "../pairs";
import { currencyPairs } from "../Home/CurrenciesDropdown";
import ButtonLimitMarket from "../Home/ButtonLimitMarket";
import LeverageSlider from "../Home/LeverageSlider";
import refresh_pnl from "../../config/price_refresher.js";
import { verify_wallet_address } from "../../config/wallet_signer";
import { getUserAddress, connectWallet } from "../../config/connection_manager";
import DisplayPositionsOrders from "../Home/DisplayPositionsOrders";
import UsersetMenu from "../Home/UsersetMenu";


function close_modal() {
  document.getElementById("modal-close-position").style.display = "none";
}

async function cancel_order_manager(orderid, walletaddress, handleLoad) {
  await fetch(`https://latf-paper-trading-backend.herokuapp.com/battle/order/byid?orderid=${orderid}`)
    .then((response) => response.json())
    .then((data) => {
      const quantity = data[0].quantity;
      const orderprice = data[0].orderprice;
      cancel_order(orderid, walletaddress, quantity, orderprice, handleLoad);
    });
}

async function cancel_order(
  orderid,
  walletaddress,
  quantity,
  orderprice,
  handleLoad
) {
  // Check a way to query quantity and order price from DB to be sure
  await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/battle/cancel/order?orderid=${orderid}&walletaddress=${walletaddress}&quantity=${quantity}&orderprice=${orderprice}`
  )
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      handleLoad();
      // window.location.reload();
    });
}

async function load_orders(walletaddress, handleLoad, battleid) {
  // Change walletaddress (uppercase) to see someone else orders.
  console.log("LOADING ORDERS", walletaddress, battleid);
  await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/battle/orders/user?walletaddress=${walletaddress}&battleid=${battleid}`
  )
    .then((response) => response.json())
    .then((data) => {
      console.log("BATTLE ORDERS === \n\n", data, "\n\n");
      const fragment = document.createDocumentFragment(); // Create a document fragment to hold the new rows

      // Iterate over the data and construct the new rows in the document fragment
      data.forEach((order, index) => {
        let color = "tomato";
        if (order.side === "long") {
          color = "green";
        }

        const row = document.createElement("tr");
        row.className =
          "border-b bg-gray-800 border-gray-700 hover:bg-gray-700 hover:border-gray-800";

        const th = document.createElement("th");
        th.scope = "row";
        th.className = `py-1 px-3 font-medium ${color}-color whitespace-nowrap`;
        th.innerHTML = `
          <div class="font-medium">${order.contract}</div>
          <div class="text-xs text-gray-200">${order.leverage}x</div>
        `;
        row.appendChild(th);

        const tdQuantity = document.createElement("td");
        tdQuantity.className = "py-3 px-3";
        tdQuantity.textContent = order.quantity.toFixed(5);
        row.appendChild(tdQuantity);

        const tdOrderPrice = document.createElement("td");
        tdOrderPrice.className = "py-3 px-3";
        tdOrderPrice.textContent = order.orderprice;
        row.appendChild(tdOrderPrice);

        const tdTakeProfit = document.createElement("td");
        tdTakeProfit.className = "py-3 px-3";
        tdTakeProfit.id = `edit-order-tp-${index}`;
        tdTakeProfit.textContent = order.takeprofit || "";
        row.appendChild(tdTakeProfit);

        const tdStopLoss = document.createElement("td");
        tdStopLoss.className = "py-3 px-3";
        tdStopLoss.id = `edit-order-sl-${index}`;
        tdStopLoss.textContent = order.stoploss || "";
        row.appendChild(tdStopLoss);

        const tdOrderID = document.createElement("td");
        tdOrderID.className = "py-3 px-3";
        tdOrderID.textContent = order.orderid;
        row.appendChild(tdOrderID);

        const tdCancelButton = document.createElement("td");
        tdCancelButton.className = "py-3 px-3";
        tdCancelButton.id = `cancel-button-${index}`;
        row.appendChild(tdCancelButton);

        fragment.appendChild(row);

        const cancelButton = document.createElement("button");
        cancelButton.onclick = function () {
          cancel_order_manager(order.orderid, walletaddress, handleLoad);
        };
        cancelButton.id = `button-cancel-order-${index}`;
        cancelButton.className = "btn-close btn-close-white";
        cancelButton.value = order.orderid;
        tdCancelButton.appendChild(cancelButton);

        const tpButton = createEditButton(
          order.orderid,
          "tp",
          `edit-order-tp-${index}`
        );
        tdTakeProfit.appendChild(tpButton);

        const slButton = createEditButton(
          order.orderid,
          "sl",
          `edit-order-sl-${index}`
        );
        tdStopLoss.appendChild(slButton);
      });

      const table = document.getElementById("orders-table");
      clearTable(table); // Clear the contents of the table

      table.appendChild(fragment); // Append the document fragment to the table, updating the table in a single operation
      const ordersTable = document.getElementById("main-table-orders");
      if (ordersTable) {
        const n_orders = ordersTable.getElementsByTagName("tr").length - 1;
        document.getElementById("orders-count").innerHTML = n_orders;
      }
      // Perform any visual changes or animations here after the update is complete
    });
}

function clearTable(table) {
  while (table.firstChild) {
    table.removeChild(table.firstChild);
  }
}

function createEditButton(orderId, type, parentID) {
  const button = document.createElement("button");
  button.onclick = function () {
    console.log(`Clicked on button edit ${type}`, button.value);
    allow_edit_order(orderId, type, button.id, parentID);
  };
  button.value = orderId;
  button.id = `button-edit-order-${type}-${orderId}`;
  button.className =
    "p-0.5 bg-yellow rounded-xl hover:rounded-3xl hover:bg-yellowlight transition-all duration-300 text-white";
  button.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2"><path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" /></svg>`;
  return button;
}

async function allow_edit_order(orderid, tpSl, buttonID, parentID) {
  const button = document.createElement("button");
  const input = document.createElement("input");
  input.className = "text-black text-center";
  button.className = "text-green-300";
  button.textContent = "✓";
  button.value = orderid;

  let url = "";
  if (tpSl === "tp") {
    url = `https://latf-paper-trading-backend.herokuapp.com/battle/edit/order/tp?orderid=${button.value}&takeprofit=`;
  } else {
    url = `https://latf-paper-trading-backend.herokuapp.com/battle/edit/order/sl?orderid=${button.value}&stoploss=`;
  }
  button.onclick = async function () {
    input.style.display = "none";
    button.style.display = "none";
    await fetch(url + input.value)
      .then((response) => response.json())
      .then((data) => {
        if (data === 200) {
          document.getElementById(parentID).textContent = input.value;
          window.location.reload();
        }
      });
  };
  document.getElementById(parentID).textContent = "";
  document.getElementById(parentID).appendChild(button);
  document.getElementById(parentID).appendChild(input);
}

async function fetch_new_prices(walletaddress) {
  const proxyURL = "https://latf-proxy.herokuapp.com/";
  await fetch(
    `${proxyURL}https://api.binance.com/api/v3/ticker/price?symbols=${pairs}`
  )
    .then((response) => response.json())
    .then((data) => {
      // console.log("using proxy here");
      var dict_current_prices = {};
      let i = 0;
      while (i < data.length) {
        try {
          dict_current_prices[`${data[i].symbol}`] = data[i].price;
          localStorage.setItem(data[i].symbol, data[i].price);
          var price = data[i].price;
          // console.log(price, price.length);
          if (parseFloat(price) > 1) {
            price = parseFloat(price).toFixed(2);
          } else {
            price = parseFloat(price).toFixed(5);
          }
          document.getElementById(data[i].symbol).textContent = `${price}`;
        } catch (err) {
          console.log(err, data[i].symbol, "Add the pair to the dropdown");
        }
        i++;
      }
      // console.log(dict_current_prices);
      refresh_pnl(walletaddress, dict_current_prices);
    });
}

async function position_manager(
  walletaddress,
  list_contracts,
  handleLoad,
  battleid
) {
  const proxyURL = "https://latf-proxy.herokuapp.com/";
  await fetch(
    `${proxyURL}https://api.binance.com/api/v3/ticker/price?symbols=${list_contracts}`
  )
    .then((response) => response.json())
    .then((data) => {
      console.log("using proxy here");
      var dict_current_prices = {};
      let i = 0;
      while (i < data.length) {
        try {
          dict_current_prices[`${data[i].symbol}`] = data[i].price;
          localStorage.setItem(data[i].symbol, data[i].price);
          var price = data[i].price;
          // console.log(price, price.length);
          if (parseFloat(price) > 1) {
            price = parseFloat(price).toFixed(2);
          } else {
            price = parseFloat(price).toFixed(5);
          }
          document.getElementById(data[i].symbol).textContent = `${price}`;
        } catch (err) {
          console.log(err, data[i].symbol, "Add the pair to the dropdown");
        }
        i++;
      }
      console.log(dict_current_prices);
      load_positions(walletaddress, dict_current_prices, handleLoad, battleid);
    });
}

async function load_dropdown_prices(list_contracts) {
  const proxyURL = "https://latf-proxy.herokuapp.com/";
  await fetch(
    `${proxyURL}https://api.binance.com/api/v3/ticker/price?symbols=${list_contracts}`
  )
    .then((response) => response.json())
    .then((data) => {
      console.log("using proxy here");
      var dict_current_prices = {};
      let i = 0;
      while (i < data.length) {
        try {
          dict_current_prices[`${data[i].symbol}`] = data[i].price;
          localStorage.setItem(data[i].symbol, data[i].price);
          var price = data[i].price;
          // console.log(price, price.length);
          if (parseFloat(price) > 1) {
            price = parseFloat(price).toFixed(2);
          } else {
            price = parseFloat(price).toFixed(5);
          }
          document.getElementById(data[i].symbol).textContent = `${price}`;
        } catch (err) {
          console.log(err, data[i].symbol, "Add the pair to the dropdown");
        }
        i++;
      }
    });
}

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.handleLoad = this.handleLoad.bind(this);
    this.handler = this.handler.bind(this);
    this.handleSymbolSelect = this.handleSymbolSelect.bind(this);
    this.state = {
      selectedSymbol: localStorage.getItem("selectedSymbol") || "BTCUSDT",
    };
  }

  handleSymbolSelect(symbol) {
    this.setState({ selectedSymbol: symbol });
  }

  handler() {
    console.log("length = ", this.search.value.length);
    if (this.tpPrice.value.length === 0) {
      this.setState({
        recapTP: "",
        recapTPmarket: "",
      });
    } else if (document.getElementById("radio-short").checked) {
      this.setState({
        recapTP: -(
          (this.tpPrice.value - this.search.value) *
          this.search_bis.value
        ).toFixed(2),
        recapTPmarket: -(
          (this.tpPrice.value -
            parseFloat(localStorage.getItem(geturl_symbol()))) *
          this.search_bis.value
        ).toFixed(2),
      });
    } else {
      this.setState({
        recapTP: (
          (this.tpPrice.value - this.search.value) *
          this.search_bis.value
        ).toFixed(2),
        recapTPmarket: (
          (this.tpPrice.value -
            parseFloat(localStorage.getItem(geturl_symbol()))) *
          this.search_bis.value
        ).toFixed(2),
      });
    }
    if (this.slPrice.value.length === 0) {
      this.setState({
        recapSL: "",
        recapSLmarket: "",
      });
    } else if (document.getElementById("radio-short").checked) {
      this.setState({
        recapSL: -(
          (this.slPrice.value - this.search.value) *
          this.search_bis.value
        ).toFixed(2),
        recapSLmarket: -(
          (this.slPrice.value -
            parseFloat(localStorage.getItem(geturl_symbol()))) *
          this.search_bis.value
        ).toFixed(2),
      });
    } else {
      this.setState({
        recapSL: (
          (this.slPrice.value - this.search.value) *
          this.search_bis.value
        ).toFixed(2),
        recapSLmarket: (
          (this.slPrice.value -
            parseFloat(localStorage.getItem(geturl_symbol()))) *
          this.search_bis.value
        ).toFixed(2),
      });
    }
    if (this.search.value.length === 0) {
      this.setState({
        recapTP: "",
        recapSL: "",
      });
    }
  }

  toggleDropdown = (e) => {
    this.setState((prevState) => ({
      dropdownVisible: !prevState.dropdownVisible,
    }));
  };

  componentDidMount() {
    this.handleLoad();
    this.interval = setInterval(this.handleLoad, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleLoad = async (e) => {
    document.getElementById("symbol-selected").textContent = geturl_symbol();
    document.getElementById("symbol-selected-img").src = currencyPairs.find(
      (pair) => pair.symbol === geturl_symbol()
    ).imgSrc;
    document.getElementById("symbol-selected-mobile").textContent = geturl_symbol();
    document.getElementById("symbol-selected-img-mobile").src = currencyPairs.find(
      (pair) => pair.symbol === geturl_symbol()
    ).imgSrc;
    const battleid = geturl_battleid();
    load_dropdown_prices(pairs);
    if (localStorage?.getItem("isWalletConnected") === "true") {
      const addr = await getUserAddress();
      console.log("addr", addr);
      try {
        await fetch(
          `https://latf-paper-trading-backend.herokuapp.com/balance?walletaddress=${addr}`
        )
          .then((response) => response.json())
          .then((data) => {
            // To remove once openbeta banner is removed
            localStorage.setItem("isWalletConnected", true);
            localStorage.setItem("walletAddress", addr);
            localStorage.setItem("balance", data.balance);
            const battleid = geturl_battleid();
            load_orders(
              addr.toString().toUpperCase(),
              this.handleLoad,
              battleid
            );
            position_manager(
              addr.toString().toUpperCase(),
              pairs,
              this.handleLoad,
              battleid
            );
            risk_management_tool(addr);

            // make a function that is called every 5 seconds that call fetch_new_prices
            // setInterval(() => {
            fetch_new_prices(addr);
            getDynamicBalanceBis(addr);
            // }, 5000);
          });
      } catch (error) {
        document.getElementById("sign-up-popup").classList.remove("hidden");
      }
    } else {
      try {
        console.log("HERE", localStorage.getItem("wagmi.connected") === "true");
        const isConnected = localStorage.getItem("wagmi.connected") === "true";
        if (isConnected) {
          setTimeout(() => {
            connect();
            console.log("x");
          }, 2000);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  state = {
    query: "BINANCE:BTCUSDT",
    searchSymbol: "BTC/USDT",
    ordervalue: 0,
    ordervalueMarket: 0,
    closeQuantityMarket: 0,
    closeQuantityLimit: 0,
    closePriceLimit: 0,
    navbarOpen: 0,
    setNavbarOpen: 0,
    slPrice: "",
    tpPrice: "",
  };

  handleInputChange = () => {
    document.getElementById("limit-order-value1").style.display = "block";
    document.getElementById("limit-order-value2").style.display = "none";
    document.getElementById("market-order-value1").style.display = "block";
    document.getElementById("market-order-value2").style.display = "none";
    this.setState({
      ordervalueMarket: (
        parseFloat(localStorage.getItem(geturl_symbol())) *
        this.search_bis.value *
        1.01
      ).toFixed(2),
      ordervalue: (this.search.value * this.search_bis.value).toFixed(2),
    });
    if (this.tpPrice.value.length === 0) {
      this.setState({
        recapTP: "",
        recapTPmarket: "",
      });
    } else if (document.getElementById("radio-short").checked) {
      this.setState({
        recapTP: -(
          (this.tpPrice.value - this.search.value) *
          this.search_bis.value
        ).toFixed(2),
        recapTPmarket: -(
          (this.tpPrice.value -
            parseFloat(localStorage.getItem(geturl_symbol()))) *
          this.search_bis.value
        ).toFixed(2),
      });
    } else {
      this.setState({
        recapTP: (
          (this.tpPrice.value - this.search.value) *
          this.search_bis.value
        ).toFixed(2),
        recapTPmarket: (
          (this.tpPrice.value -
            parseFloat(localStorage.getItem(geturl_symbol()))) *
          this.search_bis.value
        ).toFixed(2),
      });
    }
    if (this.slPrice.value.length === 0) {
      this.setState({
        recapSL: "",
        recapSLmarket: "",
      });
    } else if (document.getElementById("radio-short").checked) {
      this.setState({
        recapSL: -(
          (this.slPrice.value - this.search.value) *
          this.search_bis.value
        ).toFixed(2),
        recapSLmarket: -(
          (this.slPrice.value -
            parseFloat(localStorage.getItem(geturl_symbol()))) *
          this.search_bis.value
        ).toFixed(2),
      });
    } else {
      this.setState({
        recapSL: (
          (this.slPrice.value - this.search.value) *
          this.search_bis.value
        ).toFixed(2),
        recapSLmarket: (
          (this.slPrice.value -
            parseFloat(localStorage.getItem(geturl_symbol()))) *
          this.search_bis.value
        ).toFixed(2),
      });
    }
    if (this.search.value.length === 0) {
      this.setState({
        recapTP: "",
        recapSL: "",
      });
    }
  };

  handleModalQuantityChangeMarket = () => {
    this.setState({
      closeQuantityMarket: this.search_quantity.value,
    });
  };
  handleModalQuantityChangeLimit = () => {
    this.setState({
      closeQuantityLimit: this.search_quantityLimit.value,
    });
  };
  handleModalPriceChangeLimit = () => {
    this.setState({
      closePriceLimit: this.search_PriceLimit.value,
    });
  };

  handleSymbolChange = () => {
    const input = this.searchSymbol.value;
    // Get all of the table rows
    const rows = document.querySelectorAll("tbody tr");

    // Loop through each row
    rows.forEach((row) => {
      // Get the first column of the row (where the symbol is)
      const symbolCell = row.querySelector("td:nth-child(1)");

      // If the symbol contains the user's input, show the row,
      // otherwise, hide it
      try {
        if (
          symbolCell.textContent.toLowerCase().includes(input.toLowerCase())
        ) {
          row.style.display = "table-row";
        } else {
          row.style.display = "none";
        }
      } catch {}
    });
  };

  render() {
    const { query } = this.state;
    const { ordervalue } = this.state;
    const { ordervalueMarket } = this.state;
    const { recapTP } = this.state;
    const { recapTPmarket } = this.state;
    const { recapSL } = this.state;
    const { recapSLmarket } = this.state;
    const { tpPrice } = this.state;
    const { slPrice } = this.state;
    const { closeQuantityMarket } = this.state;
    const { closeQuantityLimit } = this.state;
    const { closePriceLimit } = this.state;
    return (
      <div className="box">
        <div
          id="modal-close-position"
          className="content rounded-xl hidden text-center grid grid-rows-6 gap-20 p-1"
          style={{
            backgroundImage: 'url("../../images/backgrounddetails.png")',
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
        >
          <div className="grid place-items-center place-content-center gap-4">
            <div className="flex row-span-1">
              <button
                onClick={close_modal}
                className="btn-close btn-close-white absolute top-0 right-0 rotator-45"
              ></button>
            </div>
            <div className="row-span-1">
              <h2 class="text-center text-totalWhite text-3xl font-bold mb-4">
                Closing <span class="text-yellow">Position</span>
              </h2>
              <div className="row-span-1 grid grid-cols-2 place-content-stretch">
                <div className="inline-flex">
                  <button
                    onClick={display_close_market}
                    id="btn-close-market"
                    className="w-full hover:bg-tvcolor text-yellow font-semibold hover:text-totalWhite hover:border-transparent font-bold py-2 px-4 rounded-l bg-gray-700"
                  >
                    Market
                  </button>
                </div>
                <div>
                  <button
                    onClick={display_close_limit}
                    id="btn-close-limit"
                    className="w-full hover:bg-tvcolor text-gray-500 font-semibold hover:text-gray-600 hover:border-transparent font-bold py-2 px-4 rounded-r bg-bbbglight"
                  >
                    Limit
                  </button>
                </div>
              </div>
              <div className="row-span-1">
                <p id="store_order_id" className="text-sm italic">
                  {" "}
                  Order ID:
                </p>
                <p id="store_quantity" className="text-sm italic"></p>
              </div>
              <div id="limit-close" className="hidden row-span-1">
                <form className="w-full max-w-lg">
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div
                      id="div-close-limit-price"
                      className="w-full md:w-1/2 px-3"
                    >
                      <label
                        className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2"
                        htmlFor="grid-first-name"
                      >
                        Closing Price
                      </label>
                      <input
                        id="close-limit-price"
                        className="text-center appearance-none block w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        placeholder=""
                        ref={(input_bis) =>
                          (this.search_PriceLimit = input_bis)
                        }
                        onChange={this.handleModalPriceChangeLimit}
                      />
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                      <label
                        className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Closing Qty
                      </label>
                      <input
                        id="close-limit-qty"
                        className="text-center appearance-none block w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        placeholder=""
                        ref={(input_bis) =>
                          (this.search_quantityLimit = input_bis)
                        }
                        onChange={this.handleModalQuantityChangeLimit}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div id="market-close" className="row-span-1">
                <form className="w-full max-w-lg">
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-3">
                      <label
                        className="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Closing Qty
                      </label>
                      <input
                        id="close-market-qty"
                        className="text-center appearance-none block w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        placeholder=""
                        ref={(input) => (this.search_quantity = input)}
                        onChange={this.handleModalQuantityChangeMarket}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="flex place-content-center">
            <div
              id="modal-text-market"
              className="text-center p-6 max-w-sm bg-tvcolor rounded-lg border border-gray-200 shadow-md mb-1"
            >
              {closeQuantityMarket ? (
                <p className="mb-3 font-normal text-totalWhite text-totalWhite">
                  {closeQuantityMarket} contract(s) will be closed at the
                  current price.
                </p>
              ) : (
                "Selected quantity contracts will be closed at the current price."
              )}
            </div>
            <div
              id="modal-text-limit"
              className="text-center p-6 max-w-sm bg-tvcolor rounded-lg border border-gray-200 shadow-md mb-1 hidden"
            >
              {closeQuantityLimit ? (
                <p className="mb-3 font-normal text-totalWhite text-totalWhite">
                  {closeQuantityLimit} contract(s)&nbsp;
                </p>
              ) : (
                "Selected quantity contracts(s) "
              )}
              {closePriceLimit ? (
                <p className="mb-3 font-normal text-totalWhite text-totalWhite">
                  will be closed at ${closePriceLimit}.
                </p>
              ) : (
                "will be closed at $..."
              )}
            </div>
          </div>
          <div className="row-span-1">
            <button
              onClick={() => {
                close_partial_positon(this.handleLoad, geturl_battleid());
                document.getElementById("modal-close-position").style.display =
                  "none";
              }}
              className="text-totalWhite font-semibold rounded-md hover:scale-95 px-2 py-1 bg-gray-700"
            >
              Submit
            </button>
          </div>
        </div>

        <div
          className="float-right column2 text-center border-l-4 border-black overflow-auto py-1 space-y-1"
          style={{ backgroundColor: "#17181f" }}
        >
          <a onClick={this.handleInputChange}>
            <LeverageSlider></LeverageSlider>
          </a>
          <ButtonLimitMarket {...{ limit_order, market_order }} />
          <div className="space-y-3">
            <div className="text-totalWhite">
              <form className="">
                <div className="flex flex-wrap mb-2 px-2">
                  <div id="div-order-price" className="w-full md:w-1/2 px-3">
                    <label
                      className="block uppercase tracking-wide text-totalWhite text-xs font-bold mb-2"
                      htmlFor="grid-first-name"
                    >
                      Order Price
                    </label>
                    <input
                      ref={(input) => (this.search = input)}
                      onChange={this.handleInputChange}
                      id="order-price"
                      className="text-center appearance-none block w-full bg-gray-200 text-black border border-gray-200 rounded py-1 px-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      placeholder=""
                      autoComplete="off"
                    ></input>
                  </div>
                  <div className="w-full md:w-1/2 px-3 grid justify-items-center">
                    <div>
                      <label
                        className="block uppercase tracking-wide text-totalWhite text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Order Qty
                      </label>
                      <input
                        ref={(input) => (this.search_bis = input)}
                        onChange={this.handleInputChange}
                        id="order-qty"
                        className="text-center appearance-none block w-full bg-gray-200 text-black border border-gray-200 rounded py-1 px-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        placeholder=""
                        autoComplete="off"
                      ></input>
                    </div>
                    <div className="w-2/3">
                      <a onClick={this.handler}>
                        <Qtyslider></Qtyslider>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="flex place-content-center space-x-2 -mb-6">
                  <RadioTPSL handler={this.handler} />
                </div>
                <div id="div-tpsl" className="flex flex-wrap mb-2 px-2 hidden">
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide text-totalWhite text-xs font-bold mb-2"
                      htmlFor="grid-first-name"
                    >
                      Take Profit
                    </label>
                    <input
                      ref={(input1) => (this.tpPrice = input1)}
                      onChange={this.handleInputChange}
                      id="take-profit"
                      className="text-center appearance-none block w-full bg-gray-200 text-black border rounded py-1 px-1 leading-tight focus:outline-none focus:bg-white"
                      placeholder=""
                      autoComplete="off"
                    ></input>
                    {recapTP ? (
                      <p id="recapTP" className="text-gray-400">
                        {recapTP}
                      </p>
                    ) : (
                      <p id="recapTP" className="text-gray-400"></p>
                    )}
                    {recapTPmarket ? (
                      <p id="recapTPmarket" className="text-gray-400 hidden">
                        {recapTPmarket}
                      </p>
                    ) : (
                      <p
                        id="recapTPmarket"
                        className="text-gray-400 hidden"
                      ></p>
                    )}
                  </div>
                  <div className="w-full md:w-1/2 px-3 grid justify-items-stretch">
                    <label
                      className="block uppercase tracking-wide text-totalWhite text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Stop Loss
                    </label>
                    <input
                      ref={(input2) => (this.slPrice = input2)}
                      onChange={this.handleInputChange}
                      id="stop-loss"
                      className="text-center appearance-none block w-full bg-gray-200 text-black border rounded py-1 px-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type=""
                      placeholder=""
                      autoComplete="off"
                    ></input>
                    {recapSL ? (
                      <p id="recapSL" className="text-gray-400">
                        {recapSL}
                      </p>
                    ) : (
                      <p id="recapSL" className="text-gray-400"></p>
                    )}
                    {recapSLmarket ? (
                      <p id="recapSLmarket" className="text-gray-400 hidden">
                        {recapSLmarket}
                      </p>
                    ) : (
                      <p
                        id="recapSLmarket"
                        className="text-gray-400 hidden"
                      ></p>
                    )}
                    {/* <div
                      id="risk-management-mode"
                      className="text-sm text-gray-400 italic hidden"
                    >
                      <label>
                        <input
                          defaultChecked=""
                          id="risk-management-checkbox"
                          type="checkbox"
                          onClick={risk_management}
                        />
                        &nbsp;Risk Management
                      </label>
                    </div>
                    <div
                      className="w-3/4 justify-self-center hidden"
                      id="risk-management-slider"
                    >
                      <a onClick={this.handler}>
                        <QtysliderRiskMngt></QtysliderRiskMngt>
                      </a>
                    </div> */}
                  </div>
                </div>
              </form>
            </div>
            <div className="grid gap-1 flex place-items-center">
              <div id="div-order-value">
                <div className="text-totalWhite flex relative inline-block w-full justify-center">
                  {ordervalue ? (
                    <span id="limit-order-value1" className="text-totalWhite">
                      Order cost:{" "}
                      {(ordervalue / localStorage.getItem("leverage")).toFixed(
                        2
                      )}
                      $
                    </span>
                  ) : (
                    <span id="limit-order-value1" className="text-totalWhite">
                      Order cost: 0.00$
                    </span>
                  )}
                  <span
                    id="limit-order-value2"
                    className="text-totalWhite"
                  ></span>
                  <img
                    className="w-6 h-6 ml-1 rounded-full"
                    src="/images/coins.png"
                  ></img>
                </div>
              </div>
              <div id="div-market-value" className="hidden">
                <div className="text-totalWhite flex relative inline-block w-full justify-center">
                  {ordervalueMarket ? (
                    <span id="market-order-value1" className="text-totalWhite">
                      Order cost:{" "}
                      {(
                        ordervalueMarket / localStorage.getItem("leverage")
                      ).toFixed(2)}
                      $
                    </span>
                  ) : (
                    <span id="market-order-value1" className="text-totalWhite">
                      Order cost: 0.00$
                    </span>
                  )}
                  <span
                    id="market-order-value2"
                    className="text-totalWhite"
                  ></span>
                  <img
                    className="w-6 h-6 ml-1 rounded-full"
                    src="/images/coins.png"
                  ></img>
                </div>
              </div>
              <div className="flex justify-center w-full">
                <details>
                  <summary className="text-blue-400 hover:text-blue-500 py-2 px-2">
                    Advanced
                  </summary>
                  <UsersetMenu />
                  <div className="flex justify-around space-x-2">
                    <div>
                      <label className="text-sm font-medium text-gray-300">
                        Note:
                      </label>
                    </div>
                    <div>
                      <input id="note" className="rounded-r-xl"></input>
                    </div>
                  </div>
                </details>
              </div>
              <div>
                <div id="btn-limit" className="space-x-0.5">
                  <button
                    onClick={() => {
                      open_limit_long(this.handleLoad);
                    }}
                    className="bg-normalGreen hover:scale-95 text-white font-bold py-2 px-3 rounded-l-full text-base"
                    type="button"
                  >
                    Open Long
                  </button>
                  <button
                    onClick={() => {
                      open_limit_short(this.handleLoad);
                    }}
                    className="bg-tomato hover:scale-95 text-white font-bold py-2 px-3 rounded-r-full text-base"
                    type="button"
                  >
                    Open Short
                  </button>
                </div>
                <div id="btn-market" className="hidden space-x-0.5">
                  <button
                    onClick={() => {
                      open_market_long(this.handleLoad);
                    }}
                    className="bg-normalGreen hover:scale-95 text-white font-bold py-2 px-3 rounded-l-full text-base"
                    type="button"
                  >
                    Open Long
                  </button>
                  <button
                    onClick={() => {
                      open_market_short(this.handleLoad);
                    }}
                    className="bg-tomato hover:scale-95 text-white font-bold py-2 px-3 rounded-r-full text-base"
                    type="button"
                  >
                    Open Short
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row2 mb-4 bg-tvcolor grid grid-cols-8 float-left border-black">
          <DisplayPositionsOrders
            {...{ display_positions, document, display_orders }}
          />
          <div className="col-span-6 lg:col-span-7">
            <div
              id="table-positions"
              className="h-full border-totalWhite text-xl text-totalWhite"
            >
              <div className="relative bg-tvcolor rounded-bl-xl">
                <div className="overflow-x-auto">
                  <table
                    id="main-table-positions"
                    className="w-full text-sm text-left text-gray-500 text-gray-400"
                  >
                    <thead className="text-center text-xs uppercase bg-gray-700 text-gray-400 sticky top-0">
                      <tr>
                        <th scope="col" className="py-1 px-2">
                          Contract
                        </th>
                        <th scope="col" className="py-1 px-2">
                          Qty
                        </th>
                        <th scope="col" className="py-1 px-2">
                          Order Value
                        </th>
                        <th scope="col" className="py-1 px-2">
                          Entry Price
                        </th>
                        <th scope="col" className="py-1 px-2">
                          Current Price
                        </th>
                        <th scope="col" className="py-1 px-2">
                          TP
                        </th>
                        <th scope="col" className="py-1 px-2">
                          SL
                        </th>
                        <th scope="col" className="py-1 px-2">
                          ID
                        </th>
                        <th scope="col" className="py-1 px-2">
                          Unrealized P&L
                        </th>
                        <th scope="col" className="py-1 px-2">
                          Close
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      id="positions-table"
                      className="text-totalWhite"
                    ></tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              id="table-orders"
              className="h-full border-totalWhite text-xl text-totalWhite hidden"
            >
              <div className="relative bg-tvcolor rounded-bl-xl">
                <div className="overflow-x-auto">
                  <table
                    id="main-table-orders"
                    className="w-full text-sm text-left text-gray-500 text-gray-400 text-center"
                  >
                    <thead className="text-xs uppercase bg-gray-700 text-gray-400">
                      <tr>
                        <th scope="col" className="py-1 px-2">
                          Contract
                        </th>
                        <th scope="col" className="py-1 px-2">
                          Qty
                        </th>
                        <th scope="col" className="py-1 px-2">
                          Order Price
                        </th>
                        <th scope="col" className="py-1 px-2">
                          TP
                        </th>
                        <th scope="col" className="py-1 px-2">
                          SL
                        </th>
                        <th scope="col" className="py-1 px-2">
                          ID
                        </th>
                        <th scope="col" className="py-1 px-2">
                          Cancel
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      id="orders-table"
                      className="text-totalWhite"
                    ></tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;

function limit_order() {
  const btn_limit = document.getElementById("limit-order");
  const btn_market = document.getElementById("market-order");
  const btn_limit_order = document.getElementById("btn-limit");
  const btn_market_order = document.getElementById("btn-market");
  const div_order_price = document.getElementById("div-order-price");
  const div_order_value = document.getElementById("div-order-value");
  // document.getElementById("risk-management-mode").className =
  //   "text-sm text-gray-400 italic hidden";
  // document.getElementById("risk-management-slider").className = "hidden";
  // document.getElementById("risk-management-checkbox").checked = false;
  btn_limit.style.color = "#f0a61b";
  if ((btn_market.style.color = "#f0a61b")) {
    btn_market.style.color = "#757575";
    btn_market_order.style.display = "none";
  }
  document.getElementById("div-market-value").className = "hidden";
  document.getElementById("recapTP").className = "visible text-gray-400";
  document.getElementById("recapSL").className = "visible text-gray-400";
  document.getElementById("recapTPmarket").className = "hidden";
  document.getElementById("recapSLmarket").className = "hidden";
  div_order_price.style.display = "block";
  btn_limit_order.style.display = "block";
  div_order_value.style.display = "block";
}

function market_order() {
  const btn_limit = document.getElementById("limit-order");
  const btn_market = document.getElementById("market-order");
  const btn_limit_order = document.getElementById("btn-limit");
  const btn_market_order = document.getElementById("btn-market");
  const div_order_price = document.getElementById("div-order-price");
  const div_order_value = document.getElementById("div-order-value");
  // document.getElementById("risk-management-mode").className =
  //   "text-sm text-gray-400 italic";

  btn_market.style.color = "#f0a61b";
  if ((btn_limit.style.color = "#f0a61b")) {
    btn_limit.style.color = "#757575";
    btn_limit_order.style.display = "none";
    div_order_value.style.display = "none";
  }
  div_order_price.style.display = "none";
  btn_market_order.style.display = "block";
  document.getElementById("div-market-value").className = "visible";
  document.getElementById("recapTPmarket").className = "visible text-gray-400";
  document.getElementById("recapSLmarket").className = "visible text-gray-400";
  document.getElementById("recapTP").className = "hidden";
  document.getElementById("recapSL").className = "hidden";
}

function risk_management() {
  if (document.getElementById("risk-management-checkbox").checked) {
    document.getElementById("risk-management-slider").className =
      "w-3/4 justify-self-center";
  } else {
    document.getElementById("risk-management-slider").className = "hidden";
  }
}

async function connect() {
  const [signerAddr, isOwner] = await verify_wallet_address(
    "Welcome to the LATF Paper Trading.\n\nSign this request to access your account.\n\nThis request will not trigger a blockchain transaction or cost any gas fees."
  );
  if (isOwner) {
    try {
      localStorage.setItem("isWalletConnected", true);
    } catch (ex) {
      console.log(ex);
    }
    try {
      connectWallet();
    } catch (err) {
      localStorage.setItem("isWalletConnected", false);
      document.getElementById("sign-up-popup").classList.remove("hidden");
    }
  }
}

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

function geturl_symbol() {
  return localStorage.getItem("selectedSymbol");
}

async function create_order(
  contract,
  quantity,
  orderprice,
  takeprofit,
  stoploss,
  liquidationprice,
  ordertype,
  ordertime,
  orderid,
  side,
  signerAddr,
  note,
  leverage,
  handleLoad
) {
  const setchecked = get_set_checked();
  var notp, nosl;
  if (isNaN(takeprofit)) {
    notp = "X";
  }
  if (isNaN(stoploss)) {
    nosl = "X";
  }
  if (
    isNaN(quantity) ||
    isNaN(orderprice) ||
    quantity < 0 ||
    orderprice < 0 ||
    takeprofit < 0 ||
    stoploss < 0
  ) {
    alert("Please fill all order fields before submitting an order.");
    return 1;
  }
  if (ordertype == "limit") {
    const contractPrice = parseFloat(localStorage.getItem(contract));
    if (side == "long") {
      if (orderprice > contractPrice * 1.005) {
        orderprice = contractPrice;
      }
    } else if (side == "short") {
      if (orderprice < contractPrice * 0.995) {
        orderprice = contractPrice;
      }
    }
    // check if order value isn't above balance now
    const battleId = geturl_battleid();
    const balance = parseFloat(
      document.getElementById("battleBalance").textContent
    );
    const orderValue = (quantity * orderprice) / leverage || 1;
    if (orderValue > balance) {
      alert(
        "The order price is set incorrectly, and would lead to unwanted losses."
      );
      return 1;
    }
    await fetch(
      `https://latf-paper-trading-backend.herokuapp.com/battle/limit/create/order?note=${note}&setchecked=${setchecked}&contract=${contract}&quantity=${quantity}&orderprice=${orderprice}&takeprofit=${takeprofit}&stoploss=${stoploss}&liquidationprice=${liquidationprice}&ordertype=${ordertype}&ordertime=${ordertime}&orderid=${orderid}&side=${side}&walletaddress=${signerAddr}&notp=${notp}&nosl=${nosl}&battleid=${battleId}&leverage=${
        localStorage.getItem("leverage") || 1
      }`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        handleLoad();
        // window.location.reload();
      });
  } else if (ordertype == "market") {
    const battleId = geturl_battleid();
    await fetch(
      `https://latf-paper-trading-backend.herokuapp.com/battle/market/create/order?note=${note}&setchecked=${setchecked}&contract=${contract}&quantity=${quantity}&orderprice=${orderprice}&takeprofit=${takeprofit}&stoploss=${stoploss}&liquidationprice=${liquidationprice}&ordertype=${ordertype}&ordertime=${ordertime}&orderid=${orderid}&side=${side}&walletaddress=${signerAddr}&notp=${notp}&nosl=${nosl}&battleid=${battleId}&leverage=${
        localStorage.getItem("leverage") || 1
      }`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        handleLoad();
        // window.location.reload();
      });
  }
}

async function open_limit_long(handleLoad) {
  if (localStorage?.getItem("isWalletConnected") === "true") {
    const contract = geturl_symbol();
    const leverage = localStorage.getItem("leverage");
    const quantity = parseFloat(document.getElementById("order-qty").value);
    const orderprice = parseFloat(document.getElementById("order-price").value);
    const takeprofit = parseFloat(document.getElementById("take-profit").value);
    const stoploss = parseFloat(document.getElementById("stop-loss").value);
    const liquidationprice = 0;
    const ordertype = "limit";
    const d = new Date(),
      ordertime =
        [
          (d.getMonth() + 1).padLeft(),
          d.getDate().padLeft(),
          d.getFullYear(),
        ].join("/") +
        " " +
        [
          d.getHours().padLeft(),
          d.getMinutes().padLeft(),
          d.getSeconds().padLeft(),
        ].join(":");
    const orderid = uuidv4().toString();
    const side = "long";
    const note = document.getElementById("note").value;
    const [signerAddr, isOwner] = await verify_wallet_address(
      `Sign to ${side} ${contract} at ${orderprice} for a quantity of ${quantity} using a leverage of ${leverage} with your paper money.\n\nThis request will not trigger a blockchain transaction or cost any gas fees.`
    );
    async function asyncCall() {
      if (isOwner) {
        if (await has_balance(quantity, orderprice, signerAddr, leverage)) {
          create_order(
            contract,
            quantity,
            orderprice,
            takeprofit,
            stoploss,
            liquidationprice,
            ordertype,
            ordertime,
            orderid,
            side,
            signerAddr,
            note,
            leverage,
            handleLoad
          );
        } else {
          alert("Insufficient balance, reduce your order quantity.");
        }
      }
    }
    asyncCall();
  } else {
    alert("You aren't currently connected.");
  }
}

async function open_limit_short(handleLoad) {
  if (localStorage?.getItem("isWalletConnected") === "true") {
    const contract = geturl_symbol();
    const leverage = localStorage.getItem("leverage");
    const quantity = parseFloat(document.getElementById("order-qty").value);
    const orderprice = parseFloat(document.getElementById("order-price").value);
    const takeprofit = parseFloat(document.getElementById("take-profit").value);
    const stoploss = parseFloat(document.getElementById("stop-loss").value);
    const liquidationprice = orderprice * 2;
    const ordertype = "limit";
    const d = new Date(),
      ordertime =
        [
          (d.getMonth() + 1).padLeft(),
          d.getDate().padLeft(),
          d.getFullYear(),
        ].join("/") +
        " " +
        [
          d.getHours().padLeft(),
          d.getMinutes().padLeft(),
          d.getSeconds().padLeft(),
        ].join(":");
    const orderid = uuidv4().toString();
    const side = "short";
    const note = document.getElementById("note").value;
    const [signerAddr, isOwner] = await verify_wallet_address(
      `Sign to ${side} ${contract} at ${orderprice} for a quantity of ${quantity} with your paper money.\n\nThis request will not trigger a blockchain transaction or cost any gas fees.`
    );
    async function asyncCall() {
      if (isOwner) {
        console.log(await has_balance(quantity, orderprice, signerAddr));
        if (await has_balance(quantity, orderprice, signerAddr, leverage)) {
          create_order(
            contract,
            quantity,
            orderprice,
            takeprofit,
            stoploss,
            liquidationprice,
            ordertype,
            ordertime,
            orderid,
            side,
            signerAddr,
            note,
            leverage,
            handleLoad
          );
        } else {
          alert(
            "Insufficient balance, the order is set to your maximum available balance."
          );
          const available_balance = localStorage.getItem("balance");
          const max_quantity =
            (available_balance / orderprice) * localStorage.getItem("leverage");
          create_order(
            contract,
            max_quantity,
            orderprice,
            takeprofit,
            stoploss,
            liquidationprice,
            ordertype,
            ordertime,
            orderid,
            side,
            signerAddr,
            note,
            leverage,
            handleLoad
          );
        }
      }
    }
    asyncCall();
  } else {
    alert("You aren't currently connected.");
  }
}

async function open_market_long(handleLoad) {
  if (localStorage?.getItem("isWalletConnected") === "true") {
    console.log("open_market_long wallet is connected.");
    const contract = geturl_symbol();
    const leverage = localStorage.getItem("leverage");
    const quantity = parseFloat(document.getElementById("order-qty").value);
    const takeprofit = parseFloat(document.getElementById("take-profit").value);
    const stoploss = parseFloat(document.getElementById("stop-loss").value);
    const ordertype = "market";
    const orderid = uuidv4().toString();
    const side = "long";
    const note = document.getElementById("note").value;
    const [signerAddr, isOwner] = await verify_wallet_address(
      `Sign to ${side} ${contract} at current price for a quantity of ${quantity} with your paper money.\n\nThis request will not trigger a blockchain transaction or cost any gas fees.`
    );
    const proxyURL = "https://latf-proxy.herokuapp.com/";
    await fetch(
      `${proxyURL}https://api.binance.com/api/v3/ticker/price?symbol=${contract}`
    )
      .then((response) => response.json())
      .then((data) => {
        const d = new Date(),
          ordertime =
            [
              (d.getMonth() + 1).padLeft(),
              d.getDate().padLeft(),
              d.getFullYear(),
            ].join("/") +
            " " +
            [
              d.getHours().padLeft(),
              d.getMinutes().padLeft(),
              d.getSeconds().padLeft(),
            ].join(":");
        const ticker_price = parseFloat(data.price);
        const orderprice = ticker_price;
        const liquidationprice = 0;
        async function asyncCall() {
          if (isOwner) {
            if (await has_balance(quantity, orderprice, signerAddr, leverage)) {
              create_order(
                contract,
                quantity,
                orderprice,
                takeprofit,
                stoploss,
                liquidationprice,
                ordertype,
                ordertime,
                orderid,
                side,
                signerAddr,
                note,
                leverage,
                handleLoad
              );
            } else {
              alert(
                "Insufficient balance, the order is set to your maximum available balance."
              );
              const available_balance = localStorage.getItem("balance");
              const max_quantity =
                (available_balance / orderprice) *
                localStorage.getItem("leverage");
              create_order(
                contract,
                max_quantity,
                orderprice,
                takeprofit,
                stoploss,
                liquidationprice,
                ordertype,
                ordertime,
                orderid,
                side,
                signerAddr,
                note,
                leverage,
                handleLoad
              );
            }
          } else {
            alert("You aren't currently connected.");
          }
        }
        asyncCall();
      });
  }
}

async function open_market_short(handleLoad) {
  const contract = geturl_symbol();
  if (localStorage?.getItem("isWalletConnected") === "true") {
    const contract = geturl_symbol();
    const leverage = localStorage.getItem("leverage");
    const quantity = parseFloat(document.getElementById("order-qty").value);
    const takeprofit = parseFloat(document.getElementById("take-profit").value);
    const stoploss = parseFloat(document.getElementById("stop-loss").value);
    const ordertype = "market";
    const orderid = uuidv4().toString();
    const side = "short";
    const note = document.getElementById("note").value;
    const [signerAddr, isOwner] = await verify_wallet_address(
      `Sign to ${side} ${contract} at current price for a quantity of ${quantity} with your paper money.\n\nThis request will not trigger a blockchain transaction or cost any gas fees.`
    );
    const proxyURL = "https://latf-proxy.herokuapp.com/";
    await fetch(
      `${proxyURL}https://api.binance.com/api/v3/ticker/price?symbol=${contract}`
    )
      .then((response) => response.json())
      .then((data) => {
        const d = new Date(),
          ordertime =
            [
              (d.getMonth() + 1).padLeft(),
              d.getDate().padLeft(),
              d.getFullYear(),
            ].join("/") +
            " " +
            [
              d.getHours().padLeft(),
              d.getMinutes().padLeft(),
              d.getSeconds().padLeft(),
            ].join(":");
        const ticker_price = parseFloat(data.price);
        const orderprice = ticker_price;
        const liquidationprice = orderprice * 2;
        async function asyncCall() {
          if (isOwner) {
            if (await has_balance(quantity, orderprice, signerAddr, leverage)) {
              create_order(
                contract,
                quantity,
                orderprice,
                takeprofit,
                stoploss,
                liquidationprice,
                ordertype,
                ordertime,
                orderid,
                side,
                signerAddr,
                note,
                leverage,
                handleLoad
              );
            } else {
              alert(
                "Insufficient balance, the order is set to your maximum available balance."
              );
              const available_balance = localStorage.getItem("balance");
              const max_quantity =
                (available_balance / orderprice) *
                localStorage.getItem("leverage");
              create_order(
                contract,
                max_quantity,
                orderprice,
                takeprofit,
                stoploss,
                liquidationprice,
                ordertype,
                ordertime,
                orderid,
                side,
                signerAddr,
                note,
                leverage,
                handleLoad
              );
            }
          } else {
            alert("You aren't currently connected.");
          }
        }
        asyncCall();
      });
  }
}

async function has_balance(quantity, orderprice, signerAddr, leverage) {
  const ordervalue = (parseFloat(quantity) * parseFloat(orderprice)) / leverage;
  const battleId = geturl_battleid();
  console.log("here battle id :::  ", battleId);
  const return_value = await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/battle/currentbalance?walletaddress=${signerAddr}&battleid=${battleId}`
  )
    .then((response) => response.json())
    .then((data) => {
      console.log("BALANCE BATTLE = ", data);
      const balance = data.currentBalance;
      return ordervalue < balance;
    });
  return return_value;
}

function display_orders() {
  const table_orders = document.getElementById("table-orders");
  const table_positions = document.getElementById("table-positions");
  table_positions.style.display = "none";
  table_orders.style.display = "block";
}

function display_positions() {
  const table_orders = document.getElementById("table-orders");
  const table_positions = document.getElementById("table-positions");
  table_orders.style.display = "none";
  table_positions.style.display = "block";
}

function get_set_checked() {
  const filterPage = localStorage.getItem("setPage");
  var ids = [];
  var journal_set = document.getElementById("set1");
  if (filterPage == 2) {
    journal_set = document.getElementById("set2");
  } else if (filterPage == 3) {
    journal_set = document.getElementById("set3");
  }
  if (journal_set == null) {
    console.log("journal_set is null");
    return;
  }
  console.log(filterPage);
  const elems = journal_set.querySelectorAll("*[id]");
  let i = 0;
  while (i < elems.length) {
    if (document.getElementById(elems[i].id).checked) {
      ids.push(elems[i].id.slice(0, -9));
    }
    i++;
  }
  console.log(ids);
  return ids;
}

function get_set() {
  var ids = [];
  const elems = document.querySelectorAll(`[id^="div-set-"]`);
  let i = 0;
  while (i < elems.length) {
    // push into ids if the elem is displayed
    if (elems[i].style.display !== "none") {
      ids.push(elems[i].id.slice(8));
    }
    i++;
  }
  return ids;
}

async function remove_set_element(set_to_remove, walletaddress) {
  const set = get_set();
  set_to_remove = set_to_remove.slice(8);
  const final_set = set.filter((e) => e !== set_to_remove);
  console.log(final_set, walletaddress);
  var str_final_set = "[";
  let i = 0;
  while (i < final_set.length) {
    if (i === final_set.length - 1) {
      str_final_set += `"${final_set[i]}"`;
    } else {
      str_final_set += `"${final_set[i]}",`;
    }
    i++;
  }
  str_final_set += "]";
  console.log(str_final_set);
  await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/set/edit?walletaddress=${walletaddress}&userset=${str_final_set}`
  )
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      document.getElementById(`div-set-${set_to_remove}`).style.display =
        "none";
    });
}

async function risk_management_tool(walletaddress) {
  try {
    const past_positions = await fetch(
      `https://latf-paper-trading-backend.herokuapp.com/user/archive/positions?walletaddress=${walletaddress}`
    )
      .then((response) => response.json())
      .then((data) => {
        localStorage.setItem(
          "total_balance",
          data[data.length - 1].total_balance
        );
      });
  } catch {
    localStorage.setItem("total_balance", localStorage.getItem("balance"));
  }
  console.log(
    localStorage.getItem("balance"),
    localStorage.getItem("total_balance")
  );
}

async function getDynamicBalanceBis(walletaddress) {
  // the difference is that we don't display loader
  const battleId = geturl_battleid();
  const total_assets = await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/battle/user/dynamicbalance?walletaddress=${walletaddress}&battleid=${battleId}`
  )
    .then((response) => response.json())
    .then((data) => {
      // console.log(data);
      return data;
    });
  // console.log(total_assets, "total_assets");
  try {
    document.getElementById("total-assets").innerHTML = total_assets.toFixed(2);
  } catch {
    console.log("total-assets element not found");
  }
}

function geturl_battleid() {
  const url = window.location.href;
  const battleid = url.split("/")[5];
  return battleid;
}
