import React from "react";
import Navbar from "./Navbar";
import GraphPositivity from "./GraphPositivity";
import { getAccount } from "@wagmi/core";
import { verify_wallet_address } from "../config/wallet_signer";
import { getUserAddress } from "../config/connection_manager";

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.handleLoad = this.handleLoad.bind(this);
    this.state = {
      selectedTab: "users",
    };
  }

  handleTabClick = async (tab) => {
    const addr = await getUserAddress();
    try {
      const response = await fetch(
        `https://latf-paper-trading-backend.herokuapp.com/verify/admin?walletaddress=${addr}`
      );
      if (response.ok) {
        this.setState({ selectedTab: tab });
        // if tab is bugs, trigger load_bugs
        if (tab === "bugs") {
          load_bugs();
        }
        if (tab === "users") {
          load_users();
        }
      }
    } catch (error) {
      console.error(error);
      alert("An error occurred while verifying your wallet address.");
      localStorage.setItem("isWalletConnected", false);
    }
  };

  componentDidMount() {
    this.handleLoad();
  }

  componentWillUnmount() {}

  handleLoad = async (e) => {
    const addr = await getUserAddress();
    const upper_addr_wallet = addr.toUpperCase();
    try {
      const response = await fetch(
        `https://latf-paper-trading-backend.herokuapp.com/verify/admin?walletaddress=${addr}`
      );
      if (response.ok) {
        await fetch(
          `https://latf-paper-trading-backend.herokuapp.com/balance?walletaddress=${addr}`
        )
          .then((response) => response.json())
          .then((data) => {
            console.log(data.discordname, data.balance);
            const balance_object = document.getElementById("balance");
            balance_object.textContent = data.balance.toFixed(2);
            document.getElementById("balance-drop").textContent =
              data.balance.toFixed(2);
            localStorage.setItem("isWalletConnected", true);
            load_users();
            load_nb_orders();
            load_nb_positions();
            load_nb_archived();
          });
      } else {
        console.log(response);
        alert("Please connect with a whitelisted address.");
        localStorage.setItem("isWalletConnected", false);
      }
    } catch (error) {
      console.error(error);
      alert("An error occurred while verifying your wallet address.");
      localStorage.setItem("isWalletConnected", false);
    }
  };

  state = {};
  render() {
    const { selectedTab } = this.state;
    return (
      <div className="bg-bbbglight h-full">
        <Navbar {...{ connect }} />
        <div className="bg-bbbglight h-auto w-full">
          <div className="flex justify-center items-center text-totalWhite dark:bg-totalGray-800 dark:text-totalGray-300">
            <div
              className={`border-1 border-gray-500 rounded-l-full py-2 px-4 font-bold text-xl text-center grid col-span-1 cursor-pointer ${
                selectedTab === "users" ? "bg-yellow" : "hover:bg-gray-800"
              }`}
              onClick={() => this.handleTabClick("users")}
            >
              Users
            </div>
            <div
              className={`border-1 border-gray-500 py-2 px-4 font-bold text-xl text-center grid col-span-1 cursor-pointer ${
                selectedTab === "statistics" ? "bg-yellow" : "hover:bg-gray-800"
              }`}
              onClick={() => this.handleTabClick("statistics")}
            >
              Statistics
            </div>
            <div
              className={`border-1 border-gray-500 py-2 px-4 font-bold text-xl text-center grid col-span-1 cursor-pointer ${
                selectedTab === "bugs" ? "bg-yellow" : "hover:bg-gray-800"
              }`}
              onClick={() => this.handleTabClick("bugs")}
            >
              Bugs
            </div>
            {/* add a tab for Community Vote */}
            <div
              className={`border-1 border-gray-500 rounded-r-full py-2 px-4 font-bold text-xl text-center grid col-span-1 cursor-pointer ${
                selectedTab === "community" ? "bg-yellow" : "hover:bg-gray-800"
              }`}
              onClick={() => this.handleTabClick("community")}
            >
              Community
            </div>
          </div>

          <div className="flex justify-center items-center text-totalWhite dark:bg-gray-800 dark:text-gray-300">
            <div className="py-2 px-4 font-bold text-xl text-center grid col-span-1">
              Number of users: <span id="nb-users"></span>
            </div>
            <div className="py-2 px-4 font-bold text-xl text-center grid col-span-1">
              Number of orders: <span id="nb-orders"></span>
            </div>
            <div className="py-2 px-4 font-bold text-xl text-center grid col-span-1">
              Number of positions: <span id="nb-positions"></span>
            </div>
            <div className="py-2 px-4 font-bold text-xl text-center grid col-span-1">
              Number of archived positions: <span id="nb-archived"></span>
            </div>
          </div>

          <div className="mx-auto h-3/4 px-10 overflow-auto flex justify-center">
            {selectedTab === "users" && (
              <>
                <div className="py-2 px-4">
                  <input
                    type="text"
                    id="search-input"
                    className="border rounded w-64 py-2 px-3"
                    placeholder="Search by username"
                    onChange={() => search_user()}
                  />
                </div>
                <table
                  id="users-table"
                  className="py-4 px-4 w-full text-center shadow-md rounded-md text-gray-800 border-collapse"
                >
                  <thead className="text-xs uppercase bg-gray-50 bg-gray-700 text-gray-400 ">
                    <tr className="text-center text-base font-serif font-base">
                      <th scope="col" className="py-3 px-6">
                        Username
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Account created
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Wallet Address
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-totalWhite"></tbody>
                </table>
              </>
            )}
            {selectedTab === "statistics" && (
              <div className="bg-bbbglight h-auto w-full">
                <div
                  classname="chart-container"
                  style={{ position: "relative", height: "90%", width: "99%" }}
                >
                  <GraphPositivity />
                </div>
              </div>
            )}
            {selectedTab === "bugs" && (
              <div className="bg-bbbglight h-auto w-full">
                <table
                  id="bugs-table"
                  className="py-4 px-4 w-full text-center shadow-md rounded-md text-gray-800 border-collapse"
                >
                  <thead className="text-xs uppercase bg-gray-50 bg-gray-700 text-gray-400 ">
                    <tr className="text-center text-base font-serif font-base">
                      <th scope="col" className="py-3 px-6">
                        Id
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Message
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Name
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-totalWhite"></tbody>
                </table>
              </div>
            )}
            {selectedTab === "community" && (
              // the idea of this tab is to create posts to send to backend. 1 title and 2 options.
              <div className="bg-bbbglight h-auto w-full">
                <div className="flex flex-col justify-center items-center">
                  <div className="flex flex-col justify-center items-center bg-totalWhite bg-opacity-90 rounded-xl p-2">
                    <div className="py-2 px-4">
                      <input
                        type="text"
                        id="title-input"
                        className="border rounded w-64 py-2 px-3"
                        placeholder="Title"
                      />
                    </div>
                    <div className="py-2 px-4">
                      <input
                        type="text"
                        id="option1-input"
                        className="border rounded w-64 py-2 px-3"
                        placeholder="Option 1"
                      />
                    </div>
                    <div className="py-2 px-4">
                      <input
                        type="text"
                        id="option2-input"
                        className="border rounded w-64 py-2 px-3"
                        placeholder="Option 2"
                      />
                    </div>
                    <div className="py-2 px-4">
                      <button
                        className="bg-yellowlegit-500 hover:bg-yellowlegit-700 text-white font-bold py-2 px-4 rounded"
                        onClick={() => post_community()}
                      >
                        Post
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div style="display:none;">
          <a href="https://www.hostnjoy.com">HostnJoy</a>
        </div>
      </div>
    );
  }
}

export default Admin;

async function connect() {
  const [signerAddr, isOwner] = await verify_wallet_address(
    "Welcome to the LATF Paper Trading.\n\nSign this request to access your account.\n\nThis request will not trigger a blockchain transaction or cost any gas fees."
  );
  if (isOwner) {
    const response = await fetch(
      `https://latf-paper-trading-backend.herokuapp.com/verify/admin?walletaddress=${signerAddr}`
    );
    if (response.ok) {
      try {
        localStorage.setItem("isWalletConnected", true);
      } catch (ex) {
        console.log(ex);
      }
      await fetch(
        `https://latf-paper-trading-backend.herokuapp.com/balance?walletaddress=${signerAddr}`
      )
        .then((response) => response.json())
        .then((data) => {
          const balance_object = document.getElementById("balance");
          balance_object.textContent = data.balance.toFixed(2);
          document.getElementById("balance-drop").textContent =
            data.balance.toFixed(2);
          //   const connect_button = document.getElementById("connect_button");
          //   connect_button.outerHTML = `
          // <div class="flex relative inline-block float-right">
          // 	<div class="relative text-sm text-gray-100">
          // 		<div id="userButton" class="flex items-center focus:outline-none mr-3">
          // 			<img class="w-10 h-10 rounded-full mr-4" src="https://api.dicebear.com/7.x/bottts/svg?seed=${data.discordname.substring(
          //         0,
          //         data.discordname.length - 5
          //       )}.svg?colorful=true" alt="Avatar of User"> <span class="hidden md:inline-block text-gray-100 text-lg">Hi, ${data.discordname.substring(
          //     0,
          //     data.discordname.length - 5
          //   )}</span>
          // 				<svg class="pl-2 h-2 fill-current text-gray-100" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129" xmlns: xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 129 129">
          // 				<g>
          // 					<path d="m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z"></path>
          // 				</g>
          // 			</svg>
          // 		</div>
          // 	</div>
          // </div>
          // `;
        });
    } else {
      alert("Your wallet address is not whitelisted.");
      localStorage.setItem("isWalletConnected", false);
    }
  }
}

function isInArray(value, array) {
  return array.indexOf(value) > -1;
}

var groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

async function delete_bug(id) {
  fetch(`https://latf-paper-trading-backend.herokuapp.com/bugs/delete?id=${id}`)
    .then((response) => response.json())
    .then((data) => {
      const deletedRow = document.querySelector(`[data-id='${id}']`);
      if (deletedRow) {
        deletedRow.remove();
      }
    });
}

async function load_bugs() {
  fetch("https://latf-paper-trading-backend.herokuapp.com/bugs")
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      let n_bugs = data.length;
      // document.getElementById("nb-bugs").innerHTML = n_bugs;
      data.forEach((row) => {
        const tr = document.createElement("tr");
        tr.className =
          " text-totalWhite text-lg font-mono text-center border-b bg-gray-800 border-gray-700 bg-gradient-to-l hover:from-dashboardgrada hover:to-dashboardgradb";
        tr.setAttribute("data-id", row.id);
        const td1 = document.createElement("td");
        const span = document.createElement("span");
        span.className = "capitalize font-medium";
        span.innerHTML = row.id;
        td1.appendChild(span);
        tr.appendChild(td1);
        const td2 = document.createElement("td");
        td2.innerHTML = row.message;
        tr.appendChild(td2);
        const td3 = document.createElement("td");
        td3.innerHTML = row.walletaddress;
        const button = document.createElement("button");
        button.className = "btn-close btn-close-white rotator-45";
        button.type = "button";
        button.setAttribute("aria-label", "Close");
        button.addEventListener("click", () => {
          delete_bug(row.id);
        });
        td3.appendChild(button);
        tr.appendChild(td3);
        document.getElementById("bugs-table").appendChild(tr);
      });
    });
}

async function load_users() {
  fetch("https://latf-paper-trading-backend.herokuapp.com/users")
    .then((response) => response.json())
    .then((data) => {
      // get the nb of rows
      let n_users = data.length;
      document.getElementById("nb-users").innerHTML = n_users;
      data.forEach((row) => {
        const tr = document.createElement("tr");
        // on click, open https://latf-paper-trading-backend.herokuapp.com//user/dynamicbalance?walletaddress=${row.walletaddress}
        tr.onclick = function () {
          window.open(
            `https://latf-paper-trading-backend.herokuapp.com/user/dynamicbalance?walletaddress=${row.walletaddress}`,
            "_blank"
          );
        };
        tr.className =
          " text-totalWhite text-lg font-mono text-center border-b bg-gray-800 border-gray-700 bg-gradient-to-l hover:from-dashboardgrada hover:to-dashboardgradb";
        tr.innerHTML = `
            <td class="py-1 px-6 columns-3">
            <img class="w-10 h-10 rounded-full mr-4" src="https://api.dicebear.com/7.x/bottts/svg?seed=${
              row.discordname
            }.svg?colorful=true" alt="Avatar of User" onclick="window.open('https://latf-paper-trading-frontend.herokuapp.com/dashboard/checker?walletAddress=${row.walletaddress.toUpperCase()}')">
            <span class="capitalize font-medium">${row.discordname}</span>
            </td>
            <td class="py-1 px-6">${row.created_at}</td>
            <td class="py-1 px-6">${row.walletaddress}</td>
          `;
        document.getElementById("users-table").appendChild(tr);
      });
    });
}

function search_user() {
  const searchTerm = document.getElementById("search-input").value;
  console.log(searchTerm);
  const rows = document
    .getElementById("users-table")
    .getElementsByTagName("tr");
  for (let i = 1; i < rows.length; i++) {
    const username =
      rows[i].getElementsByTagName("td")[0].children[1].textContent;
    if (username.toLowerCase().indexOf(searchTerm.toLowerCase()) === -1) {
      rows[i].style.display = "none";
    } else {
      rows[i].style.display = "";
    }
  }
}

async function load_nb_orders() {
  fetch("https://latf-paper-trading-backend.herokuapp.com/orders")
    .then((response) => response.json())
    .then((data) => {
      // get the nb of rows
      let n_orders = data.length;
      document.getElementById("nb-orders").innerHTML = n_orders;
    });
}

async function load_nb_positions() {
  fetch("https://latf-paper-trading-backend.herokuapp.com/positions")
    .then((response) => response.json())
    .then((data) => {
      // get the nb of rows
      let n_trades = data.length;
      document.getElementById("nb-positions").innerHTML = n_trades;
    });
}

async function load_nb_archived() {
  fetch("https://latf-paper-trading-backend.herokuapp.com/archive/positions")
    .then((response) => response.json())
    .then((data) => {
      // get the nb of rows
      let n_trades = data.length;
      document.getElementById("nb-archived").innerHTML = n_trades;
    });
}

async function post_community() {
  const title = document.getElementById("title-input").value;
  const option_a = document.getElementById("option1-input").value;
  const option_b = document.getElementById("option2-input").value;
  if (title === "" || option_a === "" || option_b === "") {
    return alert("Please fill all the fields");
  }
  await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/community/create/vote?title=${title}&option_a=${option_a}&option_b=${option_b}`
  )
    .then((response) => response.json())
    .then((data) => {
      if (data === 200) {
        alert("Community vote created successfully");
      }
    });
}