import React from "react";
import Slider from "@mui/material/Slider";

const marks = [
  {
    value: 50,
    label: '50%',
  },
];

async function valuetext(value) {
  const order_price_elem = await document.getElementById("order-price");
  if (document.getElementById("div-order-price").style.display !== "none") { // if limit order selected
    const symbol = geturl_symbol();
    if (symbol === 0) {
      return;
    }
    const order_price = order_price_elem.value;
    if (order_price === "") {
      return;
    }
    const balance = localStorage.getItem("balance")
    var qty = value / 100 * parseFloat(balance) / parseFloat(order_price) * 0.9999;
    // multiply by leverage (from localStorage) if there is a leverage
    const leverage = localStorage.getItem("leverage");
    if (leverage !== null) {
      qty = qty * leverage;
    }
    document.getElementById("order-qty").value = qty.toFixed(4)
    document.getElementById("limit-order-value1").style.display = "none";
    document.getElementById("limit-order-value2").style.display = "block";
    document.getElementById("limit-order-value2").textContent = `Order cost: ${(qty * order_price / leverage).toFixed(2)}$`
  }
  else { // if market order selected
    const symbol = geturl_symbol();
    if (symbol === 0) {
      return;
    }
    const currentprice = parseFloat(localStorage.getItem(symbol)) * 1.01
    const balance = localStorage.getItem("balance")
    var qty = value / 100 * parseFloat(balance) / parseFloat(currentprice);
    const leverage = localStorage.getItem("leverage");
    if (leverage !== null) {
      qty = qty * leverage;
    }
    console.log("current price market", currentprice, qty * currentprice / leverage)
    document.getElementById("order-qty").value = qty.toFixed(4)
    console.log(qty)
    document.getElementById("market-order-value1").style.display = "none";
    document.getElementById("market-order-value2").style.display = "block";
    document.getElementById("market-order-value2").textContent = `Order cost: ${(qty * currentprice / leverage).toFixed(2)}$`
  }
}

function Qtyslider() {
  const [value, setValue] = React.useState();

  const handleChange = (event, newValue) => {
    if (typeof newValue === 'number' && newValue !== 0) {
      setValue(newValue);
      valuetext(newValue);
    }
  };
  return (
    <Slider
      aria-label="Quantity"
      defaultValue={0}
      onChange={handleChange}
      // onClick={() => valuetext(value)}
      valueLabelDisplay="auto"
      step={10}
      marks={marks}
      min={0}
      max={100}
    />
  );
}

export default React.memo(Qtyslider)

function geturl_symbol() {
  return localStorage.getItem("selectedSymbol");
  // let queryString = window.location.href.split('?')[1];
  // let params = new URLSearchParams(queryString);
  // const url_param = params.get("symbol");
  // try {
  //   const index = url_param.indexOf(':');
  //   return url_param.slice(index + 1,);
  // }
  // catch {
  //   alert("Please select a cryptocurrency on the chart first.")
  //   return 0;
  // }
}