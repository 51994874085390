export default async function load_archives(walletaddress, chart) {
  await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/user/archive/positions?walletaddress=${walletaddress}`
  )
    .then((response) => response.json())
    .then((unsorted_data) => {
      const data = sort_orders(unsorted_data);
      console.log(chart.current);
      var ctx = document.getElementById("canvas").getContext("2d");
      var gradient = ctx.createLinearGradient(0, 0, 0, 400);
      gradient.addColorStop(0, "#71B280");
      gradient.addColorStop(1, "#134E5E");
      console.log(chart.current.data.datasets[0]);
      chart.current.data.datasets[0].backgroundColor = gradient;
      let i = 0;
      chart.current.data.datasets[0].data = [];
      chart.current.data.labels = [];
      while (i < data.length) {
        chart.current.data.datasets[0].data.push(data[i].total_balance);
        chart.current.data.labels.push(data[i].ordertime.substring(0, 10));
        i++;
      }
      chart.current.update();
      load_archive_table(data);
    });
}

function sort_orders(data_unsorted) {
	data_unsorted.sort((a, b) => {
		// Parse the ordertime values into Date objects
		const dateA = new Date(a.ordertime);
		const dateB = new Date(b.ordertime);
	  
		// Compare the Date objects and return a value based on the comparison
		if (dateA > dateB) return 1;
		if (dateA < dateB) return -1;
		return 0;
	  });
	  return data_unsorted;
}

function load_archive_table(data_unsorted) {
	const data = sort_orders(data_unsorted);
  let i = data.length - 1;
  while (i >= 0) {
    let color = "tomato-color";
    if (
      (data[i].orderprice < data[i].contractprice && data[i].profitloss > 0) ||
      (data[i].orderprice > data[i].contractprice && data[i].profitloss < 0)
    ) {
      color = "green-color";
    }
    document.getElementById("archive-table").innerHTML += `
		<tr class="text-center border-b bg-gray-800 border-gray-700 bg-gradient-to-l hover:from-dashboardgrada hover:to-dashboardgradb">
		  <th
			scope="row"
			class="py-1 px-2 font-medium ${color} whitespace-nowrap"
		  >
			${data[i].contract}
		  </th>
		  <td class="py-1 px-2">${data[i].ordertime}</td>
		  <td class="py-1 px-2">${data[i].quantity.toFixed(3)}</td>
		  <td class="py-1 px-2">${data[i].orderprice}</td>
		  <td class="py-1 px-2">${data[i].contractprice}</td>
		  <td class="py-1 px-2 ${is_profitloss(
        parseFloat(data[i].profitloss)
      )}">${parseFloat(data[i].profitloss).toFixed(2)}</td>
		  <td class="py-1 px-2">${data[i].total_balance.toFixed(1)}</td>
		  <td class="py-1 px-2">
			<div id="${data[i].orderid}" class="flex items-center place-content-center">
				${data[i].note}
				<a href="#edit-note">
        <button class="edit-button ml-2" data-note="${data[i].note}" data-orderid="${data[i].orderid}">
        <i class="material-icons text-blue-200 hover:scale-125">edit_note</i></button>
				</a>
        </div>
        </td>
        <td class="py-1 px-2">${data[i].setchecked}</td>
        <td class="py-1 px-2">${data[i].orderid.substring(0, 8)}... </td>
		</tr>`;
    i--;
  }
  document.querySelectorAll(".edit-button").forEach((button) => {
    button.addEventListener("click", (event) => {
      const note = button.dataset.note;
      openEditZone(note, button.dataset.orderid);
    });
  });
}

function openEditZone(note, orderid) {
  const existingContainer = document.querySelector("#edit-note");
  if (existingContainer) {
    document.body.removeChild(existingContainer);
  }
  const container = document.createElement("div");
  container.id = "edit-note";
  container.className =
    "bg-opacity-95 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 lg:w-1/4 w-5/6 bg-gray-800 rounded-lg shadow-lg p-4";
  container.innerHTML = `
  <div class="mx-auto max-w-md">
  <form class="w-full px-6">
    <a href="#" class="close-link absolute top-4 right-4 no-underline text-totalWhite">X</a>
    <label for="note" class="block mb-2 font-bold text-gray-200">Note:</label>
    <textarea id="note" name="note" rows="4" cols="50" class="w-full p-2 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300">${note}</textarea>
    <br>
    <input type="submit" value="Save" class="mx-auto mt-2 px-4 py-2 font-bold text-white bg-blue-500 rounded-full hover:bg-blue-700 focus:outline-none focus:shadow-outline flex">
  </form>
</div>
`;
  const form = container.querySelector("form");
  form.addEventListener("submit", async (event) => {
	event.preventDefault();
	const editedNote = form.querySelector("#note").value;
	try {
	  const response = await fetch(`https://latf-paper-trading-backend.herokuapp.com/archive/edit/note?note=${editedNote}&orderid=${orderid}`);
	  const data = await response.json();
	  document.getElementById(orderid).innerHTML = `
	  ${editedNote}
	  <a href="#edit-note">
		  <button class="edit-button ml-2" data-note="${editedNote}" data-orderid="${orderid}">
		  <i class="material-icons text-blue-200">edit_note</i></button>
	  </a> 
	  `;
	  document.querySelectorAll(".edit-button").forEach((button) => {
		button.addEventListener("click", (event) => {
		  const note = button.dataset.note;
		  openEditZone(note, button.dataset.orderid);
		});
	  });
	  document.body.removeChild(container);
	} catch (error) {
	  console.error(error);
	}
  });
  
  container.addEventListener("click", (event) => {
    if (event.target.classList.contains("close-link")) {
      document.body.removeChild(container);
    }
  });
  document.body.appendChild(container);
}

function is_profitloss(profitloss) {
  if (profitloss < 0) {
    return "tomato-color";
  }
  return "green-color";
}
