import React, { useEffect, useState } from 'react';

const calculateTimeLeft = (createdDate) => {
  const totalMilliseconds = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
  const createdTime = new Date(createdDate).getTime();
  const currentTime = new Date().getTime();
  const timeDifference = createdTime + totalMilliseconds - currentTime;

  if (timeDifference <= 0) {
    // Poll has finished
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    };
  }

  // Calculate days, hours, minutes, and seconds from time difference
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  return {
    days,
    hours,
    minutes,
    seconds
  };
};

function TimeLeft({ development }) {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(development.created_at));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(development.created_at));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [development.created_at]);

  return (
    <div className="text-center mb-6">
      <h2 className="text-2xl font-bold mb-2 text-gray-500">
        Time Left
      </h2>
      <div className="flex justify-center space-x-4 text-gray-600">
        <div>
          <span className="text-lg font-semibold text-gray-500">{timeLeft.days}</span>
          <span className="text-sm block text-gray-500">Days</span>
        </div>
        <div>
          <span className="text-lg font-semibold text-gray-500">{timeLeft.hours}</span>
          <span className="text-sm block text-gray-500">Hours</span>
        </div>
        <div>
          <span className="text-lg font-semibold text-gray-500">{timeLeft.minutes}</span>
          <span className="text-sm block text-gray-500">Minutes</span>
        </div>
        <div>
          <span className="text-lg font-semibold text-gray-500">{timeLeft.seconds}</span>
          <span className="text-sm block text-gray-500">Seconds</span>
        </div>
      </div>
    </div>
  );
}

export default TimeLeft;
