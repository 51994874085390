import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import CopyButton from "./CopyButton";

const ReferralsPage = () => {
  const [referralsExample, setReferralsExample] = useState([
    {
      id: 1,
      name: "Referral 1",
      email: "referral1@example.com",
      level: 3,
      dateJoined: "2023-05-01",
    },
    {
      id: 2,
      name: "Referral 2",
      email: "referral2@example.com",
      level: 7,
      dateJoined: "2023-05-05",
    },
    {
      id: 3,
      name: "Referral 3",
      email: "referral3@example.com",
      level: 2,
      dateJoined: "2023-05-10",
    },
  ]);
  const [referrals, setReferrals] = useState([]);
  // wait for referrals to be fetched
  const [referralsLoaded, setReferralsLoaded] = useState(false);

  // separate referrals and subreferrals
  const referralArray = referrals.filter(
    (referral) => referral.referral_type === "referee"
  );
  const subReferralArray = referrals.filter(
    (referral) => referral.referral_type === "subreferee"
  );

  // validate referrals and subreferrals
  const validatedReferrals = referralArray.filter(
    (referral) => referral.level > 5
  );
  const validatedSubReferrals = subReferralArray.filter(
    (subreferral) => subreferral.level > 5
  );

  const fetchReferrals = async (referrercode) => {
    try {
      console.log("referrercode", referrercode);
      await fetch(
        `https://latf-paper-trading-backend.herokuapp.com/referrer/list?referrercode=${referrercode}`
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("data", data);
          // print every referral
          data.forEach((referral) => {
            console.log("/profile?userid=" + window.btoa(referral.profile_url));
          });
          setReferrals(data);
          setReferralsLoaded(true);
        });
    } catch (error) {
      console.error("Error while fetching referrals:", error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      const walletaddress = localStorage.getItem("walletAddress");
      await fetch(
        `https://latf-paper-trading-backend.herokuapp.com/balance?walletaddress=${walletaddress}`
      )
        .then((response) => response.json())
        .then((data) => {
          document.getElementById("referralLink").innerHTML =
            "https://www.tradingarena.io/invite?code=" + data.referrercode;
          fetchReferrals(data.referrercode);
          const balance_object = document.getElementById("balance");
          balance_object.textContent = data.balance.toFixed(2);
          document.getElementById("balance-drop").textContent =
            data.balance.toFixed(2);
        });
    }
    fetchData();
  }, []);

  return (
    <div className="">
      <Navbar />
      <div className="p-2 ">
        <div className="container mx-auto py-6 space-y-10">
          <div class="text-5xl font-extrabold text-center mb-2 text-blue-500">
            <span class="text-gray-600">Refer</span>
            ral
          </div>
          <div className="flex flex-col space-y-6 md:flex-row md:space-y-0 lg:space-x-10">
            <div className="flex-1">
              <div className="text-center">
                <div className="bg-totalWhite rounded-3xl">
                  <div className="bg-blue-500 rounded-t-3xl">
                    <span className="text-3xl font-bold mb-4 mt-8 text-totalWhite">
                      Referral Link
                    </span>
                  </div>
                  <p className="text-sm text-gray-500">
                    Share your link with your friends to earn rewards.
                  </p>
                  <div className="flex items-center justify-center">
                    <div className="bg-totalWhite bg-opacity-80 rounded-2xl flex items-center border">
                      <span
                        id="referralLink"
                        className="text-gray-600 p-3 font-semibold nordique"
                      ></span>
                      <CopyButton />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-1">
              <div className="bg-totalWhite text-center rounded-3xl h-full flex flex-col relative">
                <div className="bg-blue-500 rounded-t-3xl ">
                  <span className="text-3xl font-bold mb-4 mt-8 text-totalWhite">
                    Referral Details
                  </span>
                  <span className="absolute bg-gray-400 text-white px-2 py-0.5 text-xs rounded">
                    Soon
                  </span>
                </div>
                <div className="flex items-center justify-center text-lg font-semibold text-gray-500">
                  <h5 className="mr-3 border-r border-gray-400 border-dashed pr-3">
                    Validated referrals: {validatedReferrals.length}
                  </h5>
                  <h5>
                    Validated sub-referrals: {validatedSubReferrals.length}
                  </h5>
                </div>

                <p className="text-sm text-gray-500 px-4 py-1">
                  Subreferrals are referrals of your referrals. Referrals are
                  validated once the referee reaches level 5.
                </p>
              </div>
            </div>
          </div>
          <div>
            <h2 className="text-2xl font-bold mb-2">Referral List</h2>
            {referrals.length === 0 && referralsLoaded ? (
              <div className="overflow-x-auto">
                <table className="min-w-full rounded-lg divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr className="">
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Username
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Level
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Date Joined
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {referralsExample.map((referral) => (
                      <tr
                        key={referral.id}
                        className={referral.level > 5 ? "bg-green-300" : ""}
                      >
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="font-semibold">{referral.name}</div>
                          <div>{referral.email}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {referral.level}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {referral.dateJoined}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="overflow-x-auto">
                <table className="min-w-full rounded-lg divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr className="">
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Username
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Referral Type
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Level
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Date Joined
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {referrals.map((referral) => (
                      <tr
                        onClick={() =>
                          (window.location.href =
                            "/profile?userid=" +
                            window.btoa(referral.profile_url))
                        }
                        className={
                          referral.level > 5
                            ? "bg-green-300 cursor-pointer hover:bg-green-400"
                            : "cursor-pointer hover:bg-gray-100"
                        }
                      >
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <img
                              class="w-10 h-10 rounded-full mr-4"
                              alt="Avatar of User"
                              src={`https://api.dicebear.com/7.x/bottts/svg?seed=${referral.discordname.slice(
                                0,
                                -5
                              )}.svg?colorful=true`}
                            ></img>

                            <div className="font-semibold">
                              {referral.discordname.slice(0, -5)}
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {referral.referral_type.charAt(0).toUpperCase() +
                            referral.referral_type.slice(1)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {/* if level is empty, then assign 0 */}
                          {referral.level ? referral.level : 0}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {referral.created_at}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralsPage;
