import React from "react";
import Navbar from "../NavbarNoBg";
import SignUpInvite from "./SignUpInvite";
import { getAccount } from "@wagmi/core";
import { verify_wallet_address } from "../../config/wallet_signer";
import anime from "animejs/lib/anime.es.js";
import VideoLanding from "../VideoLanding";
import { getUserAddress, connectWallet } from "../../config/connection_manager";

class Invite extends React.Component {
  constructor(props) {
    super(props);
    this.handleLoad = this.handleLoad.bind(this);
    this.state = {
      clicks: 0,
    };
  }

  componentDidMount() {
    this.handleLoad();
  }

  componentWillUnmount() {}

  handleLoad = async (e) => {
    const wrapper = document.getElementById("tiles");

    let columns = 0,
      rows = 0,
      toggled = false;

    const toggle = () => {
      toggled = !toggled;

      document.body.classList.toggle("toggled");
    };

    const handleOnClick = (index) => {
      this.setState({ clicks: this.state.clicks + 1 });
      if (this.state.clicks % 2 == 0) {
        document.getElementById("sign-up-popup").style.display = "none";
        // document.getElementById("landingvideo").style.display = "none";
      } else {
        document.getElementById("sign-up-popup").style.display = "block";
        // document.getElementById("landingvideo").style.display = "block";
      }
      toggle();

      anime({
        targets: ".tile",
        opacity: toggled ? 0 : 1,
        delay: anime.stagger(50, {
          grid: [columns, rows],
          from: index,
        }),
      });
    };

    const createTile = (index) => {
      const tile = document.createElement("div");

      tile.classList.add("tile");
      tile.classList.add("bg-bbbglight");

      tile.style.opacity = toggled ? 0 : 1;

      tile.onclick = (e) => handleOnClick(index);

      return tile;
    };

    const createTiles = (quantity) => {
      Array.from(Array(quantity)).map((tile, index) => {
        wrapper.appendChild(createTile(index));
      });
    };

    const createGrid = () => {
      wrapper.innerHTML = "";

      const size = document.body.clientWidth > 800 ? 90 : 70;

      columns = Math.floor(document.body.clientWidth / size);
      rows = Math.floor(document.body.clientHeight / size);

      wrapper.style.setProperty("--columns", columns);
      wrapper.style.setProperty("--rows", rows);

      createTiles(columns * rows);
    };

    createGrid();

    window.onresize = () => createGrid();

    document.body.classList.add("body-landing");

    if (localStorage?.getItem("isWalletConnected") === "true") {
      // Check if browser is running Metamask
      const addr = await getUserAddress();
      await fetch(
        `https://latf-paper-trading-backend.herokuapp.com/balance?walletaddress=${addr}`
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data.discordname, data.balance);
          localStorage.setItem("isWalletConnected", true);
        });
    } else {
      localStorage.setItem("isWalletConnected", false);
    }
  };
  state = {};
  render() {
    return (
      <>
        <div id="tiles"></div>
        {/* <h1 id="title" class="centered-title font-semibold">
          Trading
          <span className="fancy neon ml-8">Arena</span>
        </h1> */}
        <div className="flex bottomleft">
          <span className="nordique font-semibold text-sm md:text-lg">
            Experience the thrill of trading in a whole new way.
          </span>
        </div>
        <div className="centered">
          <i
            className="material-icons inline-block align-bottom"
            style={{ "font-size": "380px", transform: "translate(0%, 0%)" }}
          >
            {" "}
            stadium
          </i>{" "}
        </div>
        <div className="topleft">
          <Navbar {...{ connect }} />
        </div>
        <SignUpInvite {...{ document }} />
        <div
          className="h-full w-full bg-black space-y-10"
          style={{
            backgroundImage: 'url("../../images/iphone-feature-bg-01.svg")',
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
        >
          {/* text centered write Join Now */}
          <div className="flex justify-center nordique">
            <h1 className="text-5xl text-white font-bold mt-10"></h1>
          </div>
          <div className="stars"></div>
          <div className="flex justify-center">
            <div className="columns-2 flex justify-center space-x-2">
              <div className="rounded-l-3xl bg-bbbglight hover:scale-95 transform duration-300 ease-in-out">
                <a href="#sign-up-popup" className="">
                  <button
                    type="button"
                    onClick={() => {
                      document
                        .getElementById("sign-up-popup")
                        .classList.remove("hidden");
                    }}
                    className="kave-btn md:px-24 md:py-6 px-12 py-2 nordique font-semibold text-totalWhite"
                  >
                    <span className="kave-line"></span>
                    Sign Up
                  </button>
                </a>
              </div>
              <div className="rounded-r-3xl bg-bbbglight hover:scale-95 transform duration-300 ease-in-out">
                <a href="#" className="">
                  <button
                    type="button"
                    onClick={() => {
                      window.location.href = "/?symbol=BINANCE%3ABTCUSDT";
                    }}
                    className="md:py-6 kave-btn px-12 py-2 md:px-24 nordique font-semibold text-totalWhite"
                  >
                    <span class="kave-line"></span>
                    Log In
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <div
              id="landingvideo"
              className="w-5/6 h:5/6 md:w-1/2 md:h-1/2 z-40"
            >
              <VideoLanding />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Invite;

async function connect() {
  const [signerAddr, isOwner] = await verify_wallet_address(
    "Welcome to the LATF Paper Trading.\n\nSign this request to access your account.\n\nThis request will not trigger a blockchain transaction or cost any gas fees."
  );
  if (isOwner) {
    try {
      localStorage.setItem("isWalletConnected", true);
    } catch (ex) {
      console.log(ex);
    }
    try {
      connectWallet();
    } catch (err) {
      localStorage.setItem("isWalletConnected", false);
      document.getElementById("sign-up-popup").classList.remove("hidden");
    }
  }
}

var groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

async function delete_bug(id) {
  fetch(`https://latf-paper-trading-backend.herokuapp.com/bugs/delete?id=${id}`)
    .then((response) => response.json())
    .then((data) => {
      const deletedRow = document.querySelector(`[data-id='${id}']`);
      if (deletedRow) {
        deletedRow.remove();
      }
    });
}

async function load_bugs() {
  fetch("https://latf-paper-trading-backend.herokuapp.com/bugs")
    .then((response) => response.json())
    .then((data) => {
      let n_bugs = data.length;
      // document.getElementById("nb-bugs").innerHTML = n_bugs;
      data.forEach((row) => {
        const tr = document.createElement("tr");
        tr.className =
          " text-totalWhite text-lg font-mono text-center border-b bg-gray-800 border-gray-700 bg-gradient-to-l hover:from-dashboardgrada hover:to-dashboardgradb";
        tr.setAttribute("data-id", row.id);
        const td1 = document.createElement("td");
        const span = document.createElement("span");
        span.className = "capitalize font-medium";
        span.innerHTML = row.id;
        td1.appendChild(span);
        tr.appendChild(td1);
        const td2 = document.createElement("td");
        td2.innerHTML = row.message;
        tr.appendChild(td2);
        const td3 = document.createElement("td");
        td3.innerHTML = row.walletaddress;
        const button = document.createElement("button");
        button.className = "btn-close btn-close-white rotator-45";
        button.type = "button";
        button.setAttribute("aria-label", "Close");
        button.addEventListener("click", () => {
          delete_bug(row.id);
        });
        td3.appendChild(button);
        tr.appendChild(td3);
        document.getElementById("bugs-table").appendChild(tr);
      });
    });
}

async function load_users() {
  fetch("https://latf-paper-trading-backend.herokuapp.com/users")
    .then((response) => response.json())
    .then((data) => {
      // get the nb of rows
      let n_users = data.length;
      document.getElementById("nb-users").innerHTML = n_users;
      data.forEach((row) => {
        const tr = document.createElement("tr");
        // on click, open https://latf-paper-trading-backend.herokuapp.com//user/dynamicbalance?walletaddress=${row.walletaddress}
        tr.onclick = function () {
          window.open(
            `https://latf-paper-trading-backend.herokuapp.com/user/dynamicbalance?walletaddress=${row.walletaddress}`,
            "_blank"
          );
        };
        tr.className =
          " text-totalWhite text-lg font-mono text-center border-b bg-gray-800 border-gray-700 bg-gradient-to-l hover:from-dashboardgrada hover:to-dashboardgradb";
        tr.innerHTML = `
            <td class="py-1 px-6 columns-3">
            <img class="w-10 h-10 rounded-full mr-4" src="https://api.dicebear.com/7.x/bottts/svg?seed=${row.discordname}.svg?colorful=true" alt="Avatar of User">
            <span class="capitalize font-medium">${row.discordname}</span>
            </td>
            <td class="py-1 px-6">${row.created_at}</td>
            <td class="py-1 px-6">${row.walletaddress}</td>
          `;
        document.getElementById("users-table").appendChild(tr);
      });
    });
}

function search_user() {
  const searchTerm = document.getElementById("search-input").value;
  console.log(searchTerm);
  const rows = document
    .getElementById("users-table")
    .getElementsByTagName("tr");
  for (let i = 1; i < rows.length; i++) {
    const username =
      rows[i].getElementsByTagName("td")[0].children[1].textContent;
    if (username.toLowerCase().indexOf(searchTerm.toLowerCase()) === -1) {
      rows[i].style.display = "none";
    } else {
      rows[i].style.display = "";
    }
  }
}

async function load_nb_orders() {
  fetch("https://latf-paper-trading-backend.herokuapp.com/orders")
    .then((response) => response.json())
    .then((data) => {
      // get the nb of rows
      let n_orders = data.length;
      document.getElementById("nb-orders").innerHTML = n_orders;
    });
}

async function load_nb_positions() {
  fetch("https://latf-paper-trading-backend.herokuapp.com/positions")
    .then((response) => response.json())
    .then((data) => {
      // get the nb of rows
      let n_trades = data.length;
      document.getElementById("nb-positions").innerHTML = n_trades;
    });
}

async function load_nb_archived() {
  fetch("https://latf-paper-trading-backend.herokuapp.com/archive/positions")
    .then((response) => response.json())
    .then((data) => {
      // get the nb of rows
      let n_trades = data.length;
      document.getElementById("nb-archived").innerHTML = n_trades;
    });
}
