import React from "react";
import { ResponsiveSankey } from "@nivo/sankey";

const LandingSankey = ({ data }) => (
  <ResponsiveSankey
    data={data}
    isInteractive={true}
    margin={{ top: 40, right: -150, bottom: 40, left: 160 }}
    align="justify"
    colors={{ scheme: "purple_blue" }}
    linkBlendMode="difference"
    nodeOpacity={0}
    sort="input"
    nodeHoverOpacity={0}
    nodeHoverOthersOpacity={0}
    nodeThickness={26}
    nodeSpacing={60}
    nodeBorderWidth={2}
    nodeTooltip={({ node }) => (
      <div
        style={{
          background: "white",
          padding: "9px 12px",
          border: "1px solid #ccc",
          display: "none",
        }}
      >
        <div>{node.label}</div>
      </div>
    )}
    nodeBorderColor={{
      from: "color",
      modifiers: [["darker", 0.8]],
    }}
    theme={{
      tooltip: {
        container: {
          background: "black",
          color: "white",
          display: "none",
        },
      },
      fontSize: 18,
    }}
    nodeBorderRadius={0}
    linkOpacity={1}
    linkHoverOpacity={1}
    linkHoverOthersOpacity={0.1}
    linkContract={10}
    enableLinkGradient={true}
    labelPosition="outside"
    labelOrientation="horizontal"
    labelPadding={-17}
    // labelTextColor={{
    //     from: 'color',
    //     modifiers: [
    //         [
    //             'darker',
    //             0.6
    //         ]
    //     ]
    // }}
    // Use the labelBackgroundColor property to set the background color of the labels
    labelBackgroundColor="#17181f"
    labelTextColor="lightblue"
    label={(link) => `${link.id} \n *${link.value}`}
  />
);

export default LandingSankey;
