import React from "react";
import Web3 from "web3";
import { ethers } from "ethers";
import Navbar from "./Navbar";
import whitelist_array from "./whitelist";
import SignUp from "./SignUp";
import { getAccount } from "@wagmi/core";
import { verify_wallet_address } from "../config/wallet_signer";
import { getUserAddress, connectWallet } from "../config/connection_manager";

class Leaderboard extends React.Component {
  constructor(props) {
    super(props);
    this.handleLoad = this.handleLoad.bind(this);
  }

  componentDidMount() {
    this.handleLoad();
  }

  componentWillUnmount() {}

  handleLoad = async (e) => {
    manage_leaderboard(this.state.page);
    if (localStorage?.getItem("isWalletConnected") === "true") {
      const addr = await getUserAddress();
      try {
        await fetch(
          `https://latf-paper-trading-backend.herokuapp.com/balance?walletaddress=${addr}`
        )
          .then((response) => response.json())
          .then((data) => {
            console.log(data.discordname, data.balance);
            const balance_object = document.getElementById("balance");
            balance_object.textContent = data.balance.toFixed(2);
            document.getElementById("balance-drop").textContent =
              data.balance.toFixed(2);
            localStorage.setItem("isWalletConnected", true);
          });
      } catch (error) {
        document.getElementById("sign-up-popup").classList.remove("hidden");
      }
    } else {
      localStorage.setItem("isWalletConnected", false);
    }
  };

  state = {};
  render() {
    return (
      <>
        <Navbar {...{ connect }} />
        <SignUp {...{ document }} />
        <div className="grid grid-rows-7 h-full w-full bg-tvcolor">
          <div className="row-span-1"></div>
          <div className="bg-tvcolor grid grid-cols-10 row-span-6 px-10">
            <div className="col-span-10 overflow-auto">
              <div
                id="div-table-leaderboard"
                className="h-full border-totalWhite text-xl text-totalWhite overflow-auto"
              >
                <div class="text-5xl font-extrabold text-center mb-2 text-yellow">
                  <span class="text-gray-100">Leader</span>
                  board
                </div>
                <div className="overflow-x-auto relative bg-tvcolor rounded-3xl">
                  <table
                    id="element-leaderboard-table"
                    className="sortable w-full text-sm text-left text-gray-500 text-gray-400 relative justify-evenly"
                  >
                    <thead className="text-xs uppercase bg-gray-50 bg-gray-700 text-gray-400 ">
                      <tr className="text-center text-base font-serif font-base">
                        <th scope="col" className="py-3 px-6">
                          #
                        </th>
                        <th scope="col" className="py-3 px-6">
                          Name
                        </th>
                        <th
                          onClick={() => sortTable(1)}
                          scope="col"
                          className="py-3 px-6 cursor-pointer active:bg-gray-300 hover:bg-gray-200"
                        >
                          Total Balance
                        </th>
                        <th
                          onClick={() => sortTable(2)}
                          scope="col"
                          className="py-3 px-6 cursor-pointer active:bg-gray-300 hover:bg-gray-200"
                        >
                          Winrate
                        </th>
                        <th
                          onClick={() => sortTable(3)}
                          scope="col"
                          className="py-3 px-6 cursor-pointer active:bg-gray-300 hover:bg-gray-200"
                        >
                          APPT
                        </th>
                        <th
                          onClick={() => sortTable(5)}
                          scope="col"
                          className="py-3 px-6"
                        >
                          Trades count
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      id="leaderboard-table"
                      className="text-totalWhite"
                    ></tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Leaderboard;

async function connect() {
  const [signerAddr, isOwner] = await verify_wallet_address(
    "Welcome to the LATF Paper Trading.\n\nSign this request to access your account.\n\nThis request will not trigger a blockchain transaction or cost any gas fees."
  );
  if (isOwner) {
    try {
      localStorage.setItem("isWalletConnected", true);
    } catch (ex) {
      console.log(ex);
    }
    try {
      connectWallet();
    } catch (err) {
      localStorage.setItem("isWalletConnected", false);
      document.getElementById("sign-up-popup").classList.remove("hidden");
    }
  }
}

async function manage_leaderboard(page) {
  await fetch(`https://latf-paper-trading-backend.herokuapp.com/leaderboard`)
      .then((response) => response.json())
      .then((data) => {
          console.log(data);
          // keep toop 100
          data = data.slice(0, 100);
          populate_leaderboard(data);
      });
}

async function populate_leaderboard(array_users_metrics) {
  const parent = document.getElementById("leaderboard-table");
  let i = 0;
  while (i < array_users_metrics.length) {
    parent.innerHTML += `
		<tr 
     class="cursor-pointer text-lg font-mono text-center border-b bg-gray-800 border-gray-700 bg-gradient-to-l hover:from-dashboardgrada hover:to-dashboardgradb"
     onclick="window.location.href = '/profile?userid=${window.btoa(
       array_users_metrics[i].walletaddress
     )}';"
     >
		<th
		id="leaderboard-rank-${i}"
		scope="row"
		class="py-4 px-6 font-medium whitespace-nowrap"
		>
		${i}
		</th>
		<td class="py-4 px-6">
      <div class="">
      <div class="columns-3 items-center">
        <div>
        <img class="flex flex-col justify-centerw-10 h-10 rounded-full mr-4" src="https://api.dicebear.com/7.x/bottts/svg?seed=${
          array_users_metrics[i].discordname
        }.svg?colorful=true" alt="Avatar of User">
        </div>
      <div>
        <span class="font-medium">${array_users_metrics[i].discordname}</span>
        </div>
        </div>
      </div>
		</td>
    <td class="py-4 px-6 text-center">
    ${array_users_metrics[i].totalbalance.toFixed(2)}
    <div class="text-sm 
                ${
                  ((array_users_metrics[i].totalbalance - 100000) / 100000) *
                    100 >=
                  0
                    ? "text-green-500"
                    : "text-red-500"
                }
                mt-1">
      (${(
        ((array_users_metrics[i].totalbalance - 100000) / 100000) *
        100
      ).toFixed(0)}%)
    </div>
  </td>
      <td class="py-4 px-6">${
        isNaN(parseFloat(array_users_metrics[i].winrate))
          ? "0.00"
          : parseFloat(array_users_metrics[i].winrate).toFixed(2)
      }</td>
    <td class="py-4 px-6">${
      isNaN(parseFloat(array_users_metrics[i].appt))
        ? "0.00"
        : parseFloat(array_users_metrics[i].appt).toFixed(2)
    }</td>
    
		<td class="py-4 px-6">${array_users_metrics[i].tradescount}</td>
		</tr>
		`;
    i++;
  }
  await sortTable(1);
}

async function sortTable(n) {
  var table,
    rows,
    switching,
    i,
    x,
    y,
    shouldSwitch,
    dir,
    switchcount = 0;
  table = await document.getElementById("element-leaderboard-table");
  switching = true;
  // Set the sorting direction to ascending:
  dir = "desc";
  /* Make a loop that will continue until
	no switching has been done: */
  while (switching) {
    // Start by saying: no switching is done:
    switching = false;
    rows = table.rows;
    /* Loop through all table rows (except the
		first, which contains table headers): */
    for (i = 1; i < rows.length - 1; i++) {
      // Start by saying there should be no switching:
      shouldSwitch = false;
      /* Get the two elements you want to compare,
			one from current row and one from the next: */
      x = rows[i].getElementsByTagName("TD")[n];
      y = rows[i + 1].getElementsByTagName("TD")[n];
      /* Check if the two rows should switch place,
			based on the direction, asc or desc: */
      if (x.innerHTML == "NaN") {
        x.innerHTML = 0;
      }
      if (y.innerHTML == "NaN") {
        y.innerHTML = 0;
      }
      if (dir == "asc") {
        if (parseFloat(x.innerHTML) > parseFloat(y.innerHTML)) {
          // If so, mark as a switch and break the loop:
          shouldSwitch = true;
          break;
        }
      } else if (dir == "desc") {
        if (parseFloat(x.innerHTML) < parseFloat(y.innerHTML)) {
          // If so, mark as a switch and break the loop:
          shouldSwitch = true;
          break;
        }
      }
    }
    if (shouldSwitch) {
      /* If a switch has been marked, make the switch
			and mark that a switch has been done: */
      rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
      switching = true;
      // Each time a switch is done, increase this count by 1:
      switchcount++;
    } else {
      /* If no switching has been done AND the direction is "asc",
			set the direction to "desc" and run the while loop again. */
      if (switchcount == 0 && dir == "asc") {
        dir = "desc";
        switching = true;
      }
    }
  }
  reRank(table);
  const leaderboard_table = document.getElementById("leaderboard-table");
  // reset all rows leaderboard_table.children[0].children[0].classList to default
  for (let i = 0; i < leaderboard_table.children.length; i++) {
    leaderboard_table.children[i].children[0].classList =
      "py-4 px-6 font-medium whitespace-nowrap";
  }
  leaderboard_table.children[0].children[0].classList.add("text-yellow");
  leaderboard_table.children[1].children[0].classList.add("text-silver");
  leaderboard_table.children[2].children[0].classList.add("text-bronze");
  leaderboard_table.children[0].children[0].innerHTML = `1<i class="material-icons text-2xl text-yellow hover:scale-125">military_tech</i>`;
  leaderboard_table.children[1].children[0].innerHTML = `2<i class="material-icons text-silver hover:scale-125">military_tech</i>`;
  leaderboard_table.children[2].children[0].innerHTML = `3<i class="material-icons text-bronze hover:scale-125">military_tech</i>`;
}

function reRank(table) {
  let i = 1;
  const rows = table.rows;
  while (i < rows.length) {
    rows[i].getElementsByTagName("TH")[0].innerHTML = i;
    i++;
  }
}
