import React, { useEffect } from "react";
import "./styles/App.css";
import { BrowserRouter as Router, Route, Routes, Redirect } from "react-router-dom";
import Home from "./pages/Home/Home";
import Leaderboard from "./pages/Leaderboard";
import LeaderboardTw from "./pages/LeaderboardTw";
import Rewards from "./pages/Rewards";
import Dashboard from "./pages/Dashboard";
import DashboardOther from "./pages/DashboardOther";
import Admin from "./pages/Admin";
import LandingPage from "./pages/LandingPage";
import Redirecter from "./pages/Redirect";
import Profile from "./pages/Profile/Profile";
import MySkillTree from "./pages/Profile/MySkillTree";
import ReferralsPage from "./pages/Profile/ReferralsPage";
import Invite from "./pages/Profile/Invite";
import CommunityPage from "./pages/Community/CommunityPage";
import TournamentPage from "./pages/Tournament/TournamentPage";
import TournamentPageAirdrop from "./pages/Tournament/TournamentPageAirdrop";
import Lobby from "./pages/PvP/Lobby";
import Battle from "./pages/PvP/Battle";
import BattleResult from "./pages/PvP/BattleResult";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { arbitrum, mainnet, polygon } from "wagmi/chains";
import Discover from "./pages/Posts/Discover";
import Feed from "./pages/Posts/Feed";
import BettingInterface from "./pages/Betting/BettingInterface";

const chains = [arbitrum, mainnet, polygon];
const projectId = "5f84c7c55a2c00bbc6c827d0e70c4d1c";

const { provider } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 1, chains }),
  provider,
});
const ethereumClient = new EthereumClient(wagmiClient, chains);

function App() {
  useEffect(() => {
    if (window.location.protocol === "http:" && !window.location.host.includes("localhost")) 
    {
      window.location.href = `https://${window.location.host}${window.location.pathname}`;
    }
  }, []);
  return (
    <>
    <WagmiConfig client={wagmiClient}>
      <div className="App">
        <Router>
          <Routes>
            <Route path="/trade" element={<Home />} />
            <Route path="/" element={<Redirecter />} />
            <Route path="/leaderboard" element={<Leaderboard />} />
            <Route path="/leaderboardtw" element={<LeaderboardTw />} />
            <Route path="/rewards" element={<Rewards />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/dashboard/checker" element={<DashboardOther />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/home" element={<LandingPage />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/skilltree" element={<MySkillTree />} />
            <Route path="/referrals" element={<ReferralsPage />} />
            <Route path="/invite" element={<Invite />} />
            <Route path="/community" element={<CommunityPage />} />
            <Route path="/tournament" element={<TournamentPage />} />
            <Route path="/tournament/airdrop" element={<TournamentPageAirdrop />} />
            <Route path="/pvp" element={<Lobby />} />
            <Route path="/pvp/battle/:battleId" element={<Battle />} />
            <Route path="/pvp/battleresult" element={<BattleResult />} />
            <Route path="/posts/discover" element={<Discover />} />
            <Route path="/posts/feed" element={<Feed />} />
            <Route path="/betting" element={<BettingInterface />} />
          </Routes>
        </Router>
      </div>
    </WagmiConfig>
    <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </>
  );
}

export default App;
