import React from 'react';

function BattleResults() {
    return (
        <div className="min-h-screen bg-gray-100 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg shadow-lg">
                <h1 className="text-2xl font-bold mb-4">Battle Results</h1>
                <div className="mb-4">Winner: User A</div>
                <button className="bg-blue-500 text-white px-4 py-2 rounded" onClick={backToLobby}>Back to Lobby</button>
            </div>
        </div>
    );
}

function backToLobby() {
    alert("Back to Lobby!");
}

export default BattleResults;
