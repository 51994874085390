import React from "react";
import Navbar from "./Navbar";
import LandingSankey from "./LandingSankey";
import LandingSankeyReverse from "./LandingSankeyReverse";
import { DoughnutChart } from "./DoughnutChart";
import SignUp from "./SignUp";
import Topup from "./Topup";
import { verify_wallet_address } from "../config/wallet_signer";
import { getUserAddress, connectWallet } from "../config/connection_manager";

const data = {
  nodes: [
    {
      id: "LATF MEMBER",
      nodeColor: "hsl(31, 70%, 50%)",
    },
    {
      id: "Global Rank",
      nodeColor: "hsl(112, 70%, 50%)",
    },
    {
      id: "Achievements",
      nodeColor: "hsl(33, 70%, 50%)",
    },
    {
      id: "Early Sign Up",
      nodeColor: "hsl(58, 70%, 50%)",
    },
    {
      id: "Twitter interaction",
      nodeColor: "hsl(306, 70%, 50%)",
    },
    {
      id: "Center",
      nodeColor: "hsl(306, 70%, 50%)",
    },
  ],
  links: [
    {
      source: "LATF MEMBER",
      target: "Center",
      value: 0,
    },
    {
      source: "Global Rank",
      target: "Center",
      value: 0,
    },
    {
      source: "Achievements",
      target: "Center",
      value: 0,
    },
    {
      source: "Early Sign Up",
      target: "Center",
      value: 2,
    },
    {
      source: "Twitter interaction",
      target: "Center",
      value: 0,
    },
  ],
};

var datareverse = {
  nodes: [
    {
      id: "LATF MEMBER",
      nodeColor: "hsl(31, 70%, 50%)",
    },
    {
      id: "Global Rank",
      nodeColor: "hsl(112, 70%, 50%)",
    },
    {
      id: "Achievements",
      nodeColor: "hsl(33, 70%, 50%)",
    },
    {
      id: "Early Sign Up",
      nodeColor: "hsl(58, 70%, 50%)",
    },
    {
      id: "Twitter interaction",
      nodeColor: "hsl(306, 70%, 50%)",
    },
    {
      id: "Center",
      nodeColor: "hsl(306, 70%, 50%)",
    },
  ],
  links: [
    {
      source: "LATF MEMBER",
      target: "Center",
      value: 0,
    },
    {
      source: "Global Rank",
      target: "Center",
      value: 0,
    },
    {
      source: "Achievements",
      target: "Center",
      value: 0,
    },
    {
      source: "Early Sign Up",
      target: "Center",
      value: 200000,
    },
    {
      source: "Twitter interaction",
      target: "Center",
      value: 0,
    },
  ],
};

class Rewards extends React.Component {
  constructor(props) {
    super(props);
    this.handleLoad = this.handleLoad.bind(this);
    this.state = {
      multiplicator: 1,
    };
  }

  componentDidMount() {
    this.handleLoad();
  }

  componentWillUnmount() {}

  async set_global_multiplier(walletaddress, current_multiplicator) {
    console.log("wallet1 == ", walletaddress);
    // call /leaderboard to get user_rank
    const rank_details = await fetch(
      "https://latf-paper-trading-backend.herokuapp.com/user/rank/leaderboard?walletaddress=" +
        walletaddress
    )
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    const rank = rank_details.rank;
    const tournament_users_length = rank_details.length;
    const percentage_rank = (rank / tournament_users_length) * 100;
    console.log("Rank === ", rank, "Percentage Rank === ", percentage_rank);
    if (rank === 1) {
      data.links[1].value = 50;
      datareverse.links[1].value = 50 * 100000;
    } else if (rank === 2) {
      data.links[1].value = 30;
      datareverse.links[1].value = 30 * 100000;
    } else if (rank === 3) {
      data.links[1].value = 20;
      datareverse.links[1].value = 20 * 100000;
    } else if (percentage_rank <= 3) {
      data.links[1].value = 10;
      datareverse.links[1].value = 15 * 100000;
    } else if (percentage_rank <= 5) {
      data.links[1].value = 10;
      datareverse.links[1].value = 12 * 100000;
    } else if (percentage_rank <= 10) {
      data.links[1].value = 10;
      datareverse.links[1].value = 10 * 100000;
    } else if (percentage_rank <= 25) {
      data.links[1].value = 5;
      datareverse.links[1].value = 5 * 100000;
    } else if (percentage_rank <= 50) {
      data.links[1].value = 2;
      datareverse.links[1].value = 2  * 100000;
    } else {
      data.links[1].value = 0;
      datareverse.links[1].value = 0 * 100000;
    }
    console.log("value === ", data.links[1].value);
    this.setState({
      multiplicator: data.links[1].value + current_multiplicator,
      data: data,
    });
  }

  handleLoad = async (e) => {
    if (localStorage?.getItem("isWalletConnected") === "true") {
      const addr = await getUserAddress();
      try {
        await fetch(
          `https://latf-paper-trading-backend.herokuapp.com/balance?walletaddress=${addr}`
        )
          .then((response) => response.json())
          .then((data_fetched) => {
            console.log(data_fetched.discordname, data_fetched.balance);
            const balance_object = document.getElementById("balance");
            balance_object.textContent = data_fetched.balance.toFixed(2);
            document.getElementById("balance-drop").textContent =
              data_fetched.balance.toFixed(2);
            localStorage.setItem("isWalletConnected", true);
            // Hide element sign-up
            document.getElementById("sign-up").style.display = "none";
            const latf_duration = data_fetched.latf_duration;
            if (latf_duration > 10) {
              latf_duration = 10;
            }
            data.links[0].value = latf_duration;
            datareverse.links[0].value = latf_duration * 100000;
            let i = 0;
            let multiplicator = 0;
            while (i < data.links.length) {
              multiplicator += data.links[i].value;
              i++;
            }
            // set this.multiplicator
            this.setState({ multiplicator });
            this.set_global_multiplier(addr, multiplicator);
            manage_balance_doughnut(data);
            countDownClaim(addr[0]);
          });
      } catch (error) {
        document.getElementById("sign-up-popup").classList.remove("hidden");
      }
    } else {
      localStorage.setItem("isWalletConnected", false);
    }
  };

  state = {
    data: data,
  };
  render() {
    const { multiplicator } = this.state;
    return (
      <>
        <Navbar {...{ connect }} />
        <div className="grid grid-rows-7 h-full w-full bg-landingbg relative">
          <div className="md:grid md:grid-cols-10 row-span-6 bg-landingbg">
            <div className="md:py-4 md:col-span-2 text-totalWhite bg-landingbg mt-10 md:mt-0 md:space-y-20 place-content-center">
              <div className="relative">
                <div
                  className="font-semibold absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 p-3 rounded-lg"
                  style={{
                    backgroundImage:
                      'url("../../images/backgrounddetails.png")',
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                  }}
                >
                  Post Open
                  <span className="text-blue-600 italic"> Beta</span>
                </div>
                <div className="absolute inset-0 opacity-50 z-0"></div>
                <div className="ttt-sm hover:ttt-md z-20">
                <DoughnutChart multiplicator={multiplicator} />
                </div>
              </div>
              <Topup {...{ alert }} />
            </div>
            <SignUp {...{ document }} />
            <div class="col-span-8 lg:px-10 lg:pr-20 bg-landingbg z-2 md:space-y-4 py-4">
              <div
                id="sign-up"
                className="flex place-content-center place-items-center md:-ml-20 md:mr-20"
              >
                <div class="sm:w-5/12 md:w-4/12 lg:w-3/12 mb-12 md:mb-0 w-1/2">
                  <img
                    src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg"
                    class="w-full"
                    alt="Phone image"
                  />
                </div>
                <div className="sm:w-5/12 md:w-3/12 lg:w-2/12 md:h-3/12 lg:h-2/12">
                  <a href="#sign-up-popup">
                    <button
                      type="button"
                      onClick={() => {
                        document
                          .getElementById("sign-up-popup")
                          .classList.remove("hidden");
                      }}
                      className="inline-block px-6 py-2.5 bg-blue-600 text-white font-extrabold text-lg leading-tight rounded-full shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                    >
                      Sign Up
                    </button>
                  </a>
                </div>
              </div>
              <div
                className=" row-span-3 grid grid-cols-8 bg-landingcompobg rounded-3xl border-landingcompobg relative"
                style={{ height: 500 }}
              >
                <div
                  className="z-40 font-semibold absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 p-3 rounded-lg text-totalWhite"
                  style={{
                    backgroundImage:
                      'url("../../images/backgrounddetails.png")',
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                  }}
                >
                  Post Open
                  <span className="text-blue-600 italic"> Beta</span>
                </div>
                <div className="ttt-sm hover:ttt-md col-span-3 toright -mr-20 text hidden md:block">
                  <LandingSankey {...{ data }} />
                </div>
                <div className="ttt-sm hover:ttt-md background-animate bg-gradient-to-r from-dashboardgrada to-dashboardgradb bg-opacity-30 justify-items-center w-full h-5/6 rounded-full opacity-97 col-span-8 md:col-span-2 z-10 self-center text-center shadow-3xl">
                  <div className="flex justify-center items-center h-full relative grid grid-rows-10">
                    <div className="col-start-1 row-span-5 row-start-1">
                      <h2
                        className="block align-top text-4xl opacity-80 text-landingmultiplier shadow-3xl shadow-green-200 px-5"
                        style={{ margin: "0 0 10px 0" }}
                      >
                        Reward Multiplier
                      </h2>
                    </div>
                    <div className="col-start-1 row-span-5 row-start-2">
                      <span
                        id="multiplicator"
                        className="text-5xl font-bold text-green-400 block align-top"
                        style={{ margin: "0 0 10px 0" }}
                      >
                        x{multiplicator}
                      </span>
                    </div>
                    <div className="absolute w-5/6 h-5/6 rounded-full bg-landingbg opacity-100 -z-50 self-center justify-self-center"></div>
                  </div>
                </div>
                <div className="ttt-sm hover:ttt-md col-span-3 rotate-180 -ml-24 hidden md:block">
                  <LandingSankeyReverse {...{ datareverse }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Rewards;

async function connect() {
  const [signerAddr, isOwner] = await verify_wallet_address(
    "Welcome to the LATF Paper Trading.\n\nSign this request to access your account.\n\nThis request will not trigger a blockchain transaction or cost any gas fees."
  );
  if (isOwner) {
    try {
      localStorage.setItem("isWalletConnected", true);
    } catch (ex) {
      console.log(ex);
    }
    try {
      connectWallet();
    } catch (err) {
      localStorage.setItem("isWalletConnected", false);
      document.getElementById("sign-up-popup").classList.remove("hidden");
    }
  }
}

function check_if_null(latf, global, achievements, early, twitter) {
  if (latf == 0) {
    document.getElementById("div-latf").style.display = "none";
  }
  if (global > 0) {
    document.getElementById("div-global").style.display = "block";
  }
  if (achievements > 0) {
    document.getElementById("div-achievements").style.display = "block";
  }
  if (early == 0) {
    document.getElementById("div-early").style.display = "none";
  }
  if (twitter > 0) {
    document.getElementById("div-twitter").style.display = "block";
  }
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function manage_balance_doughnut(sankey_data) {
  console.log("", sankey_data);
  const latf = sankey_data.links[0].value * 100000;
  const global = sankey_data.links[1].value * 100000;
  const achievements = sankey_data.links[2].value * 100000;
  const early = sankey_data.links[3].value * 100000;
  const twitter = sankey_data.links[4].value * 100000;
  // assign to the variables under the doughnut chart these values, using the id of the respective span
  check_if_null(latf, global, achievements, early, twitter);
  document.getElementById("latf-balance").innerHTML = numberWithCommas(latf);
  document.getElementById("global-balance").innerHTML =
    numberWithCommas(global);
  document.getElementById("achievements-balance").innerHTML =
    numberWithCommas(achievements);
  document.getElementById("early-balance").innerHTML = numberWithCommas(early);
  document.getElementById("twitter-balance").innerHTML =
    numberWithCommas(twitter);
  // add all these variable in the starting balance
  document.getElementById("starting-balance").innerHTML =
    "$ " +
    numberWithCommas(100000 + latf + global + achievements + early + twitter);
}

async function countDownClaim(walletaddress) {
  await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/user/lastclaim?walletaddress=${walletaddress}`
  )
    .then((response) => response.json())
    .then((data) => {
      var currentdate = new Date();
      document.getElementById("topup-countdown").textContent = timeDifference(
        currentdate,
        data.datetopup,
        data.latf_duration
      );
    });
}

function timeDifference(current, previous, isLATF) {
  previous = new Date(previous);
  // if isLATF, then create a countdown for 15 days, if not for 30 days
  var elapsed = current - previous;
  if (isLATF) {
    var difference = 1 * 24 * 60 * 60 * 1000;
    console.log(elapsed, difference, elapsed - difference);
    if (elapsed > difference) {
      return "Claim now !";
    } else {
      // return the remaining time to reach claim the "Clain now" message with the following format : XD days, XH hours, XM minutes, XS seconds
      const days = Math.floor((difference - elapsed) / 1000 / 60 / 60 / 24);
      const hours = Math.floor((difference - elapsed) / 1000 / 60 / 60) % 24;
      const minutes = Math.floor((difference - elapsed) / 1000 / 60) % 60;
      const seconds = Math.floor((difference - elapsed) / 1000) % 60;
      document.getElementById("claim-button").style.display = "none";
      return days + "D " + hours + "H " + minutes + "M";
    }
  } else {
    var difference = 2 * 24 * 60 * 60 * 1000;
    if (elapsed > difference) {
      return "Claim now !";
    } else {
      const days = Math.floor((difference - elapsed) / 1000 / 60 / 60 / 24);
      const hours = Math.floor((difference - elapsed) / 1000 / 60 / 60) % 24;
      const minutes = Math.floor((difference - elapsed) / 1000 / 60) % 60;
      const seconds = Math.floor((difference - elapsed) / 1000) % 60;
      document.getElementById("claim-button").style.display = "none";
      return days + "D " + hours + "H " + minutes + "M";
    }
  }
}
