import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import { getUserAddress } from "../../config/connection_manager";
import TimeLeft from "./TimeLeft";

const CommunityPage = ({ connect }) => {
  const [developments, setDevelopments] = useState([]);
  const [comments, setComments] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [discordName, setDiscordname] = useState("Visitor");
  const [commentsToShow, setCommentsToShow] = useState(3);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function getUser() {
      const addr_user = await getUserAddress();
      await fetch(
        `https://latf-paper-trading-backend.herokuapp.com/balance?walletaddress=${addr_user}`
      )
        .then((response) => response.json())
        .then((data) => {
          setDiscordname(data.discordname.slice(0, -5));
          const balance_object = document.getElementById("balance");
          // if !balance_object, retry in 2 seconds
          if (!balance_object) {
            setTimeout(getUser, 200);
            return;
          }
          balance_object.textContent = data.balance.toFixed(2);
          document.getElementById("balance-drop").textContent =
            data.balance.toFixed(2);
          document.getElementById("username").textContent =
            data.discordname.slice(0, -5);
          document.getElementById(
            "img-avatar"
          ).src = `https://api.dicebear.com/7.x/bottts/svg?seed=${data.discordname.slice(
            0,
            -5
          )}.svg?colorful=true`;
        });
    }
    getUser();
  }, []);

  function isOutdatedPoll(created_at) {
    const sevenDaysInMillis = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
    const createdTime = new Date(created_at).getTime();
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - createdTime;

    if (timeDifference > sevenDaysInMillis) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    async function fetchVotes() {
      try {
        const walletAddress = await getUserAddress();
        const response = await fetch(
          "https://latf-paper-trading-backend.herokuapp.com/community/list/vote"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch votes.");
        }
        const data = await response.json();
        // Vérifier les votes de l'utilisateur
        const userVotes = await fetchUserVotes(walletAddress);
        const votedDevelopmentIds = userVotes.map((vote) => vote.post_id);
        // Mettre à jour les développements avec les informations de vote de l'utilisateur
        const updatedDevelopments = await Promise.all(
          data.map(async (development) => {
            const comments = await fetchComments(development.id);
            development.comments = comments;
            console.log("Id: ", development.id, "Comments: ", comments);
            if (isOutdatedPoll(development.created_at)) {
              development.hasVoted = true;
            }
            if (votedDevelopmentIds.includes(development.id)) {
              development.hasVoted = true;
              const userVote = userVotes.find(
                (vote) => vote.post_id === development.id
              );
              if (userVote.choice === "a") {
                development.hasVotedA = true;
                development.hasVotedB = false;
              } else if (userVote.choice === "b") {
                development.hasVotedA = false;
                development.hasVotedB = true;
              }
            }
            return development;
          })
        );
        setDevelopments(updatedDevelopments);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    }
    fetchVotes();
  }, []);

  const handleChoiceSelect = async (id, choice) => {
    const updatedDevelopments = developments.map((development) => {
      if (development.id === id) {
        if (choice === "a") {
          development.count_option_a += 1;
          development.hasVotedA = true;
          development.hasVotedB = false;
        } else if (choice === "b") {
          development.count_option_b += 1;
          development.hasVotedB = true;
          development.hasVotedA = false;
        }
        development.hasVoted = true;
      } else if (development.id === id) {
        alert("You have already voted for this development!");
      }
      return development;
    });

    setDevelopments(updatedDevelopments);
    const canVote = await checkCanVote(id);
    if (canVote) {
      // call /community/vote with post_id, choice, wallet_address
      const walletAddress = await getUserAddress();
      await fetch(
        `https://latf-paper-trading-backend.herokuapp.com/community/vote?walletaddress=${walletAddress}&post_id=${id}&choice=${choice}`
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
        });
    }
  };

  const handleCommentSubmit = async (id, comment, discordname) => {
    try {
      if (comment === "") {
        return;
      }
      const response = await fetch(
        `https://latf-paper-trading-backend.herokuapp.com/community/vote/${id}/comment?comment=${comment}&discordname=${discordName}&walletaddress=${await getUserAddress()}`
      );
      const updatedCommentsResponse = await fetch(
        `https://latf-paper-trading-backend.herokuapp.com/community/vote/${id}/comments`
      );
      const updatedCommentsData = await updatedCommentsResponse.json();
      // Update the local comments state with the retrieved data
      const updatedDevelopments = developments.map((development) => {
        if (development.id === id) {
          development.comments = updatedCommentsData;
        }
        return development;
      });

      var elements = document.getElementsByClassName("comment-input");
      for (var i = 0; i < elements.length; i++) {
        var element = elements[i];
        element.placeholder = "Comment posted!";
        element.style.backgroundColor = "#e5e7eb";
        setTimeout(
          function (el) {
            el.placeholder = "Leave a comment";
            el.style.backgroundColor = "#F3F4F6";
          },
          2000,
          element
        );
      }
      setDevelopments(updatedDevelopments);
    } catch (error) {
      setError(error.message);
    }
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
    setCommentsToShow(3);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) =>
      Math.min(prevPage + 1, developments.length - 1)
    );
    setCommentsToShow(3);
  };

  const getVotePercentage = (optionCount, totalVotes) => {
    return (optionCount / totalVotes) * 100;
  };

  if (isLoading) {
    return <div></div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const development = developments[currentPage];
  console.log("Development: ", development, development.hasVoted);
  const circleColor_a = development.hasVotedA ? "bg-blue-500" : "bg-gray-100";
  const circleColor_b = development.hasVotedB ? "bg-blue-500" : "bg-gray-100";

  return (
    <div className="body-landing h-full flex flex-col">
      <Navbar {...{ connect }} />
      <div className="flex-1 flex flex-col">
        <div className="w-full flex flex-col">
          <div className="py-2 max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-0 lg:px-8 flex flex-col items-center justify-center">
            <div className="space-y-4">
              {development.hasVoted && (
                <div
                  key={development.id}
                  className={`p-4 rounded-3xl bg-totalWhite`}
                >
                  <h2 className="text-xl font-bold mb-2 border-b border-black text-center">
                    {development.title}
                  </h2>
                  <TimeLeft {...{ development }} />
                  <div className="bg-blue-800 bg-opacity-10 rounded-t-xl p-2">
                    <div className="row">
                      <div className="column">
                        <span className={`circle ${circleColor_a}`}></span>
                        <span className="nordique text-black font-semibold">
                          {development.option_a}
                        </span>
                      </div>
                      <span className="percent text-black">
                        {getVotePercentage(
                          development.count_option_a,
                          development.count_option_a +
                            development.count_option_b
                        ).toFixed(2)}{" "}
                        %
                      </span>
                    </div>
                    <div className="h-4 w-40 md:w-72 bg-gray-200 rounded-full">
                      <div
                        className="h-full bg-blue-500 rounded-full"
                        style={{
                          width: `${getVotePercentage(
                            development.count_option_a,
                            development.count_option_a +
                              development.count_option_b
                          )}%`,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="bg-yellow bg-opacity-10 rounded-b-xl p-2">
                    <div className="row">
                      <div className="column">
                        <span className={`circle ${circleColor_b}`}></span>
                        <span className="nordique text-black font-semibold">
                          {development.option_b}
                        </span>
                      </div>
                      <span className="percent text-black">
                        {getVotePercentage(
                          development.count_option_b,
                          development.count_option_a +
                            development.count_option_b
                        ).toFixed(2)}{" "}
                        %
                      </span>
                    </div>
                    <div className="h-4 w-40 md:w-72 bg-gray-200 rounded-full">
                      <div
                        className="h-full bg-blue-500 rounded-full"
                        style={{
                          width: `${getVotePercentage(
                            development.count_option_b,
                            development.count_option_a +
                              development.count_option_b
                          )}%`,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="mt-4">
                    <h2 className="text-2xl font-bold mb-2">Comments</h2>
                    {development.comments
                      .slice(-commentsToShow)
                      .reverse()
                      .map((comment) => (
                        <div
                          key={comment.id}
                          className="bg-gray-100 p-1 rounded border-b-2"
                        >
                          <div className="">
                            <div className="flex items-center">
                              <div>
                                <img
                                  className="cursor-pointer flex flex-col justify-centerw-10 h-10 rounded-full mr-4"
                                  src={`https://api.dicebear.com/7.x/bottts/svg?seed=${comment.discordname}.svg?colorful=true`}
                                  alt="Avatar of User"
                                  onClick={() => {
                                    window.location.href = `/profile?userid=${window.btoa(
                                      comment.walletaddress
                                    )}`;
                                  }}
                                />
                              </div>
                              <div>
                                <span
                                  className="cursor-pointer font-medium text-gray-500 hover:underline"
                                  onClick={() => {
                                    window.location.href = `/profile?userid=${window.btoa(
                                      comment.walletaddress
                                    )}`;
                                  }}
                                >
                                  {comment.discordname}
                                </span>
                              </div>
                            </div>
                          </div>
                          <p className="text-gray-800 -mb-1">
                            {comment.comment_text}
                          </p>
                          {/* add the comment.date in the bottom right of the div */}
                          <div className="flex justify-end">
                            <span className="text-gray-500 text-xs">
                              {comment.date.replace("T", " ").slice(5, -8)}{" "}
                            </span>
                          </div>
                        </div>
                      ))}
                    <div className="flex justify-center">
                      <button
                        onClick={() => setCommentsToShow((prev) => prev + 5)}
                        className="bg-blue-500 text-white py-1 px-4 rounded-b-3xl hover:bg-blue-600 text-center mb-2"
                      >
                        See more
                      </button>
                    </div>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        const comment = e.target.elements.comment.value;
                        handleCommentSubmit(development.id, comment);
                        e.target.reset();
                      }}
                    >
                      <input
                        type="text"
                        name="comment"
                        maxLength="1500"
                        autoComplete="off"
                        placeholder="Leave a comment"
                        className="comment-input border-2 border-gray-200 rounded-md p-2 w-full mb-4"
                      />
                      <button
                        type="submit"
                        className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              )}
              {!development.hasVoted && (
                <div
                  key={development.id}
                  className="p-6 bg-white shadow-lg rounded-3xl"
                >
                  <h2 className="text-2xl font-bold mb-4 text-center text-gray-800 border-b border-black">
                    {development.title}
                  </h2>
                  <TimeLeft {...{ development }} className="mb-4" />

                  {["a", "b"].map((option) => (
                    <div
                      key={option}
                      className={`cursor-pointer flex items-center justify-between p-4 my-2 bg-gray-100 hover:bg-gray-200 rounded-xl transition-colors duration-200 ease-in-out ${
                        development.hasVoted ? "pointer-events-none" : ""
                      }`}
                      onClick={() => handleChoiceSelect(development.id, option)}
                    >
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          name="poll"
                          id={`opt-${development.id}-${option}`}
                          className="hidden"
                        />
                        <label
                          htmlFor={`opt-${development.id}-${option}`}
                          className={`opt-${option}`}
                        >
                          <div className="flex items-center">
                            <span className={`circle mr-2`}></span>
                            <span className="text-gray-800 font-semibold">
                              {development[`option_${option}`]}
                            </span>
                          </div>
                          <span className="percent text-gray-600"></span>
                        </label>
                      </div>
                    </div>
                  ))}

                  <div className="mt-6">
                    <h2 className="text-2xl font-bold mb-4 text-gray-800">
                      Comments
                    </h2>
                    {development.comments
                      .slice(-commentsToShow)
                      .reverse()
                      .map((comment) => (
                        <div
                          key={comment.id}
                          className="bg-gray-50 p-4 rounded border-b-2 mb-2"
                        >
                          <div className="flex items-center mb-2">
                            <img
                              className="cursor-pointer w-10 h-10 rounded-full mr-4"
                              src={`https://api.dicebear.com/7.x/bottts/svg?seed=${comment.discordname}.svg?colorful=true`}
                              alt="Avatar of User"
                              onClick={() => {
                                window.location.href = `/profile?userid=${window.btoa(
                                  comment.walletaddress
                                )}`;
                              }}
                            />
                            <span
                              className="cursor-pointer font-medium text-gray-500 hover:underline"
                              onClick={() => {
                                window.location.href = `/profile?userid=${window.btoa(
                                  comment.walletaddress
                                )}`;
                              }}
                            >
                              {comment.discordname}
                            </span>
                          </div>
                          <p className="text-gray-800">
                            {comment.comment_text}
                          </p>
                          <div className="flex justify-end">
                            <span className="text-gray-500 text-xs">
                              {comment.date.replace("T", " ").slice(5, -8)}{" "}
                            </span>
                          </div>
                        </div>
                      ))}
                    <div className="flex justify-center">
                      <button
                        onClick={() => setCommentsToShow((prev) => prev + 5)}
                        className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 text-center mb-2"
                      >
                        See more
                      </button>
                    </div>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        const comment = e.target.elements.comment.value;
                        handleCommentSubmit(development.id, comment);
                        e.target.reset();
                      }}
                    >
                      <input
                        type="text"
                        name="comment"
                        maxLength="1500"
                        autoComplete="off"
                        placeholder="Leave a comment"
                        className="comment-input border-2 border-gray-200 rounded-md p-2 w-full mb-4"
                      />
                      <button
                        type="submit"
                        className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 w-full"
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        {currentPage !== 0 && (
          <button
            onClick={handlePrevPage}
            className="md:ml-4 text-white py-2 md:px-4 rounded-md fixed left-0 top-1/2 transform -translate-y-1/2"
          >
            <i
              class="material-icons text-blue-500 hover:text-blue-400 hover:scale-110"
              style={{ fontSize: "2rem" }}
            >
              chevron_left
            </i>
          </button>
        )}
        {currentPage !== developments.length - 1 && (
          <button
            onClick={handleNextPage}
            className="md:mr-4 text-white py-2 md:px-4 rounded-md fixed top-1/2 right-0 transform -translate-y-1/2"
          >
            <i
              class="material-icons text-blue-500 hover:text-blue-400 hover:scale-110"
              style={{ fontSize: "2rem" }}
            >
              chevron_right
            </i>
          </button>
        )}
      </div>
    </div>
  );
};

export default CommunityPage;

async function checkCanVote(developmentId) {
  const walletAddress = await getUserAddress();
  return await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/community/user/canvote?walletaddress=${walletAddress}&post_id=${developmentId}`
  )
    .then((response) => response.json())
    .then((data) => {
      if (data === 200) {
        return true;
      } else {
        return false;
      }
    });
}

async function fetchUserVotes(walletAddress) {
  const response = await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/community/user/votes?walletaddress=${walletAddress}`
  );
  if (!response.ok) {
    throw new Error("Failed to fetch user votes.");
  }
  const votes = await response.json();
  return votes;
}

async function fetchComments(developmentId) {
  const response = await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/community/vote/${developmentId}/comments`
  );
  if (!response.ok) {
    throw new Error("Failed to fetch comments.");
  }
  const comments = await response.json();
  return comments;
}
