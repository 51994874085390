import React from "react";
import Web3 from "web3";
import { ethers } from "ethers";
import Navbar from "./Navbar";
import whitelist_array from "./whitelist";
import SignUp from "./SignUp";
import { getAccount } from "@wagmi/core";
import { verify_wallet_address } from "../config/wallet_signer";
import { getUserAddress, connectWallet } from "../config/connection_manager";
import MaintenanceComponent from "./MaintenanceComponent";

function existCommonElement(array1, array2) {
  for (let i = 0; i < array1.length; i++) {
    for (let j = 0; j < array2.length; j++) {
      if (array1[i] === array2[j]) {
        return true;
      }
    }
  }
  return false;
}

class LeaderboardTw extends React.Component {
  constructor(props) {
    super(props);
    this.handleLoad = this.handleLoad.bind(this);
  }

  componentDidMount() {
    this.handleLoad();
  }

  componentWillUnmount() {}

  handleLoad = async (e) => {
    manage_leaderboard();
    if (localStorage?.getItem("isWalletConnected") === "true") {
      // Check if browser is running Metamask
      const addr = await getUserAddress();
      try {
        await fetch(
          `https://latf-paper-trading-backend.herokuapp.com/balance?walletaddress=${addr}`
        )
          .then((response) => response.json())
          .then((data) => {
            console.log(data.discordname, data.balance);
            const balance_object = document.getElementById("balance");
            balance_object.textContent = data.balance.toFixed(2);
            document.getElementById("balance-drop").textContent =
              data.balance.toFixed(2);
            localStorage.setItem("isWalletConnected", true);
            document.getElementById("twitter-handle").innerHTML = `${
              data.twitterusername || "Not disclosed"
            }`;
          });
      } catch (error) {
        document.getElementById("sign-up-popup").classList.remove("hidden");
      }
    } else {
      localStorage.setItem("isWalletConnected", false);
    }
  };

  state = {};
  render() {
    return (
      <>
        <Navbar {...{ connect }} />
        <SignUp {...{ document }} />
          <MaintenanceComponent />
        <div className="grid grid-rows-7 h-full w-full bg-tvcolor">
          <div className="row-span-1 bg-bbcard grid justify-items-center py-16">
            <div class="bg-bbcard Grid__Container_leaderboard border-gray-500 rounded-b-xl space-x-10 ">
              <div className="forliquid Stat__Card">
                <span className="Stat__Container ">
                  <span className="Stat__Title">Twitter Handle</span>
                  <div
                    id="twitter-handle"
                    className="z-1 text-lg text-totalWhite normal-case Nordique"
                  >
                    ...
                  </div>
                  <button
                    onClick={() => {
                      tw_settings();
                      document.getElementById("btn-tw-claim").style.display =
                        "none";
                    }}
                    className="flex justify-end"
                    id="btn-tw-claim"
                  >
                    <div className="px-2 py-2 bg-blue-500 text-totalWhite hover:bg-green-500 rounded-lg font-semibold Nordique">
                      Claim
                    </div>
                  </button>
                </span>
                <div className="liquid"></div>
              </div>
              <div className="wrapper grid grid-rows-5 gap-1 content-center bg-bbcard">
                <div className="row-span-1 flex content-center flex items-center">
                  <a
                    href="https://twitter.com/luckychartape"
                    target="_blank"
                    className="hover:scale-110"
                  >
                    <img
                      class="w-24 h-24 mb-3 rounded-full shadow-lg"
                      src="https://i.ibb.co/prNDQFP/lucky-pp-twiter.jpg"
                      alt="Lucky image"
                    />
                  </a>
                </div>
                <div className="tabs row-span-4">
                  <div className="tab">
                    <input
                      type="radio"
                      name="css-tabs"
                      id="tab-1"
                      defaultChecked="True"
                      className="tab-switch"
                    />
                    <label htmlFor="tab-1" className="tab-label rounded">
                      <i class="material-icons py-1">favorite</i>
                    </label>
                    <div className="tab-content Nordique">
                      A <i class="material-icons text-indigo">favorite</i> on
                      LuckyChartApe's tweets provides 10 points.
                      <span className="italic text-sm display: block">
                        Updated every 24 hours.
                      </span>
                    </div>
                  </div>
                  <div className="tab">
                    <input
                      type="radio"
                      name="css-tabs"
                      id="tab-2"
                      className="tab-switch"
                    />
                    <label htmlFor="tab-2" className="tab-label rounded">
                      <i class="material-icons py-1">chat</i>
                    </label>
                    <div className="tab-content Nordique">
                      A <i class="material-icons text-indigo">chat</i> on
                      LuckyChartApe's tweets provides 25 points.
                    </div>
                  </div>
                  <div className="tab">
                    <input
                      type="radio"
                      name="css-tabs"
                      id="tab-3"
                      className="tab-switch"
                    />
                    <label htmlFor="tab-3" className="tab-label rounded">
                      <i class="material-icons py-1">repeat</i>
                    </label>
                    <div className="tab-content Nordique">
                      A <i class="material-icons text-indigo">repeat</i> on
                      LuckyChartApe's tweets provides 50 points.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-tvcolor grid grid-cols-10 row-span-6 px-10">
            <div className="col-span-10 overflow-auto">
              <div
                id="div-table-leaderboard"
                className="h-full border-totalWhite text-xl text-totalWhite overflow-auto"
              >
                <div class="text-5xl font-extrabold text-center mb-2 text-indigo mt-4">
                  <span class="text-gray-100">Leaderboard</span>
                  &nbsp;Twitter
                </div>
                <div className="overflow-x-auto relative bg-tvcolor rounded-3xl">
                  <table
                    id="element-leaderboard-table"
                    className="sortable w-full text-sm text-left text-gray-500 text-gray-400 relative justify-evenly"
                  >
                    <thead className="text-xs uppercase bg-gray-50 bg-gray-700 text-gray-400 ">
                      <tr className="text-center text-base font-serif font-base">
                        <th scope="col" className="py-3 px-6">
                          #
                        </th>
                        <th scope="col" className="py-3 px-6">
                          Name
                        </th>
                        <th scope="col" className="py-3 px-6">
                          Twitter Handle
                        </th>
                        <th
                          onClick={() => sortTable(2)}
                          scope="col"
                          className="py-3 px-6"
                        >
                          Points
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      id="leaderboard-table"
                      className="text-totalWhite"
                    ></tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default LeaderboardTw;

async function connect() {
  const [signerAddr, isOwner] = await verify_wallet_address(
    "Welcome to the LATF Paper Trading.\n\nSign this request to access your account.\n\nThis request will not trigger a blockchain transaction or cost any gas fees."
  );
  if (isOwner) {
    try {
      localStorage.setItem("isWalletConnected", true);
    } catch (ex) {
      console.log(ex);
    }
    try {
      connectWallet();
    } catch (err) {
      localStorage.setItem("isWalletConnected", false);
      document.getElementById("sign-up-popup").classList.remove("hidden");
    }
  }
}

function isInArray(value, array) {
  return array.indexOf(value) > -1;
}

async function manage_leaderboard() {
  await fetch(`https://latf-paper-trading-backend.herokuapp.com/users`)
    .then((response) => response.json())
    .then((data) => {
      populate_leaderboard(data);
    });
}

function populate_leaderboard(data) {
  const parent = document.getElementById("leaderboard-table");
  let i = 0;
  console.log(data[i].twitterpoints);
  while (i < data.length) {
    if (!data[i].twitterpoints) {
      data[i].twitterpoints = 0;
    }
    if (!data[i].twitterusername) {
      data[i].twitterusername = "Not disclosed";
    }
    parent.innerHTML += `
		<tr class="Nordique text-lg font-mono text-center border-b bg-gray-800 border-gray-700 bg-gradient-to-l hover:from-dashboardgrada hover:to-dashboardgradb">
		<th
		id="leaderboard-rank-${i}"
		scope="row"
		class="py-4 px-6 font-medium whitespace-nowrap"
		>
		${i}
		</th>
    <td class="py-4 px-2">
    <div class="">
      <div class="columns-3 items-center">
        <div>
          <img class="flex flex-col justify-center w-10 h-10 rounded-full mr-4" src="https://api.dicebear.com/7.x/bottts/svg?seed=${
            data[i].discordname
          }.svg?colorful=true" alt="Avatar of User">
        </div>
        <div class="flex flex-col justify-center">
          <span class="font-medium Nordique">${
            data[i].discordname.length > 15
              ? data[i].discordname.slice(0, 15)
              : data[i].discordname
          }</span>
        </div>
      </div>
      </div>
    </td>
  
    <td class="py-4 px-6 no-underline Nordique">
      <a class="no-underline" href='https://twitter.com/${
        data[i].twitterusername
      }' target="_blank">${data[i].twitterusername}</a>
    </td>
		<td class="py-4 px-6 Nordique">${data[i].twitterpoints}</td>
		</tr>`;
    i++;
  }
  sortTable(2);
}

async function sortTable(n) {
  var table,
    rows,
    switching,
    i,
    x,
    y,
    shouldSwitch,
    dir,
    switchcount = 0;
  table = await document.getElementById("element-leaderboard-table");
  switching = true;
  dir = "desc";
  while (switching) {
    switching = false;
    rows = table.rows;
    for (i = 1; i < rows.length - 1; i++) {
      // Start by saying there should be no switching:
      shouldSwitch = false;
      /* Get the two elements you want to compare,
			one from current row and one from the next: */
      x = rows[i].getElementsByTagName("TD")[n];
      y = rows[i + 1].getElementsByTagName("TD")[n];
      console.log(x, y);
      /* Check if the two rows should switch place,
			based on the direction, asc or desc: */
      if (x.innerHTML == "NaN") {
        x.innerHTML = 0;
      }
      if (y.innerHTML == "NaN") {
        y.innerHTML = 0;
      }
      if (dir == "asc") {
        if (parseFloat(x.innerHTML) > parseFloat(y.innerHTML)) {
          // If so, mark as a switch and break the loop:
          shouldSwitch = true;
          break;
        }
      } else if (dir == "desc") {
        if (parseFloat(x.innerHTML) < parseFloat(y.innerHTML)) {
          // If so, mark as a switch and break the loop:
          shouldSwitch = true;
          break;
        }
      }
    }
    if (shouldSwitch) {
      /* If a switch has been marked, make the switch
			and mark that a switch has been done: */
      rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
      switching = true;
      // Each time a switch is done, increase this count by 1:
      switchcount++;
    } else {
      /* If no switching has been done AND the direction is "asc",
			set the direction to "desc" and run the while loop again. */
      if (switchcount == 0 && dir == "asc") {
        dir = "desc";
        switching = true;
      }
    }
  }
  reRank(table);
}

function reRank(table) {
  let i = 1;
  const rows = table.rows;
  while (i < rows.length) {
    rows[i].getElementsByTagName("TH")[0].innerHTML = i;
    i++;
  }
}

async function tw_settings() {
  console.log("x");
  const parent = document.getElementById("twitter-handle");
  parent.innerHTML = "";
  parent.appendChild(document.createElement("input"));
  parent.lastChild.className = "text-gray-800";
  // create a button like this one: <i class="material-icons text-indigo">repeat</i>
  // and add it to a button, which you can then add to the parent
  const button = document.createElement("button");
  parent.appendChild(button);
  button.appendChild(document.createElement("i"));
  parent.lastChild.classList.add("material-icons", "text-green-300");
  parent.lastChild.innerHTML = "done";

  // add an event listener to the button
  // make the user sign a message to get their address
  // then send a request to the backend to update the user's twitter handle
  parent.lastChild.addEventListener("click", async () => {
    const input = document.getElementById("twitter-handle").firstChild.value;
    console.log(input);
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = await getUserAddress();
    await fetch(
      `https://latf-paper-trading-backend.herokuapp.com/set/twitterusername?walletaddress=${signer}&twitterusername=${input}`,
      {}
    );
    window.location.reload();
  });
}
