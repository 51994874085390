import React, { useState, useEffect } from "react";
import BattlesHistory from "./BattlesHistory";

const Stats = ({ userWalletAddress }) => {
  // Add state variables for global rank and twitter rank
  const [globalRank, setGlobalRank] = useState("");
  const [globalTop, setGlobalTop] = useState("");
  const [twitterRank, setTwitterRank] = useState("");
  const [twitterTop, setTwitterTop] = useState("");

  // Define a useEffect to load ranks when userWalletAddress changes
  useEffect(() => {
    async function loadRanks() {
      try {
        // Load global rank
        const globalResponse = await fetch(
          `https://latf-paper-trading-backend.herokuapp.com/user/rank/leaderboard?walletaddress=${userWalletAddress}`
        );
        const globalData = await globalResponse.json();
        setGlobalRank(globalData.rank);
        setGlobalTop(
          `${((globalData.rank / globalData.length) * 100).toFixed(2)}%`
        );

        // Load twitter rank
        const twitterResponse = await fetch(
          `https://latf-paper-trading-backend.herokuapp.com/user/rank/leaderboard/twitter?walletaddress=${userWalletAddress}`
        );
        const twitterData = await twitterResponse.json();
        setTwitterRank(twitterData.rank);
        setTwitterTop(
          `${((twitterData.rank / twitterData.length) * 100).toFixed(2)}%`
        );
      } catch (error) {
        console.error("Error loading ranks:", error);
      }
    }

    loadRanks();
  }, [userWalletAddress]);

  return (
    <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
      {/* Global leaderboard */}
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-2 py-3 sm:px-2 bg-blue-500">
          <h2 className="text-lg font-medium text-white text-center">
            Global Leaderboard
          </h2>
        </div>
        <div className="border border-gray-200 px-4 py-3 sm:p-0">
          <table className="w-full text-center">
            <thead>
              <tr>
                <th className="border-b border-gray-200 p-2">Rank</th>
                <th className="border-l border-gray-200 p-2">Top</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border-r border-gray-200 p-2">{globalRank}</td>
                <td className="border-t border-gray-200 p-2">{globalTop}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* Twitter leaderboard */}
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-2 py-3 sm:px-2 bg-blue-500">
          <h2 className="text-lg font-medium text-white text-center">
            Twitter Leaderboard
          </h2>
        </div>
        <div className="border border-gray-200 px-4 py-3 sm:p-0">
          <table className="w-full text-center">
            <thead>
              <tr>
                <th className="border-b border-gray-200 p-2">Rank</th>
                <th className="border-l border-gray-200 p-2">Top</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border-r border-gray-200 p-2">{twitterRank}</td>
                <td className="border-t border-gray-200 p-2">{twitterTop}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* put BattleHistory with userWalletAddress */}
      <BattlesHistory userWalletAddress={userWalletAddress} />
    </div>
  );
};

export default Stats;