import { v4 as uuidv4 } from "uuid";
import { ethers } from "ethers";
import { pairs } from "../pages/pairs.js";
import { verify_wallet_address } from "./wallet_signer.js";

async function create_limit_close_order(
  orderid,
  contract,
  quantity,
  orderprice,
  newtakeprofit,
  stoploss,
  liquidationprice,
  ordertype,
  ordertime,
  neworderid,
  side,
  walletaddress,
  setchecked,
  note,
  notp,
  nosl,
  leverage,
  handleLoad
) {
  await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/close/partial/limit?newtakeprofit=${newtakeprofit}&neworderid=${neworderid}&contract=${contract}&quantity=${quantity}&orderprice=${orderprice}&stoploss=${stoploss}&liquidationprice=${liquidationprice}&ordertype=${ordertype}&ordertime=${ordertime}&orderid=${orderid}&side=${side}&walletaddress=${walletaddress}&setchecked=${setchecked}&note=${note}&notp=${notp}&nosl=${nosl}&leverage=${leverage}`
  )
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      emptyTable();
      handleLoad();
      // window.location.reload();
    });
}

async function limit_close_order(
  orderid,
  walletaddress,
  close_quantity,
  newtakeprofit,
  handleLoad
) {
  const neworderid = uuidv4().toString();
  await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/position/byid?orderid=${orderid}`
  )
    .then((response) => response.json())
    .then((data) => {
      try {
        const contract = data[0].contract;
        const quantity = close_quantity;
        const orderprice = data[0].orderprice;
        const stoploss = data[0].stoploss;
        const liquidationprice = data[0].liquidationprice;
        const ordertype = data[0].ordertype;
        const ordertime = data[0].ordertime;
        const side = data[0].side;
        const walletaddress = data[0].walletaddress;
        const setchecked = data[0].setchecked;
        const note = data[0].note;
        const notp = data[0].notp;
        const nosl = data[0].nosl;
        const leverage = data[0].leverage;
        if (close_quantity < data[0].quantity) {
          create_limit_close_order(
            orderid,
            contract,
            quantity,
            orderprice,
            newtakeprofit,
            stoploss,
            liquidationprice,
            ordertype,
            ordertime,
            neworderid,
            side,
            walletaddress,
            setchecked,
            note,
            notp,
            nosl,
            leverage,
            handleLoad
          );
        } else if (close_quantity === data[0].quantity) {
          alert(
            "The closing quantity of this order is equal to the total position quantity. Please adjust your [Take Profit] price instead."
          );
        } else {
          alert("Incorrect order quantity");
        }
      } catch {
        alert(
          "This position doesn't exist anymore. Please refresh your webpage."
        );
      }
    });
}

async function auth_partial(walletaddress) {
  const token = await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/auth?walletaddress=${walletaddress}`
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return token;
}

async function market_close_manager_partial(
  orderid,
  walletaddress,
  list_contracts,
  close_quantity,
  handleLoad
) {
  const proxyURL = "https://latf-proxy.herokuapp.com/";
  await fetch(
    `${proxyURL}https://api.binance.com/api/v3/ticker/price?symbols=${list_contracts}`
  )
    .then((response) => response.json())
    .then((data) => {
      var dict_current_prices = {};
      let i = 0;
      while (i < data.length) {
        dict_current_prices[`${data[i].symbol}`] = data[i].price;
        i++;
      }
      console.log(dict_current_prices);
      market_get_profit_loss_partial(
        orderid,
        walletaddress,
        dict_current_prices,
        close_quantity,
        handleLoad
      );
    });
}

async function market_get_profit_loss_partial(
  orderid,
  walletaddress,
  dict_current_prices,
  close_quantity,
  handleLoad
) {
  await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/position/byid?orderid=${orderid}`
  )
    .then((response) => response.json())
    .then((data) => {
      console.log("data :", data);
      const leverage = data[0].leverage;
      const ordervalue = data[0].orderprice * close_quantity;
      const orderprice = data[0].orderprice;
      const ordertime = data[0].ordertime;
      const currentprice = parseFloat(
        dict_current_prices[`${data[0].contract}`]
      );
      console.log(`current price of the asset at closing is : ${currentprice}`);
      const diff = (currentprice - data[0].orderprice) / data[0].orderprice;
      var profitloss = ordervalue * (diff * -1);
      if (data[0].side == "long") {
        profitloss = ordervalue * diff;
      }
      if (close_quantity < data[0].quantity) {
        verificate_token_partial(
          orderid,
          walletaddress,
          profitloss,
          ordervalue,
          close_quantity,
          orderprice,
          currentprice,
          ordertime,
          data[0].contract,
          data[0].setchecked,
          data[0].note,
          leverage,
          handleLoad
        );
      } else if (close_quantity === data[0].quantity) {
        console.log(
          "Closing full market position ; params = ",
          orderid,
          walletaddress,
          profitloss,
          ordervalue,
          close_quantity,
          orderprice,
          currentprice,
          ordertime
        );
        verificate_token(
          orderid,
          walletaddress,
          profitloss,
          ordervalue,
          close_quantity,
          orderprice,
          currentprice,
          ordertime,
          data[0].contract,
          data[0].setchecked,
          data[0].note,
          leverage
        );
      } else {
        alert("Incorrect order quantity.");
      }
    });
}

async function verificate_token_partial(
  orderid,
  walletaddress,
  profitloss,
  ordervalue,
  close_quantity,
  orderprice,
  currentprice,
  ordertime,
  contract,
  setchecked,
  note,
  leverage,
  handleLoad
) {
  const auth_token = uuidv4().toString();
  await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/verificate/token?walletaddress=${walletaddress}&token=${auth_token}`
  )
    .then((response) => response.json())
    .then((data) => {
      console.log("Token verification:", data);
      if (data === 200) {
        get_balance(
          orderid,
          walletaddress,
          profitloss,
          ordervalue,
          auth_token,
          close_quantity,
          orderprice,
          currentprice,
          ordertime,
          contract,
          setchecked,
          note,
          leverage,
          handleLoad
        );
      } else {
        alert("We couldn't close your position, please retry.");
      }
    });
}

async function get_balance(
  orderid,
  walletaddress,
  profitloss,
  ordervalue,
  auth_token,
  quantity,
  orderprice,
  contractprice,
  ordertime,
  contract,
  setchecked,
  note,
  leverage,
  handleLoad
) {
  await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/balance?walletaddress=${walletaddress}`
  )
    .then((response) => response.json())
    .then((data) => {
      const balance = data.balance;
      close_position_manager(
        orderid,
        walletaddress,
        profitloss,
        ordervalue,
        auth_token,
        quantity,
        orderprice,
        contractprice,
        ordertime,
        balance,
        contract,
        setchecked,
        note,
        leverage,
        handleLoad
      );
    });
}

async function close_position_manager(
  orderid,
  walletaddress,
  profitloss,
  ordervalue,
  auth_token,
  quantity,
  orderprice,
  contractprice,
  ordertime,
  balance,
  contract,
  setchecked,
  note,
  leverage,
  handleLoad
) {
  await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/positionsorders/user?walletaddress=${walletaddress}`
  )
    .then((response) => response.json())
    .then((data) => {
      let i = 0;
      let totalbalance = balance;
      while (i < data.length) {
        totalbalance +=
          (data[i].orderprice * data[i].quantity) / data[i].leverage || 1;
        i++;
      }
      market_close_partial(
        orderid,
        walletaddress,
        profitloss,
        ordervalue,
        auth_token,
        quantity,
        orderprice,
        contractprice,
        ordertime,
        totalbalance,
        contract,
        setchecked,
        note,
        leverage,
        handleLoad
      );
    });
}

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

async function market_close_partial(
  orderid,
  walletaddress,
  profitloss,
  ordervalue,
  token,
  close_quantity,
  orderprice,
  contractprice,
  ordertime,
  totalbalance,
  contract,
  setchecked,
  note,
  leverage,
  handleLoad
) {
  console.log(`Order Value = ${ordervalue}`, typeof ordervalue);
  console.log(`Order Value + Profit Loss = ${ordervalue} + ${profitloss}`);
  const d = new Date(),
    ordertime_close =
      [
        (d.getMonth() + 1).padLeft(),
        d.getDate().padLeft(),
        d.getFullYear(),
      ].join("/") +
      " " +
      [
        d.getHours().padLeft(),
        d.getMinutes().padLeft(),
        d.getSeconds().padLeft(),
      ].join(":");
  await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/close/partial/market?note=${note}&orderid=${orderid}&walletaddress=${walletaddress}&profitloss=${profitloss}&ordervalue=${ordervalue}&token=${token}&quantity=${close_quantity}&orderprice=${orderprice}&ordertime=${ordertime_close}&contractprice=${contractprice}&totalbalance=${
      totalbalance + profitloss
    }&contract=${contract}&setchecked=${setchecked}&leverage=${leverage}`
  )
    .then((response) => response.json())
    .then((data) => {
      console.log("final data:", data);
      emptyTable();
      handleLoad();
      auth_partial();
      // window.location.reload();
    });
}

async function auth(walletaddress) {
  //5
  const token = await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/auth?walletaddress=${walletaddress}`
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return token;
}

async function market_close_manager(orderid, walletaddress, list_contracts, handleLoad) {
  //1
  const proxyURL = "https://latf-proxy.herokuapp.com/";
  await fetch(
    `${proxyURL}https://api.binance.com/api/v3/ticker/price?symbols=${list_contracts}`
  )
    .then((response) => response.json())
    .then((data) => {
      var dict_current_prices = {};
      let i = 0;
      while (i < data.length) {
        dict_current_prices[`${data[i].symbol}`] = data[i].price;
        i++;
      }
      console.log(dict_current_prices);
      market_get_profit_loss(orderid, walletaddress, dict_current_prices, handleLoad);
    });
}

async function market_get_profit_loss(
  orderid,
  walletaddress,
  dict_current_prices,
  handleLoad
) {
  //2
  await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/position/byid?orderid=${orderid}`
  )
    .then((response) => response.json())
    .then((data) => {
      const leverage = data[0].leverage;
      const ordervalue = data[0].orderprice * data[0].quantity;
      const orderprice = data[0].orderprice;
      const ordertime = data[0].ordertime;
      const currentprice = parseFloat(
        dict_current_prices[`${data[0].contract}`]
      );
      const note = data[0].note;
      console.log(`current price of the asset at closing is : ${currentprice}`);
      const diff = (currentprice - data[0].orderprice) / data[0].orderprice;
      var profitloss = ordervalue * (diff * -1);
      if (data[0].side == "long") {
        profitloss = ordervalue * diff;
      }
      verificate_token(
        orderid,
        walletaddress,
        profitloss,
        ordervalue,
        data[0].quantity,
        orderprice,
        currentprice,
        ordertime,
        data[0].contract,
        data[0].setchecked,
        note,
        leverage,
        handleLoad
      );
    });
}

async function verificate_token(
  orderid,
  walletaddress,
  profitloss,
  ordervalue,
  close_quantity,
  orderprice,
  currentprice,
  ordertime,
  contract,
  setchecked,
  note,
  leverage,
  handleLoad
) {
  //3
  console.log(
    "Instant close :",
    orderid,
    walletaddress,
    profitloss,
    ordervalue,
    close_quantity,
    orderprice,
    currentprice,
    ordertime
  );
  const auth_token = uuidv4().toString();
  await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/verificate/token?walletaddress=${walletaddress}&token=${auth_token}`
  )
    .then((response) => response.json())
    .then((data) => {
      console.log("Token verification:", data);
      if (data === 200) {
        get_balance_full_market_close(
          orderid,
          walletaddress,
          profitloss,
          ordervalue,
          close_quantity,
          orderprice,
          currentprice,
          ordertime,
          auth_token,
          contract,
          setchecked,
          note,
          leverage,
          handleLoad
        );
      } else {
        alert("We couldn't close your position, please retry.");
      }
    });
}

async function get_balance_full_market_close(
  orderid,
  walletaddress,
  profitloss,
  ordervalue,
  close_quantity,
  orderprice,
  currentprice,
  ordertime,
  auth_token,
  contract,
  setchecked,
  note,
  leverage,
  handleLoad
) {
  await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/balance?walletaddress=${walletaddress}`
  )
    .then((response) => response.json())
    .then((data) => {
      const balance = data.balance;
      market_close_position_manager(
        orderid,
        walletaddress,
        profitloss,
        ordervalue,
        close_quantity,
        orderprice,
        currentprice,
        ordertime,
        auth_token,
        balance,
        contract,
        setchecked,
        note,
        leverage,
        handleLoad
      );
    });
}

async function market_close_position_manager(
  orderid,
  walletaddress,
  profitloss,
  ordervalue,
  close_quantity,
  orderprice,
  currentprice,
  ordertime,
  auth_token,
  balance,
  contract,
  setchecked,
  note,
  leverage,
  handleLoad
) {
  await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/positionsorders/user?walletaddress=${walletaddress}`
  )
    .then((response) => response.json())
    .then((data) => {
      let i = 0;
      let totalbalance = balance;
      while (i < data.length) {
        totalbalance +=
          (data[i].orderprice * data[i].quantity) / data[i].leverage || 1;
        i++;
      }
      console.log("total balance = ", totalbalance);
      market_close(
        orderid,
        walletaddress,
        profitloss,
        ordervalue,
        close_quantity,
        orderprice,
        currentprice,
        ordertime,
        auth_token,
        totalbalance,
        contract,
        setchecked,
        note,
        leverage,
        handleLoad
      );
    });
}

async function market_close(
  orderid,
  walletaddress,
  profitloss,
  ordervalue,
  close_quantity,
  orderprice,
  currentprice,
  ordertime,
  token,
  balance,
  contract,
  setchecked,
  note,
  leverage,
  handleLoad
) {
  //4
  console.log(`Order Value = ${ordervalue}`, typeof ordervalue);
  console.log(`Order Value + Profit Loss = ${ordervalue} + ${profitloss}`);
  const d = new Date(),
    ordertime_close =
      [
        (d.getMonth() + 1).padLeft(),
        d.getDate().padLeft(),
        d.getFullYear(),
      ].join("/") +
      " " +
      [
        d.getHours().padLeft(),
        d.getMinutes().padLeft(),
        d.getSeconds().padLeft(),
      ].join(":");
  console.log(
    "result new balance = ",
    balance,
    profitloss,
    "=",
    balance + profitloss
  );
  await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/close/market?orderid=${orderid}&walletaddress=${walletaddress}&profitloss=${profitloss}&ordervalue=${ordervalue}&token=${token}&quantity=${close_quantity}&orderprice=${orderprice}&contractprice=${currentprice}&ordertime=${ordertime_close}&totalbalance=${
      balance + profitloss
    }&contract=${contract}&setchecked=${setchecked}&note=${note}&leverage=${leverage}`
  )
    .then((response) => response.json())
    .then((data) => {
      console.log("final data:", data);
      emptyTable();
      handleLoad();
      auth(walletaddress);
      // window.location.reload();
    });
}

function is_profitloss(profitloss) {
  if (profitloss < 0) {
    return "tomato-color";
  }
  return "green-color";
}

// CHANGE notp and nosl
async function allow_edit_position(orderid, tpSl, buttonID, parentID) {
  if (tpSl === "tp") {
    console.log(buttonID, parentID);
    document.getElementById(buttonID).style.display = "none";
    const button = document.createElement("button");
    const buttonCancel = document.createElement("button");
    const input = document.createElement("input");
    input.className = "text-black text-center";
    input.value = document.getElementById(parentID).textContent;
    button.className = "text-green-300 font-semibold";
    button.textContent = "✓";
    buttonCancel.className = "text-tomato font-semibold";
    buttonCancel.textContent = "X";
    button.value = orderid;
    buttonCancel.onclick = function () {
      button.remove();
      input.remove();
      buttonCancel.remove();
      document.getElementById(buttonID).style.display = "";
      isEditingPosition = false;
    };
    button.onclick = async function () {
      console.log(button.value, input.value);
      await fetch(
        `https://latf-paper-trading-backend.herokuapp.com/edit/tp?orderid=${button.value}&takeprofit=${input.value}`
      )
        .then((response) => response.json())
        .then((data) => {
          button.remove();
          input.remove();
          buttonCancel.remove();
          document.getElementById(buttonID).style.display = "";
          const buttonedit = document.getElementById(buttonID);
          document.getElementById(parentID).textContent = input.value;
          document.getElementById(parentID).appendChild(buttonedit);
          isEditingPosition = false;
        });
    };
    document.getElementById(parentID).appendChild(button);
    document.getElementById(parentID).appendChild(input);
    document.getElementById(parentID).appendChild(buttonCancel);
  } else {
    document.getElementById(buttonID).style.display = "none";
    const button = document.createElement("button");
    const buttonCancel = document.createElement("button");
    const input = document.createElement("input");
    input.className = "text-black text-center";
    input.value = document.getElementById(parentID).textContent;
    button.className = "text-green-300 font-semibold";
    buttonCancel.className = "text-tomato font-semibold";
    button.textContent = "✓";
    buttonCancel.textContent = "X";
    button.value = orderid;
    buttonCancel.onclick = function () {
      button.remove();
      input.remove();
      buttonCancel.remove();
      document.getElementById(buttonID).style.display = "";
      isEditingPosition = false;
    };
    button.onclick = async function () {
      console.log(button.value, input.value);
      await fetch(
        `https://latf-paper-trading-backend.herokuapp.com/edit/sl?orderid=${button.value}&stoploss=${input.value}`
      )
        .then((response) => response.json())
        .then((data) => {
          button.remove();
          input.remove();
          buttonCancel.remove();
          document.getElementById(buttonID).style.display = "";
          const buttonedit = document.getElementById(buttonID);
          document.getElementById(parentID).textContent = input.value;
          document.getElementById(parentID).appendChild(buttonedit);
          isEditingPosition = false;
        });
    };
    document.getElementById(parentID).appendChild(button);
    document.getElementById(parentID).appendChild(input);
    document.getElementById(parentID).appendChild(buttonCancel);
  }
}

export function display_close_limit() {
  console.log("XXX");
  const div_limit_close = document.getElementById("limit-close");
  const div_market_close = document.getElementById("market-close");
  const btn_close_market = document.getElementById("btn-close-market");
  const btn_close_limit = document.getElementById("btn-close-limit");
  btn_close_market.className =
    "w-full hover:bg-tvcolor text-totalWhite font-semibold hover:text-totalWhite py-2 px-4 border border-lightBlue hover:border-transparent rounded";
  btn_close_limit.className =
    "w-full hover:bg-tvcolor text-yellow font-semibold hover:text-totalWhite py-2 px-4 border border-lightBlue hover:border-transparent rounded";
  div_limit_close.style.display = "block";
  div_market_close.style.display = "none";
  try {
    const textLimit = (document.getElementById(
      "modal-text-limit"
    ).style.display = "block");
    const textMarket = (document.getElementById(
      "modal-text-market"
    ).style.display = "none");
  } catch (err) {
    console.log(err);
  }
}
export function display_close_market() {
  console.log("XXX");
  const div_limit_close = document.getElementById("limit-close");
  const div_market_close = document.getElementById("market-close");
  const btn_close_market = document.getElementById("btn-close-market");
  const btn_close_limit = document.getElementById("btn-close-limit");
  div_market_close.style.display = "block";
  div_limit_close.style.display = "none";
  btn_close_limit.className =
    "w-full hover:bg-tvcolor text-totalWhite font-semibold hover:text-totalWhite py-2 px-4 border border-lightBlue hover:border-transparent rounded";
  btn_close_market.className =
    "w-full hover:bg-tvcolor text-yellow font-semibold hover:text-totalWhite py-2 px-4 border border-lightBlue hover:border-transparent rounded";
  try {
    const textLimit = document.getElementById("modal-text-limit");
    const textMarket = (document.getElementById(
      "modal-text-market"
    ).style.display = "block");
    textLimit.style.display = "none";
    textMarket.style.display = "block";
  } catch {}
}

let currentOpenComponent = null;

function expand_position(data) {
  if (currentOpenComponent !== null) {
    return;
  }
  currentOpenComponent = 1;
  // display data in a new component on top of the page. Create a button to close the component
  const data_string = JSON.stringify(data);
  const container = document.createElement("div");
  // create a row to display the data
  container.id = "expand-position";
  container.className =
    "opacity-95 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 lg:w-3/4 fit-content w-5/6 bg-gray-800 rounded-3xl shadow-lg p-4";
  container.style.backgroundImage = "url('../../images/backgrounddetails.png')";
  container.style.backgroundSize = "cover";
  container.style.backgroundPosition = "center";
  container.innerHTML = `
  <div class="mx-auto">
  <h2 class="text-center text-totalWhite text-3xl font-bold mb-4">Position <span class="text-yellowlegit-300">Details</span></h2>
  <form class="w-full px-2">
    <a href="" class="text-yellowlegit-400 close-link absolute top-4 right-4 text-3xl no-underline rotator-45">&times;</a>
    <div id="position-details" class="text-center place-items-center grid-template-columns grid sm:grid-cols-2 md:grid-cols-4 gap-1 max-h-full">
    </div>
  </form>
</div>
  `;
  document.body.appendChild(container);

  // Create the form elements to display the data
  const positionDetails = document.getElementById("position-details");
  const contract = document.createElement("div");
  contract.innerHTML = `
    <div class="flex flex-col text-totalWhite">
      <label class="font-semibold leading-loose text-yellowlegit-300">Contract</label>
      <div class="px-2 sm:w-20 md:w-24 hover:bg-bbcard py-2 text-gray-300 border-2 focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none">${data.contract}</div>
    </div>
  `;
  positionDetails.appendChild(contract);

  const orderTime = document.createElement("div");
  orderTime.innerHTML = `
    <div class="flex flex-col text-totalWhite">
      <label class="font-semibold leading-loose text-yellowlegit-300">Order Time</label>
      <div class="px-2 sm:w-20 md:w-24 hover:bg-bbcard py-2 text-gray-300 border-2  focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none">${data.ordertime}</div>
    </div>
  `;
  positionDetails.appendChild(orderTime);

  const orderPrice = document.createElement("div");
  orderPrice.innerHTML = `
    <div class="flex flex-col text-totalWhite">
    <label class="font-semibold leading-loose text-yellowlegit-300">Order Price</label>
    <div class="px-2 sm:w-20 md:w-24 hover:bg-bbcard py-2 text-gray-300 border-2  focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none">${data.orderprice}</div>
  </div>
  `;
  positionDetails.appendChild(orderPrice);

  const quantity = document.createElement("div");
  quantity.innerHTML = `
    <div class="flex flex-col text-totalWhite">
    <label class="font-semibold leading-loose text-yellowlegit-300">Quantity</label>
    <div class="px-2 sm:w-20 md:w-24 hover:bg-bbcard py-2 text-gray-300 border-2  focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none">${data.quantity}</div>
    </div>
    `;
  positionDetails.appendChild(quantity);

  const note = document.createElement("div");
  note.innerHTML = `
    <div class="flex flex-col text-totalWhite">
    <label class="font-semibold leading-loose text-yellowlegit-300">Note</label>
    <div class="px-2 sm:w-20 md:w-24 hover:bg-bbcard py-2 text-gray-300 border-2  focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none">${
      data.note || "/"
    }</div>
  </div>
  `;
  positionDetails.appendChild(note);

  const userset = document.createElement("div");
  userset.innerHTML = `
    <div class="flex flex-col text-totalWhite">
    <label class="font-semibold leading-loose text-yellowlegit-300">User Set</label>
    <div class="px-2 sm:w-20 md:w-24 hover:bg-bbcard md:w-32 py-2 text-gray-300 border-2  focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none">${
      data.setchecked || "/"
    }</div>
  </div>
  `;
  positionDetails.appendChild(userset);

  const takeProfit = document.createElement("div");
  takeProfit.innerHTML = `
    <div class="flex flex-col text-totalWhite">
    <label class="font-semibold leading-loose text-yellowlegit-300">Take Profit</label>
    <div class="px-2 sm:w-20 md:w-24 hover:bg-bbcard py-2 text-gray-300 border-2  focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none">${
      data.takeprofit || "/"
    }</div>
  </div>
  `;
  positionDetails.appendChild(takeProfit);

  const stopLoss = document.createElement("div");
  stopLoss.innerHTML = `
    <div class="flex flex-col text-totalWhite">
    <label class="font-semibold leading-loose text-yellowlegit-300">Stop Loss</label>
    <div class="px-2 sm:w-20 md:w-24 hover:bg-bbcard py-2 text-gray-300 border-2  focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none">${
      data.stoploss || "/"
    }</div>
  </div>
  `;
  positionDetails.appendChild(stopLoss);

  const closeLink = document.querySelector(".close-link");
  closeLink.addEventListener("click", (e) => {
    e.preventDefault();
    document.body.removeChild(container);
    currentOpenComponent = null;
  });
  document.addEventListener('mousedown', function(e) {
    // make sure that we doesn't click on something inside the container
    if (currentOpenComponent && !document.getElementById("expand-position").contains(e.target)) {
      e.preventDefault();
      const container = document.getElementById("expand-position");
      if (container) {
        document.body.removeChild(container);
        currentOpenComponent = null;
      }
    }
  });
}

let isEditingPosition = false;

export async function load_positions(walletaddress, dict_current_prices, handleLoad) {
  if (isEditingPosition) {
    return; // Skip refreshing the table
  }
  const response = await fetch(`https://latf-paper-trading-backend.herokuapp.com/positions/user?walletaddress=${walletaddress}`);
  const data = await response.json();
  const positionsTable = document.getElementById("positions-table");
  positionsTable.innerHTML = '';

  for (let i = 0; i < data.length; i++) {
    const color = data[i].side === "long" ? "green" : "tomato";
    const ordervalue = data[i].orderprice * data[i].quantity;
    const currentprice = parseFloat(dict_current_prices[data[i].contract]);
    const diff = (currentprice - data[i].orderprice) / data[i].orderprice;
    let profitloss = ordervalue * (diff * -1);
    if (data[i].side === "long") {
      profitloss = ordervalue * diff;
    }

    const positionRow = document.createElement("tr");
    positionRow.dataset.orderid = JSON.stringify(data[i]);
    positionRow.id = `row-position-${i}`;
    positionRow.classList.add("text-center", "border-b", "bg-gray-800", "border-gray-700", "hover:bg-gray-700", "hover:border-gray-800");
    positionRow.addEventListener("click", function (event) {
      const targetTagName = event.target.tagName;
      if (targetTagName !== "BUTTON" && targetTagName !== "A" && targetTagName !== "DIV" && targetTagName !== "path" && targetTagName !== "svg" && targetTagName !== "INPUT") {
        const orderid = JSON.parse(positionRow.getAttribute("data-orderid"));
        expand_position(orderid);
      }
    });

    positionRow.innerHTML = `
      <th id="position-contract-${i}" scope="row" class="py-1 px-3 font-medium ${color}-color whitespace-nowrap">
        <button>
          <div onclick="window.location='/?symbol=BINANCE%3A${data[i].contract}';localStorage.setItem('selectedSymbol','${data[i].contract}');" class="font-medium">${data[i].contract}</div>
        </button>
        <div class="text-xs text-gray-200"><span id="position-leverage-${i}">${data[i].leverage}</span>x Leverage</div>
      </th>
      <td id="position-quantity-${i}" class="py-3 px-3">${data[i].quantity.toFixed(4)}</td>
      <td id="position-ordervalue-${i}" class="py-3 px-3">${(data[i].orderprice * data[i].quantity).toFixed(4)}</td>
      <td id="position-orderprice-${i}" class="py-3 px-3">${data[i].orderprice}</td>
      <td id="position-currentprice-${i}" class="py-3 px-3">${currentprice}</td>
      <td id="edit-position-tp-${i}" class="relative flex-row py-3 px-3 space-x-1">${data[i].takeprofit || ""}</td>
      <td id="edit-position-sl-${i}" class="relative flex-row py-3 px-3 space-x-1">${data[i].stoploss || ""}</td>
      <td class="py-3 px-3 italic">${data[i].orderid.substring(0, 8)}...</td>
      <td id="position-profitloss-${i}" class="position-profitloss py-3 px-3 ${is_profitloss(profitloss)}">
        ${profitloss.toFixed(2)}
        <div class="text-sm mt-1 ${profitloss >= 0 ? "text-green-500" : "text-red-500"}">
          (${((profitloss / ordervalue / data[i].leverage) * 100).toFixed(1)}%)
        </div>
      </td>
      <td id="close-position-${i}" class="py-3 px-3 space-x-1 flex flex-row items-center"></td>
    `;

    positionsTable.appendChild(positionRow);

    const closeFullButton = document.createElement("button");
    closeFullButton.id = `button-close-full-order-${i}`;
    closeFullButton.textContent = "Full Close";
    closeFullButton.className = "bg-bbbglight hover:text-totalWhite text-gray-400 font-bold py-1 px-1 rounded";
    closeFullButton.value = data[i].orderid;
    closeFullButton.onclick = function () {
      console.log(`Clicked on button market close ${closeFullButton.value}`);
      async function market_confirm(orderid, pairs) {
        const [walletaddress, isOwner] = await verify_wallet_address("Sign this request to close your position at current market price.\n\nThis request will not trigger a blockchain transaction or cost any gas fees.");
        if (isOwner) {
          market_close_manager(closeFullButton.value, walletaddress, pairs, handleLoad);
        }
      }
      market_confirm(closeFullButton.value, pairs);
    };

    const partialCloseButton = document.createElement("button");
    partialCloseButton.id = `button-manage-order-${i}`;
    partialCloseButton.className = "bg-bbbglight hover:text-totalWhite text-gray-400 font-bold py-1 px-1 rounded button";
    partialCloseButton.dataset.modalToggle = "popup-modal";
    partialCloseButton.innerHTML = "Partial Close";
    partialCloseButton.value = data[i].orderid;
    partialCloseButton.onclick = function () {
      document.getElementById("modal-close-position").style.display = "block";
      document.getElementById("store_order_id").value = partialCloseButton.value;
      document.getElementById("store_order_id").textContent = `Order ID: ${partialCloseButton.value}`;
      document.getElementById("close-market-qty").value = partialCloseButton.parentElement.parentElement.childNodes[3].textContent;
      document.getElementById("close-limit-qty").value = partialCloseButton.parentElement.parentElement.childNodes[3].textContent;
    };

    const editTpButton = document.createElement("button");
    editTpButton.id = `button-edit-position-tp-${i}`;
    editTpButton.className = "p-0.5 bg-yellow rounded-xl hover:rounded-3xl hover:bg-yellowlight transition-all duration-300 text-backgroundbase";
    editTpButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2"><path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" /></svg>`;
    editTpButton.value = data[i].orderid;
    editTpButton.onclick = function () {
      console.log(`Clicked on button edit tp`, editTpButton.value);
      isEditingPosition = true;
      allow_edit_position(editTpButton.value, "tp", editTpButton.id, `edit-position-tp-${i}`);
    };

    const editSlButton = document.createElement("button");
    editSlButton.id = `button-edit-position-sl-${i}`;
    editSlButton.className = "p-0.5 bg-yellow rounded-xl hover:rounded-3xl hover:bg-yellowlight transition-all duration-300 text-backgroundbase";
    editSlButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-xl" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2"><path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" /></svg>`;
    editSlButton.value = data[i].orderid;
    editSlButton.onclick = function () {
      console.log(`Clicked on button edit sl`);
      isEditingPosition = true;
      allow_edit_position(editSlButton.value, "sl", editSlButton.id, `edit-position-sl-${i}`);
    };

    document.getElementById(`edit-position-tp-${i}`).appendChild(editTpButton);
    document.getElementById(`edit-position-sl-${i}`).appendChild(editSlButton);
    document.getElementById(`close-position-${i}`).appendChild(closeFullButton);
    document.getElementById(`close-position-${i}`).appendChild(partialCloseButton);
  }

  load_positions_orders_count();
  isEditingPosition = false;
}


export async function close_partial_positon(handleLoad) {
  const orderid = document.getElementById("store_order_id").value;
  if (document.getElementById("limit-close").style.display === "block") {
    console.log("limit close order");
    const quantity = parseFloat(
      document.getElementById("close-limit-qty").value
    );
    const newtakeprofit = parseFloat(
      document.getElementById("close-limit-price").value
    );
    const [walletaddress, isOwner] = await verify_wallet_address(
      `Sign this request to create a limit take profit order at ${newtakeprofit} for a quantity of ${quantity}.\n\nThis request will not trigger a blockchain transaction or cost any gas fees.`
    );
    limit_close_order(orderid, walletaddress, quantity, newtakeprofit, handleLoad);
  } else {
    console.log("market close order");
    const quantity = parseFloat(
      document.getElementById("close-market-qty").value
    );
    const [walletaddress, isOwner] = await verify_wallet_address(
      `Sign this request to create a market take profit order for a quantity of ${quantity} at current market price.\n\nThis request will not trigger a blockchain transaction or cost any gas fees.`
    );
    market_close_manager_partial(orderid, walletaddress, pairs, quantity, handleLoad);
  }
}

function emptyTable() {
  const positionsTable = document.getElementById("positions-table");
  const ordersTable = document.getElementById("orders-table");

  // Clear positions table
  while (positionsTable.rows.length > 0) {
    positionsTable.deleteRow(0);
  }

  // Clear orders table
  while (ordersTable.rows.length > 0) {
    ordersTable.deleteRow(0);
  }
}

function load_positions_orders_count() {
  const positionsTable = document.getElementById("main-table-positions");
  const ordersTable = document.getElementById("main-table-orders");

  if (positionsTable) {
    const n_positions = (positionsTable.getElementsByTagName("tr").length - 1);
    document.getElementById("positions-count").innerHTML = n_positions;
  }

  if (ordersTable) {
     const n_orders = (ordersTable.getElementsByTagName("tr").length - 1);
    document.getElementById("orders-count").innerHTML = n_orders;
  }
}