import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import { getUserAddress } from "../../config/connection_manager";
import EditableDescription from "./EditableDescription";
import EditProfileOverlay from "./EditProfile";
import Stats from "./Stats";
import Feed from "../Posts/Feed";
import ProfileTabs from "./ProfileTabs";

function getUserId() {
  var url = window.location.href;
  var urlObject = new URL(url);
  var userid = urlObject.searchParams.get("userid");
  return userid;
}

const Profile = ({ connect }) => {
  const [profileDescription, setProfileDescription] = useState("");
  const [showEditOverlay, setShowEditOverlay] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [userWalletAddress, setUserWalletAddress] = useState("");
  const [showStats, setShowStats] = useState(false);
  const [showFeed, setShowFeed] = useState(true);

  const toggleEditOverlay = () => {
    setShowEditOverlay(!showEditOverlay);
  };

  const preventDefaultClick = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    async function getUser() {
      var userid = getUserId();
      const addr = window.atob(userid);
      const addr_user = await getUserAddress();
      setUserWalletAddress(addr);
      await fetch(
        `https://latf-paper-trading-backend.herokuapp.com/balance?walletaddress=${addr}`
      )
        .then((response) => response.json())
        .then((data) => {
          setProfileDescription(data.description);
          document.getElementById("profile-description").textContent =
            data.description;
          if (addr === addr_user) {
            setIsOwner(true);
          }
          const balance_object = document.getElementById("balance");
          balance_object.textContent = data.balance.toFixed(2);
          document.getElementById("balance-drop").textContent =
            data.balance.toFixed(2);
          document.getElementById("username").textContent =
            data.discordname.slice(0, -5);
          localStorage.setItem("discordname", data.discordname);
          document.getElementById(
            "img-avatar"
          ).src = `https://api.dicebear.com/7.x/bottts/svg?seed=${data.discordname.slice(
            0,
            -5
          )}.svg?colorful=true`;
        });
    }
    getUser();
  }, []);

  return (
    <div className="body-landing h-full flex flex-col">
      {/* Navbar */}
      <Navbar {...{ connect }} />

      {/* Main content area */}
      <div className="flex-1 flex">
        {/* Left-side tabs */}
        <div className="w-1/5 bg-gray-100">
          <div className="">
            <ProfileTabs
              {...{
                showStats,
                setShowStats,
                setShowFeed,
                showFeed,
                window,
                getUserId,
              }}
            />
          </div>
        </div>
        {/* Right-side profile */}
        <div className="w-4/5">
          {/* Content panel */}
          <div className="w-full flex flex-col">
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-0 lg:px-8 flex flex-col items-center justify-center"></div>
            <div className="space-y-10 ">
              <div className="p-6">
                <div className="relative p-6">
                  {/* Edit button */}
                  {isOwner && (
                    <button
                      className="items-center flex absolute top-0 right-0 px-2 py-1 bg-blue-500 hover:text-blue-500 text-totalWhite hover:bg-opacity-0 border-2 border-blue-500 rounded-full font-semibold Nordique"
                      onClick={toggleEditOverlay}
                    >
                      <div>Edit Prof&zwnj;ile</div>
                      <i className="material-icons text-sm">edit</i>
                    </button>
                  )}
                  {/* Avatar and name */}
                  <div className="bg-totalWhite bg-opacity-20 rounded-xl">
                    <div className="flex items-center justify-center">
                      <div className="flex-shrink-0">
                        <img
                          id="img-avatar"
                          className="h-24 w-24 rounded-full"
                          alt="Avatar"
                        />
                      </div>
                      <div className="ml-4 space-y-2">
                        <div className="items-center">
                          <div className="flex space-x-2 items-center">
                            <p className="text-gray-400">Discord Username:</p>
                            <h1
                              id="username"
                              className="text-4xl font-bold text-black nordique"
                            ></h1>
                            <div
                              className="relative flex items-center"
                              id="info-discriminator"
                            >
                              <div className="info-discord flex items-center">
                                <i className="material-icons text-blue-500 hover:scale-110">
                                  info
                                </i>
                                <span className="extra-info-discord text-totalWhite font-semibold ml-2 text-center">
                                  Discriminator is hidden
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <EditableDescription
                          initialDescription={profileDescription}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                      <div className="h-4 w-40 md:w-72 bg-gray-200 rounded-full">
                        <div
                          className="h-full bg-blue-400 rounded-full"
                          style={{ width: "50%" }}
                        ></div>
                      </div>
                      <p className="text-lg text-gray-500">
                        Level (coming soon)
                      </p>
                    </div>
                  </div>
                </div>
                {/* Leaderboard */}
                {showStats && <Stats userWalletAddress={userWalletAddress} />}
                {showFeed && <Feed />}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showEditOverlay && (
        <EditProfileOverlay
          discordName={`${localStorage.getItem("discordname")}`}
          profileDescription={`${
            document.getElementById("profile-description").innerHTML
          }`}
          profilePicture={`${document.getElementById("img-avatar").src}`}
          onClose={() => {
            setShowEditOverlay(false);
          }}
        />
      )}
    </div>
  );
};

export default Profile;
