import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Assuming you're using React Router for routing

const BattlesHistory = ({ userWalletAddress }) => {
  const [pastBattles, setPastBattles] = useState([]);
  const [page, setPage] = useState(0);
  const perPage = 3;
  const displayedBattles = pastBattles.slice(page * perPage, (page + 1) * perPage);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `https://latf-paper-trading-backend.herokuapp.com/battle/results?walletaddress=${userWalletAddress}`
        );
        const data = await response.json();

        // Filter out duplicate battles based on battleid
        const uniqueBattles = data.filter((battle, index, self) =>
          index === self.findIndex((b) => b.battleid === battle.battleid)
        );

        setPastBattles(uniqueBattles);
        console.log(uniqueBattles);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [userWalletAddress]);

  return typeof userWalletAddress === "string" ? (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
    <div className="px-2 py-3 sm:px-2 bg-blue-500">
        <h2 className="text-xl font-medium text-white text-center">Current Elo Rating: {pastBattles[0]?.elorating}</h2>
      </div>
      <div className="border border-gray-200 px-4 py-3 sm:p-0">
        <h3 className="text-lg font-medium mb-2 text-center">Past Battles:</h3>
        <div className="grid grid-cols-1 gap-2 sm:gap-4">
          {displayedBattles.map((battle, index) => {
            const isWinner = parseFloat(battle.user_balance) > parseFloat(battle.opponent_balance);
            return (
              <div key={`${battle.battleid}_${index}`} className="relative bg-white p-2 sm:p-4 rounded shadow">
                <h4 className="text-base sm:text-lg font-semibold mb-1">
                  <Link 
                    to={`https://www.tradingarena.io/pvp/battle/${battle.battleid}`}
                    className="text-blue-500 hover:underline"
                  >
                    Battle ID: {battle.battleid}
                  </Link>
                </h4>
                <div className="grid grid-cols-2 gap-1">
                  <p className="text-gray-500">Your Balance: {battle.user_balance}</p>
                  <p className="text-gray-500">Opponent's: {battle.opponent_balance}</p>
                </div>
                <p className="text-sm text-gray-500 absolute right-1">{new Date(Number(battle.endtime)).toLocaleString()}</p>
                <p className={`font-bold ${isWinner ? 'text-green-500' : 'text-red-500'}`}>
                  {isWinner ? 'Won' : 'Lost'}
                </p>
              </div>
            );
          })}
        </div>
        <div className="mt-2 flex justify-between">
          <button 
            className={`px-3 py-1 sm:px-4 sm:py-2 rounded bg-gray-300 ${page === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={() => setPage((prev) => Math.max(prev - 1, 0))}
            disabled={page === 0}
          >
            Previous
          </button>
          <button 
            className="px-3 py-1 sm:px-4 sm:py-2 rounded bg-gray-300"
            onClick={() => setPage((prev) => (prev + 1) * perPage < pastBattles.length ? prev + 1 : prev)}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  ) : null;  
};

export default BattlesHistory;
