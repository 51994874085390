import React, { useState } from 'react';

function ButtonLimitMarket(props) {
  const [selectedButton, setSelectedButton] = useState('limit-order');

  const handleButtonClick = (event) => {
    setSelectedButton(event.target.id);
    if (event.target.id === 'limit-order') {
      props.limit_order();
    } else if (event.target.id === 'market-order') {
      props.market_order();
    }
  }

  return (
    <div className="inline-flex">
      <button
        className={`bg-bbbglight ${selectedButton === 'limit-order' ? 'bg-gray-700' : 'hover:text-gray-600'} text-yellow font-bold py-2 px-4 rounded-l ${selectedButton === 'limit-order' ? 'text-yellow' : ''}`}
        onClick={handleButtonClick}
        id="limit-order"
      >
        Limit
      </button>
      <button
        className={`bg-bbbglight ${selectedButton === 'market-order' ? 'bg-gray-700' : 'text-gray-500 hover:text-gray-600'} font-bold py-2 px-4 rounded-r ${selectedButton === 'market-order' ? 'text-yellow' : ''}`}
        onClick={handleButtonClick}
        id="market-order"
      >
        Market
      </button>
    </div>
  );
}
export default ButtonLimitMarket;
