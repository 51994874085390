import React, { useEffect, useState } from "react";
import { getUserAddress } from "../../config/connection_manager";

const EditableDescription = ({ initialDescription }) => {
  const [description, setDescription] = useState(initialDescription);
  const [editing, setEditing] = useState(false);
  const [isOwner, setIsOwner] = useState(false);

  // on load, check if the user is the owner of the profile
  // if so, set isOwner to true
  // if not, set isOwner to false
  useEffect(() => {
    const checkOwner = async () => {
      var url = window.location.href;
      var urlObject = new URL(url);
      var userid = urlObject.searchParams.get("userid");
      const addr = window.atob(userid);
      if (addr === (await getUserAddress())) {
        setIsOwner(true);
      }
    };
    checkOwner();
  }, []);

  const handleEditClick = () => {
    setEditing(true);
  };

  const handleCancelClick = () => {
    setEditing(false);
    window.location.reload();
  };

  const handleSaveClick = async () => {
    // Call your backend endpoint here with the updated description
    // For example: /profile/description/edit?newDescription=<updatedDescription>
    const addr = await getUserAddress();
    await fetch(
      `https://latf-paper-trading-backend.herokuapp.com/profile/description/edit?description=${description}&walletaddress=${addr}`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      });

    console.log("Updating description:", description);
    setEditing(false);
  };

  const handleChange = (event) => {
    setDescription(event.target.value.slice(0, 255));
  };

  return (
    <div id="edit-button" className="flex items-center space-x-2">
      {editing ? (
        <>
          <textarea
            maxLength="255"
            className="border border-gray-300 p-2 rounded"
            value={description}
            onChange={handleChange}
          ></textarea>
          <button
            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
            onClick={handleSaveClick}
          >
            Save
          </button>
          <button
            className="bg-red-400 hover:bg-red-500 text-white px-4 py-2 rounded"
            onClick={handleCancelClick}
          >
            Cancel
          </button>
        </>
      ) : (
        <>
          <p
            id="profile-description"
            className="text-backgroundbase break-words font-semibold p-2 italic"
          >
            {description}
          </p>
        </>
      )}
    </div>
  );
};

export default EditableDescription;
