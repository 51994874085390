import React, { useState, useEffect } from "react";
import axios from "axios";
import PostPerformances from "./PostPerformances";

function Post({ post, walletAddress, onDelete }) {
  const [isLiked, setIsLiked] = useState(false);
  const [isReshared, setIsReshared] = useState(false);
  const [comment, setComment] = useState("");
  const [likesCount, setLikesCount] = useState(post.likes);
  const [resharesCount, setResharesCount] = useState(post.reshares);
  const [commentsCount, setCommentsCount] = useState(post.comments);
  const [comments, setComments] = useState([]);
  const [showComments, setShowComments] = useState(false);
  const isContentLong = post.content.length > 500;
  const [showFullContent, setShowFullContent] = useState(!isContentLong);
  const [showOverlay, setShowOverlay] = useState(false);
  const [loadedCommentsCount, setLoadedCommentsCount] = useState(5);
  const [showFullScreenImage, setShowFullScreenImage] = useState(false);

  const toggleFullScreenImage = () => {
    setShowFullScreenImage(!showFullScreenImage);
  };

  const toggleShowFullContent = () => {
    setShowFullContent(!showFullContent);
  };

  useEffect(() => {
    axios
      .get(
        `https://latf-paper-trading-backend.herokuapp.com/comments/${post.id}`
      )
      .then((response) => {
        setComments(response.data);
        setCommentsCount(response.data.length);
      })
      .catch((error) => {
        console.error("Error fetching comments:", error);
      });
  }, [post.id]);

  useEffect(() => {
    axios
      .get(`https://latf-paper-trading-backend.herokuapp.com/likes/${post.id}`)
      .then((response) => {
        setLikesCount(response.data.length);
        response.data.forEach((like) => {
          if (like.walletaddress === walletAddress) {
            setIsLiked(true);
          }
        });
      })
      .catch((error) => {
        console.error("Error fetching likes:", error);
      });
  }, [post.id, walletAddress]);

  useEffect(() => {
    axios
      .get(
        `https://latf-paper-trading-backend.herokuapp.com/reshares/${post.id}`
      )
      .then((response) => {
        setResharesCount(response.data.length);
        response.data.forEach((reshare) => {
          if (reshare.walletaddress === walletAddress) {
            setIsReshared(true);
          }
        });
      })
      .catch((error) => {
        console.error("Error fetching reshares:", error);
      });
  }, [post.id, walletAddress]);

  const handleLike = async () => {
    try {
      const response = await axios.post(
        `https://latf-paper-trading-backend.herokuapp.com/posts/${post.id}/like`,
        { walletAddress: walletAddress }
      );

      // Based on the response, you can update the state
      if (response.data.message === "Post liked successfully") {
        setIsLiked(true);
        setLikesCount((prev) => prev + 1);
      } else if (response.data.message === "Like removed successfully") {
        setIsLiked(false);
        setLikesCount((prev) => prev - 1);
      }
    } catch (error) {
      console.error("Error handling the like:", error);
    }
  };

  const handleReshare = async () => {
    try {
      const response = await axios.post(
        `https://latf-paper-trading-backend.herokuapp.com/posts/${post.id}/reshare`,
        { walletAddress: walletAddress }
      );
      if (response.data.message === "Post reshared successfully") {
        setIsReshared(true);
        setResharesCount((prev) => prev + 1);
      } else if (response.data.message === "Reshare removed successfully") {
        setIsReshared(false);
        setResharesCount((prev) => prev - 1);
      }
    } catch (error) {
      console.error("Error handling the reshare:", error);
    }
  };

  const deletePost = async () => {
    if (!window.confirm("Are you sure you want to delete this post?")) return;
    try {
      const response = await axios.delete(
        `https://latf-paper-trading-backend.herokuapp.com/posts/remove/${post.id}`,
        { data: { walletAddress: walletAddress } }
      );
      if (response.status === 204) {
        onDelete(post.id);
      } else {
        console.error("Failed to delete post:", response.data.message);
      }
    } catch (error) {
      console.error("Error deleting the post:", error);
    }
  };

  const handleComment = () => {
    if (comment === "") return;
    axios
      .post(
        `https://latf-paper-trading-backend.herokuapp.com/posts/${post.id}/comment`,
        { walletAddress: walletAddress, content: comment }
      )
      .then(() => {
        axios
          .get(
            `https://latf-paper-trading-backend.herokuapp.com/comments/${post.id}`
          )
          .then((response) => {
            setComments(response.data);
            setCommentsCount(response.data.length);
          })
          .catch((error) => {
            console.error("Error fetching comments:", error);
          });
        setComment("");
        if (!showComments) {
          setShowComments(true);
        }
      })
      .catch((error) => {
        console.error("Error adding comment:", error);
      });
  };

  const toggleComments = () => {
    setShowOverlay(true);
    setLoadedCommentsCount(5);
  };

  const loadMoreComments = () => {
    setLoadedCommentsCount(loadedCommentsCount + 5);
  };

  const closeOverlay = (e) => {
    if (e.target.id === "overlay") {
      setShowOverlay(false);
    }
  };

  useEffect(() => {
    if (showOverlay) {
      document.addEventListener("click", closeOverlay);
    }
    return () => {
      document.removeEventListener("click", closeOverlay);
    };
  }, [showOverlay]);

  return (
    <div className="bg-white p-4 shadow rounded-lg mb-4">
      {post.reshared_by && (
        <div className="reshared-by mb-2 flex items-center text-gray-600 text-sm">
          Reshared by {post.reshared_by.slice(0, -5)}
        </div>
      )}
      <div className="flex items-center relative">
        <div>
          <img
            className="cursor-pointer w-10 h-10 rounded-full mr-4"
            src={`https://api.dicebear.com/7.x/bottts/svg?seed=${post.discordname?.slice(
              0,
              -5
            )}.svg?colorful=true`}
            alt="Avatar of User"
            onClick={() => {
              window.location.href = `/profile?userid=${window.btoa(
                post.walletaddress
              )}`;
            }}
          />
        </div>
        <div className="text-md">
          <span
            className="cursor-pointer font-medium text-gray-500 hover:underline"
            onClick={() => {
              window.location.href = `/profile?userid=${window.btoa(
                post.walletaddress
              )}`;
            }}
          >
            {post.discordname?.slice(0, -5)}
          </span>
          {" - "}
          <TimeAgo createdAt={post.created_at} />
          {walletAddress === post.walletaddress && (
            <div className="">
              <button
                onClick={deletePost}
                className="absolute right-0 top-0 bg-red-500 text-white text-xs px-2 py-1 rounded hover:bg-red-600 focus:outline-none"
              >
                Delete
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="mt-3 text-lg">
        {showFullContent ? post.content : `${post.content.slice(0, 500)}...`}
        {isContentLong && (
          <button
            className="text-blue-500 mt-2"
            onClick={toggleShowFullContent}
          >
            {showFullContent ? "Show Less" : "Read More"}
          </button>
        )}
        {post.image && (
          <div className="image-container">
            <img
              src={post.image}
              alt="Post Image"
              className="mt-2 rounded-lg cursor-pointer"
              onClick={toggleFullScreenImage}
            />
          </div>
        )}
      </div>
      <PostPerformances
        {...{
          toggleComments,
          commentsCount,
          handleReshare,
          isReshared,
          resharesCount,
          handleLike,
          isLiked,
          likesCount,
        }}
      />
      {showOverlay && (
        <div
          id="overlay"
          className="z-20 fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
        >
          <div className="bg-white p-4 rounded-lg w-4/5 max-h-[80vh] overflow-y-auto">
            <div className="flex items-center">
              <div>
                <img
                  className="cursor-pointer w-10 h-10 rounded-full mr-4"
                  src={`https://api.dicebear.com/7.x/bottts/svg?seed=${post.discordname?.slice(
                    0,
                    -5
                  )}.svg?colorful=true`}
                  alt="Avatar of User"
                  onClick={() => {
                    window.location.href = `/profile?userid=${window.btoa(
                      post.walletaddress
                    )}`;
                  }}
                />
              </div>
              <div className="text-md">
                <span
                  className="cursor-pointer font-medium text-gray-500 hover:underline"
                  onClick={() => {
                    window.location.href = `/profile?userid=${window.btoa(
                      post.walletaddress
                    )}`;
                  }}
                >
                  {post.discordname?.slice(0, -5)}
                </span>
                {" - "}
                <TimeAgo createdAt={post.created_at} />
              </div>
            </div>
            <div className="mt-3 text-lg">
              {showFullContent
                ? post.content
                : `${post.content.slice(0, 500)}...`}
              {isContentLong && (
                <button
                  className="text-blue-500 mt-2"
                  onClick={toggleShowFullContent}
                >
                  {showFullContent ? "Show Less" : "Read More"}
                </button>
              )}
              {post.image && (
                <img
                  src={post.image}
                  alt="Post Image"
                  className="z-20 rounded-md max-w-full mx-auto mt-4 mb-4 transform transition-transform duration-300 hover:scale-105"
                  onClick={toggleFullScreenImage}
                />
              )}{" "}
            </div>
            <PostPerformances
              {...{
                toggleComments,
                commentsCount,
                handleReshare,
                isReshared,
                resharesCount,
                handleLike,
                isLiked,
                likesCount,
              }}
            />
            <input
              type="text"
              className="border rounded-lg w-full p-2"
              placeholder="Add a comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <button
              className="bg-blue-500 text-white text-sm font-semibold px-4 py-2 rounded-lg mt-2"
              onClick={handleComment}
            >
              Post
            </button>
            <div className="mt-4">
              {comments.slice(0, loadedCommentsCount).map((comment) => (
                <div
                  key={comment.id}
                  className="bg-gray-100 p-1 rounded- border-b-2"
                >
                  <div className="flex items-center">
                    <div>
                      <img
                        className="cursor-pointer w-10 h-10 rounded-full mr-4"
                        src={`https://api.dicebear.com/7.x/bottts/svg?seed=${comment.discordname?.slice(
                          0,
                          -5
                        )}.svg?colorful=true`}
                        alt="Avatar of User"
                        onClick={() => {
                          window.location.href = `/profile?userid=${window.btoa(
                            comment.walletaddress
                          )}`;
                        }}
                      />
                    </div>
                    <div className="text-md">
                      <span
                        className="cursor-pointer font-medium text-gray-500 hover:underline"
                        onClick={() => {
                          window.location.href = `/profile?userid=${window.btoa(
                            comment.walletaddress
                          )}`;
                        }}
                      >
                        {comment.discordname?.slice(0, -5)}
                      </span>
                      {" - "}
                      <TimeAgo createdAt={comment.created_at} />
                    </div>
                  </div>
                  <div className="mt-1 text-lg ml-12">{comment.content}</div>
                </div>
              ))}
            </div>
            {comments.length > loadedCommentsCount && (
              <button
                className="mt-2 bg-blue-500 text-white px-4 py-2 rounded-lg"
                onClick={loadMoreComments}
              >
                Load More
              </button>
            )}
          </div>
        </div>
      )}
      {showFullScreenImage && (
        <div
          className="z-20 fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 flex justify-center items-center"
          onClick={toggleFullScreenImage}
        >
          <img
            src={post.image}
            alt="Post Image Fullscreen"
            className="rounded-lg max-h-[90vh] max-w-full object-contain"
          />
        </div>
      )}
    </div>
  );
}

export default Post;

function TimeAgo({ createdAt }) {
  const postDate = new Date(createdAt);
  const currentDate = new Date();
  const diffInSeconds = Math.floor((currentDate - postDate) / 1000);

  let timeAgo = "";

  if (diffInSeconds < 60) {
    timeAgo = `${diffInSeconds}s ago`;
  } else if (diffInSeconds < 3600) {
    timeAgo = `${Math.floor(diffInSeconds / 60)}m ago`;
  } else if (diffInSeconds < 86400) {
    timeAgo = `${Math.floor(diffInSeconds / 3600)}h ago`;
  } else {
    timeAgo = `${Math.floor(diffInSeconds / 86400)}d ago`;
  }

  return <span className="text-gray-500 text-xs">{timeAgo}</span>;
}
