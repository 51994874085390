import React, { useEffect, useState } from "react";
import axios from "axios";
import { getUserAddress } from "../../config/connection_manager";
import Post from "./Post";

function Feed() {
  const [posts, setPosts] = useState([]);
  const [walletAddress, setWalletAddress] = useState("");

  const handleDeletePost = (postId) => {
    setPosts((posts) => posts.filter((post) => post.id !== postId));
  };

  function getUserId() {
    var url = window.location.href;
    var urlObject = new URL(url);
    var userid = urlObject.searchParams.get("userid");
    return userid;
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const userId = getUserId();
        const addr = window.atob(userId);
        console.log("From Feed.js1: ", addr);
        setWalletAddress(addr);
        console.log("From Feed.js2: ", addr);
        const response = await axios.get(
          `https://latf-paper-trading-backend.herokuapp.com/feed/${addr}`
        );
        setPosts(response.data);
      } catch (error) {
        console.error("Error fetching feed:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div>
      <div className="container mx-auto py-4">
        <h2 className="text-2xl font-semibold mb-4">Feed</h2>
        {posts.map((post) => (
          <Post
            key={post.id}
            post={post}
            walletAddress={walletAddress}
            onDelete={handleDeletePost}
          />
        ))}
      </div>
    </div>
  );
}

export default Feed;
