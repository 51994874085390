export default function refresh_pnl(walletaddress, dict_current_prices) {
  document.title = `${geturl_symbol()} - ${parseFloat(dict_current_prices[`${geturl_symbol()}`])}`;
  // find all elements having position-profitloss in their class, but potentially more than that
  var pnl_elements = document.getElementsByClassName("position-profitloss");
  for (var i = 0; i < pnl_elements.length; i++) {
    // row-position-0
    var position_element = document.getElementById("row-position-" + i);
    // console.log("row-position-" + i);
    // print the current order price of this position
    var orderprice = document.getElementById(
      "position-orderprice-" + i
    ).innerHTML;
    var quantity = document.getElementById("position-quantity-" + i).innerHTML;
    var leverage = document.getElementById("position-leverage-" + i).innerHTML;
    var contract = document.getElementById("position-contract-" + i);
    // get contract first div child
    var contract_pair = contract.children[0].children[0].innerHTML;
    // get contract class
    var contract_class = contract.className;
    var side = "long";
    if (contract_class.includes("tomato")) {
      side = "short";
    }
    var ordervalue = orderprice * quantity;
    let color = "tomato-color";
    const currentprice = parseFloat(dict_current_prices[`${contract_pair}`]);
    const diff = (currentprice - orderprice) / orderprice;
    var profitloss = ordervalue * (diff * -1);
    if (side == "long") {
      profitloss = ordervalue * diff;
    }
    if (profitloss > 0) {
      color = "green-color";
    }
    var profitloss_element = document.getElementById(
      "position-profitloss-" + i
    );
    profitloss_element.innerHTML = profitloss.toFixed(2);
    // do the same for position-currentprice- + i
    var currentprice_element = document.getElementById(
      "position-currentprice-" + i
    );
    currentprice_element.innerHTML = currentprice;
    // change color of profitloss to color, knowing that there is already a color in the classname and I use tailwind
    profitloss_element.className = profitloss_element.className.replace(
      /tomato-color|green-color/g,
      color
    );

    // add profitloss percentage
    const pnl_percentage = ((profitloss / ordervalue / leverage) * 100).toFixed(1);
    // console.log(pnl_percentage, "pnl_percentage")
    const pnl_percentage_element = document.createElement("div");
    pnl_percentage_element.innerHTML = `(${pnl_percentage}%)`;
    pnl_percentage_element.classList.add("text-sm", "mt-1");
    pnl_percentage_element.classList.add(
      profitloss >= 0 ? "text-green-500" : "text-red-500"
    );
    profitloss_element.appendChild(pnl_percentage_element);
  }
}


{/* <td id="position-profitloss-${i}" class="position-profitloss py-3 px-3 ${is_profitloss(
  profitloss
)}">
${profitloss.toFixed(2)}
<div class="text-sm mt-1 ${
  profitloss >= 0 ? 'text-green-500' : 'text-red-500'
}">
  (${((profitloss / ordervalue / data[i].leverage) * 100).toFixed(0)}%)
</div>
</td> */}

function geturl_symbol() {
  return localStorage.getItem("selectedSymbol");
    // let queryString = window.location.href.split("?")[1];
    // let params = new URLSearchParams(queryString);
    // const url_param = params.get("symbol");
    // try {
    //   const index = url_param.indexOf(":");
    //   return url_param.slice(index + 1);
    // } catch {
    //   alert("Please select a cryptocurrency on the chart first.");
    //   throw new Error("No contract selected.");
    // }
  }