import React from "react";
import { ResponsiveSankey } from "@nivo/sankey";
import { color } from "@mui/system";

const LandingSankeyReverse = ({ datareverse }) => (
<ResponsiveSankey
  data={datareverse}
  isInteractive={true}
  margin={{ top: 40, right: 0, bottom: 40, left: 50 }}
  align="center"
  sort="input"
  colors={{ scheme: 'purple_blue' }}
  linkBlendMode="difference"
  nodeOpacity={0}
  nodeHoverOthersOpacity={0.35}
  nodeThickness={10}
  nodeSpacing={60}
  nodeBorderWidth={2}
  theme = {{"tooltip": {
    "container": {
        "background": "black",
        "color": "white",
        "display": 'none',
    }},
    "fontSize": 18,
    }} 
  nodeBorderColor={{
      from: 'color',
      modifiers: [
          [
              'darker',
              0.8
          ]
      ]
  }}
  nodeBorderRadius={5}
  linkOpacity={1}
  linkHoverOpacity={1}
  linkHoverOthersOpacity={0.1}
  linkContract={1}
  enableLinkGradient={true}
  labelOrientation="vertical"
  labelPosition="outside"
  labelPadding={16}
  labelTextColor={{
      from: 'color',
      modifiers: [
          [
              'darker',
              0.5
          ]
      ]
  }}
  // Utilisez la propriété labelBackgroundColor pour définir la couleur de fond des étiquettes
  labelBackgroundColor="#17181f"
  // Utilisez la propriété labelRotation pour remettre les étiquettes dans le bon sens
  // Définissez la propriété label pour afficher la valeur des noeuds sur les liens
  label={link => `${link.value}`}
/>

);

export default LandingSankeyReverse;
