import React, { useEffect, useState } from "react";
import Web3 from "web3";
import { ethers } from "ethers";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import DashGraph from "./DashGraph";
import Navbar from "./Navbar";
import Filter from "./Filter";
import SignUp from "./SignUp";
import { verify_wallet_address } from "../config/wallet_signer";
import { getAccount } from "@wagmi/core";
import { getUserAddress } from "../config/connection_manager";
import { connectWallet } from "../config/connection_manager";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.handleLoad = this.handleLoad.bind(this);
  }

  componentDidMount() {
    console.log("loading page and running handleLoad");
    // window.addEventListener("load", this.handleLoad);
    this.handleLoad();
  }

  componentWillUnmount() {
    // window.removeEventListener("load", this.handleLoad);
  }

  handleLoad = async (e) => {
    console.log("running handleLoad");
    try {
      if (localStorage?.getItem("isWalletConnected") === "true") {
      const addr = await getUserAddress()
      console.log("address handleload: ", addr)
        await fetch(
          `https://latf-paper-trading-backend.herokuapp.com/balance?walletaddress=${addr}`
        )
          .then((response) => response.json())
          .then((data) => {
            console.log(data.discordname, data.balance);
            calculate_indicators(addr);
            const balance_object = document.getElementById("balance");
            balance_object.textContent = data.balance.toFixed(2);
            document.getElementById("balance-drop").textContent =
              data.balance.toFixed(2);
            localStorage.setItem("isWalletConnected", true);
            load_archive_filters(data);
            document.getElementById("canvas").value = addr;
          });
      } else {
        localStorage.setItem("isWalletConnected", false);
      }
    } catch (error) {
      console.log("handleLoad Error: \n\n", error);
      document.getElementById("sign-up-popup").classList.remove("hidden");
    }
  };

  state = {
    query: "BINANCE:BTCUSDT",
    ordervalue: 0,
    navbarOpen: 0,
    setNavbarOpen: 0,
    filtersChecked: [],
  };

  render() {
    const { query } = this.state;
    const { navbarOpen } = this.state;
    const { setNavbarOpen } = this.state;
    const { ordervalue } = this.state;
    const { filtersChecked } = this.state;
    return (
      <>
        <Navbar {...{ connect }} />
        <SignUp {...{ document }} />
        <div className="bg-tvcolor text-center place-content-center flex grid grid-rows-6">
          <div className="gradient-text bg-tvcolor row-span-1 sm:row-span-2 text-3xl font-extrabold text-center text-gray-200 border-gray-500 ">
            Metrics
          </div>
          <div class="bg-tvcolor Grid__Container gap-4 row-span-4 border-gray-500 rounded-b-xl">
            <div className="forliquid Stat__Card">
              <span className="Stat__Container ">
                <span className="Stat__Title">Winrate</span>
                <div id="winrate" className="Stat__Content z-1"></div>
                <button
                  onClick={() => return_card("winrate")}
                  className="flex justify-end"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-return-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"
                    />
                  </svg>
                </button>
              </span>
              <div className="liquid"></div>
            </div>
            <div className="forliquid Stat__Card">
              <span className="Stat__Container ">
                <span className="Stat__Title">Average Win</span>
                <p id="average-win" className="Stat__Content z-1"></p>
                <button
                  onClick={() => return_card("average-win")}
                  className="flex justify-end"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-return-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"
                    />
                  </svg>
                </button>
              </span>
              <div className="liquid"></div>
            </div>
            <div className="forliquid Stat__Card">
              <span className="Stat__Container ">
                <span className="Stat__Title">Average Loss</span>
                <p id="average-loss" className="Stat__Content z-1"></p>
                <button
                  onClick={() => return_card("average-loss")}
                  className="flex justify-end"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-return-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"
                    />
                  </svg>
                </button>
              </span>
              <div className="liquid"></div>
            </div>
            <div className="forliquid Stat__Card">
              <span className="Stat__Container ">
                <span className="Stat__Title">APPT</span>
                <p id="ratio" className="Stat__Content z-1"></p>
                <button
                  onClick={() => return_card("ratio")}
                  className="flex justify-end"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-return-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"
                    />
                  </svg>
                </button>
              </span>
              <div className="liquid"></div>
            </div>
          </div>
        </div>
        <div className="w-full h-full grid grid-rows-3 grid-flow-col gap-3 text-totalWhite bg-tvcolor text-center py-1">
          <div className="row-span-1 col-span-3 px-10">
            <div
              classname="chart-container"
              style={{ position: "relative", height: "90%", width: "99%" }}
            >
              {/* Archive positions being load here with graph */}
              <DashGraph />
            </div>
          </div>
          <div id="filter-component" className="row-span-1 col-span-3">
            <Filter
              {...{ load_filters, filter_long_short, get_filters_checked }}
            />
            <div className="flex justify-end px-5">
              <button
                onClick={exportToCsv}
                id="export-button-csv"
                className="bg-blue-600 text-white rounded-tl-full px-4 py-2 flex items-center justify-center hover:bg-blue-500"
              >
                <i
                  class="material-icons"
                  style={{
                    position: "relative",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                >
                  download
                </i>
                CSV
              </button>
              <button
                onClick={exportToExcel}
                id="export-button-excel"
                className="bg-green-600 text-white rounded-tr-full px-4 py-2 flex items-center justify-center hover:bg-green-500"
              >
                <i
                  class="material-icons"
                  style={{
                    position: "relative",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                >
                  download
                </i>
                Excel
              </button>
            </div>
            <div className="sm:px-4 bg-tvcolor">
              <div className="bg-tvcolor grid grid-cols-10">
                <div className="col-span-10 overflow-auto">
                  <div
                    id="table-past-positions"
                    className="rounded-3xl h-full border-totalWhite text-xl text-totalWhite overflow-auto"
                  >
                    <div className="overflow-x-auto relative bg-tvcolor">
                      <table
                        id="archive-table-full"
                        className="w-full text-sm text-left text-gray-400 relative justify-evenly"
                      >
                        <thead className="text-xs uppercase bg-gray-50 bg-gray-700 ">
                          <tr className="text-center">
                            <th scope="col" className="py-3 px-6">
                              Contract
                            </th>
                            <th scope="col" className="py-3 px-6">
                              Order Time
                            </th>
                            <th scope="col" className="py-3 px-6">
                              Qty
                            </th>
                            <th scope="col" className="py-3 px-6">
                              Open Price
                            </th>
                            <th scope="col" className="py-3 px-6">
                              Close Price
                            </th>
                            <th scope="col" className="py-3 px-6">
                              PnL
                            </th>
                            <th scope="col" className="py-3 px-6">
                              Balance
                            </th>
                            <th scope="col" className="py-3 px-6">
                              Note
                            </th>
                            <th scope="col" className="py-3 px-6">
                              Set
                            </th>
                            <th scope="col" className="py-3 px-6">
                              ID
                            </th>
                          </tr>
                        </thead>
                        <tbody
                          id="archive-table"
                          className="text-totalWhite"
                        ></tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Dashboard;

async function connect() {
  const [signerAddr, isOwner] = await verify_wallet_address(
    "Welcome to the LATF Paper Trading.\n\nSign this request to access your account.\n\nThis request will not trigger a blockchain transaction or cost any gas fees."
  );
  if (isOwner) {
    try {
      localStorage.setItem("isWalletConnected", true);
    } catch (ex) {
      console.log(ex);
    }
    try {
      connectWallet();
    } catch (err) {
      localStorage.setItem("isWalletConnected", false);
    }
  }
}

async function fetch_archive(walletaddress) {
  const past_positions = await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/user/archive/positions?walletaddress=${walletaddress}`
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  return past_positions;
}

async function calculate_indicators(walletaddress) {
  const past_positions = await fetch_archive(walletaddress);
  let n_wins = 0;
  let i = 0;
  let average_win = 0;
  let average_loss = 0;
  let total_profits = 0;
  let total_losses = 0;
  while (i < past_positions.length) {
    if (past_positions[i].profitloss >= 0) {
      n_wins++;
      average_win +=
        (past_positions[i].profitloss / past_positions[i].total_balance) * 100;
      total_profits += parseFloat(past_positions[i].profitloss);
    } else {
      average_loss +=
        (past_positions[i].profitloss / past_positions[i].total_balance) * 100;
      total_losses += parseFloat(past_positions[i].profitloss);
    }
    i++;
  }
  const n_loss = n_wins - i;
  const winrate = ((n_wins / i) * 100).toFixed(2);
  average_win = (average_win / n_wins).toFixed(3);
  average_loss = (average_loss / n_loss).toFixed(3);
  const ratio =
    ((winrate / 100) * total_profits) / n_wins -
    ((1 - winrate / 100) * total_losses) / n_loss;
  document.getElementById("average-win").textContent = isNaN(average_win)
    ? "0%"
    : average_win + "%";
  document.getElementById("average-loss").textContent = isNaN(average_loss)
    ? "0%"
    : average_loss + "%";
  document.getElementById("winrate").textContent = isNaN(winrate)
    ? "0%"
    : winrate + "%";
  document.getElementById("ratio").textContent = isNaN(ratio)
    ? "0.000"
    : ratio.toFixed(3);
}

function check_filters_checked() {
  const filters = document.getElementById("filters-archive");
  const elems = filters.querySelectorAll("*[id]");
  let i = 0;
  while (i < elems.length) {
    if (document.getElementById(elems[i].id).checked) {
      console.log(elems[i].id);
    }
    i++;
  }
}

function load_archive_filters(data) {
  let i = 0;
  var array_set = JSON.parse(data.userset);
  while (i < array_set.length) {
    const li = document.createElement("li");
    li.innerHTML = `      
    <div class="flex items-center pl-3 text-gray-400 ">
      <input id="${array_set[i]}-checkbox-list" type="checkbox" value="${array_set[i]}" class="w-4 h-4">
      <label for="${array_set[i]}-checkbox-list" class="py-3 ml-2 w-full text-lg text-gray-400">${array_set[i]}</label>
	  </div>`;
    li.className =
      "w-full border-b border-gray-200 sm:border-b-0 sm:border-r border-gray-600 ";
    if (i === array_set.length - 1) {
      li.className =
        "w-full  border-b border-gray-200 h-full sm:border-b-0 border-gray-600 ";
    }
    document.getElementById("filters-archive").appendChild(li);
    i++;
  }
  i = 0;
  array_set = JSON.parse(data.userset2);
  while (i < array_set.length) {
    const li = document.createElement("li");
    li.innerHTML = `      
    <div class="flex items-center pl-3 text-gray-400 ">
      <input id="${array_set[i]}-checkbox-list" type="checkbox" value="${array_set[i]}" class="w-4 h-4">
      <label for="${array_set[i]}-checkbox-list" class="py-3 ml-2 w-full text-lg text-gray-400">${array_set[i]}</label>
	  </div>`;
    li.className =
      "w-full  border-b border-gray-200 h-full sm:border-b-0 sm:border-r border-gray-600 ";
    if (i === array_set.length - 1) {
      li.className =
        "w-full  border-b border-gray-200 h-full sm:border-b-0 border-gray-600 ";
    }
    document.getElementById("filters-archive2").appendChild(li);
    i++;
  }
  i = 0;
  array_set = JSON.parse(data.userset3);
  while (i < array_set.length) {
    const li = document.createElement("li");
    li.innerHTML = `      
    <div class="flex items-center pl-3 text-gray-400 ">
      <input id="${array_set[i]}-checkbox-list" type="checkbox" value="${array_set[i]}" class="w-4 h-4">
      <label for="${array_set[i]}-checkbox-list" class="py-3 ml-2 w-full text-lg text-gray-400">${array_set[i]}</label>
	  </div>`;
    li.className =
      "w-full  border-b border-gray-200 h-full sm:border-b-0 sm:border-r border-gray-600 ";
    if (i === array_set.length - 1) {
      li.className =
        "w-full  border-b border-gray-200 h-full sm:border-b-0 border-gray-600 ";
    }
    document.getElementById("filters-archive3").appendChild(li);
    i++;
  }
}

function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;
  a = a.sort();
  b = b.sort();
  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

function filter_based_checked(array_set) {
  filter_long_short(array_set);
  const oTable = document.getElementById("archive-table");
  var rowLength = oTable.rows.length;
  let i = 0;
  while (i < rowLength) {
    var oCells = oTable.rows.item(i).cells;
    var setchecked = oCells.item(8).innerHTML.split(",");
    console.log(arraysEqual(array_set, setchecked), array_set, setchecked);
    // && row not hided
    if (
      arraysEqual(array_set, setchecked) &&
      oTable.rows[i].style.display !== "none"
    ) {
      oTable.rows[i].style.display = "";
    } else if (!arraysEqual(array_set, setchecked) && array_set.length > 0) {
      oTable.rows[i].style.display = "none";
    }
    i++;
  }
  load_filtered_stats(oTable);
}

function get_filters_checked() {
  // hide everything
  const oTable = document.getElementById("archive-table");
  var rowLength = oTable.rows.length;
  var i = 0;
  while (i < rowLength) {
    oTable.rows[i].style.display = "none";
    i++;
  }
  var filters = document.getElementById("filters-archive");
  if (
    document
      .getElementById("filters-archive2")
      .parentNode.classList.contains("visible")
  ) {
    var filters = document.getElementById("filters-archive2");
  } else if (
    document
      .getElementById("filters-archive3")
      .parentNode.classList.contains("visible")
  ) {
    var filters = document.getElementById("filters-archive3");
  }
  const elems = filters.querySelectorAll("*[id]");
  i = 0;
  let array_set = [];
  while (i < elems.length) {
    if (document.getElementById(elems[i].id).checked) {
      array_set.push(elems[i].value);
    }
    i++;
  }
  console.log("filter_checked = ", array_set);
  filter_based_checked(array_set);
}

function filter_long_short(array_set) {
  const oTable = document.getElementById("archive-table");
  var rowLength = oTable.rows.length;
  var i = 0;
  while (i < rowLength) {
    if (
      document.getElementById("long").checked &&
      document.getElementById("short").checked
    ) {
      oTable.rows[i].style.display = "";
    } else if (document.getElementById("short").checked) {
      if (oTable.rows[i].cells[0].className.includes("tomato")) {
        oTable.rows[i].style.display = "";
      }
    } else if (document.getElementById("long").checked) {
      if (oTable.rows[i].cells[0].className.includes("green")) {
        oTable.rows[i].style.display = "";
      }
    }
    i++;
  }
}

function load_filters(page) {
  filter_long_short();
  var filters = document.getElementById("filters-archive");
  if (page === 2) {
    filters = document.getElementById("filters-archive2");
  } else if (page === 3) {
    filters = document.getElementById("filters-archive3");
  }
  var filterChecked = [];
  let i = 0;
  while (i < filters.childNodes.length) {
    if (filters.childNodes[i].childNodes[1].childNodes[1].checked) {
      filterChecked.push(
        filters.childNodes[i].childNodes[1].childNodes[1].value
      );
    }
    i++;
  }
  i = 0;
  var setchecked = [];
  const oTable = document.getElementById("archive-table");
  var rowLength = oTable.rows.length;
  for (i = 0; i < rowLength; i++) {
    var oCells = oTable.rows.item(i).cells;
    var cellLength = oCells.length;
    for (var j = 8; j < 9; j++) {
      // change j = *X* to fit the column nb of the set, and j < X+1
      setchecked = oCells.item(j).innerHTML.split(",");
      if (arraysEqual(filterChecked, setchecked)) {
        console.log(
          setchecked,
          filterChecked,
          arraysEqual(filterChecked, setchecked)
        );
        oTable.rows.item(i).style.display = "";
      } else {
        oTable.rows.item(i).style.display = "none";
      }
      if (filterChecked.length === 0) {
        oTable.rows.item(i).style.display = "";
      }
    }
  }
  load_filtered_stats(oTable);
}

function load_filtered_stats(oTable) {
  let i = 0;
  let n_position_filtered = 0;
  var rowLength = oTable.rows.length;
  let n_wins = 0;
  let average_win = 0;
  let average_loss = 0;
  let total_profits = 0;
  let total_losses = 0;
  for (i = 0; i < rowLength; i++) {
    var oCells = oTable.rows.item(i).cells;
    const cell_profitloss = oCells.item(5).innerHTML;
    const cell_totalbalance = oCells.item(6).innerHTML;
    if (oTable.rows.item(i).style.display === "") {
      if (cell_profitloss >= 0) {
        n_wins++;
        total_profits += parseFloat(cell_profitloss);
        average_win += (cell_profitloss / cell_totalbalance) * 100;
      } else {
        total_losses += parseFloat(cell_profitloss);
        average_loss += (cell_profitloss / cell_totalbalance) * 100;
      }
      n_position_filtered++;
    }
  }
  const n_loss = n_wins - n_position_filtered;
  const winrate = ((n_wins / n_position_filtered) * 100).toFixed(2);
  console.log(n_wins, n_loss, winrate, total_profits, total_losses);
  average_win = (average_win / n_wins).toFixed(3);
  average_loss = (average_loss / n_loss).toFixed(3);
  const ratio =
    ((winrate / 100) * total_profits) / n_wins -
    ((1 - winrate / 100) * total_losses) / n_loss;
  document.getElementById("average-win").textContent = average_win + "%";
  document.getElementById("average-loss").textContent = average_loss + "%";
  document.getElementById("winrate").textContent = winrate + "%";
  document.getElementById("ratio").textContent = ratio.toFixed(3);
  document.getElementById("average-win").className = "Stat__Content";
  document.getElementById("average-loss").className = "Stat__Content";
  document.getElementById("winrate").className = "Stat__Content";
  document.getElementById("ratio").className = "Stat__Content";
}

function return_card(elementID) {
  const cardText = document.getElementById(elementID);
  cardText.className = "text-gray-200 text-xs";
  if (elementID == "winrate") {
    cardText.textContent = "Σ Positions in profits / Σ Positions";
  } else if (elementID == "average-win") {
    cardText.textContent = "Average win compared to your total balance.";
  } else if (elementID == "average-loss") {
    cardText.textContent = "Average loss compared to your total balance.";
  } else if (elementID == "ratio") {
    cardText.textContent = "Average profitability per trade.";
  }
}

const exportToCsv = () => {
  const separator = ",";
  const table_id = "archive-table-full";
  // Select rows from table_id
  var rows = document.querySelectorAll("table#" + table_id + " tr");
  // Construct csv
  var csv = [];
  for (var i = 0; i < rows.length; i++) {
    // Check if row is visible
    if (rows[i].offsetParent !== null) {
      var row = [],
        cols = rows[i].querySelectorAll("td, th");
      for (var j = 0; j < cols.length; j++) {
        var data = cols[j].innerText
          .replace(/(\r\n|\n|\r)/gm, "")
          .replace(/(\s\s)/gm, " ");
        data = data.replace(/"/g, '""');
        // Push escaped string
        row.push('"' + data.replace("edit_note", "") + '"');
      }
      csv.push(row.join(separator));
    }
  }
  var csv_string = csv.join("\n");
  var filename = "export_LATF_" + new Date().toLocaleDateString() + ".csv";
  var link = document.createElement("a");
  link.style.display = "none";
  link.setAttribute("target", "_blank");
  link.setAttribute(
    "href",
    "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
  );
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

function exportToExcel() {
  var uri = "data:application/vnd.ms-excel;base64,";
  var template =
    '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
  var base64 = function (s) {
    return window.btoa(unescape(encodeURIComponent(s)));
  };
  var format = function (s, c) {
    return s.replace(/{(\w+)}/g, function (m, p) {
      return c[p];
    });
  };
  const table = document.querySelector("#archive-table-full");
  let xls = "";
  for (let i = 0; i < table.rows.length; i++) {
    let row = table.rows[i];
    if (row.style.display === "none") continue;
    let rowData = table.rows[i].outerHTML;
    xls += rowData.replace("edit_note", "");
  }
  var ctx = {
    worksheet: "Worksheet",
    table: xls,
  };
  var link = document.createElement("a");
  link.download = "export_LATF_" + new Date().toLocaleDateString() + ".xls";
  link.href = uri + base64(format(template, ctx));
  link.click();
}
