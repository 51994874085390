import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import { Line } from "react-chartjs-2";
import { useRef } from 'react';
import load_archives from "./load_data_chart"
import Web3 from "web3";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  title: {
    display: true,
    text: 'Balance',
    fontSize: 20
  },
  legend: {
    display: true,
    position: 'right'
  },
};

const labels = ['/', '/', '/'];

export const data = {  
  labels,
  datasets: [
    {
      fill: true,
      label: 'Balance',
      data: [2, 1, 1],
      borderColor: '#10424e',
      backgroundColor: '#134E5E',
    }
  ],
};

async function get_address(chartRef) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const otherWalletAddress = urlParams.get("walletAddress");
  load_archives(otherWalletAddress, chartRef);
  }
export default function DashGraphOther() {
  const chartRef = useRef();
  return (
    <Line
      id="canvas"
      options={options}
      data={data}
      ref={chartRef}
      onLoad={get_address(chartRef)}
    // onLoad={load_archives(window.userwalletaddress ,chartRef)}
    // type='line'
    // id="canvas"
    // data={this.state.data}
    // options={{
    // 	title: {
    // 		display: true,
    // 		text: 'Balance',
    // 		fontSize: 20
    // 	},
    // 	legend: {
    // 		display: true,
    // 		position: 'right'
    // 	},
    // }}
    // options={{ maintainAspectRatio: false }}
    />
  );
}