import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import { Leaderboard } from "./Leaderboard";
import { Testimonials } from "./Testimonials";
import { Footer } from "./Footer";

const TournamentPage = ({ connect }) => {
  const [tournamentDetails, setTournamentDetails] = useState({});
  const [timeRemaining, setTimeRemaining] = useState("00:00:00");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch("https://latf-paper-trading-backend.herokuapp.com/tournament/details")
      .then((res) => res.json())
      .then((data) => {
        setTournamentDetails(data);
        setIsLoading(false);
      })
      .catch((err) => console.error(err));
  }, []);

  const calculateTimeLeft = (endTime) => {
    const timeLeft = endTime - Math.floor(Date.now() / 1000);
    if (timeLeft < 0) return "00:00:00";
    const hours = Math.floor(timeLeft / 3600).toString().padStart(2, '0');
    const minutes = Math.floor((timeLeft % 3600) / 60).toString().padStart(2, '0');
    const seconds = (timeLeft % 60).toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining(calculateTimeLeft(tournamentDetails.endTime));
    }, 1000);

    return () => clearInterval(intervalId);  // Clear the interval when the component unmounts
  }, [tournamentDetails]);

  if (isLoading) return <div className="spinner"></div>;

  return (
    <>
      <Navbar {...{ connect }} />

      <div className="hero-section">
        <div className="overlay-text">
          <h1>Join the Ultimate Trading Tournament!</h1>
          <p>Compete globally and prove your trading prowess.</p>
          <button className="participate-button">Participate</button>
        </div>
      </div>

      <div className="grid-container text-totalWhite">
        <div className="grid-item">
          <i className="fee-icon"></i>
          <h2>${tournamentDetails.fee / 10 ** 18}</h2>
          <p>Entry Fee</p>
        </div>

        <div className="grid-item">
      <i className="time-icon"></i>
      <h2>{timeRemaining}</h2>
      <p className="">Time Remaining</p>
    </div>

        <div className="grid-item">
          <i className="prize-icon"></i>
          <h2>${tournamentDetails.totalParticipationFee / 10 ** 18}</h2>
          <p>Prize Pool</p>
        </div>

        <div className="grid-item">
          <i className="users-icon"></i>
          <h2>{tournamentDetails.participants?.length || 0}</h2>
          <p>Participants</p>
        </div>
      </div>

      <div className="chart-section justify-center text-center text-totalWhite">
        <h3>Leaderboard & Stats</h3>
        <Leaderboard />
      </div>

      <button className="participate-button">Join Now</button>

      <div className="testimonials">
        <h3>What Top Traders Say</h3>
        <Testimonials />
      </div>

      <Footer />
    </>
  );
};

export default TournamentPage;
