import React from "react";
import Web3 from "web3";
import { useEffect } from "react";

const SignUpInvite = ({ document }) => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const codeParam = urlParams.get("code");
    if (codeParam) {
      document.getElementById("code").value = codeParam;
      // change the input to disable
      document.getElementById("code").disabled = true;
    }
  }, []);

  return (
    <div
      id="sign-up-popup"
      className="absolute top-2/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 lg:w-2/4 w-5/6"
    >
      <div className="bg-grey-lighter flex flex-col">
        <div className="container bg-totalWhite bg-opacity-80 max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
          <div className="bg-totalWhite bg-opacity-80 px-6 py-8 rounded shadow-md text-black w-full">
            <h1 className="mb-8 text-4xl text-center font-semibold nordique text-gray-500">
              Sign <span className="text-blue-500">Up</span>
            </h1>
            <div className="mb-4">
              <input
                id="username"
                type="text"
                autoComplete="off"
                className="block border border-grey-light w-full p-3 rounded mb-4"
                name="username"
                placeholder="Username"
              />
              <div className="flex-2 relative">
                <input
                  id="walletaddress"
                  type="text"
                  className="block border border-grey-light w-full p-3 rounded"
                  name="walletaddress"
                  placeholder="Metamask Wallet Address"
                />
                <button
                  className="nordique text-lg absolute right-0 top-0 h-full px-3 bg-blue-500 text-white rounded-r hover:bg-blue-600"
                  onClick={fetchWalletAddress}
                >
                  Get Address
                </button>
              </div>
            </div>
            <div className="mb-4">
              <input
                id="email"
                type="text"
                className="block border border-grey-light w-full p-3 rounded"
                name="email"
                placeholder="Email"
              />
              <span className="block text-gray-700 italic text-sm">
                *If Latf member, please use the same email as your membership
                account.
              </span>
              <div className="mt-2 justify-center flex text-center">
                <div>
                  <label
                    htmlFor="code"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Referral Code
                  </label>
                  <input
                    id="code"
                    type="text"
                    className="block border border-grey-light w-30 p-2 rounded text-center"
                    name="code"
                    placeholder="Referral Code (Optional)"
                  ></input>
                </div>
              </div>
              <div className="px-1 py-1 flex items-center flex-col">
                <label
                  htmlFor="termsCheckbox"
                  className="text-gray-600 mt-2 mb-1"
                >
                  I accept the{" "}
                </label>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="termsCheckbox"
                    className="w-5 h-5 mr-2 hover:scale-110"
                  />
                  <label htmlFor="termsCheckbox" className="text-gray-600">
                    Terms and conditions
                  </label>
                </div>
                <details className="mt-2">
                  <summary className="text-blue-400 hover:text-blue-500 underline py-2 px-2">
                    Terms and conditions
                  </summary>
                  <span className="block text-gray-700 italic text-xs">
                    THIS IS A BETA TEST. THIS BETA TEST MAY CONTAIN ONE OR MORE
                    BETA SERVICES (THE "SERVICES") PROVIDED BY TRADINGARENA.IO
                    (“TRADING ARENA" "WE," OR "US"). BY AGREEING TO PARTICIPATE
                    IN THE BETA TEST YOU UNDERSTAND THAT THE SERVICES MAY BE
                    CANCELED AT ANY TIME FOR ANY OR NO REASON, IN TRADING
                    ARENA'S SOLE DISCRETION, WITHOUT ADVANCE NOTICE OR LIABILITY
                    TO TRADING ARENA. BY AGREEING TO PARTICIPATE IN THE BETA
                    TEST YOU UNDERSTAND THE SERVICES AND FUNCTIONALITY MAY NOT
                    WORK AS INTENDED OR DESCRIBED.
                    <br />
                    <br />
                    By participating in this beta test or using the Services,
                    you acknowledge and understand that the use of the hardware
                    wallets MetaMask is mandatory. Trading Arena is not
                    responsible for any vulnerabilities or exploits related to
                    the use of MetaMask. WE STRONGLY RECOMMEND THAT YOU LINK
                    ONLY NEW AND EMPTY METAMASK WALLETS TO OUR PLATFORM TO
                    MINIMIZE THE RISK OF LOSS OR THEFT OF YOUR DIGITAL ASSETS.
                    Please ensure that you follow all security best practices
                    when using MetaMask. By participating in this beta test or
                    using the Services, you accept full responsibility for any
                    risks associated with the use of MetaMask. MetaMask retains
                    all rights proprietary to MetaMask, and your use of MetaMask
                    is subject to compliance with all the applicable MetaMask
                    terms and conditions.
                    <br />
                    <br />
                    NEITHER THE WEBSITE, THE BETA TEST, THE SERVICES, NOR
                    TRADING ARENA OFFERS, SOLICITS OR ARRANGES FOR THE SALE OR
                    PURCHASE OF SECURITIES. THE WEBSITE, BETA TEST, AND SERVICES
                    ARE PART OF A MARKET SIMULATION. NO ACTUAL SECURITIES ARE
                    PURCHASED, SOLD OR TRADED ON THE WEBSITE, OR AS PART OF THIS
                    BETA TEST OR THE SERVICES. ALL INFORMATION IS PROVIDED FOR
                    ENTERTAINMENT, EDUCATIONAL, AND RECREATIONAL PURPOSES ONLY.
                  </span>
                </details>
              </div>
            </div>
            <button
              id="create-button"
              onClick={handleSignup}
              type="submit"
              className="nordique font-semibold text-xl w-full text-center py-3 rounded bg-blue-500 text-white hover:bg-blue-600 focus:outline-none my-1"
            >
              Create Account
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpInvite;

async function fetchWalletAddress() {
  let web3;
  if (window.ethereum) {
    web3 = new Web3(window.ethereum);
    try {
      await window.ethereum.enable();
    } catch (error) {
      console.error(error);
    }
  } else if (window.web3) {
    web3 = new Web3(window.web3.currentProvider);
  } else {
    alert(
      "Metamask not detected. Please install Metamask and refresh the page."
    );
    return null;
  }
  const accounts = await web3.eth.getAccounts();
  if (!accounts.length) {
    alert(
      "No accounts found in Web3 wallet. Enter your wallet address manually."
    );
    return null;
  }
  document.getElementById("walletaddress").value = accounts[0];
  return 0;
}

// Send to backend all fields into the users table
async function signup(username, walletaddress, email, code) {
  await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/invite/signup?walletaddress=${walletaddress}&username=${username}&email=${email}&referreecode=${code}`
  )
    .then((response) => response.json())
    .then((data) => {
      if (data === 200) {
        // change create-button for Welcome !
        document.getElementById("create-button").innerHTML = "Welcome !";
        // wait 0.5s
        setTimeout(() => {}, 500);
      }
    });
}

async function handleSignup(e) {
  // check if the checkbox is filled. If not, send a message to the user
  if (!document.getElementById("termsCheckbox").checked) {
    alert("Please accept the terms and conditions");
    return;
  }
  e.preventDefault();
  const username = document.getElementById("username");
  const walletaddress = document.getElementById("walletaddress");
  const email = document.getElementById("email");
  // get code from url
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  if (username.value === "" || walletaddress.value === "" || email.value === "") {
    alert("Please enter all mandatory fields.");
    return;
  }
  try {
    await signup(username.value, walletaddress.value, email.value, code);
    window.location.href = "/trade";
  } catch {
    alert("Failed to create account");
  }
}
