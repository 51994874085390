import React, { useState } from "react";
import { DoughnutChartTopUp } from "./DoughnutChartTopUp";
import { getUserAddress } from "../config/connection_manager";

const Topup = ({ alert }) => {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <div className="grid-rows-6 h-3/7 rounded-xl bg-landingcompobg py-4 m-2">
      {showInfo && (
        <div className="fixed grid inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
          <div
            className="flex items-center rounded-3xl p-4 shadow-lg"
            style={{
              background:
                "linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, transparent 100%)",
            }}
          >
            <p className="text-gray-200 text-center place-self-center italic font-semibold">
              Users can top up their balance in order to reach 100k total
              assets.
            </p>
          </div>
        </div>
      )}
      <div className="row-span-4">
        <DoughnutChartTopUp />
        <div className="text-center row-span-2 relative">
        <button
            id="info-button"
            type="button"
            onMouseEnter={() => setShowInfo(true)}
            onMouseLeave={() => setShowInfo(false)}
            className="z-50 absolute top-0 right-0 p-2 text-gray-400 hover:text-gray-800 focus:outline-none"
          >
            <i class="material-icons">info</i>
          </button>
          <div className="text-2xl font-bold text-gray-100">
            Balance<span className="text-indigo"> Top Up</span>
            <span className="text-indigo text-md block"> $ 50,000</span>
          </div>
          <div>
            <span className="text-md font-bold text-gray-500 block">
              Count Down To Top Up
            </span>
            <span
              id="topup-countdown"
              className="text-md text-gray-100 italic"
            ></span>
          </div>
        </div>
      </div>
      <div className="row-span-2 text-center flex space-x-2 justify-center">
        <button
          id="claim-button"
          type="button"
          // call a function to see if the user is able to claim
          onClick={() => canClaim()}
          className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
        >
          Claim
        </button>
      </div>
    </div>
  );
};

export default Topup;

async function canClaim() {
  const walletaddress = await getUserAddress();
  await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/claim?walletaddress=${walletaddress}`
  )
    .then((response) => response.json())
    .then((data) => {
      console.log(data.message);
      if (data.status === 200) {
        alert("Successful claim.");
        window.location.reload();
      } else {
        alert(data.message);
        window.location.reload();
      }
    });
  return 0;
}
