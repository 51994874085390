import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useRef } from "react";
import load_archives from "./load_data_chart";
import Web3 from "web3";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  title: {
    display: true,
    text: "Tweets positivity",
    fontSize: 20,
  },
  legend: {
    display: true,
    position: "right",
  },
  interaction: {
    mode: "index",
    intersect: false,
  },
};

const labels = ["/", "/", "/"];

export const data = {
  labels,
  datasets: [
    {
      fill: true,
      label: "Tweets positivity",
      data: [2, 1, 1],
      borderColor: "#134E5E",
      backgroundColor: "#71B280",
    },
  ],
};

async function get_positivity(chart) {
  await fetch(
    `https://latf-paper-trading-backend.herokuapp.com/twittermemory `
  )
    .then((response) => response.json())
    .then((data) => {
      console.log(data)
      chart.current.data.datasets[0].data = [];
      chart.current.data.labels = [];
      let i = 0;
      while (i < data.length) {
        chart.current.data.datasets[0].data.push(data[i].positivity);
        var today = new Date();
        var date = new Date();
        // calculate : today - data.length + i days
        date.setDate(today.getDate() - data.length + i);
        // get format mm/dd/yyyy
        date = date.toLocaleDateString();
        chart.current.data.labels.push(date);
        i++;
      }
      chart.current.update();
    });
}

export default function DashGraph() {
  const chartRef = useRef();
  return (
    <Line
      id="canvas"
      options={options}
      data={data}
      ref={chartRef}
      onLoad={get_positivity(chartRef)}
    />
  );
}
