import React, { useState, useEffect } from "react";
import Web3 from "web3";
import { getAccount } from "@wagmi/core";
import { getUserAddress } from "../../config/connection_manager";

const UsersetMenu = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  var [items, setItems] = useState([]);
  const [showAddButton, setShowAddButton] = useState(true);
  const [newItem, setNewItem] = useState("");
  const walletAddress = localStorage.getItem("walletAddress");

  useEffect(() => {
    localStorage.setItem("setPage", 1);
  }, []);
  const handlePageChange = (pageNum) => {
    setCurrentPage(pageNum);
    localStorage.setItem("setPage", pageNum);
  };

  useEffect(() => {
    try {
      if (localStorage.getItem("isWalletConnected") === "true") {
        async function callingdb() {
          const addr = await getUserAddress();
          console.log("addr userset", addr);
          await fetch(
            `https://latf-paper-trading-backend.herokuapp.com/balance?walletaddress=${addr}`
          )
            .then((response) => response.json())
            .then((data) => {
              console.log("data userset", data)
              const array = JSON.parse(data.userset);
              const itemsPerPage = 8;
              if (currentPage === 1) {
                setItems(JSON.parse(data.userset));
              } else if (currentPage === 2) {
                setItems(JSON.parse(data.userset2));
              } else if (currentPage === 3) {
                setItems(JSON.parse(data.userset3));
              }
            });
        }
        callingdb();
      }
    } catch (error) {
      console.log(error);
    }
  }, [currentPage, data]);

  const handleDeleteItem = (itemToDelete, page) => {
    setItems(items.filter((item) => item !== itemToDelete));
    items = items.filter((item) => item !== itemToDelete);
    const jsonItems = JSON.stringify(items);
    console.log(walletAddress);
    console.log(jsonItems);
    fetch(
      `https://latf-paper-trading-backend.herokuapp.com/set${page}/edit?walletaddress=${walletAddress}&userset=${jsonItems}`
    )
      .then((response) => response.json())
      .then((data) => {});
  };
  return (
    <>
      <div className="rounded-lg border-1 border-gray-500 bg-gray-700">
        {currentPage === 1 && (
          <Page1
            items={items}
            setItems={setItems}
            handleDeleteItem={handleDeleteItem}
          />
        )}
        {currentPage === 2 && (
          <Page2
            items={items}
            setItems={setItems}
            handleDeleteItem={handleDeleteItem}
          />
        )}
        {currentPage === 3 && (
          <Page3
            items={items}
            setItems={setItems}
            handleDeleteItem={handleDeleteItem}
          />
        )}
      </div>
      <div className="grid grid-cols-3 w-full px-2">
        <button
          id="btn-p1"
          className={
            currentPage === 1
              ? "text-blue-400"
              : "text-gray-200 hover:text-gray-400"
          }
          onClick={() => handlePageChange(1)}
        >
          System 1
        </button>
        <button
          id="btn-p2"
          className={
            currentPage === 2
              ? "text-blue-400"
              : "text-gray-200 hover:text-gray-400"
          }
          onClick={() => handlePageChange(2)}
        >
          System 2
        </button>
        <button
          id="btn-p3"
          className={
            currentPage === 3
              ? "text-blue-400"
              : "text-gray-200 hover:text-gray-400"
          }
          onClick={() => handlePageChange(3)}
        >
          System 3
        </button>
      </div>
    </>
  );
};

const Page1 = ({ items, setItems, handleDeleteItem }) => {
  const leftItems = items.slice(0, 4);
  const rightItems = items.slice(4);
  const [showAddButton, setShowAddButton] = useState(false);
  const [showPlusButton, setShowPlusButton] = useState(false);
  const [newItem, setNewItem] = useState("");
  const walletAddress = localStorage.getItem("walletAddress");

  useEffect(() => {
    if (items.length >= 8) {
      setShowPlusButton(false);
    } else {
      setShowPlusButton(true);
    }
  }, [items]);

  const handleAddItem = () => {
    if (items.length < 8) {
      setItems([...items, newItem]);
      setNewItem("");
      const jsonItems = JSON.stringify([...items, newItem]);
      fetch(
        `https://latf-paper-trading-backend.herokuapp.com/set/edit?walletaddress=${walletAddress}&userset=${jsonItems}`
      )
        .then((response) => response.json())
        .then((data) => {});
    }
  };

  return (
    <>
      <ul
        id="set1"
        className="grid grid-cols-2 w-full text-sm font-medium text-gray-900"
      >
        {items.map((item, index) => (
          <li
            key={index}
            className="w-full rounded-t-lg border-gray-500 self-center rounded-lg hover:bg-gray-800"
          >
            <div className="flex items-center pl-3">
              <input
                id={`${item}-checkbox`}
                type="checkbox"
                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-blue-600 ring-offset-gray-700 focus:ring-2 bg-gray-600 border-gray-500"
              />
              <label
                htmlFor={`${item}-checkbox`}
                className="py-2 ml-2 w-full text-sm font-medium text-gray-300"
              >
                {item}
              </label>
              <button
                className="btn-close"
                onClick={() => handleDeleteItem(item, "")}
              ></button>
            </div>
          </li>
        ))}
        {showPlusButton && (
          <li className="text-xl text-blue-400 place-content-center flex justify-center">
            <button onClick={() => setShowAddButton(!showAddButton)}>+</button>
          </li>
        )}
      </ul>
      {showAddButton && (
        <div className="bg-opacity-95 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 lg:w-1/4 w-5/6 bg-gray-800 rounded-lg shadow-lg p-4">
        <div class="mx-auto max-w-md">
          <div class="w-full px-6">
            <button
              onClick={() => setShowAddButton(!showAddButton)}
              class="close-link absolute top-4 right-4 no-underline text-totalWhite"
            >
              X
            </button>
            <label class="block mb-2 font-bold text-gray-200 font-semibold">
              New item:
            </label>
            <input
            type="text"
            value={newItem}
            onChange={(e) => setNewItem(e.target.value)}
            className="p-2 rounded-lg bg-white"
            ></input>
            <button
              className="mx-auto mt-2 px-4 py-2 font-bold text-white bg-blue-500 rounded-full hover:bg-blue-700 focus:outline-none focus:shadow-outline flex"
              onClick={handleAddItem}
            >
              Save
            </button>
          </div>
        </div>
      </div>
      )}
    </>
  );
};

const Page2 = ({ items, setItems, handleDeleteItem }) => {
  const leftItems = items.slice(0, 4);
  const rightItems = items.slice(4);
  const [showAddButton, setShowAddButton] = useState(false);
  const [showPlusButton, setShowPlusButton] = useState(false);
  const [newItem, setNewItem] = useState("");
  const walletAddress = localStorage.getItem("walletAddress");

  useEffect(() => {
    if (items.length >= 8) {
      setShowPlusButton(false);
    } else {
      setShowPlusButton(true);
    }
  }, [items]);

  const handleAddItem = () => {
    if (items.length < 8) {
      setItems([...items, newItem]);
      setNewItem("");
      const jsonItems = JSON.stringify([...items, newItem]);
      fetch(
        `https://latf-paper-trading-backend.herokuapp.com/set/two/edit?walletaddress=${walletAddress}&userset=${jsonItems}`
      )
        .then((response) => response.json())
        .then((data) => {});
    }
  };
  return (
    <>
      <ul
        id="set2"
        className="grid grid-cols-2 w-full text-sm font-medium text-gray-900"
      >
        {items.map((item, index) => (
          <li
            key={index}
            className="w-full rounded-t-lg border-gray-500 self-center rounded-lg hover:bg-gray-800"
          >
            <div className="flex items-center pl-3">
              <input
                id={`${item}-checkbox`}
                type="checkbox"
                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-blue-600 ring-offset-gray-700 focus:ring-2 bg-gray-600 border-gray-500"
              />
              <label
                htmlFor={`${item}-checkbox`}
                className="py-2 ml-2 w-full text-sm font-medium text-gray-300"
              >
                {item}
              </label>
              <button
                className="btn-close"
                onClick={() => handleDeleteItem(item, "/two")}
              ></button>
            </div>
          </li>
        ))}
        {showPlusButton && (
          <li className="text-xl text-blue-400 place-content-center flex justify-center">
            <button onClick={() => setShowAddButton(!showAddButton)}>+</button>
          </li>
        )}
      </ul>
      {showAddButton && (
        <div className="bg-opacity-95 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 lg:w-1/4 w-5/6 bg-gray-800 rounded-lg shadow-lg p-4">
        <div class="mx-auto max-w-md">
          <div class="w-full px-6">
            <button
              onClick={() => setShowAddButton(!showAddButton)}
              class="close-link absolute top-4 right-4 no-underline text-totalWhite"
            >
              X
            </button>
            <label for="note" class="block mb-2 font-bold text-gray-200 font-semibold">
              New item:
            </label>
            <input
            type="text"
            value={newItem}
            onChange={(e) => setNewItem(e.target.value)}
            className="p-2 rounded-lg bg-white"
            ></input>
            <button
              className="mx-auto mt-2 px-4 py-2 font-bold text-white bg-blue-500 rounded-full hover:bg-blue-700 focus:outline-none focus:shadow-outline flex"
              onClick={handleAddItem}
            >
              Save
            </button>
          </div>
        </div>
      </div>
      )}
    </>
  );
};

const Page3 = ({ items, setItems, handleDeleteItem }) => {
  const leftItems = items.slice(0, 4);
  const rightItems = items.slice(4);
  const [showAddButton, setShowAddButton] = useState(false);
  const [showPlusButton, setShowPlusButton] = useState(false);
  const [newItem, setNewItem] = useState("");
  const walletAddress = localStorage.getItem("walletAddress");

  useEffect(() => {
    if (items.length >= 8) {
      setShowPlusButton(false);
    } else {
      setShowPlusButton(true);
    }
  }, [items]);

  const handleAddItem = () => {
    if (items.length < 8) {
      setItems([...items, newItem]);
      setNewItem("");
      const jsonItems = JSON.stringify([...items, newItem]);
      fetch(
        `https://latf-paper-trading-backend.herokuapp.com/set/three/edit?walletaddress=${walletAddress}&userset=${jsonItems}`
      )
        .then((response) => response.json())
        .then((data) => {});
    }
  };
  return (
    <>
      <ul
        id="set3"
        className="grid grid-cols-2 w-full text-sm font-medium text-gray-900"
      >
        {items.map((item, index) => (
          <li
            key={index}
            className="w-full border-gray-500 self-center rounded-lg hover:bg-gray-800"
          >
            <div className="flex items-center pl-3">
              <input
                id={`${item}-checkbox`}
                type="checkbox"
                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-blue-600 ring-offset-gray-700 focus:ring-2 bg-gray-600 border-gray-500"
              />
              <label
                htmlFor={`${item}-checkbox`}
                className="py-2 ml-2 w-full text-sm font-medium text-gray-300"
              >
                {item}
              </label>
              <button
                className="btn-close"
                onClick={() => handleDeleteItem(item, "/three")}
              ></button>
            </div>
          </li>
        ))}
        {showPlusButton && (
          <li className="text-xl text-blue-400 place-content-center flex justify-center">
            <button onClick={() => setShowAddButton(!showAddButton)}>+</button>
          </li>
        )}
      </ul>
      {showAddButton && (
        <div className="bg-opacity-95 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 lg:w-1/4 w-5/6 bg-gray-800 rounded-lg shadow-lg p-4">
        <div class="mx-auto max-w-md">
          <div class="w-full px-6">
            <button
              onClick={() => setShowAddButton(!showAddButton)}
              class="close-link absolute top-4 right-4 no-underline text-totalWhite"
            >
              X
            </button>
            <label for="note" class="block mb-2 font-bold text-gray-200 font-semibold">
              New item:
            </label>
            <input
            type="text"
            value={newItem}
            onChange={(e) => setNewItem(e.target.value)}
            className="p-2 rounded-lg bg-white"
            ></input>
            <button
              className="mx-auto mt-2 px-4 py-2 font-bold text-white bg-blue-500 rounded-full hover:bg-blue-700 focus:outline-none focus:shadow-outline flex"
              onClick={handleAddItem}
            >
              Save
            </button>
          </div>
        </div>
      </div>
      )}
    </>
  );
};

export default UsersetMenu;
