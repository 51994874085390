import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";
import { getUserAddress } from "../config/connection_manager";

const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
};

export class DoughnutChart extends Component {
  constructor(props) {
    super(props);
    this.chartReference = React.createRef();
    this.state = {
      name: "React",
      data: {
        labels: [
          "Initial",
          "LATF",
          "Global",
          "Achievements",
          "Early",
          "Twitter",
        ],
        datasets: [
          {
            data: [5, 7, 6, 0, 0, 0],
            backgroundColor: [
              "#f6e8c2",
              "#efb09b",
              "#e15b84",
              "#94308d",
              "#7ea5af",
              "#290a3d",
            ],
            borderWidth: 0, // <-- ajoutez cette ligne
            borderRadius: 1,
          },
        ],
      },
      initial: 100000,
      latf: 0,
      global: 0,
      achievements: 0,
      early: 200000,
      twitter: 0,
    };
  }

  componentDidMount() {
    this.manage_balance_doughnut(this.chartReference);
  }  

  manage_balance_doughnut = async (chartReference) => {
    const walletaddress = await getUserAddress();

    const dynamic_balance = await fetch(
      "https://latf-paper-trading-backend.herokuapp.com/user/dynamicbalance?walletaddress=" +
        walletaddress
    )
      .then((response) => response.json())
      .then((data) => {
        if (data === 400) {
          return 0;
        }
        return data;
      });
    const PnL = dynamic_balance - 100000;
    // fetch multiplicator
    const global = PnL * this.props.multiplicator;
    await fetch(
      `https://latf-paper-trading-backend.herokuapp.com/balance?walletaddress=${walletaddress}`
    )
      .then((response) => response.json())
      .then((data_fetched) => {
        const latf_duration = data_fetched.latf_duration;
        const initial = 100000 * this.props.multiplicator;
        const latf = 100000 * latf_duration * this.props.multiplicator;
        const achievements = 0 * this.props.multiplicator;
        const early = 200000 * this.props.multiplicator;
        const twitter = 0 * this.props.multiplicator;
        this.setState({
          initial: initial,
          latf: latf,
          global: Math.round(global),
          achievements: achievements,
          early: early,
          twitter: twitter,
        });
        if (chartReference.current) {
          // DOUGHNUT VALUES ARE UPDATED HERE
          chartReference.current.$context.chart.config._config.data.datasets[0].data =
            [initial, latf, global, achievements, early, twitter];
          console.log(
            "updating doughnut partition",
            chartReference.current.$context.chart.config._config.data
              .datasets[0].data
          );
          chartReference.current.$context.chart.update();
        }
      });
  };

  render() {
    return (
      <div className="grid-rows-6 h-3/7 rounded-xl bg-landingcompobg py-4 m-2">
        <div className="row-span-3">
          <div className="outer-div">
            <div className="">
              <Doughnut
                ref={this.chartReference}
                data={this.state.data}
                options={options}
                width="300"
                height="150"
              />
            </div>
            <div className="inner-div">
              <i className="material-icons text-gray-100">lock_open</i>
            </div>
          </div>
        </div>
        <div className="text-center row-span-1">
          <div className="text-2xl font-bold text-gray-100">
            Starting
            <span className="text-green-400"> Balance</span>
          </div>
        </div>
        <div className="text-center row-span-2">
          <div className="font-bold text-gray-100 grid grid-rows-7">
            <div>
              <span id="starting-balance" className="text-green-400 text-md">
                {" "}
                $
                {this.state.initial +
                  this.state.latf +
                  this.state.global +
                  this.state.achievements +
                  this.state.early +
                  this.state.twitter}
              </span>
            </div>
            <div className="text-sm">
              <span className="text-gray-500">Initial Balance: +$</span>
              <span id="initial-balance" className="text-gray-500">
                {this.state.initial}
              </span>
            </div>
            <div id="div-early" className="text-sm">
              <span className="text-gray-500">Early Sign Up: +$</span>
              <span id="early-balance" className="text-gray-500">
                {this.state.early}
              </span>
            </div>
            <div id="div-latf" className="text-sm">
              <span className="text-gray-500">LATF Member: +$</span>
              <span id="latf-balance" className="text-gray-500">
                {this.state.latf}
              </span>
            </div>
            <div id="div-global" className="text-sm">
              <span className="text-gray-500">Global Rank: +$</span>
              <span id="global-balance" className="text-gray-500">
                {this.state.global}
              </span>
            </div>
            <div id="div-achievements" className="text-sm">
              <span className="text-gray-500">Achievements: +$</span>
              <span id="achievements-balance" className="text-gray-500">
                {this.state.achievements}
              </span>
            </div>
            <div id="div-twitter" className="text-sm">
              <span className="text-gray-500">Twitter Interaction: + $</span>
              <span id="twitter-balance" className="text-gray-500">
                {this.state.twitter}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

async function set_global_multiplier(walletaddress) {
  const rank_details = await fetch(
    "https://latf-paper-trading-backend.herokuapp.com/user/rank/leaderboard?walletaddress=" +
      walletaddress
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  const rank = rank_details.rank;
  const tournament_users_length = rank_details.length;
  const percentage_rank = (rank / tournament_users_length) * 100;
  if (rank === 1) {
    return 50;
  } else if (rank === 2) {
    return 30;
  } else if (rank === 3) {
    return 20;
  } else if (percentage_rank <= 10) {
    return 10;
  } else if (percentage_rank <= 25) {
    return 5;
  } else if (percentage_rank <= 50) {
    return 2;
  } else {
    return 0;
  }
}
