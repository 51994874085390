import React, { useState, useEffect } from "react";
import DropdownMenu from "./DropdownMenu";
import BugReport from "./BugReport";
import MetamaskModal from "./MetamaskModal";
import { connectWallet, getUserAddress } from "../config/connection_manager";

const Navbar = ({ connect }) => {
  const [showBugReport, setShowBugReport] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // on load, check if user is connected to metamask
  useEffect(() => {
    async function get_address() {
      if (localStorage.getItem("isWalletConnected") === "true") {
        try {
          const addr = await getUserAddress();
          const addr_display =
            addr.substring(0, 4) +
            "..." +
            addr.substring(addr.length - 4, addr.length);
          console.log("addr === ", addr);
          document.getElementById("connect-span").innerHTML = addr_display;
          document
            .getElementById("connect-btn")
            .classList.add("bg-color-animation");
          document
            .getElementById("connect-btn")
            .classList.add("hover:scale-95");
          document.getElementById("connect-btn").classList.remove("nordique");
        } catch (error) {
          console.error(error);
          localStorage.setItem("isWalletConnected", false);
          var button = document.getElementById("connect-btn");
          button.classList.add("show-bubbles");
          document.getElementById("connect-span").innerHTML = "Connect";
        }
      } else {
        document.getElementById("connect-span").innerHTML = "Connect";
        var button = document.getElementById("connect-btn");
        button.classList.add("show-bubbles");
      }
    }
    get_address();
  });

  function openModal() {
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
  }

  async function connect_metamask() {
    connectWallet();
  }

  return (
    <div className="bg-normalbg border-b-4 border-black">
      <div className="bg-yellow-300 text-totalWhite py-1 mb-1 text-center text-sm italic">
        Thanks for participating in the beta <span id="username-banner"></span>!
        Please report any bugs by clicking on the fIag icon. We appreciate your
        feedback and will be pushing an update soon.{" "}
      </div>
      <meta charSet="utf-8" />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      ></link>
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
        crossOrigin="anonymous"
      />
      <script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
        crossOrigin="anonymous"
      />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0"
      />
      <title>LATF Paper Trading</title>
      <div className="h-fit">
        <nav className="navbar navbar-expand-lg navbar-dark bg-backgroundbase p-1">
          <div className="container-fluid bg-backgroundbase">
            {/* create a button that display BugReport on click */}
            <button onClick={() => setShowBugReport(true)}>
              <div className="report-div absolute top-2 left-2">
                <div class="info">
                  <i class="material-icons text-yellow hover:scale-110">
                    flag_circle
                  </i>
                  <span class="extra-info text-totalWhite font-semibold">
                    Report a bug
                  </span>
                </div>
              </div>
            </button>
            {showBugReport && (
              <div className="z-20 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <BugReport onClose={() => setShowBugReport(false)} />
              </div>
            )}
            <a className="navbar-brand ml-7 animate-float mt-1" href="/">
              <img
                src="/images/logo-TA10.png"
                style={{ width: "250px" }}
                alt="TA Logo"
                height="35"
                width="320"
              ></img>
            </a>
            <div
              className={"flex flex-grow items-center"}
              id="example-navbar-danger"
            >
              <ul className="navbar-nav ms-auto ">
                <li className="nav-item z-10">
                  <button
                    id="connect-btn"
                    className="bubbles-animation hover:bg-blue-600 px-4 py-2 bg-color-animation nordique text-semibold text-totalWhite rounded-full relative"
                    onClick={openModal}
                  >
                    <span id="connect-span" className="font-semibold">
                      <div role="status">
                        <svg
                          aria-hidden="true"
                          class="w-6 h-6 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span class="sr-only">Loading...</span>
                      </div>
                    </span>
                  </button>
                  {showModal && (
                    <MetamaskModal
                      walletconnect_connect={connect}
                      connect={connect_metamask}
                      closeModal={closeModal}
                      connector={connect}
                    />
                  )}
                </li>
              </ul>

              <ul className="navbar-nav ms-auto space-x-10 d-flex align-items-center Nordique font-bold">
                <li className="nav-item nav-lg">
                  <a
                    id="home-navbar"
                    href="/"
                    className={`text-gray-200  no-underline text-lg border-gray-300 ${
                      window.location.href.includes("trade")
                        ? "gradient-text"
                        : ""
                    }`}
                  >
                    Trade
                  </a>
                </li>
                <li className="nav-item nav-lg relative">
                  <a
                    id="home-navbar"
                    href="/pvp"
                    className={`text-gray-200 no-underline text-lg border-gray-300 ${
                      window.location.href.includes("pvp")
                        ? "gradient-text"
                        : ""
                    }`}
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    PvP
                    <span className="flex-grow" />{" "}
                    <span className="bg-red-400 text-white px-1 py-0.5 text-xs rounded absolute bottom-5 left-6">
                      New
                    </span>
                  </a>
                </li>

                <li className="nav-item nav-lg">
                  <div className="dropdown">
                    <div
                      id="leaderboard-navbar"
                      className={`${
                        window.location.href.includes("leaderboard")
                          ? "gradient-text"
                          : ""
                      } dropbtn text-gray-200 no-underline border-gray-300 text-lg`}
                    >
                      <a id="link-leaderboard-navbar">Leaderboard</a>
                    </div>
                    <div className="dropdown-content">
                      <a
                        href="/leaderboard"
                        className="text-gray-200  text-sm"
                        id="global-navbar"
                      >
                        Global
                        <i className="material-icons inline-block align-bottom">
                          {" "}
                          leaderboard
                        </i>
                      </a>
                      <a
                        id="twitter-navbar"
                        href="/leaderboardtw"
                        className="text-gray-200 text-sm"
                      >
                        Twitter
                      </a>
                    </div>
                  </div>
                </li>
                <li className="nav-item nav-lg">
                  <a
                    id="dashboard-navbar"
                    href="/dashboard"
                    className={`${
                      window.location.href.includes("dashboard")
                        ? "gradient-text"
                        : ""
                    } text-gray-200  no-underline text-lg border-gray-300`}
                  >
                    Dashboard
                  </a>
                </li>
                <li className="nav-item nav-lg">
                  <a
                    id="community-navbar"
                    href="/posts/discover"
                    className={`${
                      window.location.href.includes("/posts/discover")
                        ? "gradient-text"
                        : ""
                    } text-gray-200  no-underline text-lg border-gray-300`}
                  >
                    Community
                  </a>
                </li>
                <li className="nav-item nav-lg">
                  <a
                    id="profile-navbar"
                    href={`/profile?userid=${window.btoa(
                      localStorage.getItem("walletAddress") ||
                        "MFhGQzNDMUE5MjkwRjgwRUM5OUJFQ0UzNTNBQjEyMDAxRDRDMjM3QUQz"
                    )}`}
                    className={`${
                      window.location.href.includes("profile")
                        ? "gradient-text"
                        : ""
                    } text-gray-200 no-underline text-lg border-gray-300`}
                  >
                    Prof&zwnj;ile
                  </a>
                </li>

                <li className="z-10 nav-item nav-lg py-2 px-2 bg-bbbglightplus rounded-lg border-1 border-gray-600">
                  <div>
                    <span className="text-totalWhite">Balance:</span>
                    <div className="flex relative inline-block float-right">
                      <span id="balance" className="ml-2"></span>{" "}
                      <img
                        className="w-6 h-6 ml-1 rounded-full"
                        src="/images/coins.png"
                      />
                    </div>
                  </div>
                  {window.location.pathname === "/trade" ? (
                    <div>
                      <span className="text-totalWhite">Total Assets:</span>
                      <div className="flex relative inline-block float-right">
                        <span id="total-assets" className="ml-2"></span>{" "}
                        <img
                          className="w-6 h-6 ml-1 rounded-full"
                          src="/images/coins.png"
                        />
                      </div>
                    </div>
                  ) : null}
                </li>
                <li className="nav-item navdrop">
                  <DropdownMenu />
                </li>
              </ul>
            </div>
            <div className="place-items-center text-center">
              <div className=""></div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
