import React from "react";

const PostPerformances = ({
  toggleComments,
  commentsCount,
  handleReshare,
  isReshared,
  resharesCount,
  handleLike,
  isLiked,
  likesCount,
}) => (
  <div className="flex items-center space-x-4 mt-4">
    <button className="text-sm font-semibold" onClick={toggleComments}>
      <i className="material-icons py-1 text-gray-500">chat</i>
      <span className="text-gray-400">{commentsCount}</span>
    </button>
    <button className="text-sm font-semibold" onClick={handleReshare}>
      <i
        className={`material-icons py-1 ${
          isReshared ? "text-blue-500" : "text-gray-500"
        }`}
      >
        repeat
      </i>
      <span className="text-gray-400">{resharesCount}</span>
    </button>
    <button className="text-sm font-semibold" onClick={handleLike}>
      <i
        className={`material-icons py-1 ${
          isLiked ? "text-blue-500" : "text-gray-500"
        }`}
      >
        favorite
      </i>
      <span className="text-gray-400">{likesCount}</span>
    </button>
  </div>
);

export default PostPerformances;
