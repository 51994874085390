import React, { useState, useEffect } from "react";
import SignUp from "../SignUp";

const Navbar = ({ connect, balance, totalBalance, timeRemaining }) => {
  const [dots, setDots] = useState("");

  useEffect(() => {
    if (timeRemaining === "NaN:NaN:NaN") {
      const interval = setInterval(() => {
        setDots((prevDots) => (prevDots.length < 3 ? prevDots + "." : ""));
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [timeRemaining]);
  return (
    <>
      <SignUp {...{ document }} />
      <meta charSet="utf-8" />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      ></link>
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
        crossOrigin="anonymous"
      />
      <script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
        crossOrigin="anonymous"
      />

      <meta
        name="viewport"
        content="width=device-width,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0"
      />
      <title>LATF Paper Trading</title>

      <div className="h-fit">
        <nav className="navbar navbar-expand-lg navbar-dark  p-1">
          <div className="container-fluid ">
            <a className="navbar-brand ml-7 animate-float" href="/">
              <img
                src="/images/logo-TA10.png"
                style={{ width: "250px" }}
                alt="TA Logo"
                height="35"
                width="320"
              ></img>
            </a>
            <div
              className={"flex flex-grow items-center justify-center"}
              id="example-navbar-danger"
            >
              <h1 className="text-2xl font-bold nordique">
                {timeRemaining === "NaN:NaN:NaN"
                  ? `Waiting for an opponent${dots}`
                  : timeRemaining}
              </h1>
            </div>
            <li className="z-10 nav-item nav-lg py-2 px-2 bg-bbbglightplus rounded-lg border-1 border-gray-600 nordique font-semibold">
              <div>
                <span className="text-totalWhite">Balance:</span>
                <div className="flex relative inline-block float-right">
                  <span id="battleBalance" className="ml-2">
                    {balance}
                  </span>{" "}
                  <img
                    className="w-6 h-6 ml-1 rounded-full"
                    src="/images/coins.png"
                  />
                </div>
              </div>
              <div>
                <span className="text-totalWhite">Total Assets:</span>
                <div className="flex relative inline-block float-right">
                  <span id="battle-total-assets" className="ml-2">
                    {totalBalance}
                  </span>{" "}
                  <img
                    className="w-6 h-6 ml-1 rounded-full"
                    src="/images/coins.png"
                  />
                </div>
              </div>
            </li>
            <div className="place-items-center text-center">
              <div className="text-totalWhite"></div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
